import React, { useState, useRef, useEffect } from 'react';
import { AppBar, Button, Grid, Slide, ThemeProvider, createTheme, useScrollTrigger, Box, Typography, Paper, IconButton } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { useScroll } from 'framer-motion';
import AudioManager from '../../components/Scenes/ArtCode/AudioManager';

import '../Home.css';
import Container from 'src/components/Container';
import SwipeIcon from '@mui/icons-material/Swipe';

import Topbar from '../../components/TopBar';
import Bottombar from '../../components/BottomBar';
import LoadingManager from '../../Render/LoadingManager';
import Projects from '../../components/Scenes/Projects/Projects';

import { useMediaQuery } from '@mui/material';
import { getAudioState, setAudioState, toggleAudioState } from '../../utils/audioState';

const HideOnScroll = ({ children }: any) => {
  const trigger = useScrollTrigger();
  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
};

export enum ContentPanel {
  CONTENT_STAGE_HIDDEN,
  CONTENT_STAGE_PART_1,
  CONTENT_STAGE_PART_2,
  CONTENT_STAGE_PART_3
}

const ProjectsPage: React.FC = () => {
  const [contentValue, setContentValue] = useState<ContentPanel>(ContentPanel.CONTENT_STAGE_HIDDEN);
  const [infoValue, setInfoValue] = useState<ContentPanel>(ContentPanel.CONTENT_STAGE_HIDDEN);
  const [buttonInfo, setButtonInfo] = useState<string>('main');
  const [showOverlay, setShowOverlay] = useState(false);
  const [showButtons, setShowButtons] = useState(false);
  const [showInfoPopup, setShowInfoPopup] = useState(false);
  const [showCodePopup, setShowCodePopup] = useState(false);
  const [isSectionActive, setIsSectionActive] = useState(false);
  const exploreHintRef = useRef<HTMLDivElement>(null); // Add ref for explore hint

  const voyageRef = useRef<any>(null);

  const theme = createTheme({
    palette: {
      primary: {
        main: '#FFFFFF',
        contrastText: '#000000',
      },
      secondary: {
        main: '#000000'
      },
    }
  });

  const buttonColors = {
    fireworksRight: '#00000011',
    parachute: '#00000011',
    helicopter: '#00000011',
    p3: '#00000011',
    p4: '#00000011',
  };

  const OpenContent = (content: ContentPanel) => {
    setContentValue(content);
  }

  const CloseContent = () => {
    setInfoValue(ContentPanel.CONTENT_STAGE_HIDDEN);
    OpenContent(ContentPanel.CONTENT_STAGE_HIDDEN);
  }

  const toggleOverlay = () => {
    setShowOverlay(!showOverlay);
  }

  const { scrollYProgress } = useScroll();

  const handleLeftClick = () => {
    if (voyageRef.current) {
      voyageRef.current.changeImage('prev');
    }
  };

  const handleRightClick = () => {
    if (voyageRef.current) {
      voyageRef.current.changeImage('next');
    }
  };
  const handleButtonClick = (action: string) => {
    if (voyageRef.current && voyageRef.current[action]) {
      voyageRef.current[action]();
    }
  };
  const handleInfoButtonClick = () => {
    setShowInfoPopup(true);
    setIsSectionActive(true);
    if (voyageRef.current) {
      voyageRef.current.setSectionActive(true); // Update the state within Voyage
    }
  };
  
  const handleCodeButtonClick = () => {
    setShowCodePopup(true);
    setIsSectionActive(true);
    if (voyageRef.current) {
      voyageRef.current.setSectionActive(true); // Update the state within Voyage
    }
  };
  
  const closePopup = () => {
    setShowInfoPopup(false);
    setShowCodePopup(false);
    setIsSectionActive(false);
    if (voyageRef.current) {
      voyageRef.current.setSectionActive(false); // Reset the state within Voyage
    }
  };
  useEffect(() => {
  }, [isSectionActive]);


  const isLandscape = useMediaQuery('(orientation: landscape)');

  const history = useHistory();

  const handleBackClick = () => {
    window.location.href = '/artcode';
  };

  const [isSoundOn, setIsSoundOn] = useState(getAudioState());
  const projectsRef = useRef<typeof Projects>(null);

  const toggleSound = () => {
    const newState = toggleAudioState();
    setIsSoundOn(newState);
    if (projectsRef.current) {
      //projectsRef.current.toggleSound(newState);
    }
  };

  return (
    <div className="pageGlobal">
      <LoadingManager />

      <Grid container style={{ position: 'fixed', zIndex: -1 }}>
      <Projects
  ref={voyageRef}
/>
      </Grid>

      <HideOnScroll>
      <AppBar
                  position={'fixed'}
                  sx={{
                    boxShadow: 'none',
                    backgroundColor: '#00000000',
                    zIndex: 1100, // Make sure this is lower than the sound button's z-index
                  }}
                >
                  <Container  sx={{ py: { xs: 2, sm: 4 } }}>
                  <Topbar 
                    artcodeColor="#ffffff" 
                    isSoundOn={isSoundOn}
                    toggleSound={toggleSound}
                  />
                  </Container>
                </AppBar>
      </HideOnScroll>

      <AppBar position={'fixed'} sx={{ top: 'auto', bottom: 0, boxShadow: 'none', backgroundColor: '#00000000' }}>
        <Bottombar />
      </AppBar>

      <Grid container item direction="column" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%', overflowY: 'scroll' }}>
      {!isSectionActive && isLandscape && (
  <>
    <Box
      sx={{
        position: 'fixed',
        top: '15%',
        left: '10%',
        zIndex: 2,
        backgroundColor: 'rgba(1, 1, 1, 0.00)',
        padding: '8px 16px',
        borderRadius: '8px',
     
      }}
    >

    </Box>

  </>
)}

        {infoValue === ContentPanel.CONTENT_STAGE_HIDDEN && contentValue !== ContentPanel.CONTENT_STAGE_HIDDEN &&
          <Button
            variant='contained'
            onClick={() => {
              setInfoValue(contentValue);
              setContentValue(ContentPanel.CONTENT_STAGE_HIDDEN);
            }}
            sx={{
              position: 'fixed',
              justifyContent: 'center',
              alignItems: 'center',
              bottom: 100,
              mb: '30px',
              height: '40px',
              fontFamily: 'DMSans',
              fontSize: '1.0rem',
              fontWeight: '100',
              color: '#000000',
              backgroundColor: '#ffffff',
              borderRadius: '30px',
              boxShadow: 'none',
            }}>
            Click to see {buttonInfo}
          </Button>
        }
     
        <Box component="footer" sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, textAlign: 'center', padding: 2 }}>
        </Box>
      </Grid>

      {showButtons && !isSectionActive && (
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
          sx={{
            position: 'fixed',
            top: '80%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 1,
          }}
        >
        
        </Grid>

      )}



      
    </div>
  );
};

export default ProjectsPage;
