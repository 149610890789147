import { UpgradeSharp } from "@mui/icons-material";

class UpgradeManager {
    constructor(scene) {
      this.scene=scene;
      this.reloadDuration=3000;
      this.ammoIncrease = 0;
      this.healthIncrease = 0;
      this.reloadSpeedIncrease = 0;
    }
  
    applyUpgrade(upgrade) {
      if (upgrade === 'Faster Reload') {
        this.reloadDuration -= 500; // Reduce reload time, min 1 second
      } else if (upgrade === 'Increased Ammo') {
        this.ammoIncrease += 2; // Increase ammo capacity
      } else if (upgrade === 'Increased Health') {
        this.scene.playerHealth  = Math.min(this.scene.playerHealth+50,100); // Increase health
      }
    }
    handleUpgradeSelection(upgrade) {
      this.applyUpgrade(upgrade);
      this.startNextWave();  // Start the next wave after upgrade selection
    }
    
  
    getReloadDuration() {
      return this.reloadDuration; // Return current reload duration
    }
  
    getAmmoBonus() {
      return this.ammoIncrease;
    }
  
    getHealthBonus() {
      return this.healthIncrease;
    }
  }
  export default UpgradeManager;