class Score {
  constructor(initialScore = 0) {
    this.currentScore = initialScore;
    this.highScore = this.loadHighScore();
  }

  // Increment the current score by a given number of points
  incrementScore(points) {
    this.currentScore += points;
    if (this.currentScore > this.highScore) {
      this.highScore = this.currentScore;
      this.saveHighScore();
    }
  }

  // Get the current score
  getCurrentScore() {
    return this.currentScore;
  }

  // Get the high score
  getHighScore() {
    return this.highScore;
  }

  // Reset the current score to zero
  resetScore() {
    this.currentScore = 0;
  }

  // Load the high score from local storage
  loadHighScore() {
    const savedHighScore = localStorage.getItem('stormTheRoofHighScore');
    return savedHighScore ? parseInt(savedHighScore, 10) : 0;
  }

  // Save the high score to local storage
  saveHighScore() {
    localStorage.setItem('stormTheRoofHighScore', this.highScore.toString());
  }
}

export default Score;
