import * as THREE from 'three';
import Model from '../../../../Render/Model';
import { Gunner } from "../Particles/BulletEmitter";
import gsap from 'gsap';
import { AtmosphericBackdropShader } from '../../../../Shaders/AtomsphericBackdropShader';

const COLLISION_LAYER = 2;

class Environment {
  constructor(scene, loader) {
    this.scene = scene;
    this.loader = loader;
    this.loadModels();
  }

  // Load all models
  loadModels() {
    this.loadBullets();
    this.loadBuilding();
    this.loadLogo();
    this.loadBackdrop();
  }

  // Load gunners
  loadGunners() {
    this.gunners = [
      new Gunner(this.scene, new THREE.Vector3(5, -5, 15)),
      new Gunner(this.scene, new THREE.Vector3(-5, 0, 15)),
      new Gunner(this.scene, new THREE.Vector3(-15, 0, -5)),
    ];
  }

  // Load bullets (currently empty)
  loadBullets() {
    // Implementation for loading bullets
  }

  // Load bomb model
  loadBomb() {
    const bombMaterial = new THREE.MeshStandardMaterial({
      color: '#ff0044',
      dithering: true,
    });

    this.bombModel = new Model({
      modelLink: 'bombModel',
      material: bombMaterial,
      position: new THREE.Vector3(0, 0, 0), // Hide initially by setting position off-screen
      rotation: new THREE.Vector3(0.0, 0.0, 0.0),
      scale: new THREE.Vector3(0, 0, 0),
      scene: this.scene,
      name: 'stormTheRoofModel'
    });

    this.bombModel.all_model[0].position.set(0, -100, 0);
    this.bombModel.all_model[0].scale.set(3, 3, 3);
  }

  // Load backdrop model
  loadBackdrop() {
    const standardMaterial = new THREE.ShaderMaterial({
      vertexShader: AtmosphericBackdropShader.vertexShader,
      fragmentShader: AtmosphericBackdropShader.fragmentShader,
      uniforms: THREE.UniformsUtils.clone(AtmosphericBackdropShader.uniforms),
      vertexColors: true // Enable vertex colors
    });

    const modelName = 'backdropModel';
    this.bgModel = new Model({
      modelLink: modelName,
      material: standardMaterial,
      position: new THREE.Vector3(0, 0, 0), // Hide initially by setting position off-screen
      rotation: new THREE.Vector3(0.0, 0.0, 0.0),
      scale: new THREE.Vector3(100, 100, 100),
      scene: this.scene,
      name: modelName
    });

    this.bgModel.all_model.forEach(element => {
      element.name = 'backdropModel';
      element.scale.set(20, 20, 20);
      element.position.set(-40, -20, 30);
      element.layers.set(COLLISION_LAYER);

      // Initialize vertex colors
      const geometry = element.geometry;
      const positionAttribute = geometry.getAttribute('position');
      const colors = new Float32Array(positionAttribute.count * 3);
      for (let i = 0; i < colors.length; i++) {
        colors[i] = 1.0; // Initialize all colors to white
      }
      geometry.setAttribute('color', new THREE.BufferAttribute(colors, 3));
    });
  }

  // Load logo model
  loadLogo() {
    const standardMaterial = new THREE.MeshPhongMaterial({
      emissive: 0xf29852,
      emissiveIntensity: 0.65,
      dithering: true,
    });

    const modelName = 'logoModel';
    this.logoModel = new Model({
      modelLink: modelName,
      material: standardMaterial,
      position: new THREE.Vector3(0, 0, 0), // Hide initially by setting position off-screen
      rotation: new THREE.Vector3(0.0, 0.0, 0.0),
      scale: new THREE.Vector3(0, 0, 0),
      scene: this.scene,
      name: 'logoModel'
    });
  }

  // Load building model
  loadBuilding() {
    const paletteTexture = this.loader.getAsset("stormTheRoofTexture");

    const standardMaterial = new THREE.MeshBasicMaterial({
      map: paletteTexture,
    });

    const modelName = 'stormTheRoofModel';
    this.p1model = new Model({
      modelLink: modelName,
      material: standardMaterial,
      position: new THREE.Vector3(0, 0, 0), // Hide initially by setting position off-screen
      rotation: new THREE.Vector3(0.0, 0.0, 0.0),
      scale: new THREE.Vector3(0, 0, 0),
      scene: this.scene,
      name: 'stormTheRoofModel'
    });

    this.p1model.all_model[0].position.set(0, -100, 0);
  }

  // Handle damage effect
  handleDamageEffect() {
    console.log("Taking damage");
    const redColor = new THREE.Color(0xff0000);
    const originalColor = new THREE.Color(0x595959);

    // Trigger the color change using GSAP
    gsap.to(this.skyMaterial.uniforms.bottomColor.value, {
      r: redColor.r,
      g: redColor.g,
      b: redColor.b,
      duration: 0.1,
      onComplete: () => {
        gsap.to(this.skyMaterial.uniforms.bottomColor.value, {
          r: originalColor.r,
          g: originalColor.g,
          b: originalColor.b,
          duration: 0.5,
          ease: "power2.out"
        });
      }
    });
  }

  // Update method
  update() {
    // Update gunners if needed
    // this.gunners.forEach(gunner => gunner.update());
  }
}

export default Environment;
