import * as THREE from 'three';
import { createVideoTexture } from './Helper';
import { detectDevice } from './Helper';
class ProjectFolio {
  constructor(scene, loader) {
    this.scene = scene;
    this.loader = loader;
    this.sections = [];
    this.wallDistance = -5.5; // Assuming this is the correct value based on your existing code
    
    this.videoTextures = {};
    const { video: mainVideo, videoTexture: mainVideoTexture } = createVideoTexture('/videos/project_folio.mp4');
    const { video: behindVideo, videoTexture: behindVideoTexture } = createVideoTexture('/videos/project_folio_behind.mp4');

    this.videoTextures.main = { video: mainVideo, videoTexture: mainVideoTexture };
    this.videoTextures.behind = { video: behindVideo, videoTexture: behindVideoTexture };
this.deviceType = detectDevice();
this.buttonTexture = loader.getAsset('openTexture');

  }

  createSections() {
    this.sections = [
      {
        height: 1.5,
        motionText: {
          text: 'ISO PORTFOLIO',
          font: '/fonts/Akira Super Bold.ttf',
          size: this.deviceType =='phone'? 0.75: 1.5,
          position: new THREE.Vector3(0, 0, this.wallDistance),
          color: 0xffffff,
          alignment: 'center',
          maxWidth: 12,
          lineHeight: 0.7
        }
      },
      {
        height: 5,
        motionText: {
          text: 'portfolio - 2024',
          font: '/fonts/RobotoMono.ttf',
          size: 0.25,
          position: new THREE.Vector3(0, 0, -5.5),
          color: 0xffffff,
          alignment: 'center',
          maxWidth: 9,
          lineHeight: 1.2
        }
      },
      {
        height: 4.5,
        motionMesh: {
          texture: this.videoTextures.main.videoTexture, 
          size: new THREE.Vector2(11, 7),
          position: new THREE.Vector3(0, 0, this.wallDistance),
          rotation: new THREE.Euler(0, 0, 0),
          subdivisions: new THREE.Vector2(64, 64)
        }
      },
      {
        height: 3,
        motionButton: {
          texture: this.buttonTexture,
          size: new THREE.Vector2(2, 0.8),
          position: new THREE.Vector3(0, 0, this.wallDistance),
          rotation: new THREE.Euler(Math.PI,0,  0),
          subdivisions: new THREE.Vector2(32, 32),
          link: 'https://theonteo.io/'
        }
      },
      {
        height: 5,
        motionText: {
          text: 'Iso is a portfolio website rendered in full 3D. Each section of the website is a different page. The website is rendered in full 3D and the user can navigate through the website by clicking on the different sections.',
          font: '/fonts/RobotoMono.ttf',
          size: 0.25,
          position: new THREE.Vector3(0, 0, -5.5),
          color: 0xffffff,
          alignment: 'center',
          maxWidth: 9,
          lineHeight: 1.2
        }
      },
      {
        height: 6,
        motionMesh: {
          texture: this.videoTextures.behind.videoTexture, // Use main video texture
          size: new THREE.Vector2(11, 7),
          position: new THREE.Vector3(0, 0, this.wallDistance),
          rotation: new THREE.Euler(0,0, 0),
          subdivisions: new THREE.Vector2(64, 64)
        }
      },
       {
        height: 5,
        motionText: {
          text: 'Prototyping and initial concept is first created through 3d models in blender, animations are animated by code in the website',
          font: '/fonts/RobotoMono.ttf',
          size: 0.25,
          position: new THREE.Vector3(0, 0, -5.5),
          color: 0xffffff,
          alignment: 'center',
          maxWidth: 9,
          lineHeight: 1.2
        }
      },
     
    ];

    return this.sections;
  }

  update() {
    // Ensure video plays
    if (this.videoTextures.main.video && this.videoTextures.main.video.paused) {
      this.videoTextures.main.video.play().catch(error => {
        console.log('Video playback failed:', error);
      });
    }
    if (this.videoTextures.behind.video && this.videoTextures.behind.video.paused) {
      this.videoTextures.behind.video.play().catch(error => {
        console.log('Video playback failed:', error);
      });
    }
  }
}

export default ProjectFolio;