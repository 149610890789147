import * as THREE from "three";

class ParticleEmitter {
    constructor(scene) {
        this.scene = scene;
        this.particleSystems = [];
        this.particleGeometry = new THREE.SphereGeometry(0.25, 5, 5);
        this.particleMaterial = new THREE.MeshBasicMaterial({
            color: 0xffffff,
            transparent: true,
            opacity: 0.8,
        });
    }

    // Spawns a new particle system at the given position with a specified count of particles
    spawn(position, count = 20) {
        const instancedMesh = new THREE.InstancedMesh(this.particleGeometry, this.particleMaterial, count);
        const dummy = new THREE.Object3D();
        const particles = [];

        for (let i = 0; i < count; i++) {
            const particle = this.createParticle(position);
            this.updateDummy(dummy, particle);
            instancedMesh.setMatrixAt(i, dummy.matrix);
            particles.push(particle);
        }

        instancedMesh.instanceMatrix.needsUpdate = true;
        this.scene.add(instancedMesh);
        this.particleSystems.push({ mesh: instancedMesh, particles });
    }

    // Creates a single particle with random position and velocity
    createParticle(position) {
        return {
            position: new THREE.Vector3(
                position.x + THREE.MathUtils.randFloatSpread(0.5),
                position.y + THREE.MathUtils.randFloatSpread(0.5),
                position.z + THREE.MathUtils.randFloatSpread(0.5)
            ),
            velocity: new THREE.Vector3(
                THREE.MathUtils.randFloatSpread(0.9),
                THREE.MathUtils.randFloat(0.3, 0.8),
                THREE.MathUtils.randFloatSpread(0.9)
            ),
            scale: new THREE.Vector3(1, 1, 1),
            lifetime: 600
        };
    }

    // Updates the dummy object with the particle's position and scale
    updateDummy(dummy, particle) {
        dummy.position.copy(particle.position);
        dummy.scale.copy(particle.scale);
        dummy.updateMatrix();
    }

    // Updates all particle systems, removing any that have no alive particles
    update() {
        this.particleSystems.forEach((system, sysIndex) => {
            const { mesh, particles } = system;
            const dummy = new THREE.Object3D();
            let aliveParticles = 0;

            particles.forEach((particle, index) => {
                if (particle.lifetime > 0) {
                    this.updateParticle(particle);
                    this.updateDummy(dummy, particle);
                    mesh.setMatrixAt(aliveParticles++, dummy.matrix);
                }
            });

            mesh.count = aliveParticles;
            mesh.instanceMatrix.needsUpdate = true;

            if (aliveParticles === 0) {
                this.scene.remove(mesh);
                this.particleSystems.splice(sysIndex, 1);
            }
        });
    }

    // Updates a single particle's position, velocity, scale, and lifetime
    updateParticle(particle) {
        particle.velocity.y -= 0.02;
        particle.position.add(particle.velocity);
        particle.scale.multiplyScalar(0.95);
        particle.lifetime--;
    }

    // Removes all particle systems from the scene
    remove() {
        this.particleSystems.forEach(system => this.scene.remove(system.mesh));
        this.particleSystems = [];
    }
}

export default ParticleEmitter;
