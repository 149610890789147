import * as THREE from "three";

const HeightCutoffShader = {
  uniforms: {
    imageTex: { value: null },
    cutoffPosition: { value: 0.0 },
    time: { value: 0 },
    lightDirection: { value: new THREE.Vector3(1, 0.5, 1).normalize() },

    lightDirection2: { value: new THREE.Vector3(1, 0.5, 1).normalize() },
    shadowMap: { value: null },
    shadowMatrix: { value: new THREE.Matrix4() },
    scrollSpeed: { value: 0.1 },
    shadowScale: { value: 0.01 },
    shadowColor: { value: new THREE.Color(0x01153d) },
    directionalLightColor: { value: new THREE.Color(0xffffff) },
    directionalLightIntensity: { value: 3.5 },
  },
  vertexShader: `
    uniform vec3 lightDirection;
    uniform mat4 shadowMatrix;
    varying vec2 vUv;
    varying vec3 vNormal;
    varying vec4 vShadowCoord;
    varying vec3 vWorldPosition;

    void main() {
      vUv = uv;
      vNormal = normalMatrix * normal;
      vec4 worldPosition = modelMatrix * vec4(position, 1.0);
      vShadowCoord = shadowMatrix * worldPosition;
      vWorldPosition = worldPosition.xyz;
      
      gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
    }
  `,
  fragmentShader: `
    uniform sampler2D imageTex;
    uniform float cutoffPosition;
    uniform vec3 lightDirection;
    uniform vec3 lightDirection2;
    uniform sampler2D shadowMap;
    uniform float time;
    uniform float scrollSpeed;
    uniform float shadowScale;
    uniform vec3 shadowColor;
    uniform vec3 directionalLightColor;
    uniform float directionalLightIntensity;
    varying vec2 vUv;
    varying vec3 vNormal;
    varying vec4 vShadowCoord;
    varying vec3 vWorldPosition;

    void main() {
      vec4 texColor = texture2D(imageTex, vUv);
      
      vec2 shadowUv = (vShadowCoord.xy / vShadowCoord.w) * 0.5 + 0.5;
      shadowUv *= shadowScale;
      shadowUv += vec2(time * scrollSpeed, time * scrollSpeed);
      vec4 shadowMapColor = texture2D(shadowMap, fract(shadowUv));
      
      vec3 shadowEffect = mix(vec3(0.8), shadowColor * 2.0, 1.0 - shadowMapColor.r) * 2.0;
      
      // Calculate directional light contribution
      float directionalLight = max(dot(normalize(vNormal), normalize(lightDirection2)), 0.0);
      vec3 directionalLightContribution = directionalLightColor * directionalLightIntensity * directionalLight;
      
      // Combine texColor, shadowEffect, and directional light
      vec3 litColor = texColor.rgb * shadowEffect * (vec3(0.5) + directionalLightContribution);
      
      // Implement cutoff effect in world space
      float cutoff = step(cutoffPosition, vWorldPosition.y);
      
      gl_FragColor = vec4(litColor*directionalLight *3.5, texColor.a * (1.0 - cutoff));
    }
  `,
};

export default HeightCutoffShader;