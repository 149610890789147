import * as THREE from "three";

const CutoffShader = {
  uniforms: {
    imageTex: { value: null },
    cutoffFactor: { value: 0.0 },
    time: { value: 0 },
    lightDirection: { value: new THREE.Vector3(1, 1, 1).normalize() },
    shadowMap: { value: null },
    shadowMatrix: { value: new THREE.Matrix4() },
    scrollSpeed: { value: 0.1 },
    shadowScale: { value: 0.01 },
    shadowColor: { value: new THREE.Color(0x01153d) },
  },
  vertexShader: `
    uniform vec3 lightDirection;
    uniform mat4 shadowMatrix;
    varying vec2 vUv;
    varying vec3 vNormal;
    varying vec4 vShadowCoord;

    void main() {
      vUv = uv;
      vNormal = normalMatrix * normal;
      vec4 worldPosition = modelMatrix * vec4(position, 1.0);
      vShadowCoord = shadowMatrix * worldPosition;
      gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
    }
  `,
  fragmentShader: `
    uniform sampler2D imageTex;
    uniform float cutoffFactor;
    uniform vec3 lightDirection;
    uniform sampler2D shadowMap;
    uniform float time;
    uniform float scrollSpeed;
    uniform float shadowScale;
    uniform vec3 shadowColor;
    varying vec2 vUv;
    varying vec3 vNormal;
    varying vec4 vShadowCoord;

    void main() {
      vec4 texColor = texture2D(imageTex, vUv);
      
      // Implement cutoff effect
      float cutoff = step(vUv.y, 1.0 - cutoffFactor);
      
      // Shadow effect from ShadowSurfaceShader
      vec2 shadowUv = (vShadowCoord.xy / vShadowCoord.w) * 0.5 + 0.5;
      shadowUv *= shadowScale;
      shadowUv += vec2(time * scrollSpeed, time * scrollSpeed);
      vec4 shadowMapColor = texture2D(shadowMap, fract(shadowUv));
      
      vec3 shadowEffect = mix(vec3(0.8), shadowColor * 2.0, 1.0 - shadowMapColor.r) * 2.0;
      vec3 litColor = texColor.rgb * shadowEffect;

      gl_FragColor = vec4(litColor, texColor.a * cutoff);
    }
  `,
};

export default CutoffShader;