import * as THREE from 'three';

class Lighting {
  constructor(scene) {
    this.scene = scene;
    this.createLights();
  }

  createLights() {
    // Ambient light
    this.ambient = new THREE.AmbientLight(0xffffff, 0.5);
    this.scene.add(this.ambient);

    // Directional light (sun)
    this.dirLight = new THREE.DirectionalLight(0xffffff, 1);
    this.dirLight.position.set(5, 10, 7.5);
    this.dirLight.castShadow = false;
    this.scene.add(this.dirLight);

  }

  updateLighting(timeOfDay) {
    const intensity = Math.sin(timeOfDay * Math.PI) * 0.5 + 0.5;
    this.dirLight.intensity = intensity;
    this.pointLight.intensity = 1 - intensity;
  }
}

export default Lighting;
