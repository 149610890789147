import React, { useState, useRef, useEffect } from 'react';
import { AppBar, Button, Grid, Slide, ThemeProvider, createTheme, useScrollTrigger, Box, Modal, Typography } from '@mui/material';
import { useScroll } from 'framer-motion';
import gsap from 'gsap';
import { Howler } from 'howler'; // Import Howler for managing sound

import '../Home.css';
import TopbarParade from '../../components/TopBarParade';
import Bottombar from '../../components/BottomBar';
import LoadingManager from '../../Render/LoadingManager';
import StormTheRoof from '../../components/Scenes/StormTheRoof/StormTheRoof';
import Overlay from '../Misc/Overlay';
import UpgradeMenu from './UpgradeMenu';
import ParaglidingIcon from '@mui/icons-material/Paragliding';
import FlareIcon from '@mui/icons-material/Flare';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import ModeStandbyIcon from '@mui/icons-material/ModeStandby';
import GameOver from './GameOver'; // Import the new GameOver component
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolumeMuteIcon from '@mui/icons-material/VolumeMute';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import useMediaQuery from '@mui/material';
import ReplayIcon from '@mui/icons-material/Replay';

const HideOnScroll = ({ children }: any) => {
  const trigger = useScrollTrigger();
  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
};

export enum ContentPanel {
  CONTENT_STAGE_HIDDEN,
  CONTENT_STAGE_PART_1,
  CONTENT_STAGE_PART_2,
  CONTENT_STAGE_PART_3
}

const StormTheRoofPage: React.FC = () => {
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [isReloading, setIsReloading] = useState<boolean>(false); // State to track reloading
const [reloadTime, setReloadTime] = useState<number>(3); // Example reload time of 3 seconds

  const iconRef = useRef<HTMLDivElement | null>(null);
  const reloadIconRef = useRef<HTMLDivElement | null>(null);

  function detectDevice() {
    const userAgent = typeof window.navigator === "undefined" ? "" : navigator.userAgent;
    const mobile = Boolean(
      userAgent.match(
        /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i
      )
    );
    return mobile ? "mobile" : "desktop";
  }
  const deviceType = detectDevice();
const isDesktop = deviceType;
  useEffect(() => {
    if (stormTheRoofRef.current && stormTheRoofRef.current.weapon) {
      stormTheRoofRef.current.weapon.setOnReloadStart(() => {
        setIsReloading(true);
        setReloadTime(stormTheRoofRef.current.upgradeManager.getReloadDuration() / 1000);
      });
  
      stormTheRoofRef.current.weapon.setOnReloadComplete(() => {
        if (iconRef.current) {
          const iconWidth = iconRef.current.clientWidth;
          const iconHeight = iconRef.current.clientHeight;
          const offsetX = iconWidth / 2;
          const offsetY = iconHeight / 2;
    
          gsap.to(iconRef.current, {
            x: mousePosition.x - offsetX,
            y: mousePosition.y - offsetY,
            duration: 0.0000,
            ease: 'power3.out',
          });
        }
      
        setIsReloading(false);
        setReloadTime(0);
      });
    }
  }, []);
  
  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (isReloading) {
      const timerStart = Date.now();
      interval = setInterval(() => {

        const elapsed = (Date.now() - timerStart) / 1000;
        const remaining = Math.max(0, reloadTime - elapsed);
        setReloadTime(Number(remaining.toFixed(1))); // Update the countdown timer
        if (remaining <= 0) {
          clearInterval(interval);
          setIsReloading(false);
        }
      }, 1);
    }
    return () => clearInterval(interval);
  }, [isReloading, reloadTime]);
  useEffect(() => {
    const handleMouseMove = (event: MouseEvent) => {
      setMousePosition({ x: event.clientX, y: event.clientY });
    };

    window.addEventListener('mousemove', handleMouseMove);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);
  useEffect(() => {
    if (isDesktop && reloadIconRef.current) {
      const iconWidth = reloadIconRef.current.clientWidth;
      const iconHeight = reloadIconRef.current.clientHeight;
      const offsetX = iconWidth / 2;
      const offsetY = iconHeight / 2;

      gsap.to(reloadIconRef.current, {
        x: mousePosition.x - offsetX,
        y: mousePosition.y - offsetY,
        duration: 0.0,
        ease: 'power3.out',
      });
    }
    if (iconRef.current) {
      const iconWidth = iconRef.current.clientWidth;
      const iconHeight = iconRef.current.clientHeight;
      const offsetX = iconWidth / 2;
      const offsetY = iconHeight / 2;

      gsap.to(iconRef.current, {
        x: mousePosition.x - offsetX,
        y: mousePosition.y - offsetY,
        duration: 0.0000,
        ease: 'power3.out',
      });
    }
  }, [mousePosition]);
  useEffect(() => {
    if (stormTheRoofRef.current && stormTheRoofRef.current.weapon) {
      stormTheRoofRef.current.weapon.setOnReloadStart(() => {
        setIsReloading(true);
        setReloadTime((stormTheRoofRef.current.upgradeManager.getReloadDuration() / 500) -0.6);
  
        // Position the reload icon at the mouse position at the start of reloading
        if (reloadIconRef.current) {
          const iconWidth = reloadIconRef.current.clientWidth;
          const iconHeight = reloadIconRef.current.clientHeight;
          const offsetX = iconWidth / 2;
          const offsetY = iconHeight / 2;
  
          gsap.set(reloadIconRef.current, {
            x: mousePosition.x - offsetX,
            y: mousePosition.y - offsetY,
          });
        }
      });
  
      stormTheRoofRef.current.weapon.setOnReloadComplete(() => {
        setIsReloading(false);
        setReloadTime(0);
  
        // Position the regular icon at the current mouse position when reload ends
        if (iconRef.current) {
          const iconWidth = iconRef.current.clientWidth;
          const iconHeight = iconRef.current.clientHeight;
          const offsetX = iconWidth / 2;
          const offsetY = iconHeight / 2;
  
          gsap.set(iconRef.current, {
            x: mousePosition.x - offsetX,
            y: mousePosition.y - offsetY,
          });
        }
      });
    }
  }, [mousePosition]);


  const [contentValue, setContentValue] = useState<ContentPanel>(ContentPanel.CONTENT_STAGE_HIDDEN);
  const [infoValue, setInfoValue] = useState<ContentPanel>(ContentPanel.CONTENT_STAGE_HIDDEN);
  const [buttonInfo, setButtonInfo] = useState<string>('main');
  const [showOverlay, setShowOverlay] = useState(false);
  const [showButtons, setShowButtons] = useState(false);
  const [showCursor, setShowCursor] = useState<boolean>(true);
  const [reloadUIPosition, setReloadUIPosition] = useState<{ top: number, left: number }>({ top: 0, left: 0 });

  const [playerHealth, setPlayerHealth] = useState<number>(100);
  const [isGameOver, setIsGameOver] = useState<boolean>(false);

  const [currentWaveNumber, setCurrentWaveNumber] = useState(1);
  const [targetsLeft, setTargetsLeft] = useState(0);


useEffect(() => {
  const handleMouseMove = () => {
    if (isDesktop) {
      setShowCursor(false);
    }
  };

  const handleMouseLeave = () => {
    if (isDesktop) {
      setShowCursor(true);
    }
  };

  window.addEventListener('mousemove', handleMouseMove);
  window.addEventListener('mouseleave', handleMouseLeave);

  return () => {
    window.removeEventListener('mousemove', handleMouseMove);
    window.removeEventListener('mouseleave', handleMouseLeave);
  };
}, [isDesktop]);
  const [ammoCount, setAmmoCount] = useState<number>(8);
  const [showUpgradeMenu, setShowUpgradeMenu] = useState(false);
  const [isMuted, setIsMuted] = useState(false); // State to track mute status
  const [score, setScore] = useState(0);

  const toggleMute = () => {
    setIsMuted(!isMuted);
    Howler.mute(!isMuted); // Mute or unmute sound
  };
  const stormTheRoofRef = useRef<any>(null);
  const handleRestart = () => {
    window.location.reload(); // Refresh the page
  };
  const theme = createTheme({
    palette: {
      primary: {
        main: '#FFFFFF',
        contrastText: '#000000',
      },
      secondary: {
        main: '#000000'
      },
    }
  });

  const buttonColors = {
    fireworksRight: '#f54254',
    parachute: '#ed8434',
    helicopter: '#19c5cf',
    platform: '#98cf21',
    jet: '#98cf21',
    fireworksLeft: '#c23ca0',
    dayNightToggle: '#5221cf'
  };

  const handleWaveComplete = () => {
    setShowUpgradeMenu(true);
  };
;
  const handleUpgradeSelection = (upgrade: string) => {
    if (stormTheRoofRef.current) {
      stormTheRoofRef.current.handleUpgradeSelection(upgrade);
    }
    setShowUpgradeMenu(false);
  };
  const handleUpgradeChoice = (upgrade: string) => {
    setShowUpgradeMenu(false);
    // Apply upgrade logic
    if (stormTheRoofRef.current) {
      stormTheRoofRef.current.upgradeManager.applyUpgrade(upgrade); // Hypothetical method to apply the upgrade
      stormTheRoofRef.current.waveManager.startWave(); // Start the next wave
      stormTheRoofRef.current.weapon.weaponReload();
    }
  };

  const handleNextWave = () => {
    setShowUpgradeMenu(false);
    if (stormTheRoofRef.current) {
      stormTheRoofRef.current.waveManager.startWave(); // Start the next wave directly
    }
  };

  useEffect(() => {
    const handleMouseMove = () => {
      if (isDesktop) {
        setShowCursor(false);
      }
    };
  
    const handleMouseLeave = () => {
      if (isDesktop) {
        setShowCursor(true);
      }
    };
  
    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mouseleave', handleMouseLeave);
  
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseleave', handleMouseLeave);
    };
  }, [isDesktop]);
  useEffect(() => {
    if (stormTheRoofRef.current) {
      const updateWaveInfo = () => {
        setCurrentWaveNumber(stormTheRoofRef.current.waveManager.getWaveCount()+1);
        setTargetsLeft(stormTheRoofRef.current.waveManager.spawnCounters.target);
      };
  
      stormTheRoofRef.current.waveManager.setOnWaveCompleteCallback(() => {
        handleWaveComplete();
        updateWaveInfo();
      });

      const interval = setInterval(() => {
        if (stormTheRoofRef.current) {
          if(stormTheRoofRef.current.health && stormTheRoofRef.current.weapon)
          {
            setPlayerHealth(stormTheRoofRef.current.health.getCurrentHealth());
            setAmmoCount(stormTheRoofRef.current.weapon.ammoCount);
          }
          if(stormTheRoofRef.current.score)
          {
            setScore(stormTheRoofRef.current.score.getCurrentScore());
          }
          setTargetsLeft(stormTheRoofRef.current.waveManager.spawnCounters.target);
  
          if (stormTheRoofRef.current.health &&stormTheRoofRef.current.health.getCurrentHealth() <= 0) {
            setIsGameOver(true);
          }
        }
      }, 100);
  
      return () => clearInterval(interval);
    }
  }, []);

  const handleReload = () => {
    if (stormTheRoofRef.current && stormTheRoofRef.current.weapon) {
      stormTheRoofRef.current.weapon.manualReload();
    }
  };

  const OpenContent = (content: ContentPanel) => {
    setContentValue(content);
  };

  const CloseContent = () => {
    setInfoValue(ContentPanel.CONTENT_STAGE_HIDDEN);
    OpenContent(ContentPanel.CONTENT_STAGE_HIDDEN);
  };

  const toggleOverlay = () => {
    setShowOverlay(!showOverlay);
  };

  const { scrollYProgress } = useScroll();

  const handleButtonClick = (action: string) => {
    if (stormTheRoofRef.current && stormTheRoofRef.current[action]) {
      stormTheRoofRef.current[action]();
    }
  };

  useEffect(() => {
    // Handle cursor visibility based on state
    if (!showCursor) {
      document.body.style.cursor = 'none';
    } else {
      document.body.style.cursor = 'default';
    }

    return () => {
      // Ensure the cursor is shown when component unmounts
      document.body.style.cursor = 'default';
    };
  }, [showCursor]);

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === 'r' || event.key === 'R' || event.key === ' ') {
        handleReload();
      }
    };

    window.addEventListener('keydown', handleKeyPress);

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, []);

  const [drawCalls, setDrawCalls] = useState(0);
  const [frameRate, setFrameRate] = useState(0);

  useEffect(() => {
    let frameCount = 0;
    let lastTime = performance.now();

    const updateDebugInfo = () => {
      if (stormTheRoofRef.current && stormTheRoofRef.current.renderer) {
        setDrawCalls(stormTheRoofRef.current.renderer.info.render.calls);
      }

      frameCount++;
      const currentTime = performance.now();
      if (currentTime - lastTime >= 1000) {
        setFrameRate(Math.round(frameCount * 1000 / (currentTime - lastTime)));
        frameCount = 0;
        lastTime = currentTime;
      }

      requestAnimationFrame(updateDebugInfo);
    };

    updateDebugInfo();
  }, []);

  return (
    <>
          <div style={{ cursor:  'none' }}> </div>

      <LoadingManager />
      <div className="pageGlobal">

        <Grid container style={{ position: 'fixed', zIndex: -1 }}>
        {deviceType === 'desktop' &&!isReloading && (
        <Box ref={iconRef} sx={{
          position: 'fixed',
          pointerEvents: 'none',
          zIndex: 9998,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
         
          transform: isDesktop ? undefined : 'translateY(-50%)'}}>
          <ModeStandbyIcon sx={{ fontSize: 20, color: '#ffffff66' }} />
        </Box>
      )}
        <StormTheRoof ref={stormTheRoofRef} />
        </Grid>

        <HideOnScroll>
        <AppBar position={'fixed'} sx={{ boxShadow: 'none', backgroundColor: '#00000000' }}>
            <TopbarParade />
          </AppBar>
        </HideOnScroll>

        <AppBar position={'fixed'} sx={{ top: 'auto', bottom: 0, boxShadow: 'none', backgroundColor: '#00000000' }}>
          <Bottombar />
        </AppBar>

        <Overlay show={showOverlay} />

        <Grid container item direction="column" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%', overflowY: 'scroll' }}>
        <Box
  sx={{
    position: 'fixed',
    top: '10%',
    right: '10%',
    zIndex: 2,
    backgroundColor: 'rgba(1, 1, 1, 0.00)',
    padding: '8px 16px',
    borderRadius: '8px',
  }}
>
  <Typography
    variant="h4"
    sx={{
      pointerEvents: 'none',

      display: 'flex',
      alignItems: 'center',
      color: '#ffffff',
      fontSize: '1.0rem',
      fontFamily: 'roboto-mono',
    }}
  >
    <MyLocationIcon
          sx={{
            width: 30,
            height: 30,
            marginRight: 1,
          }}
        /> {score}
  </Typography>
</Box>
<Button
  sx={{
    width: 60, // Larger size for clarity
    height: 60, // Match height to width
    position: 'fixed',
    top: '85%', // Align with the health indicator's top offset
    left: '10%',
    transform: 'translateY(-50%)', // Align vertically
    zIndex: 2,
    backgroundColor: 'rgba(1, 1, 1, 0.0)', // Background with transparency
    borderRadius: '50%', // Perfectly round button
    padding: 0, // Remove internal padding
    minWidth: 0, // Ensure the button's width and height are exactly as specified
    display: 'flex', // Flexbox to center the content
    alignItems: 'center', // Center icon vertically
    justifyContent: 'center', // Center icon horizontally
    cursor: 'pointer',
  }}
  onClick={toggleMute} // Toggle mute on click
>
  {isMuted ? (
    <VolumeMuteIcon sx={{ fontSize: 30, color: '#ffffff' }} />
  ) : (
    <VolumeUpIcon sx={{ fontSize: 30, color: '#ffffff' }} />
  )}
</Button>


          
          <Box
  sx={{
    position: 'fixed',
    top: '85%',
    left: '50%',
    transform: 'translate(-50%, -50%)', // This centers the box both horizontally and vertically
    zIndex: 2,
    backgroundColor: 'rgba(1, 1, 1, 0.00)',
    padding: '8px 16px',
    borderRadius: '8px',
  }}
>
  <Typography
    variant="h4"
    sx={{
      pointerEvents: 'none',

      zIndex: 2,
      color: '#ffffff',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center', // Ensures the text and icon are centered together
      fontSize: '1.0rem', 
      fontWeight: '100',
      fontFamily: 'roboto-mono',
    }}
  >
    <LocalHospitalIcon
      sx={{
        pointerEvents: 'none',

        width: 30,
        height: 30,
        marginRight: 1,
      }}
    />
    {playerHealth}
  </Typography>
</Box>

          <Box
  sx={{
    position: 'fixed',
    top: '10%',
    left: '10%',
    zIndex: 2,
    backgroundColor: 'rgba(1, 1, 1, 0.00)', // Semi-transparent black background
    padding: '8px 16px', // Padding inside the box
    borderRadius: '8px', // Optional: add rounded corners
  }}
>
  <Typography
    variant="h4"
    sx={{
      pointerEvents: 'none',
      color: '#ffffff',
      display: 'flex',
      alignItems: 'center',
      fontSize: '1rem',
      fontWeight: '100',
      fontFamily: 'roboto-mono',
    }}
  >
    <ParaglidingIcon
      sx={{
        width: 30,
        height: 30,
        marginLeft: 2,
        paddingRight:'10px'
      }}
    />
    { targetsLeft}
  </Typography>
</Box>
          <Box
  sx={{
    position: 'fixed',
    top: '10%',
    left: '50%',
    transform: 'translateX(-50%)',
    zIndex: 2,
    padding: '8px 16px', // Padding inside the box
    borderRadius: '8px', // Optional: add rounded corners
    textAlign: 'center', // Center align text and icon horizontally
  }}
>
  <FlareIcon
    sx={{
      width: 20,
      height: 20,
      display: 'block',
      margin: '0 auto',
      color: '#ffffff', // Change icon color to white
      position: 'relative', // Change position to relative
      top: '0', // Reset top position
    }}
  />
  <Typography
    variant="h4"
    sx={{
      pointerEvents: 'none',
      color: '#ffffff', // Change text color to white
      textAlign: 'center',
      fontSize: '1rem',
      fontWeight: '100',
      fontFamily: 'roboto-mono',
    }}
  >
    DAY {currentWaveNumber}
  </Typography>
</Box>
          {infoValue === ContentPanel.CONTENT_STAGE_HIDDEN && contentValue !== ContentPanel.CONTENT_STAGE_HIDDEN &&
            <Button
              variant='contained'
              onClick={() => {
                setInfoValue(contentValue);
                setContentValue(ContentPanel.CONTENT_STAGE_HIDDEN);
              }}
              sx={{
                position: 'fixed',
                justifyContent: 'center',
                alignItems: 'center',
                bottom: 100,
                mb: '30px',
                height: '40px',
                fontFamxily: 'DMSans',
                fontSize: '1.0rem',
                fontWeight: '100',
                color: '#000000',
                backgroundColor: '#ffffff',
                borderRadius: '30px',
                boxShadow: 'none',
              }}>
              Click to see {buttonInfo}
            </Button>
          }

          
        </Grid>

        {showButtons && (
          <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2} sx={{ position: 'fixed', top: '100%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 1 }}>
            {/* Action buttons */}
          </Grid>
        )}
{isGameOver && stormTheRoofRef.current.score && <GameOver onRestart={handleRestart} score={stormTheRoofRef.current.score.getCurrentScore()} />} {/* Display the GameOver UI */}
{stormTheRoofRef.current && showUpgradeMenu&&
<UpgradeMenu 
          waveNumber={stormTheRoofRef.current.waveManager.getWaveCount()}
          isVisible={showUpgradeMenu} 
        onUpgradeSelect={handleUpgradeChoice} 
        onClose={() => setShowUpgradeMenu(false)} 
        onNextWave={handleNextWave} 
      />}

{isReloading && (
  <Box
    ref={reloadIconRef}
    sx={{
      position: 'fixed',
      pointerEvents: 'none',
      zIndex: 9998,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
     
      transform: isDesktop ? undefined : 'translateY(-50%)', // Center vertically on mobile
    }}
  >
    <RotateLeftIcon
      sx={{
        fontSize: 60,
        color: '#ffffff66',
        animation: 'spin 1s linear infinite',
        '@keyframes spin': {
          from: { transform: 'rotate(360deg)' },
          to: { transform: 'rotate(0deg)' },
        },
      }}
    />
    <Typography
      sx={{
        pointerEvents: 'none',
        position: 'absolute',
        color: '#ffffff',
        marginTop: '75px',
        fontSize: '0.65rem',
        fontWeight: 'bold',
        fontFamily: 'roboto-mono',
      }}
    >
{reloadTime.toFixed(1)}   
 </Typography>
  </Box>
)}

      <Box
        sx={{
          position: 'fixed',
          top: '20%', // Adjust this value to position it below the wave and enemy count
          left: '10%',
          zIndex: 2,
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          padding: '8px 16px',
          borderRadius: '8px',
        }}
      >
        <Typography
          variant="body2"
          sx={{
            color: '#ffffff',
            fontFamily: 'roboto-mono',
            fontSize: '0.8rem',
          }}
        >
          Draw Calls: {drawCalls}<br />
          FPS: {frameRate}
        </Typography>
      </Box>

      </div>
      
      <Button
        onClick={handleReload}
        sx={{
          position: 'fixed',
          bottom: '5%',
          left: '50%',
          transform: 'translateX(-50%)',
          zIndex: 2,
          backgroundColor: 'rgba(255, 255, 255, 0.2)',
          color: '#ffffff',
          borderRadius: '20px',
          padding: '8px 16px',
          '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.3)',
          },
        }}
      >
        <ReplayIcon sx={{ marginRight: 1 }} />
        Reload
      </Button>

    </>
  );
};

export default StormTheRoofPage;
