import * as THREE from "three";

const TextMotionShader = {
  uniforms: {
    time: { value: 0 },
    color: { value: new THREE.Color(0xffffff) },
    waveHeight: { value: 0.1 },
    waveFrequency: { value: 2.0 },
    waveSpeed: { value: 1.0 },
    touchTexture: { value: null },
    map: { value: null },
    resolution: { value: new THREE.Vector2() },
    bottomFadeStart: { value: 0.05 }, 
    bottomCutStart: { value: 0.05 }, 
    topFadeStart: { value: 0.05 }, 
    topCutStart: { value: 0.025 }, 
  },
  vertexShader: `
    uniform float time;
    uniform float waveHeight;
    uniform float waveFrequency;
    uniform float waveSpeed;
    uniform sampler2D touchTexture;
    uniform vec2 resolution;
    varying vec2 vUv;
    varying vec4 vPosition;

    void main() {
      vUv = uv;
      vec3 pos = position;
      
      // Calculate screen space UV coordinates
      vec4 worldPos = modelViewMatrix * vec4(pos, 1.0);
      vec4 projectedPos = projectionMatrix * worldPos;
      vec2 screenUV = (projectedPos.xy / projectedPos.w + 1.0) * 0.5;
      
      // Sample touch texture using screen space coordinates
      vec4 touchColor = texture2D(touchTexture, screenUV);
      
      // Apply wave effect
      float angle = (pos.x + pos.y) * waveFrequency + time * waveSpeed;
      pos.z += sin(angle) * waveHeight;
      
      // Convert screen space touch effect to world space
      vec4 touchOffset = vec4(touchColor.rgb * 2.0 - 1.0, 0.0); // Convert [0,1] to [-1,1] range
      touchOffset = inverse(projectionMatrix) * touchOffset;
      touchOffset = inverse(modelViewMatrix) * touchOffset;
      
      // Apply touch effect in world space
      pos -= touchOffset.xyz * touchColor.r * 0.045; // Adjust multiplier for desired effect strength
      
      vPosition = projectionMatrix * modelViewMatrix * vec4(pos, 1.0);
      gl_Position = vPosition;
    }
  `,
  fragmentShader: `
    uniform vec3 color;
    uniform sampler2D map;
    uniform sampler2D touchTexture;
    uniform vec2 resolution;
    varying vec2 vUv;
    varying vec4 vPosition;
    uniform float bottomFadeStart;
    uniform float bottomCutStart;
    uniform float topFadeStart;
    uniform float topCutStart;

    void main() {
      vec3 textColor = vec3(0.925,0.925,0.925);
      vec4 texColor = texture2D(map, vUv);
      
      // Convert clip space to screen space UV
      vec2 screenUV = (vPosition.xy / vPosition.w + 1.0) * 0.5;
      
      vec4 touchColor = texture2D(touchTexture, screenUV);
      
      
      
      // Modify the color based on the touch texture
      vec3 finalColor = textColor * (1.0 + touchColor.r * 3.5); // Adjust multiplier for effect intensity
      // Apply bottom fade
      float bottomFade = smoothstep(bottomCutStart, bottomFadeStart+bottomCutStart, screenUV.y);
      float topFade = smoothstep(topCutStart, topFadeStart+topCutStart, 1.0-screenUV.y);

      float alpha = 1.0;
      alpha *= bottomFade * topFade;
      
      gl_FragColor = vec4(finalColor, alpha);
    }
  `,
};

export default TextMotionShader;