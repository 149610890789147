import * as THREE from "three";
import { MathUtils, Vector2, Vector3 } from "three";
import { Mesh, BoxGeometry, MeshBasicMaterial } from 'three';
import { gsap } from "gsap";
import React, { Component } from 'react';
import { useMediaQuery } from '@mui/material';
// Renderer related
import Model from "../../../Render/Model";
import Material from "../../../Render/Material";
import LoadingManager from "../../../Render/LoadingManager";
import Scene from "../Base/Scene";
import { PropShader } from "../../../Shaders/PropShader";
import { loadFont, loadCurveFromJSON, createText } from "../Base/Helper";
import {AtmosphericSkyShader} from "../../../Shaders/AtmosphericSkyShader";
import { easeOutSine } from '../Base/Helper';
import AudioManager from '../ArtCode/AudioManager';
import Sky from "./Sky";
import { detectDevice } from '../Projects/ProjectSections/Helper';

const COLLISION_LAYER = 1;

class Voyage extends Scene {
  constructor(_options) {
    super(_options);
    this.isSectionActive = _options.isSectionActive; // Receive isSectionActive from props

    this.clickTimeout = 0;
    this.scrollDelta = 25;
    this.getState = _options.statecallback;
    this.modelLoad = _options.modelcallback;

    this.selectedPlanet = null;

    this.introPlaying=true;
    this.mouse = new THREE.Vector2();
    window.addEventListener('mousemove', this.onMouseMove.bind(this));

    this.hide = false;
    this.current = 0;
    this.clickEnabled = true;

    this.mouse = new Vector2(0, 0);
    this.mouseNormalized = new Vector2(0, 0);
    this.lerpedMouse = new Vector2(0, 0);
    this.lerpedMouseRaw = new Vector2(0, 0);
    this.boot = false;
    this.fullLoaded = false;

    this.lastUpdate = performance.now();
    this.totalTime = 0.0;
    this.startTime = performance.now();

    this.bloomIntensity = 0.8;
    this.camDirection = new Vector3(0, 0, 0);
    this.camUp = new Vector3(0, 0, 0);
    this.camSide = new Vector3(0, 0, 0);
    this.camPos = new Vector3(0, 0, 0);
    this.camLookAt = new Vector3(0, 0, 0);
    this.currDronePoint = new Vector3(0, 0, 0);

    this.sendPoint = new Vector3(0, 0, 0);
    this.tempPoint = new Vector3(0, 0, 0);

    this.sendPointLookAt = new Vector3(0, 0, 0);
    this.tempPointLookAt = new Vector3(0, 0, 0);

    const d = 25;
    const r = 1;
    const mapSize = 1024;
    this.ambient = new THREE.AmbientLight(0xffffff, 1.5);
    this.dirLight = new THREE.DirectionalLight(0xffffff, 1.5);
    this.dirLight.castShadow = true;
    this.dirLight.shadow.radius = r;
    this.dirLight.shadow.mapSize.width = mapSize;
    this.dirLight.shadow.mapSize.height = mapSize;
    this.dirLight.shadow.camera.top = this.dirLight.shadow.camera.right = d;
    this.dirLight.shadow.camera.bottom = this.dirLight.shadow.camera.left = -d;
    this.dirLight.shadow.camera.near = 0.1;
    this.dirLight.shadow.camera.far = 100;
    this.dirLight.position.set(40, 25, 20);
    this.scene.add(this.dirLight);
    this.spot = new THREE.SpotLight(0xffffff, 100.0, 80.0, 5, 10, 10);

    this.buttonVisibilityCallback = null;

    this.scene.add(this.ambient);
    this.scene.add(this.spot);

    this.dynamicMaterial = new THREE.MeshPhongMaterial({ color: 0x888888 });

    this.storeTop = 0.0;

    this.loadListener();
    this.startRender();

    this.isPointerDown = false;
    this.lastPointerPosition = new THREE.Vector2();
    this.isFixedCamera =false;
    this.requiredAssets = [
      { name: 'facadeModel', source: '/assets/facade.glb' },
      { name: 'facadeTexture', source: '/assets/facade_render.jpg', type: 'texture' },
      { name: 'voyageTexture', source: '/assets/voyage-white.png', type: 'texture' },
      { name: 'exploreTexture', source: '/assets/explore.png', type: 'texture' },
      { name: 'whiteTexture', source: '/assets/whiteTexture.jpg', type: 'texture' },

      { name: 'iceCreamTexture', source: '/assets/ice-cream.jpg', type: 'texture' },
      { name: 'sunsetTexture', source: '/assets/sunset.jpg', type: 'texture' },
      { name: 'templeTexture', source: '/assets/temple.jpg', type: 'texture' },
      { name: 'fujiTexture', source: '/assets/fuji.jpg', type: 'texture' },
      { name: 'toriTexture', source: '/assets/tori.jpg', type: 'texture' },
      { name: 'penangTexture', source: '/assets/penang.jpg', type: 'texture' },

      { name: 'circleTexture', source: '/assets/circle.png', type: 'texture' },
      { name: 'logoModel', source: '/assets/logo.glb' },
    ];
    this.loadedAssets = 0;
    this.totalAssets = this.requiredAssets.length;

    this.loadingManager = new LoadingManager();
    this.loader = this.loadingManager.loader;
    this.initializeLoading();

    this.hoveredText = null;
    
 // Initialize the current image index
 this.currentImageIndex = 0;

 // Define an array of images with their corresponding properties
 this.imageSettings = [
   { name: "POINT CLOUD", chapterImage: "/assets/chapters/chapter_1.jpeg", textureName: "voyageTexture", color: 0x444444, intensity: 0.4, heightOffset: 5.0, videoPath: '/assets/artcode_chapter_2_video.mp4', route: '/lab/point-cloud' },
   { name: "MOVING FRAMES", chapterImage: "/assets/chapters/chapter_2.jpeg", textureName: "iceCreamTexture", color: 0x444444, intensity:0.4, heightOffset: 0.0, videoPath: '/assets/artcode_chapter_2_video.mp4', route: '/lab/moving-frames' },
   { name: "STORM THE ROOF", chapterImage: "/assets/chapters/chapter_3.jpeg", textureName: "templeTexture", color: 0x444444, intensity: 0.4, heightOffset: 3.0, videoPath: '/assets/artcode_chapter_2_video.mp4', route: '/lab/storm-the-roof' },
   { name: "ONE SPACE", chapterImage: "/assets/chapters/chapter_5.jpeg", textureName: "toriTexture", color: 0x444444, intensity:0.4, heightOffset: 0.0, videoPath: '/assets/artcode_chapter_2_video.mp4', route: '/lab/interior' },
 ];

 this.planets = [];
 this.planetTexts = [];
 this.planetLines = [];

 // Add these new properties
 this.cameraPosition = new THREE.Vector3(0, 0, 20);
 this.cameraLookAt = new THREE.Vector3(0, 0, 0);
 this.targetCameraPosition = new THREE.Vector3(0, 10, 20);
 this.targetLookAt = new THREE.Vector3(0, 0, 0);
 this.lerpFactor = 0.05; // Adjust this value to change the smoothness of camera movement

 this.planetText = null;
 this.mousePosition = new THREE.Vector2();
 this.textOffset = new THREE.Vector2(10, -10); // Offset from mouse cursor
 this.lastHoveredPlanet = null;

 this.videoOffset = new THREE.Vector2(50, -50); // Offset from mouse cursor
 this.videoPlane = null;
 this.videoTextures = {};

 this.hoverScale = 1.2; // Scale factor for hover effect
 this.normalScale = detectDevice()=='phone'?2.0: 1.0; // Normal scale of planets

 // Inside Voyage component's class
this.setHoverCallback = (callback) => {
  this.hoverCallback = callback;
};

this.minZoom = 40; // Minimum zoom distance
this.maxZoom = 120; // Maximum zoom distance
this.zoomSpeed = 0.1; // Adjust this value to change zoom speed
this.targetZoom = 80; // Initial zoom level
this.currentZoom = 80; // Current zoom level
  }

  createSky() {
 this.sky = new Sky(this.scene,this.loader);
 // set sky colors
 this.sky.setSkyColors(
   new THREE.Color(0xFDffff),  // Sky blue for top color
   new THREE.Color(0x4d81fa),  // White for bottom color (horizon)
   new THREE.Color(0xFDffff),  // Sun yellow for sun color
   new THREE.Vector3(-146, 30, -30),
   0.3
 );
  }

async createPlanets() {
  const colors = [0xffb3ba, 0xffdfba, 0xffffba, 0xbaffc9];
  const xPositions = [-50, -20, 0, 20]; // Spread planets from left to right
  const yPositions = [-10, 30, -2, 15]; // Definite but seemingly random y positions
  const zPositions = [6, 30, -20, 40]; // Definite but seemingly random y positions
  
  for (let i = 0; i < 4; i++) {
    const geometry = new THREE.SphereGeometry(3.5, 32, 32);
    const material = new THREE.MeshStandardMaterial({ color: colors[i] });
    const sphere = new THREE.Mesh(geometry, material);
    
    // Position the sphere
    sphere.position.x = xPositions[i];
    sphere.position.y = yPositions[i] ;
    sphere.position.z = zPositions[i];
    
    sphere.scale.set(0,0,0);
    const textPosition = sphere.position.clone().add(new THREE.Vector3(0, -5, 3));
    // create text
    const textParachute = await createText('// 0'+(i+1).toString()+' - '+ this.imageSettings[i].name,
      textPosition,
      new THREE.Vector3(0, 0, 0),'/fonts/Roboto Mono_Regular.json'
    );
    
    // Center the text
    if (textParachute.geometry) {
      textParachute.geometry.computeBoundingBox();
      const textWidth = textParachute.geometry.boundingBox.max.x - textParachute.geometry.boundingBox.min.x;
      const textHeight = textParachute.geometry.boundingBox.max.y - textParachute.geometry.boundingBox.min.y;
      textParachute.position.set(
        textPosition.x - textWidth / 2,
        textPosition.y - textHeight,
        textPosition.z
      );
    }

    textParachute.scale.set(0, 0, 0);

    // Add "hostile planet" text for index 2 and 3
    if (i === 2 && i === 3) {
      const hostileTextPosition = textPosition.clone().add(new THREE.Vector3(0, -2, 0));
      const textHostile = await createText('<!> HOSTILE PLANET <!>',
        hostileTextPosition,
        new THREE.Vector3(0, 0, 0),'/fonts/Roboto Mono_Regular.json'
      );
      
      if (textHostile.geometry) {
        textHostile.geometry.computeBoundingBox();
        const hostileTextWidth = textHostile.geometry.boundingBox.max.x - textHostile.geometry.boundingBox.min.x;
        textHostile.position.set(
          hostileTextPosition.x - hostileTextWidth / 2,
          hostileTextPosition.y,
          hostileTextPosition.z
        );
      }
      sphere.hostileText = textHostile;
      textHostile.scale.set(0, 0, 0);
      textHostile.material.color.setHex(0xFF0000); // Set text color to red
      
      // Ensure the material is set to allow transparency
      textHostile.material.transparent = true;
      textHostile.material.opacity = 1;

      gsap.to(textHostile.material, {
        opacity: 0.25, // Animate to 0.25 opacity
        duration: 1,
        repeat: -1,
        yoyo: true,
        ease: "power1.inOut"
      });
      this.scene.add(textHostile);
      this.planetTexts.push(textHostile);
    }
    sphere.text = textParachute;
   
    this.scene.add(textParachute);
    this.planetTexts.push(textParachute);
    this.planets.push(sphere);
    this.scene.add(sphere);
    
    // Create connecting line to the next planet
    if (i < 3) {  // Don't create a line for the last planet
      const nextSpherePosition = new THREE.Vector3(xPositions[i + 1], yPositions[i + 1], zPositions[i + 1]); // Use 0 for z-position initially
      
      const lineGeometry = new THREE.BufferGeometry().setFromPoints([sphere.position, nextSpherePosition]);
      const lineMaterial = new THREE.LineBasicMaterial({ color: 0xffffff });
      const line = new THREE.Line(lineGeometry, lineMaterial);
      line.scale.set(0, 0, 0);
      this.planetLines.push(line);
      this.scene.add(line);
    }
  }
  // Add hover event listeners to planets
  this.planets.forEach((planet, index) => {
    planet.userData.hoverText = this.imageSettings[index].name || `Planet ${index + 1}`;
    planet.userData.index = index + 1;
    planet.userData.videoPath = this.imageSettings[index].videoPath;
  });
}

updatePlanets() {
  const time = performance.now() * 0.001;
  this.planets.forEach((planet, index) => {
    const speed = 0.5 / (index + 1); // Outer planets move slower
    const angle = time * speed;
    const radius = planet.position.length();
    
    //planet.position.x = Math.cos(angle) * radius;
    //planet.position.z = Math.sin(angle) * radius;
    //update text
    let textPosition;
    if (this.selectedPlanet === planet) {
      // Position text directly in front of the selected planet
      textPosition = planet.position.clone();
      
      // Centralize the text and scale it
      if (planet.text.geometry) {
        planet.text.geometry.computeBoundingBox();
        const textWidth = planet.text.geometry.boundingBox.max.x - planet.text.geometry.boundingBox.min.x;
        const textHeight = planet.text.geometry.boundingBox.max.y - planet.text.geometry.boundingBox.min.y;
        textPosition.x += textWidth ; // Center the text horizontally
        textPosition.z += 6;
        
        // Scale the text by half
        planet.text.scale.set(0.5,0.5, 0.5);
      
      }
    } else if (this.lastHoveredPlanet !== planet) {
      textPosition = planet.position.clone().add(new THREE.Vector3(0, -5, 3));
    } else {
      textPosition = this.lastHoveredPlanet.position.clone().add(new THREE.Vector3(0, -6, 3));
    }

    if (planet.text.geometry) {
      planet.text.geometry.computeBoundingBox();
      const textWidth = planet.text.geometry.boundingBox.max.x - planet.text.geometry.boundingBox.min.x;
      planet.text.position.set(
        textPosition.x - textWidth,
        textPosition.y,
        textPosition.z
      );

      if (this.selectedPlanet === planet) {
        planet.text.rotation.set(0, 0, 0); // Face the camera for selected planet
      } else {
        if(planet.hostileText)
        {
          planet.hostileText.rotation.set(-Math.PI/4, 0, 0);
          if (this.lastHoveredPlanet !== planet) {
            planet.hostileText.position.set(
              textPosition.x - textWidth,
              textPosition.y-2.5,
              textPosition.z
            );          } else {
              planet.hostileText.position.set(
                textPosition.x - textWidth,
                 -99999,
                textPosition.z
              );          }
        }
        planet.text.rotation.set(-Math.PI/4, 0, 0);
      }
    } else {
      planet.text.position.set(textPosition.x, textPosition.y, textPosition.z);
    }
  });
}

loadCurrentImage() {
 const { textureName, color, intensity, heightOffset } = this.imageSettings[this.currentImageIndex];
 //this.triggerImageTransition(textureName, color, intensity, heightOffset,fizzle);
 if (this.pointCloud && this.pointCloud.geometry && this.pointCloud.geometry.attributes.instanceOffset) {
  const fizzlePositions = this.fizzleOutPointCloud();
  this.animatePointCloud(fizzlePositions);
 }
}

changeImage(direction) {
 if (direction === 'next') {
   this.currentImageIndex = (this.currentImageIndex + 1) % this.imageSettings.length;
 } else if (direction === 'prev') {
   this.currentImageIndex = (this.currentImageIndex - 1 + this.imageSettings.length) % this.imageSettings.length;
 }
 this.loadCurrentImage();

  }

  initializeLoading() {
    this.loader.groups.assets = [{ name: 'paradeAssets', items: this.requiredAssets }];
    this.loader.on('fileEnd', this.onFileLoaded.bind(this));
    this.loader.loadNextGroup();
  }

  onFileLoaded(_resource, _data) {
    console.log(`Loaded: ${_resource.name}`);
    this.loadedAssets++;
    if (this.loadedAssets === this.totalAssets) {
      this.onAllAssetsLoaded();
    }
  }

  setButtonVisibilityCallback(callback) {
    this.buttonVisibilityCallback = callback;
  }

  updatePostProcessingSettings({ bloomIntensity, bokehAperture, focusDistance, bokehMaxBlur }) {
    if (this.postProcess) {
        if (bloomIntensity !== undefined && this.postProcess.bloomPass) {
            this.postProcess.bloomPass.strength = bloomIntensity;
        }
  
        if (this.postProcess.bokehPass) {
          
                this.postProcess.bokehPass.materialBokeh.uniforms['aperture'].value = bokehAperture;
         
         
                this.postProcess.bokehPass.materialBokeh.uniforms['focus'].value = focusDistance;
            
         
                this.postProcess.bokehPass.materialBokeh.uniforms['maxblur'].value = bokehMaxBlur;
            
        }
    }
  }


  updatePointCloudTexture(textureName) {
    const paletteTexture = this.loader.getAsset(textureName);
    const image = paletteTexture.image;
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    canvas.width = image.width;
    canvas.height = image.height;
    context.drawImage(image, 0, 0, image.width, image.height);
  
    const imageData = context.getImageData(0, 0, image.width, image.height).data;
    const positions = this.pointCloud.geometry.attributes.instancePosition.array;
    const targetPositions = [];
  
    for (let y = 0; y < image.height; y += 8) {
      for (let x = 0; x < image.width; x += 8) {
        const index = (y * image.width + x) * 4;
        const r = imageData[index] / 255 * 2;
        const g = imageData[index + 1] / 255 * 2;
        const b = imageData[index + 2] / 255 * 2;
        const a = imageData[index + 3] / 255;
  
        if (a > 0.5) {
          const targetX = (x / image.width) * 4 - 2;
          const targetY = (y / image.height) * 4 - 5;
          const targetZ = (Math.random() - 0.5) * 1;
          targetPositions.push({ x: targetZ, y: -targetY, z: -targetX});
        }
      }
    }
  
    this.animatePointCloud(targetPositions);
  }
  initTestInstancedPointCloud(imageName='', startInFizzleState = false, heightOffset = 0) {
    const paletteTexture = this.loader.getAsset(imageName);
    const circleTexture = this.loader.getAsset("circleTexture"); // Load the circle texture
    const image = paletteTexture.image;

    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    canvas.width = image.width;
    canvas.height = image.height;
    context.drawImage(image, 0, 0, image.width, image.height);

    const imageData = context.getImageData(0, 0, image.width, image.height).data;
    const imageWidth = image.width;
    const imageHeight = image.height;

    const aspectRatio = imageWidth / imageHeight; // Calculate the aspect ratio
    const geometry = new THREE.InstancedBufferGeometry();

    // Create a single point as a template for the instances
    const positions = new Float32Array([0, 0, 0]);
    geometry.setAttribute('position', new THREE.BufferAttribute(positions, 3));

    const pixelRange = 64;
    const offsets = [];
    const colors = [];
    const finalPositions = []; // To store the final positions
    const pixelCount = 25000; // Desired number of points in the cloud
    const pixelStep = Math.ceil(Math.sqrt((imageWidth * imageHeight) / pixelCount)); // Calculate step to get approximately equal number of points

    const xRange = aspectRatio > 1 ? pixelRange : pixelRange * aspectRatio; // Adjust xRange based on aspect ratio
    const yRange = aspectRatio > 1 ? pixelRange / aspectRatio : pixelRange; // Adjust yRange based on aspect ratio
    const xOffset = -xRange / 2;
    const yOffset = yRange + heightOffset; // Apply the height offset here


    for (let y = 0; y < imageHeight; y += pixelStep) {
        for (let x = 0; x < imageWidth; x += pixelStep) {
            const index = (y * imageWidth + x) * 4; // Calculate pixel index
            const r = imageData[index] / 255;
            const g = imageData[index + 1] / 255;
            const b = imageData[index + 2] / 255;
            const a = imageData[index + 3] / 255;

            if (a > 0.5) { // Only use pixels with significant alpha
                let posX, posY, posZ;

                // Calculate final position with x and y range and offset
                const finalX = (x / imageWidth) * xRange + xOffset;
                const finalY = (y / imageHeight) * -yRange + yOffset;
                const finalZ = (Math.random() * 2 - 1) / 10; // Add some depth variation

                if (startInFizzleState) {
                    // Start in random positions if in fizzle state
                    posX = (Math.random() - 0.5) * 50;
                    posY = (Math.random() - 0.5) * 50;
                    posZ = (Math.random() - 0.5) * 50;
                } else {
                    // Start in final position
                    posX = finalX;
                    posY = finalY;
                    posZ = finalZ;
                }

                offsets.push(posX, posY, posZ);
                colors.push(r, g, b);
                finalPositions.push({ x: finalZ, y: finalY, z: -finalX }); // Store final position
            }
        }
    }

    geometry.setAttribute('instanceOffset', new THREE.InstancedBufferAttribute(new Float32Array(offsets), 3));
    geometry.setAttribute('instanceColor', new THREE.InstancedBufferAttribute(new Float32Array(colors), 3));

    const material = new THREE.ShaderMaterial({
        uniforms: {
            alpha: { value: 1.0 },
            time: { value: 0.0 }, // Add time uniform
            amplitude: { value: 0.5 }, // Reduced amplitude for subtle wave
            frequency: { value: 0.2 }, // Reduced frequency for smoother wave
            pointTexture: { value: circleTexture },
            opacityCutoff: { value: 0.1 },
        },
        vertexShader: `
            attribute vec3 instanceOffset;
            attribute vec3 instanceColor;
            uniform float alpha;
            uniform float time; // Time uniform to animate the wave
            uniform float amplitude; // Amplitude of the wave
            uniform float frequency; // Frequency of the wave
            varying vec3 vColor;
            varying float vAlpha;

            void main() {
                vColor = instanceColor;
                vAlpha = alpha;

                // Apply subtle sinusoidal wave motion to the position
                vec3 animatedPosition = instanceOffset;
                animatedPosition.y += sin(animatedPosition.x * frequency + time) * amplitude;
                animatedPosition.x += cos(animatedPosition.y * frequency / 1.5 + time) * amplitude * 0.3;
                animatedPosition.z += sin(animatedPosition.y * frequency / 1.2 + time) * amplitude * 0.4;

                vec4 mvPosition = modelViewMatrix * vec4(position + animatedPosition, 1.0);
                gl_PointSize = 2.0;
                gl_Position = projectionMatrix * mvPosition;
            }
        `,
        fragmentShader: `
            uniform sampler2D pointTexture; // Receive the texture uniform
            uniform float opacityCutoff; // Opacity cutoff uniform
            varying vec3 vColor;
            varying float vAlpha; // Receive the alpha value from the vertex shader

            void main() {
                vec4 textureColor = texture2D(pointTexture, gl_PointCoord);
                float finalAlpha = vAlpha * textureColor.a; // Combine the alpha value with the texture alpha

                if(finalAlpha < opacityCutoff) {
                    discard; // Discard the fragment if the alpha is below the cutoff
                }

                gl_FragColor = vec4(vColor, finalAlpha); // Apply the alpha value to the fragment color
            }
        `,
        vertexColors: true,
        transparent: true,
    });

    const pointCloud = new THREE.Points(geometry, material);
    this.pointCloud = pointCloud;
    this.scene.add(pointCloud);

    return finalPositions; // Return the calculated final positions
}

  fizzleOutPointCloud() {
    const positions = this.pointCloud.geometry.attributes.instanceOffset.array;
    const numPoints = positions.length / 3;
    
    const randomPositions = [];
    for (let i = 0; i < numPoints; i++) {
      randomPositions.push({
        x: (Math.random() - 0.5) * 250,
        y: (Math.random() - 0.5) * 250,
        z: (Math.random() - 0.5) * 250,
      });
    }
    return randomPositions;
  }
  triggerImageTransition(textureName, color, intensity, heightOffset = 0,fizzleOut = true) {
    const timeline = gsap.timeline();
    // Step 1: Fizzle out current image, if there's an existing point cloud
    if(fizzleOut)
    {
      if (this.pointCloud && this.pointCloud.geometry && this.pointCloud.geometry.attributes.instanceOffset) {
          const fizzlePositions = this.fizzleOutPointCloud();

          // Animate the point cloud to fizzle out to random positions
          this.animatePointCloud(fizzlePositions);
      } else {
          console.warn("Point cloud or instanceOffset attribute is missing, skipping fizzle out.");
      }
    }

    // Step 2: Change lighting
    timeline.add(() => {
        this.switchColor(new THREE.Color(color), intensity);
    }, "+=1.5");

    // Step 3: Remove the current point cloud
    timeline.add(() => {
        if (this.pointCloud) {
            this.pointCloud.geometry.dispose();  // Dispose of old geometry
            this.scene.remove(this.pointCloud);
            this.pointCloud = null; // Clear reference after removal
        }
    });

}
  switchColor(targetColor, targetIntensity = 1) {
    gsap.to(this.ambient.color, {
      r: targetColor.r,
      g: targetColor.g,
      b: targetColor.b,
      duration: 1,
    });

    gsap.to(this.ambient, {
      intensity: targetIntensity,
      duration: 1,
    });
  }

  async onAllAssetsLoaded() {

    console.log("All assets loaded");
    this.createSceneObjects();
   await this.createPlanets();
   this.createSky();
    this.setupStageInteractions();

    this.initTestInstancedPointCloud('exploreTexture',false,-32); // Add this line to trigger the test point cloud creation

    this.startIntroSequence();
    // Load the first image

  

    const onMouseClick = (event) => {
      if (this.clickEnabled) {
        console.log("Mouse clicked");
        this.clickEnabled = false;
        window.removeEventListener('click', onMouseClick);
        window.removeEventListener('touchstart', onMouseClick);
      }
    };

    const onMouseUp = (event) => {
      this.clickEnabled = true;
      console.log("Mouse up");
      window.addEventListener('click', onMouseClick, false);
      window.addEventListener('touchstart', onMouseClick, false);
    };

    window.addEventListener('click', onMouseClick, false);
    window.addEventListener('touchstart', onMouseClick, false);
    window.addEventListener('mouseup', onMouseUp, false);
    window.addEventListener('touchend', onMouseUp, false);
  }


  startIntroSequence() {
    const timeline = gsap.timeline();

    // Ensure planets array exists and has elements
    if (!this.planets || this.planets.length === 0) {
      console.error("Planets array is not properly initialized.");
      return;
    }

    // Add a 2 second delay before calling loadCurrentImage
    timeline.call(() => {
      this.loadCurrentImage();

    }, null, "+=0.5");


    // Log planets array
    console.log("planets", this.planets);

    // Add a 1 second delay after calling loadCurrentImage
    timeline.add("afterImageLoad", "+=1");

    this.planets.forEach((planet, index) => {
      // Store the original scale
      const originalScale = 1;
      
      // Set initial scale to 0
      planet.scale.set(0, 0, 0);
      console.log("planet", planet);

      timeline.to(planet.scale, {
        x: originalScale,
        y: originalScale,
        z: originalScale,
        duration: 0.5,
        ease: "back.out(1.7)",
      }, `afterImageLoad+=${index * 0.25}`);
    });

    // Animate planet texts after planets animation
    if (this.planetTexts && this.planetTexts.length > 0) {
      this.planetTexts.forEach((text, index) => {
        // Set initial scale to 0
        text.scale.set(0, 0, 0);

        const scale   = detectDevice()=='phone' ?4.0:2.0;
        timeline.to(text.scale, {
          x: scale,
          y: scale,
          z: scale,
          duration: 0.5,
          ease: "back.out(1.7)",
        }, `>-0.25`); // Start slightly before the previous animation ends
      });
    }
 // Animate planet texts after planets animation
 if (this.planetLines && this.planetLines.length > 0) {
  this.planetLines.forEach((line, index) => {
    // Set initial scale to 0
    line.scale.set(0, 0, 0);

    timeline.to(line.scale, {
      x: 1,
      y: 1,
      z: 1,
      duration: 0.5,
      ease: "back.out(1.7)",
    }, `>-0.25`); // Start slightly before the previous animation ends
  });
}
    // Set isPlaying to false after all animations are complete
    timeline.add(() => {
    
      this.introPlaying = false;
    });

    if (this.buttonVisibilityCallback) {
      this.buttonVisibilityCallback();
    }
  }
  

  onMouseMove(event) {
    this.mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
    this.mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;
  
    const vector = new THREE.Vector3(this.mouse.x, this.mouse.y, 0.5);
    vector.unproject(this.newCamera.threeCamera);
    const dir = vector.sub(this.newCamera.threeCamera.position).normalize();
    const distance = -this.newCamera.threeCamera.position.z / dir.z;
    const pos = this.newCamera.threeCamera.position.clone().add(dir.multiplyScalar(distance));
  
    const point = { x: (pos.x) / 4, y: 1 - (pos.y) / 4 };

    // Update mouse position
    this.mousePosition.x = event.clientX;
    this.mousePosition.y = event.clientY;

    this.checkPlanetHover();
    this.updateVideoPlanePosition();
  }
  async setupStageInteractions() {
    // Create a text mesh for displaying planet names
    this.planetText = await createText('', 
      new THREE.Vector3(0, 15, 0), // Position the text above the planets
      new THREE.Vector3(0, 0, Math.PI)
    );
    this.planetText.name = 'planet_text';
    this.planetText.scale.set(0, 0, 0); // Hide initially
    this.scene.add(this.planetText);


    // Add mouse move event listener
    window.addEventListener('mousemove', this.onMouseMove.bind(this));

    // Preload video textures
    for (const setting of this.imageSettings) {
      if (setting.videoPath) {
        this.videoTextures[setting.name] = await this.createVideoTexture(setting.videoPath);
      }
    }

    // Create video plane
    this.createVideoPlane();
  }
  
  animatePointCloud(targetPositions, startIdleWave = false) {
    if (!this.pointCloud || !this.pointCloud.geometry || !this.pointCloud.geometry.attributes.instanceOffset) {
      console.error("PointCloud or its geometry is not properly initialized.");
      return;
    }
  
    const geometry = this.pointCloud.geometry;
    const offsets = geometry.attributes.instanceOffset.array;
    const numPoints = targetPositions.length;
  
    const initialPositions = [];
    for (let i = 0; i < numPoints; i++) {
      initialPositions.push({
        x: offsets[i * 3],
        y: offsets[i * 3 + 1],
        z: offsets[i * 3 + 2],
      });
    }
  
    gsap.to(initialPositions, {
      duration: 3,
      x: (i) => targetPositions[i].x,
      y: (i) => targetPositions[i].y,
      z: (i) => targetPositions[i].z,
      ease: "power4.inOut",
      onUpdate: () => {
        for (let i = 0; i < numPoints; i++) {
          offsets[i * 3] = initialPositions[i].x;
          offsets[i * 3 + 1] = initialPositions[i].y;
          offsets[i * 3 + 2] = initialPositions[i].z;
        }
        geometry.attributes.instanceOffset.needsUpdate = true;
      },
      onComplete: () => {
        //if (startIdleWave) 
         //this.addSubtleWaveMotion(targetPositions);
      },
    });
  }
  
  

  addSubtleWaveMotion(targetPositions) {
    const offsets = this.pointCloud.geometry.attributes.instanceOffset.array;
    const numPoints = offsets.length / 3;
}



  createSceneObjects() {
    const paletteTexture = this.loader.getAsset("facadeTexture");

    this.p1Material = new Material({
        uniforms: {
            downsideColor: { value: new THREE.Vector3(3 / 255, 3 / 255, 128 / 255) }
        },
        vertexShader: PropShader.vertexShader,
        fragmentShader: PropShader.fragmentShader
    });

    this.standardMaterial = new THREE.MeshPhongMaterial({
        map: paletteTexture,
        receiveShadow: true,
        dithering: true,
        castShadow: true
    });
    this.standardMaterial.shininess = 5;

    this.introLogo = new Model({
        modelLink: 'logoModel',
        material: this.standardMaterial,
        position: new THREE.Vector3(0, 0, 0),
        rotation: new THREE.Vector3(0.0, 0.0, 0.0),
        scale: new THREE.Vector3(0, 0, 0),
        scene: this.scene,
        name: 'introLogo'
    });

    this.introLogo.all_model[0].scale.set(0, 0, 0);

    this.fullLoaded = true;
}


  create_collision_box = (position, size, name) => {
    this.cube = new Mesh(
      new BoxGeometry(size.x, size.y, size.z),
      new MeshBasicMaterial({ color: 0x22ffffff, opacity: 0.1 }),
    );
    this.cube.position.set(position.x, position.y, position.z);
    this.cube.visible = false;
    this.cube.name = name;
    this.cube.layers.set(COLLISION_LAYER);
    this.scene.add(this.cube);
  }



  detectDevice() {
    const userAgent = navigator.userAgent;
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|Windows Phone/i.test(userAgent)) {
      return 'phone';
    }
    return 'computer';
  }

  componentDidMount() {
    console.log("scene 1 mounted");
    this.SceneMount();
    //AudioManager.loadSounds();
    //AudioManager.playSound('music');
    document.addEventListener('click', this.checkPlanetIntersections.bind(this));
    document.addEventListener('touchstart', this.checkPlanetIntersections.bind(this));
  
    this.setCamera(40, 40,window.innerHeight>window.innerWidth ? 85 : 45);
    this.setPostProcess();

  }

  componentWillUnmount() {
    document.removeEventListener('click', this.checkPlanetIntersections.bind(this));
    document.removeEventListener('touchstart', this.checkPlanetIntersections.bind(this));
  
    // Clean up if necessary
  }

  loadListener() {
    document.addEventListener("mousedown", this.onPointerDown.bind(this));
    document.addEventListener("mousemove", this.onPointerMove.bind(this));
    document.addEventListener("mouseup", this.onPointerUp.bind(this));
    document.addEventListener("touchstart", this.onPointerDown.bind(this));
    document.addEventListener("touchmove", this.onPointerMove.bind(this));
    document.addEventListener("touchend", this.onPointerUp.bind(this));
    document.addEventListener("wheel", this.onZoom.bind(this), { passive: false });
  }
  setSectionActive(isActive) {
    this.isSectionActive = isActive;
  }
  onPointerDown(event) {
    this.isPointerDown = true;
    this.lastPointerPosition.set(
      event.clientX || event.touches[0].clientX,
      event.clientY || event.touches[0].clientY
    );
  }

  onPointerMove(event) {
    if (!this.isPointerDown) {
      if (event) {
        this.mouse.x = event.clientX !== undefined ? event.clientX : (event.touches && event.touches.length > 0 ? event.touches[0].clientX : this.mouse.x);
        this.mouse.y = event.clientY !== undefined ? event.clientY : (event.touches && event.touches.length > 0 ? event.touches[0].clientY : this.mouse.y);
      }
      return;
    }

    const clientX = event.clientX || event.touches[0].clientX;
    const clientY = event.clientY || event.touches[0].clientY;

    const deltaX = (clientX - this.lastPointerPosition.x) * 0.25;
    const deltaY = (clientY - this.lastPointerPosition.y) * 0.25;

    // Get the camera's right vector
    const cameraRight = new THREE.Vector3();
    this.newCamera.threeCamera.getWorldDirection(cameraRight);
    cameraRight.cross(this.newCamera.threeCamera.up).normalize();

    // Calculate the movement in world space
    const moveX = cameraRight.multiplyScalar(-deltaX);
    const moveY = new THREE.Vector3(0, deltaY, 0);

    // Update target look-at
    this.targetLookAt.add(moveX);
    this.targetLookAt.add(moveY);

    // Clamp camera position to prevent it from moving too far
    this.targetLookAt.x = THREE.MathUtils.clamp(this.targetLookAt.x, -20, 20);
    this.targetLookAt.y = THREE.MathUtils.clamp(this.targetLookAt.y, -10, 10);
    this.targetLookAt.z = THREE.MathUtils.clamp(this.targetLookAt.z, 0, 40);

    this.lastPointerPosition.set(clientX, clientY);
  }

  onPointerUp() {
    this.isPointerDown = false;
  }

  onZoom(event) {
    event.preventDefault();
    const delta = event.deltaY * this.zoomSpeed;
    this.targetZoom = THREE.MathUtils.clamp(
      this.targetZoom + delta,
      this.minZoom,
      this.maxZoom
    );
  }

  lerp_model() {
    this.spot.lookAt(new THREE.Vector3(0.0, 0.0, 0.0));
    this.spot.position.set(-15, 25, 15);
  }

  updateCamera() {
    if (this.isFixedCamera) {
      this.cameraPosition.lerp(this.targetCameraPosition, this.lerpFactor);
      this.cameraLookAt.lerp(this.targetLookAt, this.lerpFactor);
      this.newCamera.threeCamera.position.copy(this.cameraPosition);
      this.newCamera.threeCamera.lookAt(this.cameraLookAt);
    } else {
      // Lerp the current zoom towards the target zoom
      this.currentZoom = THREE.MathUtils.lerp(this.currentZoom, this.targetZoom, 0.1);

      // Calculate the camera position based on the look-at point and the current zoom level
      const cameraOffset = new THREE.Vector3(0, 70, this.currentZoom);
      this.targetCameraPosition.copy(this.targetLookAt).add(cameraOffset);

      // Lerp camera position and look-at
      this.cameraPosition.lerp(this.targetCameraPosition, this.lerpFactor);
      this.cameraLookAt.lerp(this.targetLookAt, this.lerpFactor);

      // Update camera position and look-at
      this.newCamera.threeCamera.position.copy(this.cameraPosition);
      this.newCamera.threeCamera.lookAt(this.cameraLookAt);
    }

    // Update camera's projection matrix
    this.newCamera.threeCamera.updateProjectionMatrix();
  }
  handlePlanetClick(planetIndex) {
    const planet = this.planets[planetIndex];
    if (this.isPlaying || !planet || this.selectedPlanet) return;
    if (this.hoverCallback) {
      this.hoverCallback(null);
    }   
    this.selectedPlanet = planet;
    this.isFixedCamera = true;
    this.targetCameraPosition.copy(planet.position).add(new THREE.Vector3(0, 0, 20));
    this.targetLookAt.copy(planet.position);
  
    // Trigger image transition
    const { textureName, color, intensity, heightOffset } = this.imageSettings[planetIndex];
    this.triggerImageTransition(textureName, color, intensity, heightOffset);
    this.currentImageIndex = planetIndex;
  
    // Scale down non-selected planets and hide their text
    this.planets.forEach((p, index) => {
      if (index !== planetIndex) {
        gsap.to(p.scale, {
          x: 0,
          y: 0,
          z: 0,
          duration: 1,
          ease: "power2.inOut"
        });
        
        if (p.text) {
          gsap.to(p.text.scale, {
            x: 0,
            y: 0,
            z: 0,
            duration: 1,
            ease: "power2.inOut"
          });
        }
      } else {
        // Optionally, you can scale up the selected planet
        gsap.to(p.scale, {
          x: this.hoverScale,
          y: this.hoverScale,
          z: this.hoverScale,
          duration: 1,
          ease: "power2.inOut"
        });
      }
    });

    // Animate bloomIntensity to 0.1
    gsap.to(this, {
      bloomIntensity: 0.1,
      duration: 1,
      ease: "power2.inOut"
    });

    // Wait for 2.5 seconds before changing the page
    setTimeout(() => {
      console.log('change page');
      window.location.href = this.imageSettings[this.currentImageIndex].route;
    }, 2500);
  }
exitFixedCamera() {
  this.isFixedCamera = false;
return;
  // Restore all planets and their text
  this.planets.forEach((p) => {
    gsap.to(p.scale, {
      x: this.normalScale,
      y: this.normalScale,
      z: this.normalScale,
      duration: 1,
      ease: "power2.inOut"
    });
    
    if (p.text) {
      gsap.to(p.text.scale, {
        x: 2,
        y: 2,
        z: 2,
        duration: 1,
        ease: "power2.inOut"
      });
    }
  });
}
  checkPlanetIntersections(event) {
    if (this.isFixedCamera || !event) 
      return;

    // Check if it's a mouse click or touch event
    if (event.type !== 'click' && event.type !== 'touchstart') 
      return;

    const raycaster = new THREE.Raycaster();
    
    // Calculate mouseNormalized here
    const mouseNormalized = new THREE.Vector2();
    
    if (event.type === 'click') {
      mouseNormalized.x = (event.clientX / window.innerWidth) * 2 - 1;
      mouseNormalized.y = -(event.clientY / window.innerHeight) * 2 + 1;
    } else if (event.type === 'touchstart') {
      mouseNormalized.x = (event.touches[0].clientX / window.innerWidth) * 2 - 1;
      mouseNormalized.y = -(event.touches[0].clientY / window.innerHeight) * 2 + 1;
    }

    raycaster.setFromCamera(mouseNormalized, this.newCamera.threeCamera);
  
    const intersects = raycaster.intersectObjects(this.planets);
    console.log(mouseNormalized);

    if (intersects.length > 0) {
      const intersectedPlanet = intersects[0].object;
      const planetIndex = this.planets.indexOf(intersectedPlanet);
      console.log('intersect');

      if (planetIndex !== -1) {
        this.handlePlanetClick(planetIndex);
      }
    } else {
      this.exitFixedCamera();
      if (this.hoverCallback) {
        this.hoverCallback(null);
      }
    }
  }

  updateSky()
  {
   
      return;
// Update uniforms for greyscale sky
//skyMaterial.uniforms.topColor.value = new THREE.Color(0xdddddd); // Light grey
//skyMaterial.uniforms.bottomColor.value = new THREE.Color(0x000000); // Dark grey
this.skyMaterial.uniforms.sunColor.value = new THREE.Color(0x7d9cad); // Medium grey for sun
this.skyMaterial.uniforms.sunPosition.value.set(
  10,
  100*Math.cos(this.totalTime),
  100*Math.sin(this.totalTime)
);
this.skyMaterial.uniforms.sunIntensity.value = 3.5; // Reduced intensity for greyscale look

this.skyMaterial.needsUpdate = true;
  }
  Update() {

    // Call the new function in the Update method
    if (this.isSectionActive) {
        // Skip camera updates when a section is active
        return;
    }

    // Inside checkPlanetHover or onMouseMove
if (this.hoverCallback && this.selectedPlanet==null) {
  const currentHoverText = this.lastHoveredPlanet ? this.lastHoveredPlanet.userData.hoverText : null;
  const currentHoverIndex = this.lastHoveredPlanet ? this.lastHoveredPlanet.userData.index : null;
  const currentHoverVideoPath = this.lastHoveredPlanet ? this.lastHoveredPlanet.userData.videoPath : null;

  if (currentHoverText !== this.previousHoverText) {
    if (currentHoverText === null) {
      this.hoverCallback(null);
      console.log("null");
    } else if (this.previousHoverText === null) {
      this.hoverCallback(currentHoverText, currentHoverIndex, currentHoverVideoPath);
      console.log("callback");

    }
    this.previousHoverText = currentHoverText;
  }
}
this.currentHoverText = this.lastHoveredPlanet ? this.lastHoveredPlanet.userData.hoverText : null;
    this.updatePlanets();
    this.updateSky();
    this.dirLight.position.set(40*Math.cos(this.totalTime/25), 25, 20*Math.sin(this.totalTime/25));

    // Find the closest planet
    let closestPlanet = null;
    let closestDistance = Infinity;
    this.planets.forEach(planet => {
        const distance = this.newCamera.threeCamera.position.distanceTo(planet.position);
        if (distance < closestDistance) {
            closestDistance = distance;
            closestPlanet = planet;
        }
    });

    // Dynamically set focus based on the closest planet
    const focusDistance = closestDistance;
    const bokehFocus = focusDistance;

    this.updatePostProcessingSettings({
        bloomIntensity: this.bloomIntensity,
        focusDistance: 100,
        bokehAperture: 0.00000025, // Reduced for a less shallow depth of field
        bokehMaxBlur: 0.015, // Reduced for a less extreme blur effect
    });

    const now = performance.now();
    this.dt = (now - this.lastUpdate) * 0.001;
    this.lastUpdate = now;
    this.totalTime = (now - this.startTime) * 0.001;

    // Update the time uniform for the wave motion
    if (this.pointCloud && this.pointCloud.material && this.pointCloud.material.uniforms.time) {
        this.pointCloud.material.uniforms.time.value += this.dt;
    }

    this.lerp_model();

    this.mouseNormalized.x = this.mouse.x / window.innerWidth - 0.5;
    this.mouseNormalized.y = this.mouse.y / window.innerHeight - 0.5;

    


    if (this.isFixedCamera) {
      this.cameraPosition.lerp(this.targetCameraPosition, this.lerpFactor);
      this.cameraLookAt.lerp(this.targetLookAt, this.lerpFactor);
      this.newCamera.threeCamera.position.copy(this.cameraPosition);
      this.newCamera.threeCamera.lookAt(this.cameraLookAt);
    } else {
      this.updateCamera();
    }
    this.newCamera.threeCamera.updateMatrixWorld(true);

    this.boot = true;

    this.checkPlanetHover();
    this.updateVideoPlanePosition();
}

onMouseMove(event) {
  // Update mouse position
  this.mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
  this.mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;

  // Store actual mouse coordinates
  this.mousePosition.x = event.clientX;
  this.mousePosition.y = event.clientY;

  this.checkPlanetHover();
  this.updateVideoPlanePosition();
}

checkPlanetHover() {
  if( this.introPlaying || this.selectedPlanet !=null)
  {
    return;
  }
  if(  !this.newCamera || !this.newCamera.threeCamera) {
    console.warn('Camera not initialized');
    return;
  }

  const raycaster = new THREE.Raycaster();
  raycaster.setFromCamera(this.mouse, this.newCamera.threeCamera);

  const intersects = raycaster.intersectObjects(this.planets);

  if (intersects.length > 0) {
    const hoveredPlanet = intersects[0].object;
    if (this.lastHoveredPlanet !== hoveredPlanet) {
      if (this.lastHoveredPlanet) {
        
        this.scalePlanet(this.lastHoveredPlanet, this.normalScale);
      }
      this.lastHoveredPlanet = hoveredPlanet;
      // Check if hoveredPlanet has the necessary properties
    
      this.scalePlanet(hoveredPlanet, this.hoverScale);
    }
  } else {
    if (this.lastHoveredPlanet) {
     this.scalePlanet(this.lastHoveredPlanet, this.normalScale);
      this.lastHoveredPlanet = null;
      this.hideVideoPlane();
    }
  }
}

async createVideoTexture(videoPath) {
  const video = document.createElement('video');
  video.src = videoPath;
  video.loop = true;
  video.muted = true;
  video.setAttribute('muted', '');
  video.playsInline = true;
  video.autoplay = true;

  const videoTexture = new THREE.VideoTexture(video);
  videoTexture.minFilter = THREE.LinearFilter;
  videoTexture.magFilter = THREE.LinearFilter;
  videoTexture.format = THREE.RGBFormat;

  video.play().catch((error) => {
    console.log('Initial video playback failed:', error);
  });

  return videoTexture;
}

createVideoPlane() {
  const geometry = new THREE.PlaneGeometry(1, 1);
  const material = new THREE.MeshBasicMaterial({ transparent: true, opacity: 0 });
  this.videoPlane = new THREE.Mesh(geometry, material);
  this.videoPlane.visible = false;
  this.scene.add(this.videoPlane);
}

updateVideoPlanePosition() {
  if (this.videoPlane && this.videoPlane.visible) {
    const vector = new THREE.Vector3(
      this.mousePosition.x + this.videoOffset.x,
      this.mousePosition.y + this.videoOffset.y,
      0
    );

    vector.x = (vector.x / window.innerWidth) * 2 - 1;
    vector.y = -(vector.y / window.innerHeight) * 2 + 1;

    vector.unproject(this.newCamera.threeCamera);
    this.videoPlane.position.copy(vector);
    this.videoPlane.lookAt(this.newCamera.threeCamera.position);
  }
}

showVideoPlane(planetName) {
  if (!this.videoPlane) return;

  const videoTexture = this.videoTextures[planetName];
  if (!videoTexture) return;

  this.videoPlane.material.map = videoTexture;
  this.videoPlane.material.needsUpdate = true;
  this.videoPlane.visible = true;

  gsap.to(this.videoPlane.material, {
    opacity: 1,
    duration: 0.3,
    ease: "power2.out"
  });

  gsap.to(this.videoPlane.scale, {
    x: 0.1,
    y: 0.07,
    duration: 0.3,
    ease: "power2.out"
  });

  this.updateVideoPlanePosition();
}

hideVideoPlane() {
  if (!this.videoPlane) return;

  gsap.to(this.videoPlane.material, {
    opacity: 0,
    duration: 0.3,
    ease: "power2.in",
    onComplete: () => {
      this.videoPlane.visible = false;
    }
  });

  gsap.to(this.videoPlane.scale, {
    x: 0,
    y: 0,
    duration: 0.3,
    ease: "power2.in"
  });
}

scalePlanet(planet, scale) {
  gsap.to(planet.scale, {
    x: scale,
    y: scale,
    z: scale,
    duration: 0.3,
    ease: "power2.out"
  });
}

}

export default React.forwardRef((props, ref) => <Voyage {...props} ref={ref} />);
