import * as THREE from 'three';
import { createVideoTexture } from './Helper';
import { detectDevice } from './Helper';
import MotionButton from '../MotionButton';

class ProjectBobots {
  constructor(scene, loader) {
    this.scene = scene;
    this.loader = loader;
    this.sections = [];
    this.wallDistance = -5.5; 

    this.videoTextures = {};
    const { video: mainVideo, videoTexture: mainVideoTexture } = createVideoTexture('/videos/project_bobots_landing.mp4');
    const { video: mintVideo, videoTexture: mintVideoTexture } = createVideoTexture('/videos/project_bobots_mint.mp4');
    const { video: socialVideo, videoTexture: socialVideoTexture } = createVideoTexture('/videos/project_bobots_social.mp4');

    this.videoTextures.main = { video: mainVideo, videoTexture: mainVideoTexture };
    this.videoTextures.mint = { video: mintVideo, videoTexture: mintVideoTexture };
    this.videoTextures.social = { video: socialVideo, videoTexture: socialVideoTexture };
    this.deviceType = detectDevice();

    this.buttonTexture = loader.getAsset('openTexture');
  }

  createSections() {
    this.sections = [
      {
        height: 1.5,
        motionText: {
          text: 'BOBOTS',
          font: '/fonts/Akira Super Bold.ttf',
          size: this.deviceType =='phone'? 0.75: 1.5,
          position: new THREE.Vector3(0, 0, this.wallDistance),
          color: 0xffffff,
          alignment: 'center',
          maxWidth: 12,
          lineHeight: 0.7
        }
      },
      {
        height: 4,
        motionText: {
          text: 'web3 - 2022',
          font: '/fonts/RobotoMono.ttf',
          size: 0.25,
          position: new THREE.Vector3(0, 0, -5.5),
          color: 0xffffff,
          alignment: 'center',
          maxWidth: 9,
          lineHeight: 1.2
        }
      },
     
      // landing page
      {
        height: 4,
        motionMesh: {
          texture:  this.videoTextures.main.videoTexture,
          size: new THREE.Vector2(11, 5),
          position: new THREE.Vector3(0, 10, this.wallDistance),
          rotation: new THREE.Euler(0, 0, 0),
          subdivisions: new THREE.Vector2(64, 64)
        }
      },
      {
        height: 4,
        motionButton: {
          texture: this.buttonTexture,
          size: new THREE.Vector2(2, 0.8),
          position: new THREE.Vector3(0, 0, this.wallDistance),
          rotation: new THREE.Euler(Math.PI,0,  0),
          subdivisions: new THREE.Vector2(32, 32),
          link: 'https://bobots.netlify.app/'
        }
      },
    
      {
        height: 6,
        motionText: {
          text: 'Bobots: A Web3 NFT Game\n\nEnter the world of Bobots, where blockchain meets robotics in an exciting blockchain-powered gaming experience. Collect and trade with unique robot NFTs in a decentralized virtual universe. Leverage the power of Web3 technology to truly own your in-game assets and shape the future of this evolving digital ecosystem.',
          font: '/fonts/RobotoMono.ttf',
          size: 0.25,
          position: new THREE.Vector3(0, 0, -5.5),
          color: 0xffffff,
          alignment: 'center',
          maxWidth: 9,
          lineHeight: 1.2
        }
      },
     
      // mint page
      {
        height: 5,
        motionMesh: {
          texture:  this.videoTextures.mint.videoTexture,
          size: new THREE.Vector2(11, 6),
          position: new THREE.Vector3(0, 10, this.wallDistance),
          rotation: new THREE.Euler(0, 0, 0),
          subdivisions: new THREE.Vector2(64, 64)
        }
      },
       {
        height: 5,
        motionText: {
          text: 'Mint section for Bobots - all characters are minted onchain and viewable on the 3d environment',
          font: '/fonts/RobotoMono.ttf',
          size: 0.25,
          position: new THREE.Vector3(0, 0, -5.5),
          color: 0xffffff,
          alignment: 'center',
          maxWidth: 9,
          lineHeight: 1.2
        }
      },
      // social hub page
      {
        height: 5.5,
        motionMesh: {
          texture:  this.videoTextures.social.videoTexture,
          size: new THREE.Vector2(11, 6),
          position: new THREE.Vector3(0, 10, this.wallDistance),
          rotation: new THREE.Euler(0, 0, 0),
          subdivisions: new THREE.Vector2(64, 64)
        }
      },
      {
        height: 2,
        motionText: {
          text: 'Social hub page for Bobots - Players can select the respective character they own and chat to other players in the same play space. ',
          font: '/fonts/RobotoMono.ttf',
          size: 0.25,
          position: new THREE.Vector3(0, 0, -5.5),
          color: 0xffffff,
          alignment: 'center',
          maxWidth: 9,
          lineHeight: 1.2
        }
      }, 
      
    ];

    return this.sections;
  }
}

export default ProjectBobots;