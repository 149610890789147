import * as THREE from "three";
import ImageMotionShader from "../../../Shaders/ImageMotionShader";
import { gsap } from 'gsap';
import { detectDevice } from './ProjectSections/Helper';

class MotionButton {
  constructor(options) {
    this.texture = options.texture;
    this.size = options.size || new THREE.Vector2(15, 15);
    this.position = options.position || new THREE.Vector3(0, 0, 0);
    this.rotation = options.rotation || new THREE.Euler(0, 0, 0);
    this.subdivisions = options.subdivisions || new THREE.Vector2(64, 64);
    this.scene = options.scene;
    this.renderOrder = options.renderOrder || 0;
    this.link = options.link || ''; // New property for the link

    this.initialPosition = this.position.clone();

    this.isVisible = false;
    this.isAppearing = false;
    this.isDisappearing = false;
    this.transitionProgress = 0;

    this.createMesh();
    this.createCollisionMesh();
    this.setupInteraction();
  }

  createMesh() {
    const geometry = new THREE.PlaneGeometry(
      this.size.x,
      this.size.y,
      this.subdivisions.x,
      this.subdivisions.y
    );

    this.material = new THREE.ShaderMaterial({
      uniforms: {
        ...ImageMotionShader.uniforms,
        imageTex: { value: this.texture },
        waveHeight: { value: 0.1 },
        waveFrequency: { value: 2.0 },
        waveSpeed: { value: 1.0 },
        touchTexture: { value: null },
        transitionProgress: { value: 0 },
        pixelSize: { value: 20 }, // Adjust for desired pixelation,
        
      },
      vertexShader: ImageMotionShader.vertexShader,
      fragmentShader: ImageMotionShader.fragmentShader,
      transparent: true,
      blending: THREE.AdditiveBlending, // Changed from AdditiveBlending
      side: THREE.DoubleSide,
      depthWrite: false, // Disable depth writing
    });

    this.mesh = new THREE.Mesh(geometry, this.material);
    this.mesh.renderOrder = this.renderOrder;
    
    // Create a group and add the mesh to it
    this.group = new THREE.Group();
    this.group.add(this.mesh);
    this.group.position.copy(this.position);
    this.group.rotation.copy(this.rotation);

    // Adjust size based on device type
    const deviceType = detectDevice();
    this.group.scale.set(deviceType === 'phone' ? 0.75 : 1, deviceType === 'phone' ? 0.75 : 1, 1);

    if (this.scene) {
      this.scene.add(this.group);
    }
  }

  createCollisionMesh() {
    const geometry = new THREE.PlaneGeometry(this.size.x, this.size.y);
    const material = new THREE.MeshBasicMaterial({ 
      visible: false,
      side: THREE.DoubleSide // Make the material double-sided
    });
    this.collisionMesh = new THREE.Mesh(geometry, material);
    this.collisionMesh.name = 'ButtonCollisionMesh';
    this.group.add(this.collisionMesh);

    // Ensure the collision mesh follows the rotation of the button
    this.collisionMesh.rotation.copy(this.mesh.rotation);
  }

  setupInteraction() {
    this.mesh.userData.clickable = true;
    this.mesh.userData.link = this.link;
  }

  onClick() {
    if (this.link) {
      window.open(this.link, '_blank');
    }
  }

  show() {
    if (!this.isVisible && !this.isAppearing) {
      console.log('show MotionButton');
      this.isAppearing = true;
      this.isVisible = true;
      this.group.visible = true;
      gsap.to(this.material.uniforms.transitionProgress, {
        value: 1,
        duration: 1,
        ease: "power2.inOut",
        onUpdate: () => {
          this.transitionProgress = this.material.uniforms.transitionProgress.value;
        },
        onComplete: () => {
          this.isAppearing = false;
        }
      });
    }
  }

  hide() {
    return;
    if (this.isVisible && !this.isDisappearing) {
      console.log('hide MotionMesh');
      this.isDisappearing = true;
      gsap.to(this.material.uniforms.transitionProgress, {
        value: 0,
        duration: 1,
        ease: "power2.inOut",
        onUpdate: () => {
          this.transitionProgress = this.material.uniforms.transitionProgress.value;
        },
        onComplete: () => {
          this.isVisible = false;
          this.isDisappearing = false;
          this.group.visible = false;
        }
      });
    }
  }

  update(deltaTime) {
    if (this.material) {
      this.material.uniforms.time.value += deltaTime;
      // You might want to update transitionProgress here if not using GSAP
    }

    // Ensure the collision mesh always matches the main mesh's transformation
    if (this.collisionMesh && this.mesh) {
      this.collisionMesh.position.copy(this.mesh.position);
      this.collisionMesh.rotation.copy(this.mesh.rotation);
      this.collisionMesh.scale.copy(this.mesh.scale);
    }
  }

  setWaveProperties(height, frequency, speed) {
    if (this.material) {
      this.material.uniforms.waveHeight.value = height;
      this.material.uniforms.waveFrequency.value = frequency;
      this.material.uniforms.waveSpeed.value = speed;
    }
  }

  updateTouchTexture(texture) {
    this.touchTexture = texture;
    if (this.material) {
      this.material.uniforms.touchTexture.value = this.touchTexture;
    }
  }

  updateResolution(resolution) {
    if (this.material) {
      this.material.uniforms.resolution.value = resolution;
    }
  }
}

export default MotionButton;