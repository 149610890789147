import * as THREE from 'three';
export function  detectDevice() {
    if (navigator.userAgent.match(/Android/i)
      || navigator.userAgent.match(/webOS/i)
      || navigator.userAgent.match(/iPhone/i)
      || navigator.userAgent.match(/iPad/i)
      || navigator.userAgent.match(/iPod/i)
      || navigator.userAgent.match(/BlackBerry/i)
      || navigator.userAgent.match(/Windows Phone/i)
    ) {
      return 'phone';
    } else {
      return 'computer';
    }
  }
export function createVideoTexture(source) {
    // Create video element
    const video = document.createElement('video');
    video.src = source; // Update with your video path
    video.loop = true;
    video.muted = true;
    video.setAttribute('muted', '');
    video.playsInline = true;
    video.autoplay = true;

    // Create video texture
    const videoTexture = new THREE.VideoTexture(video);
    videoTexture.minFilter = THREE.LinearFilter;
    videoTexture.magFilter = THREE.LinearFilter;
    videoTexture.format = THREE.RGBFormat;

    // Attempt to play the video
    const playVideo = () => {
      video.play().catch(error => {
        console.log('Video playback failed:', error);
      });
    };

    // Try to play immediately
    playVideo();

    // Also try to play on user interaction
    document.addEventListener('click', playVideo, { once: true });

    return { video, videoTexture };
}