class Health {
  constructor(initialHealth = 100, maxHealth = 100) {
    this.currentHealth = initialHealth;
    this.maxHealth = maxHealth;
  }

  // Reduces the current health by the specified amount, ensuring it doesn't go below 0
  takeDamage(amount) {
    this.currentHealth = Math.max(0, this.currentHealth - amount);
    return this.currentHealth;
  }

  // Increases the current health by the specified amount, ensuring it doesn't exceed max health
  heal(amount) {
    this.currentHealth = Math.min(this.maxHealth, this.currentHealth + amount);
    return this.currentHealth;
  }

  // Sets a new maximum health and adjusts current health if necessary
  setMaxHealth(newMax) {
    this.maxHealth = newMax;
    this.currentHealth = Math.min(this.currentHealth, this.maxHealth);
  }

  // Returns the current health
  getCurrentHealth() {
    return this.currentHealth;
  }

  // Returns the maximum health
  getMaxHealth() {
    return this.maxHealth;
  }

  // Checks if the current health is 0 or less
  isDead() {
    return this.currentHealth <= 0;
  }

  // Resets the current health to the maximum health
  reset() {
    this.currentHealth = this.maxHealth;
  }
}

export default Health;
