import AudioManager from '../components/Scenes/ArtCode/AudioManager';

export const getAudioState = (): boolean => {
  const state = localStorage.getItem('audioState');
  return state === null ? true : state === 'true';
};

export const setAudioState = (isOn: boolean): void => {
  localStorage.setItem('audioState', isOn.toString());
  if (isOn) {
    AudioManager.unmute();
  } else {
    AudioManager.mute();
  }
};

export const toggleAudioState = (): boolean => {
  const newState = !getAudioState();
  setAudioState(newState);
  return newState;
};