import * as THREE from "three";

class SmokeEmitter {
    constructor(scene, texture, noiseTexture) {
        this.scene = scene;
        this.particleSystems = [];
        this.particleGeometry = new THREE.PlaneGeometry(1, 1);
        this.particleMaterial = new THREE.MeshBasicMaterial({
            map: texture,
            transparent: true,
            depthWrite: false,
            blending: THREE.AdditiveBlending,
            side: THREE.DoubleSide
        });
        this.spawnRate = 4;
        this.lastSpawnTime = 0;
    }

    spawn(position, maxParticles = 100) {
        const instancedMesh = new THREE.InstancedMesh(this.particleGeometry, this.particleMaterial, maxParticles);
        instancedMesh.instanceMatrix.setUsage(THREE.DynamicDrawUsage);
        const dummy = new THREE.Object3D();
        const particles = [];

        this.scene.add(instancedMesh);

        this.particleSystems.push({ mesh: instancedMesh, particles, position, maxParticles });
    }

    createParticle(position) {
        return {
            position: new THREE.Vector3(
                position.x + THREE.MathUtils.randFloatSpread(0.5),
                position.y,
                position.z + THREE.MathUtils.randFloatSpread(0.5)
            ),
            velocity: new THREE.Vector3(
                0,
                THREE.MathUtils.randFloat(0.002, 0.003),
                0
            ),
            scale: THREE.MathUtils.randFloat(2.0, 4.2),
            rotation: new THREE.Euler(0, 0, THREE.MathUtils.randFloat(0, Math.PI * 2)),
            lifetime: 0,
            maxLifetime: THREE.MathUtils.randInt(5000, 7000),
            opacity: 0
        };
    }

    updateDummy(dummy, particle) {
        dummy.position.copy(particle.position);
        dummy.scale.set(particle.scale, particle.scale, 1);
        dummy.rotation.copy(particle.rotation);
        dummy.updateMatrix();
    }

    update(deltaTime) {
        const currentTime = performance.now();
        const elapsedTime = (currentTime - this.lastSpawnTime) / 1000;

        this.particleSystems.forEach((system) => {
            const { mesh, particles, position, maxParticles } = system;
            const dummy = new THREE.Object3D();

            if (elapsedTime > 1 / this.spawnRate && particles.length < maxParticles) {
                const newParticle = this.createParticle(position);
                particles.push(newParticle);
                this.lastSpawnTime = currentTime;
            }

            particles.forEach((particle, index) => {
                this.updateParticle(particle, deltaTime);
                this.updateDummy(dummy, particle);
                mesh.setMatrixAt(index, dummy.matrix);
                mesh.setColorAt(index, new THREE.Color().setHSL(0, 0, particle.opacity));
            });

            if(mesh && mesh.instanceMatrix) {
                mesh.instanceMatrix.needsUpdate = true;
                if (mesh.instanceColor) {
                    mesh.instanceColor.needsUpdate = true;
                }
            }
        });
    }

    updateParticle(particle, deltaTime) {
        particle.position.add(particle.velocity.clone().multiplyScalar(deltaTime));
        particle.rotation.y = Math.PI / 2;
        
        particle.lifetime += deltaTime;
        
        const fadeInDuration = 500;
        const fadeOutDuration = 2000;
        
        if (particle.lifetime < fadeInDuration) {
            particle.opacity = (particle.lifetime / fadeInDuration) * 0.1;
        } else if (particle.lifetime > particle.maxLifetime - fadeOutDuration) {
            const fadeOutProgress = (particle.lifetime - (particle.maxLifetime - fadeOutDuration)) / fadeOutDuration;
            particle.opacity = (1 - fadeOutProgress) * 0.1;
        } else {
            particle.opacity = 0.1;
        }

        particle.opacity = Math.max(0, Math.min(1, particle.opacity)) ;

        if (particle.lifetime > particle.maxLifetime) {
            const systemIndex = this.particleSystems.findIndex(system => system.particles.includes(particle));
            if (systemIndex !== -1) {
                const particleIndex = this.particleSystems[systemIndex].particles.indexOf(particle);
                if (particleIndex !== -1) {
                    this.particleSystems[systemIndex].particles.splice(particleIndex, 1);
                }
            }
        }
    }

    remove() {
        this.particleSystems.forEach(system => this.scene.remove(system.mesh));
        this.particleSystems = [];
    }
}

export default SmokeEmitter;