import * as THREE from "three";
import { Vector2, Vector3, Raycaster, BufferGeometry, BufferAttribute, PointsMaterial, Points } from "three";
import React from 'react';
import Model from "../../../Render/Model";
import Material from "../../../Render/Material";
import LoadingManager from "../../../Render/LoadingManager";
import Scene from "../Base/Scene";
import { PropShader } from "../../../Shaders/PropShader";

import PhysicsSystem from './PhysicsSystem';
import * as CANNON from 'cannon-es';
import ProjectSections from './ProjectSections';
import gsap from 'gsap';
import { loadFont } from '../Base/Helper';
import { Reflector } from 'three/examples/jsm/objects/Reflector.js';
import AudioManager from '../../../components/Scenes/ArtCode/AudioManager'; // Import AudioManager
import { getAudioState } from '../../../utils/audioState';

class Projects extends Scene {
  constructor(_options) {
    super(_options);
    this.isSectionActive = _options.isSectionActive;

    this.clickTimeout = 0;
    this.scrollDelta = this.detectDevice() === 'phone' ? 100 : 25;

    this.mouse = new THREE.Vector2();

    this.hide = false;
    this.current = 0;
    this.clickEnabled = true;

    this.mouse = new Vector2(0, 0);
    this.mouseNormalized = new Vector2(0, 0);
    this.lerpedMouse = new Vector2(0, 0);
    this.lerpedMouseRaw = new Vector2(0, 0);
    this.boot = false;
    this.fullLoaded = false;

    this.lastUpdate = performance.now();
    this.totalTime = 0.0;
    this.startTime = performance.now();

    this.ambient = new THREE.AmbientLight(0xffffff,0.35);
    this.dirLight = new THREE.DirectionalLight(0xffffff, 1.5);
    this.dirLight.position.set(15, 10, 10);
    this.dirLight.castShadow = true;
    this.dirLight.shadow.mapSize.width = 1024;
    this.dirLight.shadow.mapSize.height = 1024;
    this.dirLight.shadow.camera.near = 1;
    this.dirLight.shadow.camera.far = 100;
    this.dirLight.shadow.camera.left = -50;
    this.dirLight.shadow.camera.right = 50;
    this.dirLight.shadow.camera.top = 50;
    this.dirLight.shadow.camera.bottom = -50;
    this.scene.add(this.dirLight);

    this.scene.add(this.ambient);

    this.startRender();

    this.isPointerDown = false;
    this.lastPointerPosition = new THREE.Vector2();

    this.requiredAssets = [
      { name: 'facadeModel', source: '/assets/facade.glb' },
      { name: 'facadeTexture', source: '/assets/facade_render.jpg', type: 'texture' },
      { name: 'voyageTexture', source: '/assets/voyage-logo.png', type: 'texture' },
      { name: 'logo3domTexture', source: '/assets/logo-3dom.png', type: 'texture' },
      { name: 'sunsetTexture', source: '/assets/sunset.jpg', type: 'texture' },
      { name: 'circleTexture', source: '/assets/circle.png', type: 'texture' },
      { name: 'logoModel', source: '/assets/logo.glb' },
      { name: 'whiteTexture', source: '/assets/landing/white.jpg', type: 'texture' },
      { name: 'ejectTexture', source: '/assets/projects/eject.png', type: 'texture' },
      { name: 'openTexture', source: '/assets/projects/open.png', type: 'texture' },

      // floppy model assets
      { name: 'floppyModel', source: '/assets/project_works_floppy.glb' },
      { name: 'readerModel', source: '/assets/project_works_disk_reader.glb' },
      { name: 'monitorModel', source: '/assets/project_works_monitor.glb' },

      { name: 'floppyBaseColorTexture', source: '/assets/projects/DefaultMaterial_BaseColor.jpg', type: 'texture' },
      { name: 'floppyMetallicTexture', source: '/assets/projects/DefaultMaterial_Metallic.jpg', type: 'texture' },
      { name: 'floppyNormalTexture', source: '/assets/projects/DefaultMaterial_Normal.jpg', type: 'texture' },
      { name: 'floppyRoughnessTexture', source: '/assets/projects/DefaultMaterial_Roughness.jpg', type: 'texture' },
      
      { name: 'floppySimpleBaseColorTexture', source: '/assets/projects/FloppySimple_BaseColor.jpg', type: 'texture' },
      { name: 'floppyIsoFolioBaseColorTexture', source: '/assets/projects/FloppyFolio_BaseColor.jpg', type: 'texture' },


      { name: 'diskReaderRoughnessTexture', source: '/assets/projects/DiskReader_Roughness.jpg', type: 'texture' },
      { name: 'diskReaderBaseColorTexture', source: '/assets/projects/DiskReader_BaseColor.jpg', type: 'texture' },
      { name: 'diskReaderMetallicTexture', source: '/assets/projects/DiskReader_Metallic.jpg', type: 'texture' },
      { name: 'diskReaderNormalTexture', source: '/assets/projects/DiskReader_Normal.jpg', type: 'texture' },
      { name: 'diskReaderAlphaTexture', source: '/assets/projects/DiskReader_Alpha.jpg', type: 'texture' },

      { name: 'dustTexture', source: '/assets/dust.png', type: 'texture' },
    ];
    this.loadedAssets = 0;
    this.totalAssets = this.requiredAssets.length;

    this.loadingManager = new LoadingManager();
    this.loader = this.loadingManager.loader;
    this.initializeLoading();

    this.hoverOnCollisionBox = false;
    this.hoveredText = null;
    this.originalCameraPosition = new THREE.Vector3(0, 20, 30);
    this.originalCameraTarget = new THREE.Vector3(0, 0, 0);
    this.selectedObject = null;
    this.selectedObjectIndex = -1;
    this.imageMaterial = null;
    this.imageMesh = null;
    this.motionMeshes = [];

    this.physicsSystem = null;
    this.raycaster = new THREE.Raycaster();
    this.mouse = new THREE.Vector2();
    this.dragPlaneY = 4; // Set the Y position for the drag plane

    this.cameraTarget = new THREE.Vector3(0, 0, 0);
    this.targetCameraPosition = new THREE.Vector3(0, 20, 30);

    this.reflectorPlane = null;
    //this.createReflectorPlane();

    this.pointLight = null;
    this.isProjectSelected = false;
    this.blinkTime = 0;
    this.blinkSpeed = 2; // Adjust this to change the blink speed
    this.createPointLight();

    this.particles = null;
    this.particleCount = 1000;
    this.particlePositions = new Float32Array(this.particleCount * 3);
    this.particleVelocities = new Float32Array(this.particleCount * 3);

    this.addEventListeners();

    // Add a callback for camera movement
    this.onCameraMove = null;

    this.floppyDisks = [];
    this.isAnimatingDisks = false;
  }

  initPhysics() {
    this.physicsSystem = new PhysicsSystem(this.scene);
    this.physicsSystem.initPhysics(); // Make sure this line is here
    this.createPhysicsObjects();
  }

  createPhysicsObjects() {
    // create floppy
    const floppyMaterial = new THREE.MeshStandardMaterial({
      map: this.loader.getAsset('floppyBaseColorTexture'),
      
      metalnessMap: this.loader.getAsset('floppyMetallicTexture'),
      normalMap: this.loader.getAsset('floppyNormalTexture'),
      roughnessMap: this.loader.getAsset('floppyRoughnessTexture'),
      envMap: this.cubemap,
      metalness: 0.75,
      roughness: 0.75,
    });

        // Create the second floppy with floppySimpleBaseColorTexture
        const floppySimpleMaterial = new THREE.MeshStandardMaterial({
          map: this.loader.getAsset('floppySimpleBaseColorTexture'),
          envMap: this.cubemap,
          metalnessMap: this.loader.getAsset('floppyMetallicTexture'),
          normalMap: this.loader.getAsset('floppyNormalTexture'),
          roughnessMap: this.loader.getAsset('floppyRoughnessTexture'),
          metalness: 0.75,
          roughness: 0.75,
        });
        // Create the third floppy with floppyIsoFolioBaseColorTexture
        const floppyIsoFolioMaterial = new THREE.MeshStandardMaterial({
          map: this.loader.getAsset('floppyIsoFolioBaseColorTexture'),
          metalnessMap: this.loader.getAsset('floppyMetallicTexture'),
          normalMap: this.loader.getAsset('floppyNormalTexture'),
          roughnessMap: this.loader.getAsset('floppyRoughnessTexture'),
          envMap: this.cubemap,
          metalness: 0.75,
          roughness: 0.75,
        });
    
    this.floppyModel = new Model({
      modelLink: 'floppyModel',
      material: floppyMaterial,
      position: new THREE.Vector3(0, 0, 0),
      rotation: new THREE.Vector3(0, 0, 0),
      scale: new THREE.Vector3(1, 1, 1),
      scene: this.scene,
      name: 'floppyModel'
    });


    const floppySimpleModel = new Model({
      modelLink: 'floppyModel',
      material: floppySimpleMaterial,
      position: new THREE.Vector3(0, 0, 0),
      rotation: new THREE.Vector3(0, 0, 0),
      scale: new THREE.Vector3(1, 1, 1),
      scene: this.scene,
      name: 'floppySimpleModel'
    });

    const projects = [
      { id: 'projectBobots', color: 0x00ff00, material: floppySimpleMaterial },
      { id: 'projectFolio', color: 0x0000ff, material: floppyIsoFolioMaterial },
    ];

    this.floppyDisks = projects.map((project, index) => {
      const boxSize = new THREE.Vector3(0.15, 10, 10);
      //const randomX = Math.random() * 20 - 10;
      //const randomZ = Math.random() * 20 - 10;
      const startY = 90 ; // Start position above the scene
      const startPos = new THREE.Vector3(index*4 , startY, index*4 - 2);
      
      const geometry = index === 1 ? floppySimpleModel.all_model[0].clone() : this.floppyModel.all_model[0].clone();
      const material = project.material;
      geometry.material = material;
      const meshSize = new THREE.Vector3(1, 2.5, 2.5);
      const box = this.physicsSystem.createModel(geometry, meshSize, boxSize, startPos, 1);
      box.body.userData = { projectId: project.id };
      
      // Disable physics initially
      box.body.type = CANNON.Body.KINEMATIC;
      
      return box;
    });

    // Start the animation sequence
    this.animateFloppyDisks();

    // Create floor
    const startYPos = 10;
    const floorSize = new THREE.Vector2(20, 20);
    const floorPos = new THREE.Vector3(0, 0, 0); // Floor at y=0
    this.physicsSystem.createFloor(floorSize, floorPos);

    // Create collision box
    const collisionBoxSize = new THREE.Vector3(12, 5, 6);
    const collisionBoxPosition = new THREE.Vector3(0, 5, 0); // Adjust position as needed
    // Create disk reader model for collision box
    const readerMaterial = new THREE.MeshStandardMaterial({
      map: this.loader.getAsset('diskReaderBaseColorTexture'),
      normalMap: this.loader.getAsset('diskReaderNormalTexture'),
      roughnessMap: this.loader.getAsset('diskReaderRoughnessTexture'),
      metalnessMap: this.loader.getAsset('diskReaderMetallicTexture'),
      envMap: this.cubemap,
      envMapIntensity: 1.5,
      metalness: 0.65,
      roughness: 0.45,

    });

    this.readerModel = new Model({
      modelLink: 'readerModel',
      material: readerMaterial,
      position: collisionBoxPosition,
      rotation: new THREE.Vector3(0, 0, 0),
      scale: new THREE.Vector3(3, 3, 3), // Adjust scale as needed
      scene: this.scene,
      name: 'diskReaderModel'
    });

    this.readerModel.all_model[0].position.set(0, 3, 0);
    this.readerModel.all_model[0].rotation.set(0,  Math.PI/2, 0);
    this.readerModel.all_model[0].scale.set(2,2,2);
    // Adjust the collision box size and position to match the disk reader model
    collisionBoxSize.set(7, 6, 12); // Adjust these values to match the model's dimensions
    collisionBoxPosition.set(-0.25, 1.5, -4); // Adjust the Y position to half the height


    
    this.physicsSystem.createCollisionBox(collisionBoxSize, collisionBoxPosition);

    
    //this.visualizeBoundary();
  }

  visualizeBoundary() {
    const { min, max } = this.physicsSystem.boundary;
    const boundaryGeometry = new THREE.BoxGeometry(
      max.x - min.x,
      max.y - min.y,
      max.z - min.z
    );
    const boundaryMaterial = new THREE.MeshBasicMaterial({
      color: 0xffffff,
      wireframe: true,
      transparent: true,
      opacity: 0.1
    });
    const boundaryMesh = new THREE.Mesh(boundaryGeometry, boundaryMaterial);
    boundaryMesh.position.set(
      (min.x + max.x) / 2,
      (min.y + max.y) / 2,
      (min.z + max.z) / 2
    );
    this.scene.add(boundaryMesh);
  }

  initializeLoading() {
    this.loader.groups.assets = [{ name: 'paradeAssets', items: this.requiredAssets }];
    this.loader.on('fileEnd', this.onFileLoaded.bind(this));
    this.loader.loadNextGroup();
  }

  onFileLoaded(_resource, _data) {
    this.loadedAssets++;
    if (this.loadedAssets === this.totalAssets) {
      this.onAllAssetsLoaded();
    }
  }

  updatePostProcessingSettings({ bloomIntensity, bokehAperture, focusDistance }) {
    if (this.postProcess) {
      if (bloomIntensity !== undefined && this.postProcess.bloomPass) {
        this.postProcess.bloomPass.strength = bloomIntensity;
      }
      if (bokehAperture !== undefined && this.postProcess.bokehPass) {
        this.postProcess.bokehPass.materialBokeh.uniforms['aperture'].value = bokehAperture;
      }
      if (focusDistance !== undefined && this.postProcess.bokehPass) {
        this.postProcess.bokehPass.materialBokeh.uniforms['focus'].value = focusDistance;
      }
    }
  }

  onAllAssetsLoaded() {
    this.createSceneObjects();
    this.initPhysics();


    this.projectSections = new ProjectSections(this.scene,this.newCamera.threeCamera,this.renderer,this.onProjectUnselected.bind(this),this.loader);
    this.projectSections.createProjectSectionMeshes(this.loader);
   // this.projectSections.createEjectButton();
    this.fullLoaded = true;

  }

  createSceneObjects() {
    const cubeTextureLoader = new THREE.CubeTextureLoader();
    this.cubemap = cubeTextureLoader.load([
      '/assets/parliament/posx.jpg',
      '/assets/parliament/negx.jpg',
      '/assets/parliament/posy.jpg',
      '/assets/parliament/negy.jpg',
      '/assets/parliament/posz.jpg',
      '/assets/parliament/negz.jpg'
    ]);
   
    const paletteTexture = this.loader.getAsset("facadeTexture");

    this.p1Material = new Material({
      uniforms: {
        downsideColor: { value: new THREE.Vector3(3 / 255, 3 / 255, 128 / 255) }
      },
      vertexShader: PropShader.vertexShader,
      fragmentShader: PropShader.fragmentShader
    });

    this.standardMaterial = new THREE.MeshPhongMaterial({
      map: paletteTexture,
      receiveShadow: true,
      dithering: true,
      castShadow: true,
      color: 0x888888
    });
    this.standardMaterial.shininess = 5;

    this.p1model = new Model({
      modelLink: 'facadeModel',
      material: this.standardMaterial,
      position: new THREE.Vector3(17, 1, 0),
      rotation: new THREE.Vector3(0.0, 0.0, 0.0),
      scale: new THREE.Vector3(0, 0, 0),
      scene: this.scene,
      name: 'facadeModel'
    });
    this.p1model.all_model[0].position.set(8.5, 0, 8.5);
    this.p1model.all_model[0].scale.set(2,1.5,2);


    // Add ambient light
    const ambientLight = new THREE.AmbientLight(0xffffff,1);
    this.scene.add(ambientLight);

    // Add directional light
    const directionalLight = new THREE.DirectionalLight(0xffffff, 0.5);
    directionalLight.position.set(5, 10, 7);
    this.scene.add(directionalLight);

    this.createPointLight();
    this.createParticles();

    this.fullLoaded = true;
  }

  createReflectorPlane() {
    const geometry = new THREE.PlaneGeometry(100, 100);
    this.reflectorPlane = new Reflector(geometry, {
      clipBias: 0.003,
      textureWidth: window.innerWidth * window.devicePixelRatio,
      textureHeight: window.innerHeight * window.devicePixelRatio,
      color: 0x777777
    });
    this.reflectorPlane.position.y = 0.05; // Adjust this value to position the plane
    this.reflectorPlane.rotateX(-Math.PI / 2); // Rotate to be horizontal
    this.scene.add(this.reflectorPlane);
  }

  createPointLight() {
    this.pointLight = new THREE.PointLight(0x00ff00, 10, 10); // Green color, high intensity, short distance
    this.pointLight.position.set(-3, 1.5, 3.75); // Adjust position as needed
    this.pointLight.visible = false; // Initially set to invisible
    this.scene.add(this.pointLight);
  }

  updatePointLight(time) {
    if (this.physicsSystem && this.physicsSystem.draggedBody) {
      this.pointLight.visible = true;
      // Oscillate the intensity using a cosine function
      const baseIntensity = 10; // Base intensity
      const intensityVariation = 5; // Amount of variation
      const frequency =this.hoverOnCollisionBox?10: 2; // Adjust this to change the speed of oscillation
      this.pointLight.intensity = intensityVariation+baseIntensity * (Math.cos(frequency * this.totalTime)+0.5);
    } else {
      this.pointLight.visible = false;
    }
  }

  setProjectSelected(isSelected) {
    this.isProjectSelected = isSelected;
  }
  animateFloppyDisks() {
    this.isAnimatingDisks = true;
    const animationDuration = 2.5;
    const delayBetweenDisks = 0.0;

    this.floppyDisks.forEach((disk, index) => {
      //disk.mesh.position=0;
      const targetY = 20; // Random end height between 10 and 15
      
      gsap.to(disk.mesh.position, {
        z:70,
        y: targetY,
        x:index*4 - 2,
        duration: animationDuration,
        delay: index * delayBetweenDisks,
        ease: "power.out",
        onUpdate: () => {
          // Update the physics body position to match the mesh during animation
          disk.body.position.copy(disk.mesh.position);
        },
        onComplete: () => {
          this.projectSections.isAnimationComplete = true;
          if (index === this.floppyDisks.length - 1) {
            this.enablePhysicsForDisks();
          }
        }
      });

      // Rotate the disk during animation
      gsap.to(disk.mesh.rotation, {
        x: 0,
        y:  -Math.PI /2 *0.9,
        z: 0,
        duration: animationDuration,
        delay: index * delayBetweenDisks,
        onUpdate: () => {
          // Update the physics body rotation to match the mesh during animation
          disk.body.quaternion.copy(disk.mesh.quaternion);
        }
      });
    });
  }

  enablePhysicsForDisks() {
    this.floppyDisks.forEach(disk => {
      disk.body.type = CANNON.Body.DYNAMIC;
      
      // Apply random velocity
      const randomVelocity = new CANNON.Vec3(
        (Math.random() - 0.5) * 100, // Random X velocity
        -Math.random() * 5,          // Random Y velocity (upward)
        (Math.random() - 0.5) * 100  // Random Z velocity
      );
      disk.body.velocity.copy(randomVelocity);
      
      // Apply random rotation
      const randomRotation = new CANNON.Vec3(
        Math.random() * Math.PI,
        Math.random() * Math.PI,
        Math.random() * Math.PI
      );
      disk.body.angularVelocity.copy(randomRotation);
    });

    this.isAnimatingDisks = false;
  }
  createParticles() {
    const geometry = new BufferGeometry();
    const dustTexture = this.loader.getAsset("dustTexture");
    const material = new PointsMaterial({
      color: 0xffffff,
      size: 2.5, // Reduced size
      transparent: true,
      opacity: 0.05, // Reduced opacity
      map: dustTexture,
      blending: THREE.AdditiveBlending,
      depthWrite: false, // Disable depth writing
      depthTest: true, // Enable depth testing
    });

    // Initialize particles in random positions
    for (let i = 0; i < this.particleCount * 3; i += 3) {
      this.particlePositions[i] = (Math.random() - 0.5) * 100;
      this.particlePositions[i + 1] = (Math.random() - 0.5) * 100;
      this.particlePositions[i + 2] = (Math.random() - 0.5) * 100;

      // Initialize velocities
      this.particleVelocities[i] = (Math.random() - 0.5) * 0.1;
      this.particleVelocities[i + 1] = (Math.random() - 0.5) * 0.1;
      this.particleVelocities[i + 2] = (Math.random() - 0.5) * 0.1;
    }

    geometry.setAttribute('position', new BufferAttribute(this.particlePositions, 3));
    this.particles = new Points(geometry, material);
    this.scene.add(this.particles);
  }

  updateParticles() {
    if(!this.particles) return;
    const positions = this.particles.geometry.attributes.position.array;

    for (let i = 0; i < positions.length; i += 3) {
      // Update positions based on velocities
      positions[i] += this.particleVelocities[i];
      positions[i + 1] += this.particleVelocities[i + 1];
      positions[i + 2] += this.particleVelocities[i + 2];

      // Wrap particles around if they go out of bounds
      if (Math.abs(positions[i]) > 50) positions[i] *= -0.9;
      if (Math.abs(positions[i + 1]) > 50) positions[i + 1] *= -0.9;
      if (Math.abs(positions[i + 2]) > 50) positions[i + 2] *= -0.9;

      // Reduce the random movement
      this.particleVelocities[i] += (Math.random() - 0.5) * 0.005;
      this.particleVelocities[i + 1] += (Math.random() - 0.5) * 0.005;
      this.particleVelocities[i + 2] += (Math.random() - 0.5) * 0.005;

      // Increase damping
      this.particleVelocities[i] *= 0.98;
      this.particleVelocities[i + 1] *= 0.98;
      this.particleVelocities[i + 2] *= 0.98;
    }

    this.particles.geometry.attributes.position.needsUpdate = true;
  }

  detectDevice() {
    const userAgent = navigator.userAgent;
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|Windows Phone/i.test(userAgent)) {
      return 'phone';
    }
    return 'computer';
  }

  componentDidMount() {
    //AudioManager.loadSounds();

    this.SceneMount();
    //AudioManager.playSound('music');

    this.setCamera(40, 40, window.innerHeight > window.innerWidth ? 55 : 35);
    this.setPostProcess();


  }

  componentWillUnmount() {
    // Clean up if necessary
    //AudioManager.stop('ambient');
  }

  

  setSectionActive(isActive) {
    this.isSectionActive = isActive;
  }

 


  addEventListeners() {
    document.addEventListener('mousedown', this.onMouseDown.bind(this));
    document.addEventListener('mousemove', this.onMouseMove.bind(this));
    document.addEventListener('mouseup', this.onMouseUp.bind(this));
    document.addEventListener('touchstart', this.onTouchStart.bind(this));
    document.addEventListener('touchmove', this.onTouchMove.bind(this));
    document.addEventListener('touchend', this.onTouchEnd.bind(this));
  }

  onMouseDown(event) {
    this.updateMousePosition(event);
    this.checkDragStart();
  }

  onMouseMove(event) {
    this.updateMousePosition(event);
    this.moveDraggedObject();
  }

  onMouseUp(event) {
    const camera = this.newCamera?.threeCamera || this.camera;
    if (!camera) {
      console.error('No camera available for raycasting');
      return;
    }

    const mousePosition = new THREE.Vector2(
      (event.clientX / window.innerWidth) * 2 - 1,
      -(event.clientY / window.innerHeight) * 2 + 1
    );

    this.physicsSystem.endDrag(mousePosition, camera, (projectId) => {
      this.onProjectSelected(projectId);
    });
  }

  onTouchStart(event) {
    if (event.touches.length === 1) {
      event.preventDefault();
      this.updateMousePosition(event.touches[0]);
      this.checkDragStart();
    }
  }

  onTouchMove(event) {
    if (event.touches.length === 1) {
      event.preventDefault();
      this.updateMousePosition(event.touches[0]);
      this.moveDraggedObject();
    }
  }

  onTouchEnd(event) {
    const camera = this.newCamera?.threeCamera || this.camera;
    if (!camera) {
      console.error('No camera available for raycasting');
      return;
    }

    const touch = event.changedTouches[0];
    const mousePosition = new THREE.Vector2(
      (touch.clientX / window.innerWidth) * 2 - 1,
      -(touch.clientY / window.innerHeight) * 2 + 1
    );

    this.physicsSystem.endDrag(mousePosition, camera, (projectId) => {
      this.onProjectSelected(projectId);
    });
  }

  updateMousePosition(event) {
    const rect = this.renderer.domElement.getBoundingClientRect();
    this.mouse.x = ((event.clientX - rect.left) / rect.width) * 2 - 1;
    this.mouse.y = -((event.clientY - rect.top) / rect.height) * 2 + 1;
  }

  checkDragStart() {
    if(this.projectSections && !this.projectSections.isAnimationComplete)
      return;
    const camera = this.newCamera?.threeCamera || this.camera;
    if (!camera) {
      console.error('No camera available for raycasting');
      return;
    }

    const raycaster = new THREE.Raycaster();
    raycaster.setFromCamera(this.mouse, camera);

    const intersects = raycaster.intersectObjects(this.physicsSystem.meshes);
    if (intersects.length > 0) {
      const meshIndex = this.physicsSystem.meshes.indexOf(intersects[0].object);
      if (meshIndex !== -1) {
        console.log('Starting drag on mesh:', meshIndex);
        this.physicsSystem.startDrag(meshIndex);
        // Trigger the camera move callback
        if (this.onCameraMove) {
          this.onCameraMove();
        }
      }
    }
  }

  checkHoverStart() {
    if(this.projectSections &&this.projectSections.isProjectSelected)
      return;
    const camera = this.newCamera?.threeCamera || this.camera;
    if (!camera) {
      console.error('No camera available for raycasting');
      return;
    }

    const raycaster = new THREE.Raycaster();
    raycaster.setFromCamera(this.mouse, camera);

    // Check for collision with the collision box
    if (this.physicsSystem.collisionBox && this.physicsSystem.collisionBox.mesh) {
      const intersectBox = raycaster.intersectObject(this.physicsSystem.collisionBox.mesh);
      if (intersectBox.length > 0) {
        this.hoverOnCollisionBox = true;
      } else {
        this.hoverOnCollisionBox = false;
      }
    } else {
      console.warn('Collision box not properly initialized');
      this.hoverOnCollisionBox = false;
    }

    if(this.physicsSystem.draggedBody && this.projectSections.isProjectSelected)
      return;

    const intersects = raycaster.intersectObjects(this.physicsSystem.meshes);
    if (intersects.length > 0) {
      const meshIndex = this.physicsSystem.meshes.indexOf(intersects[0].object);
      if (meshIndex !== -1 ) {
        this.physicsSystem.hoverObject(intersects[0].object);
      }
    }
  }
  moveDraggedObject() {
    if (this.projectSections&& this.projectSections.isAnimationComplete && this.physicsSystem && this.physicsSystem.draggedBody) {
      const camera = this.newCamera?.threeCamera || this.camera;
      if (!camera) {
        console.error('No camera available for raycasting');
        return;
      }

      const worldPosition = this.getWorldPosition(this.mouse, camera);
      this.physicsSystem.moveDrag(worldPosition);
    }
  }

  getWorldPosition(mouse, camera) {
    const raycaster = new THREE.Raycaster();
    raycaster.setFromCamera(mouse, camera);
    
    // Create a larger plane for intersection
    const planeSize = 1000; // Adjust this value as needed
    const planeGeometry = new THREE.PlaneGeometry(planeSize, planeSize);
    planeGeometry.rotateX(-Math.PI / 2); // Rotate to be horizontal
    const planeMesh = new THREE.Mesh(planeGeometry, new THREE.MeshBasicMaterial());
    planeMesh.position.y = this.dragPlaneY;

    const intersects = raycaster.intersectObject(planeMesh);
    if (intersects.length > 0) {
      const point = intersects[0].point;
      return new CANNON.Vec3(point.x, this.dragPlaneY, point.z);
    }
    
    // Fallback if no intersection
    return new CANNON.Vec3(0, this.dragPlaneY, 0);
  }

  onProjectSelected(projectId) {
    const sectionPosition = new THREE.Vector3(0, 15, 15);
    const sectionLookAt = new THREE.Vector3(0, 15, -15);
    this.projectSections.isProjectSelected = true;
    this.projectSections.setCurrentProject(`${projectId}`);
    this.projectSections.createEjectButton();
    // Animate camera target
    gsap.to(this.cameraTarget, {
      duration: 2,
      x: sectionLookAt.x,
      y: sectionLookAt.y,
      z: sectionLookAt.z,
      ease: "power2.inOut"
    });

    // Animate camera position
    gsap.to(this.targetCameraPosition, {
      duration: 1,
      x: sectionPosition.x,
      y: sectionPosition.y,
      z: sectionPosition.z,
      ease: "power2.inOut"
    });

    // Find the selected object in the physics system
    this.selectedObjectIndex = this.physicsSystem.bodies.findIndex(body => body.userData && body.userData.projectId === projectId);
    if (this.selectedObjectIndex !== -1) {
      this.selectedObject = this.physicsSystem.bodies[this.selectedObjectIndex];
      // Remove the object from physics simulation
      this.physicsSystem.world.removeBody(this.selectedObject);
    }

    this.setProjectSelected(true);
  }
  onProjectUnselected() {
    this.projectSections.clearCurrentProject();
    this.projectSections.isProjectSelected = false;

    // Animate camera back to original position
    gsap.to(this.cameraTarget, {
      duration: 0.75,
      x: this.originalCameraTarget.x,
      y: this.originalCameraTarget.y,
      z: this.originalCameraTarget.z,
      ease: "power2.inOut"
    });

    gsap.to(this.targetCameraPosition, {
      duration: 0.75,
      x: this.originalCameraPosition.x,
      y: this.originalCameraPosition.y,
      z: this.originalCameraPosition.z,
      ease: "power2.inOut"
    });


    if (this.selectedObject) {
      const selectedObject = this.selectedObject; // Store reference to selectedObject
      setTimeout(() => {
        // Animate both the physics body and the mesh
        gsap.to(selectedObject.position, {
          duration: 0.5,
          x: selectedObject.position.x,
          y: selectedObject.position.y,
          z: selectedObject.position.z + 5,
          ease: "power2.inOut",
          onComplete: () => {
            if (selectedObject) { // Check if the object still exists
              // Reset the body type to dynamic
              selectedObject.type = CANNON.Body.DYNAMIC;

              // Add the body back to the physics world
              this.physicsSystem.world.addBody(selectedObject);

              // Add velocity and angular velocity
              const randomVelocity = new CANNON.Vec3(
                (Math.random() - 0.5) * 40,
                15 + Math.random() * 20,
                (Math.random() - 0.5) * 80
              );
              selectedObject.velocity.copy(randomVelocity);
              
              const randomAngularVelocity = new CANNON.Vec3(
                (Math.random() - 0.5) * Math.PI * 5,
                (Math.random() - 0.5) * Math.PI * 5,
                (Math.random() - 0.5) * Math.PI * 5
              );
              selectedObject.angularVelocity.copy(randomAngularVelocity);
              
              selectedObject.wakeUp();
              
              // Reset the sleeping state for all bodies
              this.physicsSystem.world.bodies.forEach(body => {
                body.sleepState = 0;
                body.wakeUp();
              });
            }
            
            this.selectedObject = null;
            this.selectedObjectIndex = -1;
          }
        });
      }, 500);
    }

    this.setProjectSelected(false);
  }

  setOnCameraMove(callback) {
    this.onCameraMove = callback;
  }

  Update() {
    if (this.isSectionActive) {
      return;
    }

    this.updatePostProcessingSettings({
      bloomIntensity: 0.225,
      focusDistance: 10,
      bokehAperture: 0.000025,
    });

    const now = performance.now();
    this.dt = (now - this.lastUpdate) * 0.001;
    this.lastUpdate = now;
    this.totalTime = (now - this.startTime) * 0.001;

    if (!this.newCamera) {
      this.newCamera = { threeCamera: this.camera };
    }

    // Smoothly move the camera to the target position
    this.newCamera.threeCamera.position.lerp(this.targetCameraPosition, 0.05);
    this.newCamera.threeCamera.lookAt(this.cameraTarget);
    this.newCamera.threeCamera.updateMatrixWorld(true);

    if(this.projectSections){ 
      this.projectSections.update(this.dt);
    }
    // Update all motion meshes
    for (const motionMesh of this.motionMeshes) {
      motionMesh.update(this.dt);
    }

    // Update all motion texts
    if (this.motionTexts) {
      for (const motionText of this.motionTexts) {
        motionText.update(this.dt);
      }
    }

    // Update physics
    if (this.physicsSystem) {
      this.physicsSystem.update(this.dt,this.newCamera?.threeCamera || this.camera);
      this.checkHoverStart();
    }

 

    this.updatePointLight(this.dt);
    this.updateParticles();

    this.boot = true;
  }
}

export default React.forwardRef((props, ref) => <Projects {...props} ref={ref} />);
