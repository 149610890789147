import * as THREE from 'three';
import JetPoint from '../Enemy/JetPoint';
import ParachutePoint from '../Enemy/ParachutePoint';
import HelicopterPoint from '../Enemy/HelicopterPoint';
import AudioManager from '../Audio/AudioManager';
import Bomb from '../Enemy/Bomb';
import Model from "../../../../Render/Model";
import ParticleEmitter from '../Particles/ParticleEmitter';
import { loadCurveFromJSON } from "../../Base/Helper";

class EnemyManager {
  constructor(game, scene) {
    this.game = game;
    this.scene = scene;
    this.waveManager = game.waveManager;
    this.paths = [];
    this.pathPoints = [];
    this.allEnemies = [];
    this.enemies = {
      jets: [],
      parachuters: [],
      helicopters: []
    };
    this.bombs = [];
    this.parachutePoints = [];
    this.spawnIntervals = {
      jets: 5000,
      parachuters: 1500,
      helicopters: 10000
    };
    this.lastSpawnTimes = {
      jets: 0,
      parachuters: 0,
      helicopters: 0
    };

    this.setupPaths();
    this.setupEnemyModels();
  }

  update(deltaTime) {
    this.spawnEnemies(this.game.time.getElapsedTime());
    this.checkEnemyDespawn();
    this.updateEnemies(this.game.time.getDeltaTime());
    this.updateBombs();
  }

  async setupEnemyModels() {
    const standardMaterial = new THREE.MeshPhongMaterial({
      dithering: true,
      color: '#ffffff',
      shininess: 5
    });

    this.parachuteModel = this.createModel('parachuteModel', standardMaterial, new THREE.Vector3(0, -100, 0), new THREE.Vector3(4, 4, 4));
    this.helicopterModelMain = this.createModel('helicopterModel', standardMaterial, new THREE.Vector3(0, 999, 0));
    this.helicopterBladeModelMain = this.createModel('helicopterBladeModel', standardMaterial, new THREE.Vector3(999, 99999, 0));
    this.jetModelMain = this.createModel('jetModel', standardMaterial, new THREE.Vector3(0, 999, 0));
  }

  createModel(modelLink, material, position, scale = new THREE.Vector3(1, 1, 1)) {
    return new Model({
      modelLink,
      material,
      position,
      rotation: new THREE.Vector3(0.0, 0.0, 0.0),
      scale,
      scene: this.scene,
      name: modelLink
    });
  }

  async setupPaths() {
    const pathFiles = [
      '/paths/parade_parachute_1.json',
      '/paths/parade_parachute_2.json',
      '/paths/parade_parachute_3.json'
    ];

    for (const pathFile of pathFiles) {
      const path = await loadCurveFromJSON(pathFile);
      this.paths.push(path);
      this.pathPoints.push(path.getPoints(200));
    }
  }

  handleDamageEffect(intersectedObject) {
    this.handleEnemyDamage(this.enemies.jets, intersectedObject, 34, 50);
    this.handleEnemyDamage(this.enemies.parachuters, intersectedObject, 101, 10);
    this.handleEnemyDamage(this.enemies.helicopters, intersectedObject, 51, 20);
    this.handleBombDamage(intersectedObject);
  }

  handleEnemyDamage(enemyArray, intersectedObject, damageAmount, scoreAmount) {
    enemyArray.forEach((enemy, index) => {
      if (enemy.hitbox === intersectedObject) {
        enemy.health -= damageAmount;
        this.game.bloodEmitter.spawn(enemy.model.position, enemy.health <= 0 ? 15 : 5);

        if (enemy.health <= 0 && !enemy.destroyed) {
          this.handleEnemyDestruction(enemy, enemyArray, scoreAmount);
        }
      }
    });
  }

  handleEnemyDestruction(enemy, enemyArray, scoreAmount) {
    AudioManager.playSound('shoot_pistol');
    this.incrementScore(scoreAmount);
    enemy.destroyed = true;
    this.waveManager.spawnCounters.target--;
    this.removeEnemy(enemy, enemyArray);
  }

  handleBombDamage(intersectedObject) {
    this.bombs.forEach((bomb, index) => {
      if (bomb.hitbox === intersectedObject) {
        bomb.takeDamage(100); // Bombs are destroyed in one hit
      }
    });
  }

  spawnEnemies(currentTime) {
    Object.keys(this.enemies).forEach(type => {
      this.spawnEnemy(type, currentTime);
    });
  }

  spawnEnemy(type, currentTime) {
    const currentTimeMs = currentTime * 1000;
    if (currentTimeMs - this.lastSpawnTimes[type] > this.spawnIntervals[type] && this.waveManager.spawnCounters[type] > 0) {
      this.addEnemy(type);
      this.lastSpawnTimes[type] = currentTimeMs;
      this.waveManager.spawnCounters[type]--;
    }
  }

  addEnemy(enemyType) {
    let enemyPoint;
    switch (enemyType) {
      case 'parachuters':
        enemyPoint = new ParachutePoint(this.scene, this.parachuteModel.all_model[0].clone(), this.game.time);
        this.parachutePoints.push(enemyPoint);
        break;
      case 'helicopters':
        enemyPoint = new HelicopterPoint(this.scene, this.helicopterModelMain.all_model[0].clone(), this.helicopterBladeModelMain.all_model[0].clone(), this.game.time);
        break;
      case 'jets':
        enemyPoint = new JetPoint(this.scene, this.jetModelMain.all_model[0].clone(), this.game.time);
        break;
      default:
        console.error("Unknown enemy type: ", enemyType);
        return;
    }

    enemyPoint.pathIndex = Math.floor(Math.random() * 3);
    enemyPoint.path = this.paths[enemyPoint.pathIndex];
    enemyPoint.pathPoints = this.pathPoints[enemyPoint.pathIndex];

    this.allEnemies.push(enemyPoint);
    this.scene.add(enemyPoint.model);
    this.enemies[enemyType].push(enemyPoint);

    setTimeout(() => {
      this.waveManager.activeEnemies.push(enemyPoint);
    }, 2000);
  }

  updateEnemies(deltaTime) {
    Object.values(this.enemies).forEach(enemyArray => {
      this.updateEnemyType(enemyArray, deltaTime);
    });
  }

  updateEnemyType(enemyArray, deltaTime) {
    for (let i = enemyArray.length - 1; i >= 0; i--) {
      const enemy = enemyArray[i];
      if (enemy.update) {
        if (enemy instanceof ParachutePoint) {
          enemy.runTime += deltaTime;
        }
        enemy.update(deltaTime);
      }
      if (enemy.destroyed) {
        this.removeEnemy(enemy, enemyArray);
      }
    }
  }

  removeEnemy(enemy, enemyArray) {
    const index = enemyArray.indexOf(enemy);
    if (index !== -1) {
      enemyArray.splice(index, 1);
    }
    const activeIndex = this.waveManager.activeEnemies.indexOf(enemy);
    if (activeIndex !== -1) {
      this.waveManager.activeEnemies.splice(activeIndex, 1);
    }
    const allEnemiesIndex = this.allEnemies.indexOf(enemy);
    if (allEnemiesIndex !== -1) {
      this.allEnemies.splice(allEnemiesIndex, 1);
    }
    enemy.destroy();
  }

  checkEnemyDespawn() {
    this.allEnemies.forEach(enemy => {
      if (enemy.canDespawn) {
        this.handleEnemyDespawn(enemy);
      }
    });
  }

  handleEnemyDespawn(enemy) {
    AudioManager.playSound('explode');
    enemy.destroy();
    this.game.health.takeDamage(5);
    this.game.sky.receiveDamage();

    const parachuteIndex = this.enemies.parachuters.indexOf(enemy);
    if (parachuteIndex !== -1) {
      this.enemies.parachuters.splice(parachuteIndex, 1);
    }

    const enemyIndex = this.allEnemies.indexOf(enemy);
    if (enemyIndex !== -1) {
      this.allEnemies.splice(enemyIndex, 1);
    }

    if (!this.game.waveManager) {
      console.error("WaveManager not set");
      return;
    }
    this.game.waveManager.spawnCounters.target--;
  }



  incrementScore(amount) {
    this.game.score.incrementScore(amount);
  }

  spawnBomb(startPosition, parent, bombModel) {
    const bomb = new Bomb(this.scene, startPosition, parent, this.removeBomb.bind(this), this.waveManager.fireworks, bombModel);
    this.bombs.push(bomb);
  }

  updateBombs() {
    for (let i = this.bombs.length - 1; i >= 0; i--) {
      const bomb = this.bombs[i];
      bomb.update();
      if (bomb.destroyed) {
        this.removeBomb(bomb);
      }
    }
  }

  removeBomb(bomb) {
    const index = this.bombs.indexOf(bomb);
    if (index !== -1) {
      this.bombs.splice(index, 1);
    }
  }
}

export default EnemyManager;
