import React, { useState, useRef } from 'react';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { Typography, IconButton, Popover, List, ListItem, ListItemText, useMediaQuery } from '@mui/material';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import AudioManager from './Scenes/ArtCode/AudioManager';

interface TopbarProps {
    artcodeColor?: string;
    isSoundOn: boolean;
    toggleSound: () => void;
}

const Topbar: React.FC<TopbarProps> = ({ artcodeColor = '#0000ff99', isSoundOn, toggleSound }) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const anchorRef = useRef(null);
    const isMobile = useMediaQuery('(max-width:600px)');

    const handleVolumeToggle = () => {
        toggleSound();
        if (isSoundOn) {
            AudioManager.mute();
        } else {
            AudioManager.unmute();
        }
    };

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const handleChatClick = () => {
        window.open('https://calendly.com/theon-artcodestudio/project-discussion', '_blank');
    };

    const circleButtonStyle = {
        backgroundColor: '#f0f0f0',
        borderRadius: '50%',
        padding: '8px',
        marginLeft: '10px',
    };

    return (
        <Box
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}
            width={'100%'}
            padding={isMobile ? '10px' : '30px'}
        >
            <Box display={'flex'} alignItems={'center'}>
                <Link
                    underline="none"
                    component="a"
                    href="/"
                    fontFamily={'Glacial'}
                    style={{ letterSpacing: '5px' }}
                >
                    <Typography
                        fontFamily="Akira"
                        paddingLeft={isMobile ? '10px' : '30px'}
                        paddingBottom={isMobile ? '10px' : '30px'}
                        paddingTop={isMobile ? '10px' : '30px'}
                        align="left"
                        color={artcodeColor}
                        variant={isMobile ? "h5" : "h3"}
                        lineHeight={'0.0rem'}
                        fontSize={isMobile ? "0.8rem" : "1.0rem"}
                        fontWeight="100"
                        fontStyle={'regular'}
                        style={{ userSelect: 'none' }}
                    >
                        artcode
                    </Typography>
                </Link>
            </Box>

            <Box paddingRight={isMobile ? "10px" : "30px"} display="flex" alignItems="center">
                { !isMobile && (
                    <>
                       {false && <IconButton 
                            color="inherit" 
                            aria-label="volume" 
                            sx={{...circleButtonStyle, backgroundColor: 'white'}}
                            onClick={handleVolumeToggle}
                        >
                            {isSoundOn ? 
                                <VolumeUpIcon sx={{ color: 'black' }} /> : 
                                <VolumeOffIcon sx={{ color: 'black' }} />
                            }
                        </IconButton>}
                        <Button
                            variant="contained"
                            onClick={handleChatClick}
                            sx={{
                                backgroundColor: '#222222',
                                borderRadius: '20px',
                                padding: '8px 16px',
                                marginLeft: '10px',
                                '&:hover': {
                                    backgroundColor: '#444444',
                                },
                            }}
                        >
                            <Typography
                                fontFamily="Akira"
                                color={'#FFFFFFFF'}
                                variant="button"
                                fontSize="0.8rem"
                                style={{ userSelect: 'none' }}
                            >
                                LET'S CHAT
                            </Typography>
                        </Button>
                    </>
                )}
                <IconButton 
                    color="inherit" 
                    aria-label="menu" 
                    sx={{...circleButtonStyle, backgroundColor: 'white'}}
                    onClick={toggleMenu}
                    ref={anchorRef}
                >
                    {isMenuOpen ? <CloseIcon sx={{ color: 'black' }} /> : <MenuIcon sx={{ color: 'black' }} />}
                </IconButton>
            </Box>

            <Popover
                open={isMenuOpen}
                anchorEl={anchorRef.current}
                onClose={toggleMenu}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                PaperProps={{
                    sx: { 
                        width: isMobile ? '200px' : '300px', 
                        backgroundColor: 'white',
                        borderRadius: '10px',
                        boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
                        marginTop: '15px', // Add space between button and popup
                    },
                }}
            >
                <Box p={2}>
                    <List>
                        {isMobile && (
                            <>
                               
                                <ListItem 
                                    button 
                                    key="chat"
                                    onClick={handleChatClick}
                                    sx={{
                                        '&:hover': {
                                            backgroundColor: 'navy',
                                            '& .MuiListItemText-primary': {
                                                color: 'white',
                                            },
                                        },
                                    }}
                                >
                                    <ListItemText 
                                        primary="LET'S CHAT" 
                                        primaryTypographyProps={{
                                            fontFamily: 'Akira',
                                            fontWeight: 'bold',
                                            fontSize: isMobile ? '0.8rem' : '1rem',
                                            style: { userSelect: 'none' }
                                        }} 
                                    />
                                </ListItem>
                            </>
                        )}
                        {['PROJECTS', 'LAB'].map((text) => (
                            <ListItem 
                                button 
                                key={text}
                                onClick={() => {
                                    switch (text) {
                                      
                                        case 'PROJECTS':
                                            window.location.href = '/projects';
                                            break;
                                        case 'LAB':
                                            window.location.href = '/lab';
                                            break;
                                        default:
                                            break;
                                    }
                                }}
                                sx={{
                                    '&:hover': {
                                        backgroundColor: '#0000ff',
                                        '& .MuiListItemText-primary': {
                                            color: 'white',
                                        },
                                    },
                                }}
                            >
                                <ListItemText 
                                    primary={text} 
                                    primaryTypographyProps={{
                                        fontFamily: 'Akira',
                                        fontWeight: 'bold',
                                        fontSize: isMobile ? '0.8rem' : '1rem',
                                        style: { userSelect: 'none' }
                                    }} 
                                />
                            </ListItem>
                        ))}
                    </List>
                </Box>
            </Popover>
        </Box>
    );
};

export default Topbar;
