import * as THREE from "three";
import { MathUtils, Vector2, Vector3 } from "three";
import { Mesh, BoxGeometry, MeshBasicMaterial } from 'three';
import { gsap } from "gsap";
import React, { Component } from 'react';
import { useMediaQuery } from '@mui/material';
// Renderer related
import Model from "../../../Render/Model";
import Material from "../../../Render/Material";
import LoadingManager from "../../../Render/LoadingManager";
import Scene from "../Base/Scene";
import { PropShader } from "../../../Shaders/PropShader";
import { loadFont, loadCurveFromJSON, createText } from "../Base/Helper";
import OrbitalCamera from '../OrbitalCamera';

import { easeOutSine } from '../Base/Helper';

const COLLISION_LAYER = 1;

class TouchTexture {
  constructor(parent) {
    this.parent = parent;
    this.size = 64;
    this.maxAge = 120;
    this.radius = 0.15;
    this.trail = [];

    this.initTexture();
  }

  initTexture() {
    this.canvas = document.createElement('canvas');
    this.canvas.width = this.canvas.height = this.size;
    this.ctx = this.canvas.getContext('2d');
    this.ctx.fillStyle = 'black';
    this.ctx.fillRect(0, 0, this.canvas.width, this.canvas.height);

    this.texture = new THREE.Texture(this.canvas);

    this.canvas.id = 'touchTexture';
    this.canvas.style.width = this.canvas.style.height = `${this.canvas.width}px`;
  }

  update() {
    this.clear();

    this.trail.forEach((point, i) => {
      point.age++;
      if (point.age > this.maxAge) {
        this.trail.splice(i, 1);
      }
    });

    this.trail.forEach((point) => {
      this.drawTouch(point);
    });

    this.texture.needsUpdate = true;
  }

  clear() {
    this.ctx.fillStyle = 'black';
    this.ctx.fillRect(0, 0, this.canvas.width, this.canvas.height);
  }

  addTouch(point) {
    let force = 0;
    const last = this.trail[this.trail.length - 1];
    if (last) {
      const dx = last.x - point.x;
      const dy = last.y - point.y;
      const dd = dx * dx + dy * dy;
      force = Math.min(dd * 10000, 1);
    }
    this.trail.push({ x: point.x, y: 1 - point.y, age: 0, force });
  }

  drawTouch(point) {
    const pos = {
      x: point.x * this.size,
      y: (1 - point.y) * this.size
    };

    let intensity = 1;
    if (point.age < this.maxAge * 0.3) {
      intensity = easeOutSine(point.age / (this.maxAge * 0.3), 0, 1, 1);
    } else {
      intensity = easeOutSine(1 - (point.age - this.maxAge * 0.3) / (this.maxAge * 0.7), 0, 1, 1);
    }

    intensity *= point.force;

    const radius = this.size * this.radius * intensity;
    const grd = this.ctx.createRadialGradient(pos.x, pos.y, radius * 0.25, pos.x, pos.y, radius);
    grd.addColorStop(0, `rgba(255, 255, 255, 0.2)`);
    grd.addColorStop(1, 'rgba(0, 0, 0, 0.0)');

    this.ctx.beginPath();
    this.ctx.fillStyle = grd;
    this.ctx.arc(pos.x, pos.y, radius, 0, Math.PI * 2);
    this.ctx.fill();
  }
}

class PointCloud extends Scene {
  constructor(_options) {
    super(_options);
    this.isSectionActive = _options.isSectionActive; // Receive isSectionActive from props

    this.clickTimeout = 0;
    this.scrollDelta = 25;
    this.getState = _options.statecallback;
    this.modelLoad = _options.modelcallback;

    this.collision_part1 = _options.collision_part1_callback;
    this.collision_part2 = _options.collision_part2_callback;
    this.collision_part3 = _options.collision_part3_callback;
    this.collision_none = _options.collision_none_callback;

    this.touchTexture = new TouchTexture(this);

    this.mouse = new THREE.Vector2();
    window.addEventListener('mousemove', this.onMouseMove.bind(this));

    this.hide = false;
    this.current = 0;
    this.clickEnabled = true;

    this.mouse = new Vector2(0, 0);
    this.mouseNormalized = new Vector2(0, 0);
    this.lerpedMouse = new Vector2(0, 0);
    this.lerpedMouseRaw = new Vector2(0, 0);
    this.boot = false;
    this.fullLoaded = false;

    this.lastUpdate = performance.now();
    this.totalTime = 0.0;
    this.startTime = performance.now();

    this.camDirection = new Vector3(0, 0, 0);
    this.camUp = new Vector3(0, 0, 0);
    this.camSide = new Vector3(0, 0, 0);
    this.camPos = new Vector3(0, 0, 0);
    this.camLookAt = new Vector3(0, 0, 0);
    this.currDronePoint = new Vector3(0, 0, 0);

    this.sendPoint = new Vector3(0, 0, 0);
    this.tempPoint = new Vector3(0, 0, 0);

    this.sendPointLookAt = new Vector3(0, 0, 0);
    this.tempPointLookAt = new Vector3(0, 0, 0);

    const d = 25;
    const r = 1;
    const mapSize = 1024;
    this.ambient = new THREE.AmbientLight(0xffffff, 1);
    this.dirLight = new THREE.DirectionalLight(0xffffff, 3.95);
    this.dirLight.castShadow = true;
    this.dirLight.shadow.radius = r;
    this.dirLight.shadow.mapSize.width = mapSize;
    this.dirLight.shadow.mapSize.height = mapSize;
    this.dirLight.shadow.camera.top = this.dirLight.shadow.camera.right = d;
    this.dirLight.shadow.camera.bottom = this.dirLight.shadow.camera.left = -d;
    this.dirLight.shadow.camera.near = 0.1;
    this.dirLight.shadow.camera.far = 100;
    this.dirLight.position.set(40, 25, 20);
    this.spot = new THREE.SpotLight(0xffffff, 100.0, 80.0, 5, 10, 10);

    this.buttonVisibilityCallback = null;

    this.scene.add(this.ambient);
    this.scene.add(this.spot);

    this.dynamicMaterial = new THREE.MeshPhongMaterial({ color: 0x888888 });

    this.storeTop = 0.0;

    this.loadListener();
    this.startRender();
    this.generateCamCurve();

    this.currPointLerped = new Vector3(0, 0, 0);
    this.currPointLookAtLerped = new Vector3(0, 0, 0);
    this.lookDown = new Vector3(0, -30, 0);
    this.currPointLerped.set(-1.297, 1.948, 2.249);
    this.currPointLookAtLerped.set(-0.605, 0.054, 0.281);

    this.isPointerDown = false;
    this.lastPointerPosition = new THREE.Vector2();

    this.requiredAssets = [
      { name: 'facadeModel', source: '/assets/facade.glb' },
      { name: 'facadeTexture', source: '/assets/facade_render.jpg', type: 'texture' },
      { name: 'voyageTexture', source: '/assets/voyage-logo.png', type: 'texture' },
      
      { name: 'iceCreamTexture', source: '/assets/ice-cream.jpg', type: 'texture' },
      { name: 'sunsetTexture', source: '/assets/sunset.jpg', type: 'texture' },
      { name: 'templeTexture', source: '/assets/temple.jpg', type: 'texture' },
      { name: 'fujiTexture', source: '/assets/fuji.jpg', type: 'texture' },
      { name: 'toriTexture', source: '/assets/tori.jpg', type: 'texture' },
      { name: 'penangTexture', source: '/assets/penang.jpg', type: 'texture' },

      { name: 'circleTexture', source: '/assets/circle.png', type: 'texture' },
      { name: 'logoModel', source: '/assets/logo.glb' },
    ];
    this.loadedAssets = 0;
    this.totalAssets = this.requiredAssets.length;

    this.loadingManager = new LoadingManager();
    this.loader = this.loadingManager.loader;
    this.initializeLoading();

    this.hoveredText = null;
    
 // Initialize the current image index
 this.currentImageIndex = 0;

 // Define an array of images with their corresponding properties
 this.imageSettings = [
   { textureName: "voyageTexture", color: 0xffffff, intensity: 2.0, heightOffset: 0.0 },
   { textureName: "sunsetTexture", color: 0xffcc66, intensity: 1.0, heightOffset: 5.0 },
   { textureName: "iceCreamTexture", color: 0xc672db, intensity: 0.8, heightOffset: 0.0 },
   { textureName: "templeTexture", color: 0xffffff, intensity: 0.4, heightOffset: 3.0 },
   { textureName: "fujiTexture", color: 0x8ad5f2, intensity: 1.0, heightOffset: 5.0 },
   { textureName: "toriTexture", color: 0xffffff, intensity:0.2, heightOffset: 0.0 },

 ];

}

loadCurrentImage(fizzle) {
 const { textureName, color, intensity, heightOffset } = this.imageSettings[this.currentImageIndex];
 this.triggerImageTransition(textureName, color, intensity, heightOffset,fizzle);
}

changeImage(direction) {
 if (direction === 'next') {
   this.currentImageIndex = (this.currentImageIndex + 1) % this.imageSettings.length;
 } else if (direction === 'prev') {
   this.currentImageIndex = (this.currentImageIndex - 1 + this.imageSettings.length) % this.imageSettings.length;
 }
 this.loadCurrentImage();

  }

  initializeLoading() {
    this.loader.groups.assets = [{ name: 'paradeAssets', items: this.requiredAssets }];
    this.loader.on('fileEnd', this.onFileLoaded.bind(this));
    this.loader.loadNextGroup();
  }

  onFileLoaded(_resource, _data) {
    console.log(`Loaded: ${_resource.name}`);
    this.loadedAssets++;
    if (this.loadedAssets === this.totalAssets) {
      this.onAllAssetsLoaded();
    }
  }

  setButtonVisibilityCallback(callback) {
    this.buttonVisibilityCallback = callback;
  }

  updatePostProcessingSettings({ bloomIntensity, bokehAperture, focusDistance }) {
    if (this.postProcess) {
        if (bloomIntensity !== undefined && this.postProcess.bloomPass) {
            this.postProcess.bloomPass.strength = bloomIntensity;
        }
  
        if (bokehAperture !== undefined && this.postProcess.bokehPass) {
            this.postProcess.bokehPass.materialBokeh.uniforms['aperture'].value = bokehAperture;
        }

        if (focusDistance !== undefined && this.postProcess.bokehPass) {
            this.postProcess.bokehPass.materialBokeh.uniforms['focus'].value = focusDistance;
        }
    }
}


  updatePointCloudTexture(textureName) {
    const paletteTexture = this.loader.getAsset(textureName);
    const image = paletteTexture.image;
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    canvas.width = image.width;
    canvas.height = image.height;
    context.drawImage(image, 0, 0, image.width, image.height);
  
    const imageData = context.getImageData(0, 0, image.width, image.height).data;
    const positions = this.pointCloud.geometry.attributes.instancePosition.array;
    const targetPositions = [];
  
    for (let y = 0; y < image.height; y += 8) {
      for (let x = 0; x < image.width; x += 8) {
        const index = (y * image.width + x) * 4;
        const r = imageData[index] / 255 * 2;
        const g = imageData[index + 1] / 255 * 2;
        const b = imageData[index + 2] / 255 * 2;
        const a = imageData[index + 3] / 255;
  
        if (a > 0.5) {
          const targetX = (x / image.width) * 4 - 2;
          const targetY = (y / image.height) * 4 - 5;
          const targetZ = (Math.random() - 0.5) * 1;
          targetPositions.push({ x: targetZ, y: -targetY, z: -targetX});
        }
      }
    }
  
    this.animatePointCloud(targetPositions);
  }
  initTestInstancedPointCloud(imageName, startInFizzleState = false, heightOffset = 0) {
    const paletteTexture = this.loader.getAsset(imageName);
    const circleTexture = this.loader.getAsset("circleTexture"); // Load the circle texture
    const image = paletteTexture.image;

    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    canvas.width = image.width;
    canvas.height = image.height;
    context.drawImage(image, 0, 0, image.width, image.height);

    const imageData = context.getImageData(0, 0, image.width, image.height).data;
    const imageWidth = image.width;
    const imageHeight = image.height;

    const aspectRatio = imageWidth / imageHeight; // Calculate the aspect ratio
    const geometry = new THREE.InstancedBufferGeometry();

    // Create a single point as a template for the instances
    const positions = new Float32Array([0, 0, 0]);
    geometry.setAttribute('position', new THREE.BufferAttribute(positions, 3));

    const offsets = [];
    const colors = [];
    const finalPositions = []; // To store the final positions
    const pixelCount = 25000; // Desired number of points in the cloud
    const pixelStep = Math.ceil(Math.sqrt((imageWidth * imageHeight) / pixelCount)); // Calculate step to get approximately equal number of points

    const xRange = aspectRatio > 1 ? 24 : 24 * aspectRatio; // Adjust xRange based on aspect ratio
    const yRange = aspectRatio > 1 ? 24 / aspectRatio : 24; // Adjust yRange based on aspect ratio
    const xOffset = -xRange / 2;
    const yOffset = yRange + heightOffset; // Apply the height offset here

    for (let y = 0; y < imageHeight; y += pixelStep) {
        for (let x = 0; x < imageWidth; x += pixelStep) {
            const index = (y * imageWidth + x) * 4; // Calculate pixel index
            const r = imageData[index] / 255;
            const g = imageData[index + 1] / 255;
            const b = imageData[index + 2] / 255;
            const a = imageData[index + 3] / 255;

            if (a > 0.5) { // Only use pixels with significant alpha
                let posX, posY, posZ;

                // Calculate final position with x and y range and offset
                const finalX = (x / imageWidth) * xRange + xOffset;
                const finalY = (y / imageHeight) * -yRange + yOffset;
                const finalZ = (Math.random() * 2 - 1) / 10; // Add some depth variation

                if (startInFizzleState) {
                    // Start in random positions if in fizzle state
                    posX = (Math.random() - 0.5) * 100;
                    posY = (Math.random() - 0.5) * 100;
                    posZ = (Math.random() - 0.5) * 100;
                } else {
                    // Start in final position
                    posX = finalX;
                    posY = finalY;
                    posZ = finalZ;
                }

                offsets.push(posX, posY, posZ);
                colors.push(r, g, b);
                finalPositions.push({ x: finalZ, y: finalY, z: -finalX }); // Store final position
            }
        }
    }

    geometry.setAttribute('instanceOffset', new THREE.InstancedBufferAttribute(new Float32Array(offsets), 3));
    geometry.setAttribute('instanceColor', new THREE.InstancedBufferAttribute(new Float32Array(colors), 3));

    const material = new THREE.ShaderMaterial({
        uniforms: {
            alpha: { value: 0.0 },
            time: { value: 0.0 }, // Add time uniform
            amplitude: { value: 0.5 }, // Reduced amplitude for subtle wave
            frequency: { value: 0.2 }, // Reduced frequency for smoother wave
            pointTexture: { value: circleTexture },
            opacityCutoff: { value: 0.1 },
        },
        vertexShader: `
            attribute vec3 instanceOffset;
            attribute vec3 instanceColor;
            uniform float alpha;
            uniform float time; // Time uniform to animate the wave
            uniform float amplitude; // Amplitude of the wave
            uniform float frequency; // Frequency of the wave
            varying vec3 vColor;
            varying float vAlpha;

            void main() {
                vColor = instanceColor;
                vAlpha = alpha;

                // Apply subtle sinusoidal wave motion to the position
                vec3 animatedPosition = instanceOffset;
                animatedPosition.y += sin(animatedPosition.x * frequency + time) * amplitude;
                animatedPosition.x += cos(animatedPosition.y * frequency / 1.5 + time) * amplitude * 0.3;
                animatedPosition.z += sin(animatedPosition.y * frequency / 1.2 + time) * amplitude * 0.4;

                vec4 mvPosition = modelViewMatrix * vec4(position + animatedPosition, 1.0);
                gl_PointSize = 5.0;
                gl_Position = projectionMatrix * mvPosition;
            }
        `,
        fragmentShader: `
            uniform sampler2D pointTexture; // Receive the texture uniform
            uniform float opacityCutoff; // Opacity cutoff uniform
            varying vec3 vColor;
            varying float vAlpha; // Receive the alpha value from the vertex shader

            void main() {
                vec4 textureColor = texture2D(pointTexture, gl_PointCoord);
                float finalAlpha = vAlpha * textureColor.a; // Combine the alpha value with the texture alpha

                if(finalAlpha < opacityCutoff) {
                    discard; // Discard the fragment if the alpha is below the cutoff
                }

                gl_FragColor = vec4(vColor, finalAlpha); // Apply the alpha value to the fragment color
            }
        `,
        vertexColors: true,
        transparent: true,
    });

    const pointCloud = new THREE.Points(geometry, material);
    this.pointCloud = pointCloud;
    this.scene.add(pointCloud);

    return finalPositions; // Return the calculated final positions
}






  fizzleOutPointCloud() {
    const positions = this.pointCloud.geometry.attributes.instanceOffset.array;
    const numPoints = positions.length / 3;
    
    const randomPositions = [];
    for (let i = 0; i < numPoints; i++) {
      randomPositions.push({
        x: (Math.random() - 0.5) * 200,
        y: (Math.random() - 0.5) * 200,
        z: (Math.random() - 0.5) * 200,
      });
    }
    return randomPositions;
  }
  triggerImageTransition(textureName, color, intensity, heightOffset = 0,fizzleOut = true) {
    const timeline = gsap.timeline();

    // Step 1: Fizzle out current image, if there's an existing point cloud
    if(fizzleOut)
    {
      if (this.pointCloud && this.pointCloud.geometry && this.pointCloud.geometry.attributes.instanceOffset) {
          const fizzlePositions = this.fizzleOutPointCloud();

          // Animate the point cloud to fizzle out to random positions
          this.animatePointCloud(fizzlePositions);

          // Animate alpha to fade out during fizzle out
          timeline.to(this.pointCloud.material.uniforms.alpha, { value: 0, duration: 3.0, ease: 'power2.inOut' });

          // Delay the timeline by 1.5 seconds after the fizzle out effect
          //timeline.to({}, { duration: 1.5 });
      } else {
          console.warn("Point cloud or instanceOffset attribute is missing, skipping fizzle out.");
      }
    }

    // Step 2: Change lighting
    timeline.add(() => {
        this.switchColor(new THREE.Color(color), intensity);
    }, "+=1.5");

    // Step 3: Remove the current point cloud
    timeline.add(() => {
        if (this.pointCloud) {
            this.pointCloud.geometry.dispose();  // Dispose of old geometry
            this.scene.remove(this.pointCloud);
            this.pointCloud = null; // Clear reference after removal
        }
    });

    // Step 4: Load new image and start point cloud from fizzle state
    let finalPositions;
    timeline.add(() => {
        finalPositions = this.initTestInstancedPointCloud(textureName, true, heightOffset); // Pass heightOffset to initTestInstancedPointCloud
    });

    // Step 5: Transition points to final position using the returned finalPositions
    timeline.add(() => {
        this.animatePointCloud(finalPositions, true);

        // Animate alpha to fade in during the transition to final position
        timeline.to(this.pointCloud.material.uniforms.alpha, { value: 1, duration: 3, ease: 'power2.inOut' });
    });
}
  switchColor(targetColor, targetIntensity = 1) {
    gsap.to(this.ambient.color, {
      r: targetColor.r,
      g: targetColor.g,
      b: targetColor.b,
      duration: 1,
    });

    gsap.to(this.ambient, {
      intensity: targetIntensity,
      duration: 1,
    });
  }

  onAllAssetsLoaded() {

    console.log("All assets loaded");
    this.createSceneObjects();
    this.initTestInstancedPointCloud('voyageTexture'); // Add this line to trigger the test point cloud creation
    //this.createInstancedPointCloud('toriTexture');
    //this.createInstancedPointCloud(imageData, image.width, image.height);

    this.createTouchTextureMesh();
    this.startIntroSequence();
    // Load the first image
    this.loadCurrentImage(false);

   // this.createSimpleInstancedPointCloud('voyageTexture');

    const t = (event) => {
      if (!this.hoverAnimationsEnabled) return;
      const raycaster = new THREE.Raycaster();
      const mouse = new THREE.Vector2();
      mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
      mouse.y = - (event.clientY / window.innerHeight) * 2 + 1;

      raycaster.setFromCamera(mouse, this.newCamera.threeCamera);
      raycaster.layers.set(COLLISION_LAYER);
      const intersects = raycaster.intersectObjects(this.pointCloudGroup.children, true);
      for (let i = 0; i < intersects.length; i++) {
        if (intersects[i].object.name === 'collision_parachute') break;
      }
    };

    const onMouseClick = (event) => {
      if (this.clickEnabled) {
        console.log("Mouse clicked");
        this.clickEnabled = false;
        t(event);
        window.removeEventListener('click', onMouseClick);
        window.removeEventListener('touchstart', onMouseClick);
      }
    };

    const onMouseUp = (event) => {
      this.clickEnabled = true;
      console.log("Mouse up");
      window.addEventListener('click', onMouseClick, false);
      window.addEventListener('touchstart', onMouseClick, false);
    };

    window.addEventListener('click', onMouseClick, false);
    window.addEventListener('touchstart', onMouseClick, false);
    window.addEventListener('mouseup', onMouseUp, false);
    window.addEventListener('touchend', onMouseUp, false);
  }

  createTouchTextureMesh() {
    const touchTextureMaterial = new THREE.MeshBasicMaterial({
      map: this.touchTexture.texture,
      transparent: true,
    });

    const touchTextureGeometry = new THREE.PlaneGeometry(4, 4);
    this.touchTextureMesh = new THREE.Mesh(touchTextureGeometry, touchTextureMaterial);
    this.touchTextureMesh.position.set(0, 0, 0);

    this.scene.add(this.touchTextureMesh);
  }

  startIntroSequence() {
    if (this.buttonVisibilityCallback) {
      this.buttonVisibilityCallback();
    }
  }

  onMouseMove(event) {
    this.mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
    this.mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;
  
    const vector = new THREE.Vector3(this.mouse.x, this.mouse.y, 0.5);
    vector.unproject(this.newCamera.threeCamera);
    const dir = vector.sub(this.newCamera.threeCamera.position).normalize();
    const distance = -this.newCamera.threeCamera.position.z / dir.z;
    const pos = this.newCamera.threeCamera.position.clone().add(dir.multiplyScalar(distance));
  
    const point = { x: (pos.x) / 4, y: 1 - (pos.y) / 4 };
    this.touchTexture.addTouch(point);
  }

  
  animatePointCloud(targetPositions, startIdleWave = false) {
    if (!this.pointCloud || !this.pointCloud.geometry || !this.pointCloud.geometry.attributes.instanceOffset) {
      console.error("PointCloud or its geometry is not properly initialized.");
      return;
    }
  
    const geometry = this.pointCloud.geometry;
    const offsets = geometry.attributes.instanceOffset.array;
    const numPoints = targetPositions.length;
  
    const initialPositions = [];
    for (let i = 0; i < numPoints; i++) {
      initialPositions.push({
        x: offsets[i * 3],
        y: offsets[i * 3 + 1],
        z: offsets[i * 3 + 2],
      });
    }
  
    gsap.to(initialPositions, {
      duration: 3,
      x: (i) => targetPositions[i].x,
      y: (i) => targetPositions[i].y,
      z: (i) => targetPositions[i].z,
      ease: "power4.inOut",
      onUpdate: () => {
        for (let i = 0; i < numPoints; i++) {
          offsets[i * 3] = initialPositions[i].x;
          offsets[i * 3 + 1] = initialPositions[i].y;
          offsets[i * 3 + 2] = initialPositions[i].z;
        }
        geometry.attributes.instanceOffset.needsUpdate = true;
      },
      onComplete: () => {
        //if (startIdleWave) 
         //this.addSubtleWaveMotion(targetPositions);
      },
    });
  }
  
  

  addSubtleWaveMotion(targetPositions) {
    const offsets = this.pointCloud.geometry.attributes.instanceOffset.array;
    const numPoints = offsets.length / 3;

    const wave = () => {
        const time = performance.now() * 0.001;
        this.touchTexture.update();
        const textureData = this.touchTexture.ctx.getImageData(0, 0, this.touchTexture.size, this.touchTexture.size).data;

        for (let i = 0; i < numPoints; i++) {
            const target = targetPositions[i];
            if (!target) continue; // Skip if there's no corresponding target position

            const xIndex = Math.floor((offsets[i * 3] + 2) / 4 * this.touchTexture.size);
            const yIndex = Math.floor(((-offsets[i * 3 + 1] + 5) / 4) * this.touchTexture.size);

            const index = (yIndex * this.touchTexture.size + xIndex) * 4;
            const influence = textureData[index] / 255;

            if (influence > 0.1) {
                const dx = influence * 0.1 * Math.sin(time + i);
                const dz = influence * 0.1 * Math.cos(time + i);

                offsets[i * 3] += dx;
                offsets[i * 3 + 2] += dz;
            } else {
                offsets[i * 3] += (target.x - offsets[i * 3]) * 0.01;
                offsets[i * 3 + 1] += (target.y - offsets[i * 3 + 1]) * 0.01;
                offsets[i * 3 + 2] += (target.z - offsets[i * 3 + 2]) * 0.01;
            }
        }

        this.pointCloud.geometry.attributes.instanceOffset.needsUpdate = true;
        requestAnimationFrame(wave);
    };

    wave();
}



  createSceneObjects() {
    const paletteTexture = this.loader.getAsset("facadeTexture");

    this.p1Material = new Material({
        uniforms: {
            downsideColor: { value: new THREE.Vector3(3 / 255, 3 / 255, 128 / 255) }
        },
        vertexShader: PropShader.vertexShader,
        fragmentShader: PropShader.fragmentShader
    });

    this.standardMaterial = new THREE.MeshPhongMaterial({
        map: paletteTexture,
        receiveShadow: true,
        dithering: true,
        castShadow: true
    });
    this.standardMaterial.shininess = 5;

    this.p1model = new Model({
        modelLink: 'facadeModel',
        material: this.standardMaterial,  // Store the material here
        position: new THREE.Vector3(17, 1, 0),
        rotation: new THREE.Vector3(0.0, 0.0, 0.0),
        scale: new THREE.Vector3(0, 0, 0),
        scene: this.scene,
        name: 'facadeModel'
    });
    this.p1model.all_model[0].position.set(0, 0, 0);

    this.introLogo = new Model({
        modelLink: 'logoModel',
        material: this.standardMaterial,
        position: new THREE.Vector3(0, 0, 0),
        rotation: new THREE.Vector3(0.0, 0.0, 0.0),
        scale: new THREE.Vector3(0, 0, 0),
        scene: this.scene,
        name: 'introLogo'
    });

    this.introLogo.all_model[0].scale.set(0, 0, 0);

    this.fullLoaded = true;
}


  create_collision_box = (position, size, name) => {
    this.cube = new Mesh(
      new BoxGeometry(size.x, size.y, size.z),
      new MeshBasicMaterial({ color: 0x22ffffff, opacity: 0.1 }),
    );
    this.cube.position.set(position.x, position.y, position.z);
    this.cube.visible = false;
    this.cube.name = name;
    this.cube.layers.set(COLLISION_LAYER);
    this.scene.add(this.cube);
  }

  generateCamCurve() {
    const posPoints = [
      new THREE.Vector3(50, 24, -40),
      new THREE.Vector3(50, 24, 40)
    ];
    this.spline = new THREE.CatmullRomCurve3(posPoints);
    this.points = this.spline.getPoints(200);

    const lookAtPoints = [
      new THREE.Vector3(5, 3, -1),
      new THREE.Vector3(5, 3, 1)
    ];
    this.lookAtSpline = new THREE.CatmullRomCurve3(lookAtPoints);
    this.lookAtPoints = this.lookAtSpline.getPoints(200);
  }

  detectDevice() {
    const userAgent = navigator.userAgent;
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|Windows Phone/i.test(userAgent)) {
      return 'phone';
    }
    return 'computer';
  }

  componentDidMount() {
    console.log("scene 1 mounted");
    this.SceneMount();

    this.setCamera(40, 40,window.innerHeight>window.innerWidth ? 55 : 35);
    this.setPostProcess();
    this.orbitalCamera = new OrbitalCamera(this.newCamera.threeCamera);
    this.orbitalCamera.spherical.set(55, 999, 40);
    this.orbitalCamera.target = new THREE.Vector3(0, 9, 0);
    this.orbitalCamera.minPhi =1.36;
    this.orbitalCamera.maxPhi =1.45;
    this.orbitalCamera.minTheta =39.0;
    this.orbitalCamera.maxTheta =39.5;
    this.orbitalCamera.minRadius = 10;
    this.orbitalCamera.maxRadius = 55;
  }

  componentWillUnmount() {
    // Clean up if necessary
  }

  loadListener() {
    document.addEventListener("mousedown", this.onPointerDown.bind(this));
    document.addEventListener("mousemove", this.onPointerMove.bind(this));
    document.addEventListener("mouseup", this.onPointerUp.bind(this));
    document.addEventListener("touchstart", this.onPointerDown.bind(this));
    document.addEventListener("touchmove", this.onPointerMove.bind(this));
    document.addEventListener("touchend", this.onPointerUp.bind(this));
    document.addEventListener("wheel", this.onZoom.bind(this));
  }
  setSectionActive(isActive) {
    this.isSectionActive = isActive;
  }
  onPointerDown(event) {
    this.isPointerDown = true;
    this.lastPointerPosition.set(
      event.clientX || event.touches[0].clientX,
      event.clientY || event.touches[0].clientY
    );
  }

  onPointerMove(event) {
    if (!this.isPointerDown) {
      if (event) {
        this.mouse.x = event.clientX !== undefined ? event.clientX : (event.touches && event.touches.length > 0 ? event.touches[0].clientX : this.mouse.x);
        this.mouse.y = event.clientY !== undefined ? event.clientY : (event.touches && event.touches.length > 0 ? event.touches[0].clientY : this.mouse.y);
      }
      return;
    }

    const clientX = event.clientX || event.touches[0].clientX;
    const clientY = event.clientY || event.touches[0].clientY;

    const deltaX = clientX - this.lastPointerPosition.x;
    const deltaY = clientY - this.lastPointerPosition.y;

    const deltaTheta = deltaX * 0.05;
    const deltaPhi = deltaY * 0.05;

    this.orbitalCamera.rotate(deltaTheta, deltaPhi);

    this.lastPointerPosition.set(clientX, clientY);
  }

  onPointerUp() {
    this.isPointerDown = false;
  }

  onZoom(event) {
    const delta = event.deltaY * 0.05;
    this.orbitalCamera.zoom(delta);
  }

  lerp_model() {
    this.spot.lookAt(new THREE.Vector3(0.0, 0.0, 0.0));
    this.spot.position.set(-15, 25, 15);
  }

  Update() {
    if (this.isSectionActive) {
        // Skip camera updates when a section is active
        return;
    }

    const camera = this.newCamera.threeCamera;
    const focusTarget = new THREE.Vector3(0, 0, 0); // Default to origin if not set

    this.updatePostProcessingSettings({
        bloomIntensity: 0.35,
        focusDistance: 10,
        bokehAperture: 0.000025,
    });

    const now = performance.now();
    this.dt = (now - this.lastUpdate) * 0.001;
    this.lastUpdate = now;
    this.totalTime = (now - this.startTime) * 0.001;

    // Update the time uniform for the wave motion
    if (this.pointCloud && this.pointCloud.material && this.pointCloud.material.uniforms.time) {
        this.pointCloud.material.uniforms.time.value += this.dt;
    }

    this.lerp_model();

    this.mouseNormalized.x = this.mouse.x / window.innerWidth - 0.5;
    this.mouseNormalized.y = this.mouse.y / window.innerHeight - 0.5;

    

    this.orbitalCamera.update();
    this.newCamera.threeCamera.position.copy(this.orbitalCamera.camera.position);
    this.newCamera.threeCamera.lookAt(this.orbitalCamera.target);
    this.newCamera.threeCamera.updateMatrixWorld(true);

    this.boot = true;
}

}

export default React.forwardRef((props, ref) => <PointCloud {...props} ref={ref} />);
