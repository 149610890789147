import * as THREE from 'three';

class Time {
    constructor() {
        this.clock = new THREE.Clock();
        this.deltaTime = 0;
        this.elapsedTime = 0;
        this.lastTime = 0;
    }

    update() {
        const currentTime = this.clock.getElapsedTime();
        this.deltaTime = currentTime - this.lastTime;
        this.elapsedTime = currentTime;
        this.lastTime = currentTime;
    }

    getDeltaTime() {
        return this.deltaTime;
    }

    getElapsedTime() {
        return this.elapsedTime;
    }
}

export default Time;
