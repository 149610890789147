import * as THREE from 'three';
import gsap from 'gsap';
import Model from '../../../../Render/Model';

class BulletCounter {
  constructor(game, scene, newCamera) {
    this.game = game;
    this.scene = scene;
    this.newCamera = newCamera;
    this.bullets = [];
    this.isReloading = false;

    this.createBulletModels();
    this.createInstancedMeshes();
  }

  // Create bullet models
  createBulletModels() {
    const bulletMaterial = new THREE.MeshPhongMaterial({});

    this.bulletFullModel = new Model({
      modelLink: 'bulletFullModel',
      material: bulletMaterial,
      position: new THREE.Vector3(0, 99, 0), // Hide initially by setting position off-screen
      rotation: new THREE.Vector3(0.0, 0.0, 0.0),
      scale: new THREE.Vector3(1, 1, 1),
      scene: this.scene,
      name: 'bulletFullModel'
    });

    this.bulletEmptyModel = new Model({
      modelLink: 'bulletEmptyModel',
      material: bulletMaterial,
      position: new THREE.Vector3(0, 99, 0), // Hide initially by setting position off-screen
      rotation: new THREE.Vector3(0.0, 0.0, 0.0),
      scale: new THREE.Vector3(1, 1, 1),
      scene: this.scene,
      name: 'bulletEmptyModel'
    });
  }

  // Create instanced meshes for bullets
  createInstancedMeshes() {
    const bulletGeometry = this.bulletFullModel.all_model[0].geometry;
    const bulletMaterial = this.bulletFullModel.all_model[0].material;

    this.bulletFullInstancedMesh = new THREE.InstancedMesh(bulletGeometry, bulletMaterial, 100);
    this.bulletEmptyInstancedMesh = new THREE.InstancedMesh(bulletGeometry, bulletMaterial, 100);

    this.scene.add(this.bulletFullInstancedMesh);
    this.scene.add(this.bulletEmptyInstancedMesh);

    this.bulletFullInstancedMesh.instanceMatrix.setUsage(THREE.DynamicDrawUsage);
    this.bulletEmptyInstancedMesh.instanceMatrix.setUsage(THREE.DynamicDrawUsage);
  }

  // Update bullet positions based on camera
  updateBulletPositions() {
    if (!this.newCamera || !this.newCamera.threeCamera) {
      console.warn('Camera not initialized yet.');
      return;
    }

    const aspectRatio = window.innerWidth / window.innerHeight;
    const spacing = 0.1125;
    const slantSpacing = 0.025;
    const startY = -((this.bullets.length - 1) * spacing);
    const frustumSize = 1;
    let baseXPosition = (frustumSize * aspectRatio) - 0.2;

    if (window.innerWidth < window.innerHeight) {
      baseXPosition += 0.4;
    }

    this.bullets.forEach((bullet, index) => {
      const targetPosition = new THREE.Vector3(
        baseXPosition - index * slantSpacing,
        startY + index * spacing,
        -4 + (index === this.bullets.length - 1 ? -0.125 : 0) // Add 0.2 to z-position for the last bullet
      );

      targetPosition.applyMatrix4(this.newCamera.threeCamera.matrixWorld);

      gsap.to(bullet.position, {
        x: targetPosition.x,
        y: targetPosition.y,
        z: targetPosition.z,
        duration: 0.25,
        ease: "power.inOut",
        onUpdate: () => {
          bullet.updateMatrix();
          this.bulletFullInstancedMesh.setMatrixAt(index, bullet.matrix);
          this.bulletFullInstancedMesh.instanceMatrix.needsUpdate = true;
        }
      });

      bullet.rotation.set(0, -Math.PI / 2, -Math.PI / 2);
      bullet.scale.set(0.5, 0.5, 0.5);
    });
  }

  // Remove all bullets from the scene
  removeAllBullets() {
    this.bullets.forEach(bullet => {
      if (bullet.parent) bullet.parent.remove(bullet);
    });
    this.bullets = [];
    this.bulletFullInstancedMesh.count = 0;
    this.bulletEmptyInstancedMesh.count = 0;
  }

  // Animate a single bullet
  animateBullet(bullet) {
    if (!bullet || !bullet.position) {
      console.warn('Attempted to animate a bullet that no longer exists.');
      return;
    }

    // Replace bullet with an empty bullet model during flight
    const emptyBullet = this.bulletEmptyModel.all_model[0].clone();
    emptyBullet.position.copy(bullet.position);
    emptyBullet.rotation.copy(bullet.rotation);
    emptyBullet.scale.set(0.5, 0.5, 0.5);

    this.scene.add(emptyBullet);
    this.scene.remove(bullet);

    // Remove the bullet from the bullets array
    const bulletIndex = this.bullets.indexOf(bullet);
    if (bulletIndex > -1) {
      this.bullets.splice(bulletIndex, 1);
    }

    // Generate random values for upward movement and fall position
    const upwardDistance = THREE.MathUtils.randFloat(1.0, 2.0);
    const finalZOffset = THREE.MathUtils.randFloat(-1.0, -1.5);
    const finalXOffset = THREE.MathUtils.randFloat(-2.0, 2.0);

    // Animate the bullet to move up before going down and rotate
    gsap.to(emptyBullet.position, {
      y: `+=${upwardDistance}`,
      duration: 0.2,
      ease: "power.out",
      onComplete: () => {
        gsap.to(emptyBullet.position, {
          y: '-=6',
          z: `+=${finalZOffset}`,
          x: `+=${finalXOffset}`,
          duration: 0.9,
          ease: "power.out",
          onComplete: () => {
            if (emptyBullet && emptyBullet.parent) {
              emptyBullet.parent.remove(emptyBullet);
            }
          }
        });
      }
    });

    // Rotate the bullet during flight
    const randomRotationSpeed = THREE.MathUtils.randFloat(1.0, 2.0);
    gsap.to(emptyBullet.rotation, {
      y: Math.PI * 2 * randomRotationSpeed,
      x: Math.PI * 2 * randomRotationSpeed,
      duration: 1.3,
      ease: "none"
    });
  }

  // Create bullet counter based on current ammo count
  createBulletCounter() {
    if (this.isReloading) return;

    // Remove any existing bullets from the scene
    this.removeAllBullets();

    // Create bullets based on current ammo count
    for (let i = 0; i < this.game.weapon.ammoCount; i++) {
      const bullet = this.bulletFullModel.all_model[0].clone();
      bullet.position.x = -99;
      bullet.rotation.x = Math.PI / 2;
      this.scene.add(bullet);
      this.bullets.push(bullet);
    }

    // Animate bullets coming up to proper position
    this.animateBulletsToPosition();
  }

  // Animate bullets to their proper positions
  animateBulletsToPosition() {
    const aspectRatio = window.innerWidth / window.innerHeight;
    const spacing = 0.1125;
    const slantSpacing = 0.025;
    const startY = -((this.bullets.length - 1) * spacing);
    const frustumSize = 1;
    let baseXPosition = (frustumSize * aspectRatio) - 0.2;

    if (window.innerWidth < window.innerHeight) {
      baseXPosition += 0.4;
    }

    this.bullets.forEach((bullet, index) => {
      const targetPosition = new THREE.Vector3(
        baseXPosition - index * slantSpacing,
        startY + index * spacing,
        -4 + (index === this.bullets.length - 1 ? -0.125 : 0)
      );

      targetPosition.applyMatrix4(this.newCamera.threeCamera.matrixWorld);

      // Set initial position below the screen
      bullet.position.set(targetPosition.x, targetPosition.y - 2, targetPosition.z);

      // Animate to target position
      gsap.fromTo(bullet.position, 
        { y: targetPosition.y - 2 },
        {
          x: targetPosition.x,
          y: targetPosition.y,
          z: targetPosition.z,
          duration: 0.5,
          ease: "power2.out",
          delay: index * 0.05,
          onUpdate: () => {
            bullet.updateMatrix();
            this.bulletFullInstancedMesh.setMatrixAt(index, bullet.matrix);
            this.bulletFullInstancedMesh.instanceMatrix.needsUpdate = true;
          }
        }
      );

      gsap.to(bullet.rotation, {
        x: 0,
        y: -Math.PI / 2,
        z: -Math.PI / 2,
        duration: 0.5,
        ease: "power2.out"
      });

      gsap.to(bullet.scale, {
        x: 0.5,
        y: 0.5,
        z: 0.5,
        duration: 0.5,
        ease: "power2.out"
      });
    });
  }

  // Animate existing bullets for reload
  animateExistingBulletsForReload() {
    this.isReloading = true;
    const timeline = gsap.timeline({
      onComplete: () => {
      
      }
    });

    this.bullets.forEach((bullet, index) => {
      const fallDuration = THREE.MathUtils.randFloat(0.5, 0.8);
      const fallDistance = THREE.MathUtils.randFloat(1.5, 2.0);
      const horizontalShift = THREE.MathUtils.randFloatSpread(1.5);

      timeline.to(bullet.position, {
        y: `-=${fallDistance}`,
        x: `+=${horizontalShift}`,
        z: `+=${horizontalShift}`,
        duration: fallDuration,
        ease: "power2.in",
        delay: index * 0.0,
        onUpdate: () => {
          bullet.updateMatrix();
          this.bulletFullInstancedMesh.setMatrixAt(index, bullet.matrix);
          this.bulletFullInstancedMesh.instanceMatrix.needsUpdate = true;
        }
      }, 0);

      timeline.to(bullet.rotation, {
        x: THREE.MathUtils.randFloatSpread(Math.PI),
        y: THREE.MathUtils.randFloatSpread(Math.PI),
        z: THREE.MathUtils.randFloatSpread(Math.PI),
        duration: fallDuration,
        ease: "power1.inOut"
      }, 0);
    });

    return timeline;
  }

  // Update method to keep bullets in front of the camera
  update() {
    if (!this.isReloading) {
      this.updateBulletPositions();
    }
  }
}

export default BulletCounter;