import * as THREE from 'three';

class EventHandler {
  constructor(scene) {
    this.scene = scene;
    this.isPointerDown = false;
    this.lastPointerPosition = new THREE.Vector2();
    //mouse data
    this.mouse = new THREE.Vector2(window.innerWidth / 2, window.innerHeight / 2);
    this.mouseNormalized = new THREE.Vector2(0.5, 0.5);
    this.lerpedMouse = new THREE.Vector2(0.5, 0.5);
    this.lerpedMouseRaw = new THREE.Vector2(window.innerWidth / 2, window.innerHeight / 2);

    this.initEventListeners();
  }

  initEventListeners() {
    document.addEventListener("mousemove", this.onPointerMove.bind(this));
    document.addEventListener("mousedown", this.onPointerDown.bind(this));
    document.addEventListener("mouseup", this.onPointerUp.bind(this));

    // Touch events
    document.addEventListener("touchstart", this.onPointerDown.bind(this));
    document.addEventListener("touchmove", this.onPointerMove.bind(this));
    document.addEventListener("touchend", this.onPointerUp.bind(this));

    // Zoom event
    document.addEventListener("wheel", this.onZoom.bind(this));
  }

  onPointerDown(event) {
    this.isPointerDown = true;
    this.lastPointerPosition.set(
      event.clientX || event.touches[0].clientX,
      event.clientY || event.touches[0].clientY
    );
  }

  onPointerMove(event) {
    if (event) {
      if (event.clientX !== undefined) {
        this.mouse.x = event.clientX;
        this.mouse.y = event.clientY;
      } else if (event.touches && event.touches.length > 0) {
        this.mouse.x = event.touches[0].clientX;
        this.mouse.y = event.touches[0].clientY;
      }
    }

    this.mouseNormalized.x = this.mouse.x / window.innerWidth - 0.5;
    this.mouseNormalized.y = this.mouse.y / window.innerHeight - 0.5;
  }

  onPointerUp() {
    this.isPointerDown = false;
  }

  onZoom(event) {
    // Implement zoom logic here if needed
  }
}
export default EventHandler;