import * as THREE from 'three';
import MotionMesh from './MotionMesh';
import MotionText from './MotionText';
import MotionButton from './MotionButton';
import { gsap } from 'gsap';
import Project3dom from './ProjectSections/Project_3dom';
import ProjectBobots from './ProjectSections/Project_Bobots';
import ProjectFolio from './ProjectSections/Project_Folio';
import TouchTexture from './TouchTexture';

class ProjectSections {
  constructor(scene, camera, renderer, onProjectUnselected, loader) {
    this.loader = loader;
    this.scene = scene;
    this.camera = camera;
    this.renderer = renderer;
    this.onProjectUnselected = onProjectUnselected;
    this.isAnimationComplete = false;
    this.projects = [];
    this.currentProject = null;
    this.sections = [];
    this.currentScrollY = 0;
    this.targetScrollY = 0;
    this.deviceType =this. detectDevice(); // Use detectDevice method

    this.scrollSpeed = this.deviceType === 'phone' ? 0.4 : 0.1; // Set scroll speed based on device type
    this.totalHeight = 0;
    this.isProjectSelected = false;
    this.raycaster = new THREE.Raycaster();
    this.mouse = new THREE.Vector2();
    this.touchTexture = new TouchTexture();
    this.resolution = new THREE.Vector2(window.innerWidth, window.innerHeight);

    this.appendY = 5;
    
    this.ejectButton = null;
    this.ejectButtonPosition = this.deviceType === 'phone' ? new THREE.Vector3(0, 6, -5) : new THREE.Vector3(0, 10.25, -5);
    this.EJECT_BUTTON_LAYER = 1;
    this.EJECT_BUTTON_NAME = 'ejectButtonCollisionMesh';
    
    this.visibilityThreshold = 7.5; // Adjust this value to control when text becomes visible

    this.isAnimationComplete = false; // New flag to track animation completion

    window.addEventListener('resize', this.onWindowResize.bind(this));
    window.addEventListener('mousemove', this.onMouseMove.bind(this));

    this.setupScrollListeners();
    this.setupInteractionListeners();
  }
  detectDevice() {
    const userAgent = navigator.userAgent;
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|Windows Phone/i.test(userAgent)) {
      return 'phone';
    }
    return 'computer';
  }
  loadProjects(loader) {
    this.projects = {
      project3dom: new Project3dom(this.scene, loader),
      projectBobots: new ProjectBobots(this.scene, loader),
      projectFolio: new ProjectFolio(this.scene, loader),
    };
    return this.projects;
  }

  setCurrentProject(projectId) {
    if (this.projects[projectId]) {
      this.currentProject = this.projects[projectId];
      this.sections = this.currentProject.createSections();
      this.createMotionObjects();
      this.updateSectionPositions();
      this.createButtonMotionText();
      this.createEjectButton();
      this.isProjectSelected = true;

      // Show text for all sections
      for (const section of this.sections) {
        if (section.motionTextInstance) {
          // section.motionTextInstance.show();
        }
      }

      // Show eject button
      if (this.ejectButton) {
        this.ejectButton.show();
      }

      // Set animation complete flag to false
      this.isAnimationComplete = false;

      // Start the floppy disk animation
    } else {
      console.error(`Project with id ${projectId} not found`);
    }
  }

  clearCurrentProject() {
    this.removeMotionObjects();
    this.currentProject = null;
    this.sections = [];
    this.isProjectSelected = false;
    this.targetScrollY = 0;
    this.currentScrollY = 0;
    this.removeEjectButton();
    this.isAnimationComplete = true; // Reset animation flag
    console.log('Cleared current project');
  }

  removeMotionObjects() {
    for (const section of this.sections) {
      if (section.motionMeshInstance && section.motionMeshInstance.group) {
        this.scene.remove(section.motionMeshInstance.group);
        section.motionMeshInstance.group.traverse((child) => {
          if (child.geometry) child.geometry.dispose();
          if (child.material) child.material.dispose();
        });
      }
      if (section.motionTextInstance && section.motionTextInstance.group) {
        this.scene.remove(section.motionTextInstance.group);
        section.motionTextInstance.group.traverse((child) => {
          if (child.geometry) child.geometry.dispose();
          if (child.material) child.material.dispose();
        });
      }
      if (section.motionButtonInstance && section.motionButtonInstance.group) {
        this.scene.remove(section.motionButtonInstance.group);
        section.motionButtonInstance.group.traverse((child) => {
          if (child.geometry) child.geometry.dispose();
          if (child.material) child.material.dispose();
        });
      }
    }
    
    // Force a scene update
    this.renderer.renderLists.dispose();
  }

  removeEjectButton() {
    if (this.ejectButton) {
      this.scene.remove(this.ejectButton.group);
      this.ejectButton.group.traverse((child) => {
        if (child.geometry) child.geometry.dispose();
        if (child.material) child.material.dispose();
      });
      this.ejectButton = null;
    }
  }

  createMotionObjects() {
    this.sections.forEach((section, index) => {
      if (section.motionMesh) {
        section.motionMeshInstance = new MotionMesh({
          ...section.motionMesh,
          scene: this.scene
        });
      }
      if (section.motionText) {
        section.motionTextInstance = new MotionText({
          ...section.motionText,
          scene: this.scene
        });
        console.log('Created MotionText:', section.motionTextInstance);
      }
      if (section.motionButton) {
        section.motionButtonInstance = new MotionButton({
          ...section.motionButton,
          scene: this.scene
        });
        // Set the link on the collisionMesh's userData
        if (section.motionButtonInstance.collisionMesh) {
          section.motionButtonInstance.collisionMesh.userData.link = section.motionButton.link;
        }
        console.log('Created MotionButton:', section.motionButtonInstance);
      }
    });
  }

  addSection(sectionData) {
    const section = {
      height: sectionData.height,
      startY: this.totalHeight,
    };

    if (sectionData.motionMesh) {
      section.motionMesh = new MotionMesh(sectionData.motionMesh);
    }

    if (sectionData.motionText) {
      section.motionText = new MotionText(sectionData.motionText);
    }

    this.sections.push(section);
    this.totalHeight += section.height;
  }

  updateSectionPositions() {
    this.totalHeight = 0;
    for (let i = 0; i < this.sections.length; i++) {
      const section = this.sections[i];
      section.startY = this.totalHeight;
  
      if (section.motionMeshInstance) {
        section.motionMeshInstance.group.position.y = -section.startY;
      }
  
      if (section.motionTextInstance) {
        section.motionTextInstance.group.position.y = -section.startY;
      }
  
      this.totalHeight += section.height;
    }
  }

  createProjectSectionMeshes(loader) {
    this.projects = this.loadProjects(loader);
    if (this.projects.length === 0) {
      console.error('No projects loaded');
    }
  }

  createEjectButton() {
    if (!this.ejectButton) {
      const buttonTexture = this.loader.getAsset('ejectTexture');

      this.ejectButton = new MotionButton({
        texture: buttonTexture,
        size: new THREE.Vector2(1.5, 0.6),
        position: this.ejectButtonPosition,
        rotation: new THREE.Euler(Math.PI, 0, 0),
        subdivisions: new THREE.Vector2(32, 32),
        scene: this.scene,
        renderOrder: 1000,
        link: ''
      });

      const buttonText = new MotionText({
        text: 'Eject',
        font: '/fonts/Akira Super Bold.ttf',
        size: 0.25,
        position: new THREE.Vector3(0, 0, 0.01),
        color: 0x000000,
        scene: this.scene,
        alignment: 'center'
      });

      this.ejectButton.group.add(buttonText.group);

      this.ejectButton.collisionMesh.name = this.EJECT_BUTTON_NAME;
      this.ejectButton.collisionMesh.userData.onClickHandler = () => {
        if (typeof this.onProjectUnselected === 'function') {
          this.onProjectUnselected();
        } else {
          console.error('onProjectUnselected is not a function');
        }
      };

      this.scene.add(this.ejectButton.group);
    }

    this.ejectButton.show();
  }

  setupScrollListeners() {
    window.addEventListener('wheel', (event) => {
      this.targetScrollY += event.deltaY * 0.02;
    });

    window.addEventListener('touchstart', (event) => {
      this.touchStartY = event.touches[0].clientY;
    });

    window.addEventListener('touchmove', (event) => {
      const touchY = event.touches[0].clientY;
      const deltaY = this.touchStartY - touchY;
      this.targetScrollY += deltaY * 0.04;
      this.touchStartY = touchY;
    });
  }

  clampScrollY() {
    const maxScrollY = Math.max(0, this.totalHeight - window.innerHeight);
    this.targetScrollY = Math.max(0, Math.min(this.targetScrollY, maxScrollY));
  }

  setupInteractionListeners() {
    window.addEventListener('mousemove', (event) => {
      this.mouse.x = (event.clientX / window.innerWidth);
      this.mouse.y = 1 - (event.clientY / window.innerHeight);
      
      this.touchTexture.update(this.mouse);
    });

    window.addEventListener('click', (event) => {
  
        this.mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
        this.mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;
        this.checkIntersections();
      
    });
  }

  checkIntersections() {
    this.raycaster.setFromCamera(this.mouse, this.camera);
    const intersects = this.raycaster.intersectObjects(this.scene.children, true);

    for (let i = 0; i < intersects.length; i++) {
      const intersectedObject = intersects[i].object;
      if (intersectedObject.name === 'ButtonCollisionMesh') {
        console.log('Button clicked');
        console.log(intersectedObject.userData.link);
        if (intersectedObject.userData.link) {
          window.open(intersectedObject.userData.link, '_blank');
        }
        return;
      }
      if (intersectedObject.name === this.EJECT_BUTTON_NAME) {
        console.log('Eject button clicked');
        if (intersectedObject.userData.onClickHandler) {
          intersectedObject.userData.onClickHandler();
        }
        return;
      }
    }
  }

  createButtonMotionText() {
    // Implementation for createButtonMotionText
  }

  update(deltaTime) {
    if (!this.isProjectSelected) return;
    const addon = 10;

    
      if(this.targetScrollY < 1.5) {
        this.targetScrollY = 1.5;
      }
      if(this.targetScrollY > this.totalHeight - 5) {
        this.targetScrollY = this.totalHeight - 5;
      }
      this.currentScrollY += (this.targetScrollY - this.currentScrollY) * this.scrollSpeed;
    

    this.touchTexture.update(this.mouse);

    for (let i = 0; i < this.sections.length; i++) {
      const section = this.sections[i];
      const sectionY = addon - section.startY + this.currentScrollY + this.appendY;
      if (section.motionButtonInstance) {
        section.motionButtonInstance.update(deltaTime);
        section.motionButtonInstance.group.position.y = sectionY;
        section.motionButtonInstance.updateTouchTexture(this.touchTexture.texture);
        section.motionButtonInstance.updateResolution(this.resolution);
        
        const distanceToCamera = Math.abs(sectionY - this.camera.position.y);
        if (distanceToCamera < this.visibilityThreshold) {
          if (!section.motionButtonInstance.isVisible) {
            section.motionButtonInstance.show();
          }
        }
      }
      if (section.motionMeshInstance) {
        section.motionMeshInstance.update(deltaTime);
        section.motionMeshInstance.group.position.y = sectionY;
        section.motionMeshInstance.updateTouchTexture(this.touchTexture.texture);
        section.motionMeshInstance.updateResolution(this.resolution);
        
        const distanceToCamera = Math.abs(sectionY - this.camera.position.y);
        if (distanceToCamera < this.visibilityThreshold) {
          if (!section.motionMeshInstance.isVisible) {
            section.motionMeshInstance.show();
          }
        }
      }

      if (section.motionTextInstance) {
        section.motionTextInstance.update(deltaTime);
        section.motionTextInstance.group.position.y = sectionY;
        section.motionTextInstance.updateTouchTexture(this.touchTexture.texture);
        section.motionTextInstance.updateResolution(this.resolution);
        
        const distanceToCamera = Math.abs(sectionY - this.camera.position.y);
        if (distanceToCamera < this.visibilityThreshold) {
          if (!section.motionTextInstance.isVisible) {
            section.motionTextInstance.show();
          }
        }
      }
    }

    if (this.ejectButton) {
      this.ejectButton.update(deltaTime);
      this.ejectButton.updateTouchTexture(this.touchTexture.texture);
      this.ejectButton.updateResolution(this.resolution);
      
      this.ejectButton.group.updateMatrixWorld(true);
      
      const buttonWorldPosition = new THREE.Vector3();
      this.ejectButton.group.getWorldPosition(buttonWorldPosition);
    }
  }

  onWindowResize() {
    this.resolution.set(window.innerWidth, window.innerHeight);
  }

  onMouseMove(event) {
    this.mouse.x = event.clientX / window.innerWidth;
    this.mouse.y = 1 - (event.clientY / window.innerHeight);
  }
}

export default ProjectSections;