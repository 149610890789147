class WaveManager {
  constructor(scene) {
    this.scene = scene;
    this.currentWave = 0;
    this.onWaveComplete = null;

    // Define waves with different enemy counts
    this.waves = [
      { parachuters: 7, helicopters: 0, jets: 0 },
      { parachuters: 8, helicopters: 1, jets: 0 },
      { parachuters: 12, helicopters: 2, jets: 1 },
      { parachuters: 15, helicopters: 3, jets: 2 },
      { parachuters: 17, helicopters: 4, jets: 3 },
      { parachuters: 20, helicopters: 5, jets: 4 },
      { parachuters: 23, helicopters: 5, jets: 4 },
      { parachuters: 26, helicopters: 6, jets: 5 },
      { parachuters: 28, helicopters: 6, jets: 6 },
      { parachuters: 35, helicopters: 7, jets: 7 },
      { parachuters: 50, helicopters: 8, jets: 8 },
      { parachuters: 70, helicopters: 12, jets: 12 },
      { parachuters: 75, helicopters: 12, jets: 12 },
      { parachuters: 80, helicopters: 12, jets: 12 },
      { parachuters: 85, helicopters: 12, jets: 12 },
      { parachuters: 90, helicopters: 12, jets: 12 },
      { parachuters: 90, helicopters: 12, jets: 12 },
      { parachuters: 90, helicopters: 12, jets: 12 },
      { parachuters: 90, helicopters: 12, jets: 12 },
      { parachuters: 90, helicopters: 12, jets: 12 },
      { parachuters: 90, helicopters: 12, jets: 12 },
      { parachuters: 90, helicopters: 12, jets: 12 },
      { parachuters: 90, helicopters: 12, jets: 12 },
      { parachuters: 90, helicopters: 12, jets: 12 },
      { parachuters: 90, helicopters: 12, jets: 12 },
    ];

    this.activeEnemies = [];
    this.spawnIntervals = {
      parachuters: 550, // 0.55 seconds
      helicopters: 6000, // 6 seconds
      jets: 8000, // 8 seconds
    };
    this.spawnCounters = {
      parachuters: 0,
      helicopters: 0,
      jets: 0,
      target: 0,
    };
    this.lastSpawnTimes = {
      parachuters: 0,
      helicopters: 0,
      jets: 0,
    };

    this.waveStarted = false;
  }

  // Start the wave with delays for each enemy type
  startWave() {
    const parachuteDelay = Math.random() * 500; // Random delay between 0 and 0.5 seconds
    const helicopterDelay = Math.random() * 4000; // Random delay between 0 and 4 seconds
    const jetDelay = Math.random() * 6000; // Random delay between 0 and 6 seconds

    setTimeout(() => {
      this.lastSpawnTimes.parachuters = performance.now();
    }, parachuteDelay);

    setTimeout(() => {
      this.lastSpawnTimes.helicopters = performance.now();
    }, helicopterDelay);

    setTimeout(() => {
      this.lastSpawnTimes.jets = performance.now();
    }, jetDelay);

    setTimeout(() => {
      this.noShoot = false;
      const wave = this.waves[this.currentWave];
      this.waveStarted = true;
      this.spawnCounters.parachuters = wave.parachuters;
      this.spawnCounters.helicopters = wave.helicopters;
      this.spawnCounters.jets = wave.jets;
      this.spawnCounters.target = wave.parachuters + wave.helicopters + wave.jets;
    }, 1000); // 1-second delay

    this.noShoot = false;
    this.scene.ammoCount = 8 + this.scene.upgradeManager.getAmmoBonus();
    this.scene.bulletCounter.createBulletCounter();
  }

  // Update the wave manager
  update() {
    if (this.waveStarted) {
      // Remove destroyed enemies from the activeEnemies array
      this.activeEnemies = this.activeEnemies.filter(enemy => !enemy.destroyed);

      // Check if the wave is complete
      if (this.isWaveComplete()) {
        this.scene.bulletCounter.removeAllBullets();
        this.waveStarted = false;
        this.nextWave();
      }
    }
  }

  // Get the current wave count
  getWaveCount() {
    return this.currentWave;
  }

  // Handle wave completion
  handleWaveComplete() {
    if (this.onWaveComplete) {
      this.onWaveComplete(); // Call the callback when a wave is complete
    }
  }

  // Set the onWaveComplete callback
  setOnWaveCompleteCallback(callback) {
    this.onWaveComplete = callback;
  }

  // Handle spawning of enemies
  handleSpawning(now, type, spawnFunction) {
    if (this.spawnCounters[type] > 0 && now - this.lastSpawnTimes[type] > this.spawnIntervals[type]) {
      spawnFunction();
      this.spawnCounters[type]--;
      this.lastSpawnTimes[type] = now;
    }
  }

  // Check if the wave is complete
  isWaveComplete() {
    return (
      this.spawnCounters.parachuters === 0 &&
      this.spawnCounters.helicopters === 0 &&
      this.spawnCounters.jets === 0 &&
      this.spawnCounters.target < 1
    );
  }

  // Move to the next wave
  nextWave() {
    this.currentWave++;
    setTimeout(() => {
      this.showUpgradeMenu();
    }, 1000); // 1-second delay before showing the upgrade menu
  }

  // Show the upgrade menu
  showUpgradeMenu() {
    setTimeout(() => {
      this.onWaveComplete();
    }, 1000); // 1-second delay before showing the upgrade menu
  }
}

export default WaveManager;