import * as THREE from 'three';

export class BulletEmitter {
    constructor(scene, position) {
        this.scene = scene;
        this.position = position;
        this.geometry = new THREE.BoxGeometry(0.05, 0.05, 0.5); // Small elongated box for the trail
        this.material = this.createMaterial();
        this.particleSystems = [];
    }

    createMaterial() {
        const material = new THREE.MeshPhongMaterial({
            color: 0xf7e1b0,
            emissive: 0xf7e1b0,
            emissiveIntensity: 1000, // Increased from 300 to contribute more to bloom
            shininess: 100
        });

        // Add a custom shader to amplify the emissive contribution
        material.onBeforeCompile = (shader) => {
            shader.fragmentShader = shader.fragmentShader.replace(
                '#include <emissivemap_fragment>',
                `
                #include <emissivemap_fragment>
                totalEmissiveRadiance *= 2.0; // Amplify emissive contribution
                `
            );
        };

        return material;
    }

    shootBullet() {
        const count = 1; // Number of bullets per shot
        const instancedMesh = new THREE.InstancedMesh(this.geometry, this.material, count);
        const dummy = new THREE.Object3D();
        const particles = [];

        for (let i = 0; i < count; i++) {
            const particle = this.createParticle();
            this.updateDummy(dummy, particle);
            instancedMesh.setMatrixAt(i, dummy.matrix);
            particles.push(particle);
        }

        instancedMesh.instanceMatrix.needsUpdate = true;
        this.scene.add(instancedMesh);
        this.particleSystems.push({ mesh: instancedMesh, particles });
    }

    createParticle() {
        return {
            position: this.position.clone(),
            velocity: new THREE.Vector3(
                THREE.MathUtils.randFloat(-0.05, 0.05),
                0.25, // Speed upwards
                THREE.MathUtils.randFloat(-0.05, 0.05)
            ),
            scale: new THREE.Vector3(1, 1, 1),
            lifetime: 500
        };
    }

    updateDummy(dummy, particle) {
        dummy.position.copy(particle.position);
        dummy.scale.copy(particle.scale);
        dummy.updateMatrix();
    }

    update() {
        const dummy = new THREE.Object3D();
        this.particleSystems.forEach((system, sysIndex) => {
            const { mesh, particles } = system;
            let aliveParticles = 0;

            particles.forEach((particle, index) => {
                if (particle.lifetime > 0) {
                    this.updateParticle(particle);
                    this.updateDummy(dummy, particle);
                    dummy.lookAt(dummy.position.clone().add(particle.velocity));
                    dummy.updateMatrix();
                    mesh.setMatrixAt(aliveParticles++, dummy.matrix);
                }
            });

            mesh.count = aliveParticles;
            mesh.instanceMatrix.needsUpdate = true;

            if (aliveParticles === 0) {
                this.scene.remove(mesh);
                this.particleSystems.splice(sysIndex, 1);
            }
        });
    }

    updateParticle(particle) {
        particle.position.add(particle.velocity);
        particle.lifetime--;

        // Stretch the bullet to create a trail effect
        const length = 1.0 + (500 - particle.lifetime) * 0.02;
        particle.scale.set(1, 1, length);

        // Fade out the emissive intensity over time
        const emissiveIntensity = particle.lifetime / 500;
        this.material.emissiveIntensity = emissiveIntensity;
    }
}

export class Gunner {
    constructor(scene, position) {
        this.scene = scene;
        this.position = position;
        this.emitter = new BulletEmitter(scene, position);
        this.isFiring = false;
        this.shotInterval = 200; // Time between each shot in a burst (ms)
        this.pauseBetweenBursts = THREE.MathUtils.randInt(2000, 5000); // Random pause time between bursts (ms)
    }

    startFiring() {
        if (!this.isFiring) {
            this.isFiring = true;
            this.fireBurst();
        }
    }

    fireBurst() {
        const burstCount = THREE.MathUtils.randInt(5, 10); // Random number of shots per burst
        let shotsFired = 0;

        const fireInterval = setInterval(() => {
            if (shotsFired < burstCount) {
                this.emitter.shootBullet();
                shotsFired++;
            } else {
                clearInterval(fireInterval);
                setTimeout(() => {
                    this.fireBurst(); // Restart the next burst after a pause
                }, this.pauseBetweenBursts);
            }
        }, this.shotInterval);
    }

    update() {
        this.emitter.update();
    }
}
