import * as THREE from 'three';
import { AtmosphericSkyShader } from '../../../../Shaders/AtmosphericSkyShader';
import gsap from 'gsap';

class Sky {
  constructor(scene) {
    this.scene = scene;
    this.createSky();
  }

  // Create the sky mesh and add it to the scene
  createSky() {
    const skyMaterial = new THREE.ShaderMaterial({
      vertexShader: AtmosphericSkyShader.vertexShader,
      fragmentShader: AtmosphericSkyShader.fragmentShader,
      uniforms: THREE.UniformsUtils.clone(AtmosphericSkyShader.uniforms),
      side: THREE.BackSide
    });

    const skyGeometry = new THREE.SphereGeometry(300, 32, 32);
    this.skyMesh = new THREE.Mesh(skyGeometry, skyMaterial);
    this.scene.add(this.skyMesh);

    this.skyMaterial = skyMaterial;
  }

  // Update sky colors based on the time of day
  updateSkyColors(isDay = true) {
    if (isDay) {
      this.setSkyColors(
        new THREE.Color(0x0077ff),
        new THREE.Color(0xffffff),
        new THREE.Color(0xffff80),
        new THREE.Vector3(100, 100, 0),
        1.0
      );
    } else {
      this.setSkyColors(
        new THREE.Color(0x000033),
        new THREE.Color(0x888888),
        new THREE.Color(0x555555),
        new THREE.Vector3(-100, -10, 0),
        0.2
      );
    }
  }

  // Set sky colors with specific parameters
  setSkyColors(topColor, bottomColor, sunColor, sunPosition, sunIntensity) {
    this.skyMaterial.uniforms.topColor.value = topColor;
    this.skyMaterial.uniforms.bottomColor.value = bottomColor;
    this.skyMaterial.uniforms.sunColor.value = sunColor;
    this.skyMaterial.uniforms.sunPosition.value.copy(sunPosition);
    this.skyMaterial.uniforms.sunIntensity.value = sunIntensity;
  }

  // Handle sky color changes when receiving damage
  receiveDamage() {
    const originalTopColor = this.skyMaterial.uniforms.topColor.value.clone();
    const originalBottomColor = this.skyMaterial.uniforms.bottomColor.value.clone();
    const originalSunColor = this.skyMaterial.uniforms.sunColor.value.clone();

    // Flash red
    this.setSkyColors(
      new THREE.Color(0xff0000),
      new THREE.Color(0xff0000),
      new THREE.Color(0xff0000),
      this.skyMaterial.uniforms.sunPosition.value,
      this.skyMaterial.uniforms.sunIntensity.value
    );

    // Lerp back to original colors
    gsap.to(this.skyMaterial.uniforms.topColor.value, {
      r: originalTopColor.r,
      g: originalTopColor.g,
      b: originalTopColor.b,
      duration: 1,
      ease: "power2.out"
    });

    gsap.to(this.skyMaterial.uniforms.bottomColor.value, {
      r: originalBottomColor.r,
      g: originalBottomColor.g,
      b: originalBottomColor.b,
      duration: 1,
      ease: "power2.out"
    });

    gsap.to(this.skyMaterial.uniforms.sunColor.value, {
      r: originalSunColor.r,
      g: originalSunColor.g,
      b: originalSunColor.b,
      duration: 1,
      ease: "power2.out"
    });
  }

  // Placeholder for future updates
  update() {
    // Update logic will go here
  }
}

export default Sky;
