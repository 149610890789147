import * as THREE from "three";
import Model from "../../../../Render/Model";
import AudioManager from "../Audio/AudioManager";
import Firework from "../FX/Firework";
import EnemyPoint from "./EnemyPoint";

class JetPoint extends EnemyPoint {
    constructor(scene, model, time) {
        // Call parent constructor with default hitbox size and initial height of 35
        super(scene, model, new THREE.Vector3(3, 3, 3), 35);
        this.time = time;
        this.setupJet();
    }

    // Setup jet model properties
    setupJet() {
        this.model.scale.set(1, 1, 1); // Adjust scale as needed
        this.fullLoaded = true;
    }

    // Update the jet's path
    updatePath() {
        if (!this.pathPoints || this.pathPoints.length === 0) {
            console.error("Path points not set or empty");
            return;
        }

        // Ensure index is within bounds
        const index = Math.min(Math.floor(this.runTime * 10), 199);
        
        // Update current position
        this.currPoint.copy(this.pathPoints[index]);

        // Calculate look-at point (next point in path or current if at end)
        const nextIndex = Math.min(index + 1, this.pathPoints.length - 1);
        this.currPointLookAt.copy(this.pathPoints[nextIndex]);
    }

    // Update the jet's state
    update(index) {
        if (!this.pathPoints) {
            return;
        }

        if (this.runTime >= this.pathPoints.length / 10) {
            this.handleDestruction();
            return;
        }

        this.updatePath(index);
        this.updateLerpedPositions();
        this.updatePosition();

        this.runTime += this.time.getDeltaTime();
    }

    // Handle the destruction of the jet
    handleDestruction() {
        AudioManager.playSound('explode');
        this.destroyed = true;
        this.canDespawn = true;

        this.destroy();
    }

    // Set path points for the jet
    setPathPoints(pathPoints) {
        this.pathPoints = pathPoints;
    }

    // Destroy the jet and perform cleanup
    destroy() {
        super.destroy();
    }
}

export default JetPoint;
