import * as THREE from "three";
import EnemyPoint from "./EnemyPoint";

class HelicopterPoint extends EnemyPoint {
    constructor(scene, bodyModel, bladeModel) {
        // Call the parent class constructor with a larger hitbox and initial height of 20
        super(scene, bodyModel, new THREE.Vector3(5, 5, 10), 20);
        
        this.bladeModel = bladeModel;
        this.blade_1 = bladeModel.clone();
        this.blade_2 = bladeModel.clone();
        
        this.scene.add(this.blade_1);
        this.scene.add(this.blade_2);

        this.speed = 0.1; // Speed of helicopter movement
        this.bladeRotationSpeed = 0.5; // Speed of blade rotation

        // Randomize spawn position
        const zSpawn = 40 + Math.floor(Math.random() * 5);
        const xSpawn = 2 + Math.floor(Math.random() * 5);

        // Set initial positions
        this.model.position.set(xSpawn, 20, zSpawn);
        this.blade_1.position.set(xSpawn + 1, 20, zSpawn);
        this.blade_2.position.set(xSpawn, 20, zSpawn + 1);
        this.followPath = false;

        // Initialize currPoint to match the model's position
        this.currPoint.copy(this.model.position);
    }

    // Update the helicopter's position and blade rotation
    updatePosition() {
        // Move the helicopter directly
        this.model.position.z -= this.speed;
        
        // Update blade positions
        this.blade_1.position.copy(this.model.position);
        this.blade_2.position.copy(this.model.position);
        
        // Rotate blades
        this.blade_1.rotation.y += this.bladeRotationSpeed;
        this.blade_2.rotation.y -= this.bladeRotationSpeed;
        
        // Update hitbox position
        this.hitbox.position.copy(this.model.position);
    }

    // Update the helicopter's state
    update(index) {
        this.updatePosition();
        
        // Check if helicopter is out of bounds
        if (this.model.position.z < -45) {
            this.handleDestruction();
        }
    }

    // Handle the destruction of the helicopter
    handleDestruction() {
        this.destroy();
    }

    // Destroy the helicopter and remove its blades from the scene
    destroy() {
        this.canDespawn = true;

        super.destroy();
        this.scene.remove(this.blade_1);
        this.scene.remove(this.blade_2);
    }
}

export default HelicopterPoint;