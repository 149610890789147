import * as THREE from 'three';
import Model from '../../../../Render/Model';
import { AtmosphericScreenSpaceSkyShader } from '../../../../Shaders/AtmosphericScreenSpaceSkyShader';
const COLLISION_LAYER = 2;

class Environment {
  constructor(scene, loader) {
    this.scene = scene;
    this.loader = loader;
    this.loadModels();
  }

  // Load all models
  loadModels() {
    this.loadBackdrop();
  }

  // Load backdrop model
  loadBackdrop() {
    const paletteTexture = this.loader.getAsset("heroBackdropTexture");
    const noiseTexture = this.loader.getAsset("noiseTexture");

    this.bgMaterial = new THREE.ShaderMaterial({
      uniforms: THREE.UniformsUtils.clone(AtmosphericScreenSpaceSkyShader.uniforms),
      vertexShader: AtmosphericScreenSpaceSkyShader.vertexShader,
      fragmentShader: AtmosphericScreenSpaceSkyShader.fragmentShader,
    });

    // Set initial values for uniforms
    this.bgMaterial.uniforms.topColor.value = new THREE.Color(0xFDffff);
    this.bgMaterial.uniforms.bottomColor.value = new THREE.Color(0x4d81fa);
    this.bgMaterial.uniforms.sunColor.value = new THREE.Color(0xbbbbff);
    this.bgMaterial.uniforms.sunPosition.value = new THREE.Vector3(-146, -300, -30);
    this.bgMaterial.uniforms.sunIntensity.value = 0.4;
    this.bgMaterial.uniforms.sunSize.value = 0.2;
    this.bgMaterial.uniforms.skyTexture.value = paletteTexture;

    const modelName = 'heroBackdropModel';
    this.bgModel = new Model({
      modelLink: modelName,
      material: this.bgMaterial,
      position: new THREE.Vector3(0, 0, 0), // Hide initially by setting position off-screen
      rotation: new THREE.Vector3(0.0, 0.0, 0.0),
      scale: new THREE.Vector3(100, 100, 100),
      scene: this.scene,
      name: modelName
    });
    this.bgModel.all_model[0].position.set(-200,-35,0);
    this.bgModel.all_model[0].scale.set(40,40,40);
  }

  update(dt) {
    if (this.bgMaterial && this.bgMaterial.uniforms) {
      this.bgMaterial.uniforms.time.value += dt * 0.001; // Convert milliseconds to seconds
    }
  }
 
}

export default Environment;
