import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import { inputBaseClasses } from '@mui/material/InputBase';
import { inputLabelClasses } from '@mui/material/InputLabel';
import { alpha, styled, useTheme } from '@mui/material/styles';

import { bgGradient } from 'src/theme/css';

import Iconify from '../../components/iconify/iconify';

// ----------------------------------------------------------------------

const StyledInput = styled((props) => <TextField fullWidth {...props} />)(({ theme }) => ({
    [`& .${inputBaseClasses.input}`]: {
        color: theme.palette.common.white,
    },
    [`& .${inputLabelClasses.root}.${inputLabelClasses.shrink}`]: {
        color: theme.palette.grey[500],
        [`&.${inputLabelClasses.focused}`]: {
            color: theme.palette.grey[500],
        },
    },
}));

StyledInput.propTypes = {
    theme: PropTypes.shape({
        palette: PropTypes.shape({
            common: PropTypes.shape({
                white: PropTypes.string,
            }),
            grey: PropTypes.string,
        }),
    }),
};

// ----------------------------------------------------------------------

export default function MarketingLandingFreeSEO() {
    const theme = useTheme();

    return (
        <Box
            sx={{
                ...bgGradient({
                    color: alpha(theme.palette.grey[900], 0),
                }),
                height: '80vh',
                maxWidthidth: '100vw',
                overflow: 'hidden',
                py: { xs: 10, md: 15 },
            }}
        >
            <Container>
                <Box
                    sx={{
                        display: 'grid',
                        my: { xs: 8, md: 10 },
                        gap: { xs: 8, md: 5 },
                        backgroundColor: '#2f1c4d44',
                        borderRadius: '25px',
                        borderColor: '#613c99ff',
                        border: '#613c99ff',
                        borderWidth: 1,
                        textAlign: 'center',
                        borderStyle: 'double',
                        padding: '30px',
                        borderWidth: '2px 2px 2px 2px',
                        gridTemplateColumns: {
                            xs: 'repeat(1, 1fr)',
                            md: 'repeat(3, 1fr)',
                        },
                    }}
                >
                    <Box
                        sx={{

                            minHeight: '300px',
                            borderColor: '#ff5ec9',
                            gap: 4,

                            padding: '10px',
                            paddingTop: '50px',
                            gridTemplateColumns: {
                                xs: 'repeat(1, 1fr)',
                                sm: 'repeat(2, 1fr)',
                                md: 'repeat(4, 1fr)',
                            },
                        }}>
                        <Typography
                            variant="h1"
                            component="h2"
                            fontSize={'1.5rem'}
                            sx={{
                                color: '#ffffff',
                                mb: { xs: 3, md: 8 },
                                textAlign: { xs: 'center', md: 'left' },
                            }}
                        >
                            Get a free consultation
                        </Typography>

                        <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent={{ xs: 'center', md: 'flex-start' }}
                            sx={{ color: 'common.white', mb: 2 }}
                        >
                            <Iconify icon="carbon:email" width={24} sx={{ mr: 2 }} />

                            <Link color="inherit" href="mailto: theon@artcodestudio.co">
                                theon@artcodestudio.co
                            </Link>
                        </Stack>

                        <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent={{ xs: 'center', md: 'flex-start' }}
                            sx={{ color: 'common.white' }}
                        >
                          
                        </Stack>
                    </Box>

                    <Grid color={'#ffffff'} xs={12} md={5}>
                        <Stack alignItems={{ xs: 'center', md: 'flex-start' }}>


                        </Stack>
                    </Grid>
                </Box>
            </Container>
        </Box>
    );
}
