// AudioManager.ts
import { Howl } from 'howler';
const soundConfigs = [
  { name: 'music', src: ['/sounds/music_1.mp3'], volume: 0.125, loop: true },
  { name: 'shoot', src: ['/sounds/fire_1.wav'], volume: 0.03 },
  { name: 'shoot_pistol', src: ['/sounds/fire_pistol.wav'], volume: 0.035 },
  { name: 'beep', src: ['/sounds/beep.wav'], volume: 0.125 },
  { name: 'explode', src: ['/sounds/explode.wav'], volume: 0.03 },
  { name: 'reload_out', src: ['/sounds/rifle_out.wav'], volume: 0.03 },
  { name: 'reload_in', src: ['/sounds/rifle_in.wav'], volume: 0.01 },
  { name: 'prepare_1', src: ['/sounds/rifle_prepare_1.wav'], volume: 0.03 },
  { name: 'prepare_2', src: ['/sounds/rifle_prepare_2.wav'], volume: 0.03 },
  { name: 'prepare_3', src: ['/sounds/rifle_prepare_3.wav'], volume: 0.03 },
];

class AudioManager {
  private static instance: AudioManager;
  private sounds: { [key: string]: Howl } = {};

  private constructor() {
    // Prevent instantiation
  }

  
  public static getInstance(): AudioManager {
    if (!AudioManager.instance) {
      AudioManager.instance = new AudioManager();
    }
    return AudioManager.instance;
  }

  // Play a random prepare sound
  public playRandomPrepareSound() {
    const randomIndex = Math.floor(Math.random() * 3) + 1; // Generates a number between 1 and 3
    const soundName = `prepare_${randomIndex}`;
    this.playSound(soundName);
  }
  public mute() {
    Object.values(this.sounds).forEach(sound => {
      sound.mute(true);
    });
  }

  public unmute() {
    Object.values(this.sounds).forEach(sound => {
      sound.mute(false);
    });
  }

  // Load all sounds
  public loadSounds() {
    soundConfigs.forEach(config => {
      this.sounds[config.name] = new Howl({
        src: config.src,
        volume: config.volume,
        loop: config.loop || false,
      });
    });

  }

  // Play a sound by name
  public playSound(name: string) {
    const sound = this.sounds[name];
    if (sound) {
      sound.play();
    } else {
      console.error(`Sound ${name} not found!`);
    }
  }
}

export default AudioManager.getInstance();