import * as THREE from "three";

class Firework {
  constructor(scene, pos) {
    this.scene = scene;
    this.done = false;
    this.dest = [];
    this.colors = [];
    this.geometry = null;
    this.points = null;
    this.initialPosition = pos;
    this.material = new THREE.PointsMaterial({
      size: 0.4,
      color: 0xffffff,
      opacity: 1,
      vertexColors: true,
      transparent: true,
      depthTest: false,
    });
    this.launch();
  }

  // Reset the firework state
  reset() {
    this.scene.remove(this.points);
    if (this.explosionLight) {
      this.scene.remove(this.explosionLight);
    }
    this.dest = [];
    this.colors = [];
    this.geometry = null;
    this.points = null;
    this.explosionLight = null; // Reset the point light
  }

  // Launch the firework
  launch() {
    const { x, y, z } = this.initialPosition;
    const from = new THREE.Vector3(x, 2, z);
    const to = new THREE.Vector3(
      x + THREE.MathUtils.randFloat(-3.9, 3.9) * 10,
      y,
      z + THREE.MathUtils.randFloat(-3.9, 3.9) * 10
    );

    const color = new THREE.Color(0xffffff);
    this.colors.push(color);

    this.geometry = new THREE.BufferGeometry();
    this.points = new THREE.Points(this.geometry, this.material);

    this.geometry.setAttribute(
      "position",
      new THREE.Float32BufferAttribute([from.x, from.y, from.z], 3)
    );
    this.geometry.setAttribute(
      "color",
      new THREE.Float32BufferAttribute(color.toArray(), 3)
    );

    this.dest.push(to);
    this.scene.add(this.points);
  }

  // Explode the firework
  explode(vector) {
    this.scene.remove(this.points);
    this.dest = [];
    this.colors = [];
    this.geometry = new THREE.BufferGeometry();
    this.points = new THREE.Points(this.geometry, this.material);

    const positions = [];
    const colors = [];

    for (let i = 0; i < 80; i++) {
      const color = new THREE.Color(0xffffff);
      this.colors.push(color);

      const from = vector;
      const to = new THREE.Vector3(
        THREE.MathUtils.randInt(vector.x - 10, vector.x + 10),
        THREE.MathUtils.randInt(vector.y - 10, vector.y + 10),
        THREE.MathUtils.randInt(vector.z - 10, vector.z + 10)
      );

      positions.push(from.x, from.y, from.z);
      colors.push(color.r, color.g, color.b);
      this.dest.push(to);
    }

    this.geometry.setAttribute(
      "position",
      new THREE.Float32BufferAttribute(positions, 3)
    );
    this.geometry.setAttribute(
      "color",
      new THREE.Float32BufferAttribute(colors, 3)
    );
    this.scene.add(this.points);
  }

  // Update the firework state
  update() {
    if (this.points && this.geometry) {
      const positions = this.geometry.attributes.position.array;
      const total = positions.length / 3;

      for (let i = 0; i < total; i++) {
        positions[i * 3] += (this.dest[i].x - positions[i * 3]) / 50;
        positions[i * 3 + 1] += (this.dest[i].y - positions[i * 3 + 1]) / 50;
        positions[i * 3 + 2] += (this.dest[i].z - positions[i * 3 + 2]) / 50;
      }
      this.geometry.attributes.position.needsUpdate = true;

      if (total === 1) {
        if (Math.ceil(positions[1]) > this.dest[0].y - 15) {
          this.explode(new THREE.Vector3(positions[0], positions[1], positions[2]));
          return;
        }
      }

      if (total > 1) {
        this.material.opacity -= 0.015;
        this.material.needsUpdate = true;
      }

      if (this.material.opacity <= 0) {
        this.reset();
        this.done = true;
        return;
      }
    }
  }
}

export default Firework;