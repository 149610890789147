import '../Home.css';
import '../../../src/Global.css';
import { Typography } from '@mui/material';
import Link from '@mui/material/Link';
import { Button } from '@mui/material';
export const panel_Part2 = () => {
    return (
        <>
            <div style={{ padding: 20, overflowY: 'scroll', overflowX: 'hidden' }}>
                <Typography fontFamily="Akira"
                    paddingTop="100px"
                    align="center"
                    color={'#ffffffFF'}
                    variant="h3"
                    lineHeight={'1.5rem'}
                    fontSize="2.5rem"
                    fontWeight="100"
                    fontStyle={'normal'}>

                </Typography>
            </div>
        </>
    )
}
