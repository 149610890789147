import * as THREE from "three";

const FlagMotionShader = {
  uniforms: {
    time: { value: 0 },
    imageTex: { value: null }, 
    waveHeight: { value: 0.1 },
    waveFrequency: { value: 2.0 },
    waveSpeed: { value: 1.0 },
    polePosition: { value: new THREE.Vector3(0, 0, 0) },
    lightDirection: { value: new THREE.Vector3(1, -0.5, 0).normalize() },
  },
  vertexShader: `
    uniform float time;
    uniform float waveHeight;
    uniform float waveFrequency;
    uniform float waveSpeed;
    uniform vec3 polePosition;
    varying vec2 vUv;
    varying vec3 vNormal;

    void main() {
      vUv = uv;
      vec3 pos = position;
      
      float distanceFromPole = distance(pos, polePosition);
      float maxDistance = distance(vec3(1.0, 0.0, 0.0), polePosition);
      float waveIntensity = distanceFromPole / maxDistance;
      
      float angle = -(pos.x + pos.z + pos.y/2.0) * waveFrequency + time * waveSpeed;
      pos.x += sin(angle) * waveHeight * waveIntensity;
      pos.x-= distanceFromPole/3.0;
      // Calculate normal
      vec3 tangent = normalize(vec3(1.0, cos(angle) * waveHeight * waveFrequency * waveIntensity, 0.0));
      vec3 bitangent = normalize(vec3(0.0, 0.0, 1.0));
      vNormal = normalize(cross(tangent, bitangent));

      gl_Position = projectionMatrix * modelViewMatrix * vec4(pos, 1.0);
    }
  `,
  fragmentShader: `
    uniform sampler2D imageTex;
    uniform vec3 lightDirection;
    varying vec2 vUv;
    varying vec3 vNormal;

    void main() {
      vec4 texColor = texture2D(imageTex, vUv);
      
      // Calculate lighting
      float lightIntensity = max(dot(vNormal, lightDirection), 0.0);
      vec3 litColor = texColor.rgb * (1.0 + 0.35 * lightIntensity);

      gl_FragColor = vec4(litColor, texColor.a);
    }
  `,
};

export default FlagMotionShader;