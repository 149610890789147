import React from 'react';
import { InlineWidget } from 'react-calendly';

const CalendlyWidget: React.FC = () => {
  return (
    <div style={{ width: '100%', height: '100%' }}>
      <InlineWidget url="https://calendly.com/theon-artcodestudio" styles={{ height: '100%' }} />
    </div>
  );
};

export default CalendlyWidget;