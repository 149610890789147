import * as THREE from 'three';
import AudioManager from '../Audio/AudioManager';
import gsap from 'gsap';
const COLLISION_LAYER = 1;

class Weapon {
  constructor(scene,upgradeManager) {
    this.scene = scene;
    this.upgradeManager = upgradeManager;
    this.ammoCount = 8 + this.upgradeManager.getAmmoBonus();
    this.muzzleFlashes = [];
    this.recoilStrength = new THREE.Vector3(0.1, 0.05, 0.1);
    this.recoilRecoverySpeed = 0.5;
    this.recoilOffset = new THREE.Vector3();
    this.recoilVelocity = new THREE.Vector3();
    this.reloadTime = 1.0;
    this.isReloading = false;
    this.onReloadStart = null;
    this.onReloadComplete = null;
    this.maxAmmo = 8 + this.upgradeManager.getAmmoBonus();
  }

  setOnReloadStart(callback) {
    this.onReloadStart = callback;
  }

  setOnReloadComplete(callback) {
    this.onReloadComplete = callback;
  }

  reload() {
    if (!this.isReloading) {
      this.isReloading = true;
      if (this.onReloadStart) this.onReloadStart();
      
      setTimeout(() => {
        this.ammoCount = 8 + this.upgradeManager.getAmmoBonus();
        this.isReloading = false;
        if (this.onReloadComplete) this.onReloadComplete();
      }, this.reloadTime * 1000);
    }
  }

  manualReload() {
    if (!this.isReloading && this.ammoCount < this.maxAmmo) {
      this.startReload(true);
    }
  }

  startReload(isManual = false) {
    if (this.onReloadStart) this.onReloadStart();
    this.reloadTime = this.upgradeManager.getReloadDuration() / 1000;
    this.isReloading = true;
    AudioManager.playSound('reload_out');

    // Animate existing bullets
    this.scene.bulletCounter.animateExistingBulletsForReload();

    setTimeout(() => {
      AudioManager.playRandomPrepareSound();
    }, this.upgradeManager.reloadDuration / 2);

    setTimeout(() => {
      const bonusAmmo = isManual ? 1 : 0;
      this.ammoCount = this.maxAmmo + bonusAmmo;
      this.isReloading = false;
      AudioManager.playSound('reload_in');
      this.scene.bulletCounter.isReloading=false;
      this.scene.bulletCounter.createBulletCounter();
      if (this.onReloadComplete) {

        this.onReloadComplete();
        this.setOnReloadComplete();
      }
    }, this.upgradeManager.reloadDuration);
  }

  weaponReload() {
    this.isReloading = false;
    AudioManager.playSound('reload_in');
    this.scene.bulletCounter.createBulletCounter(); // Refresh the bullet counter
  }

  applyRecoil() {
    this.recoilVelocity.add(this.recoilStrength);
  }

  updateRecoil(deltaTime) {
    this.recoilOffset.add(this.recoilVelocity);
    this.recoilVelocity.multiplyScalar(1 - this.recoilRecoverySpeed * deltaTime);
    this.recoilOffset.multiplyScalar(1 - this.recoilRecoverySpeed * deltaTime);
  }

  getRecoilOffset() {
    return this.recoilOffset;
  }

  handleShooting(event) {
    if(this.scene.health.getCurrentHealth()<=0 || this.scene.waveManager.spawnCounters.target < 1)
      return;
    this.recoilStrength = new THREE.Vector3(Math.random() *0.5 -0.25, 0.06,Math.random() *0.5 -0.24); // Strength of recoil on x, y, z axes

    const shotBullet = this.scene.bulletCounter.bullets.pop(); // Get the last bullet
    this.scene.bulletCounter.animateBullet(shotBullet); // Animate the bullet falling

    // Apply recoil force
    const verticalRecoil = new THREE.Vector3(0, this.recoilStrength.y, 0);
    const horizontalRecoil = new THREE.Vector3(this.recoilStrength.x * (Math.random() - 0.5), 0, 0); // Random horizontal kick
    const depthRecoil = new THREE.Vector3(0, 0, -this.recoilStrength.z);

    this.recoilVelocity.add(verticalRecoil).add(horizontalRecoil).add(depthRecoil);
    // play shooting sound
    AudioManager.playSound('shoot');

    const raycaster = new THREE.Raycaster();
    const mouse = new THREE.Vector2();
    mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
    mouse.y = - (event.clientY / window.innerHeight) * 2 + 1;

    raycaster.setFromCamera(mouse, this.scene.newCamera.threeCamera);
    //raycaster.layers.set(COLLISION_LAYER); // Detect only the hitboxes
    raycaster.layers.enable(2); // Enable default layer
    raycaster.layers.enable(COLLISION_LAYER); // Enable collision layer
    // Get the position to place the muzzle flash
    const flashPosition = raycaster.ray.origin.clone().add(raycaster.ray.direction.clone().multiplyScalar(10));

    // Create a black plane for the muzzle flash
    const geometry = new THREE.PlaneGeometry(5, 5);
    const material = new THREE.MeshBasicMaterial({
      map: this.scene.loader.getAsset('muzzleTexture'), // Simple texture map for the muzzle flash
      transparent: true, // Allow transparency
      opacity: 0.15, // Start fully opaque
      blending: THREE.AdditiveBlending, // Enable additive blending for a glowing effect
      depthWrite: false  // Prevent writing to the depth buffer for proper blending
    });
    const muzzleFlash = new THREE.Mesh(geometry, material);
    // Set the initial scale very small
    const randomScale = 0.05 + Math.random() * (0.15 - 0.05);
    muzzleFlash.scale.set(randomScale, randomScale, randomScale);
    // Set the muzzle flash position and make it look at the camera
    muzzleFlash.position.copy(flashPosition);
    // Rotate muzzle flash randomly on the z axis
    muzzleFlash.lookAt(this.scene.newCamera.threeCamera.position);
    muzzleFlash.rotation.z = Math.random() * Math.PI * 2;

    // Add to the scene
    this.scene.scene.add(muzzleFlash);

    // Animate the fade-out
    gsap.timeline()
    .to(muzzleFlash.scale, {
        x: 1, 
        y: 1, 
        z: 1,
        duration: 0.1, // Fast scaling up
        ease: "power2.out"
    })
    .to(muzzleFlash.material, {
        opacity: 0, // Fade out
        duration: 0.4,
        ease: "power2.out",
        onComplete: () => {
            this.scene.scene.remove(muzzleFlash); // Remove the mesh after fading out
        }
    }, "<"); // Start fading as soon as the scaling starts

    const muzzleFlash2 = new THREE.Mesh(geometry, material);
    // Set the initial scale very small
    muzzleFlash2.scale.set(5.1, 5.1, 5.1);
    // Set the muzzle flash position and make it look at the camera
    muzzleFlash2.position.copy(flashPosition);
    muzzleFlash2.lookAt(this.scene.newCamera.threeCamera.position);
    muzzleFlash2.rotation.z = Math.random() * Math.PI * 2;

    // Add to the scene
    this.scene.scene.add(muzzleFlash2);

    // Animate the fade-out
    gsap.timeline()
    .to(muzzleFlash2.scale, {
        x: 8, 
        y: 8, 
        z: 8,
        duration: 0.1, // Fast scaling up
        ease: "power2.out"
    })
    .to(muzzleFlash2.material, {
        opacity: 0, // Fade out
        duration: 0.4,
        ease: "power2.out",
        onComplete: () => {
            this.scene.scene.remove(muzzleFlash2); // Remove the mesh after fading out
        }
    }, "<"); // Start fading as soon as the scaling starts
    this.ammoCount--;
    this.applyRecoil();
    if(this.ammoCount<1)
    {
      this.startReload();
    }
    const intersects = raycaster.intersectObjects(this.scene.scene.children, true);
    if (intersects.length > 0) {
      const intersectedObject = intersects[0].object;
  
      // Check if the intersected object is the backdrop
      if (intersectedObject.name === 'backdropModel') {
        this.damageBackdrop(intersectedObject, intersects[0].point);
      }

      // Handle damage effect in enemy manager
      if (this.scene.enemyManager) {
        this.scene.enemyManager.handleDamageEffect(intersectedObject);
      } else {
        console.warn('Enemy manager not found in the scene.');
      }

    }
  }

  damageBackdrop(backdropMesh, hitPoint) {
    const geometry = backdropMesh.geometry;
    const positionAttribute = geometry.getAttribute('position');
    const colorAttribute = geometry.getAttribute('color');

    if (!colorAttribute) {
      const colors = new Float32Array(positionAttribute.count * 3);
      for (let i = 0; i < colors.length; i++) {
        colors[i] = 1.0; // Initialize all colors to white
      }
      geometry.setAttribute('color', new THREE.BufferAttribute(colors, 3));
    }

    const damageRadius = 6; // Adjust this value to change the size of the damage area
    const damageIntensity = 0.75; // Adjust this value to change how much damage is applied per shot

    for (let i = 0; i < positionAttribute.count; i++) {
      const vertex = new THREE.Vector3();
      vertex.fromBufferAttribute(positionAttribute, i);
      vertex.applyMatrix4(backdropMesh.matrixWorld);

      const distance = hitPoint.distanceTo(vertex);
      if (distance < damageRadius) {
        const colorIndex = i * 3;
        const currentColor = colorAttribute.array[colorIndex];
        const newColor = Math.max(currentColor - damageIntensity * (1 - distance / damageRadius), 0);
        colorAttribute.array[colorIndex] = newColor;
        colorAttribute.array[colorIndex + 1] = newColor;
        colorAttribute.array[colorIndex + 2] = newColor;
      }
    }

    colorAttribute.needsUpdate = true;
  }

  update() {
    this.recoilOffset.lerp(this.recoilVelocity, this.recoilRecoverySpeed);

    // Gradually reduce the recoil velocity for smooth recoil recovery
    this.recoilVelocity.lerp(new THREE.Vector3(0, 0, 0), this.recoilRecoverySpeed);
    this.scene.newCamera.threeCamera.position.add(this.recoilOffset);
    //update delta time
    const now = performance.now();
    this.dt = (now - this.lastUpdate); // Delta time in seconds
    this.lastUpdate = now;
    this.totalTime = (now - this.startTime); // Total time in seconds
    this.muzzleFlashes = this.muzzleFlashes.filter(flash => {
      flash.material.uniforms.time.value += this.dt;
      if (flash.material.uniforms.time.value >= flash.material.uniforms.duration.value) {
        this.scene.scene.remove(flash);
        return false;
      }
      return true;
    });
  }
}

export default Weapon;