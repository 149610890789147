import * as THREE from "three";
import { MathUtils, Vector2, Vector3, Vector4 } from "three";
import React, { Component } from 'react';

//renderer related
import Model from "../../../Render/Model";
import Material from "../../../Render/Material";
import LoadingManager from "../../../Render/LoadingManager";
import Scene from "../Base/Scene";
import { PropShader } from "../../../Shaders/PropShader";
import PlayerAnimationShader from "../../../Shaders/PlayerAnimationShader";
import EnemyAnimationShader from "../../../Shaders/EnemyAnimationShader";

import BloodEmitter from './BloodEmitter';
import OrbitalCameraGame from '../StormTheRoof/Camera/OrbitalCameraGame';
import gsap from "gsap";
import AudioManager from '../StormTheRoof/Audio/AudioManager';
import { AtmosphericSkyShader } from '../../../Shaders/AtmosphericSkyShader';
import VertexColorShader from "../../../Shaders/VertexColorShader";
/******************************************************************************/
/*!
\brief  main 3d scene setup
*/
/******************************************************************************/
let COLLISION_LAYER = 1;

class BadBlood extends Scene {
  //constructor
  constructor(_options) {
    super(_options);
    this.npcArray = [];
    this.allLoaded= false;
    this.playerSpeed = 0.2;
    this.moveDirection= new Vector3(0, 0, 0);
    this.playerDirection = new Vector3(0, 0, 0);
    this.player = null;
    this.npcArray = [];

    // Define a list of enemy types with their respective texture atlases
    this.enemyTypes = [
      {
        name: "human",
        idleTexture:'humanIdleTexture',
        runTexture: 'humanRunTexture',
        deathTexture: 'humanDeathTexture',
        idleAtlasGrid: new THREE.Vector2(8, 4), // Atlas grid size for idle texture
        runAtlasGrid: new THREE.Vector2(8, 4),  // Atlas grid size for run texture
        deathAtlasGrid: new THREE.Vector2(8, 4),  // Atlas grid size for run texture

      },
      {
        name: "special",
        idleTexture:'specialIdleTexture',
        runTexture: 'specialRunTexture',
        deathTexture:'humanDeathTexture',

        idleAtlasGrid: new THREE.Vector2(8, 4), // Atlas grid size for idle texture
        runAtlasGrid: new THREE.Vector2(8, 4),  // Atlas grid size for run texture
        deathAtlasGrid: new THREE.Vector2(8, 4),  // Atlas grid size for run texture

      },
    ];
    this.spawnInterval = 300; // 1 second interval
    this.lastSpawnTime = 0;

    this.isMoving = false;
    this.frameIndex = 0;
    this.frameTime = 0;
    this.frameDuration = 100; // milliseconds per frame



    // Set up the camera offset for isometric view
    this.cameraOffset = new Vector3(0, 5, 10); // Adjust for desired isometric view

    // Add event listeners for player movement
    this.keys = {
      ArrowUp: false,
      ArrowDown: false,
      ArrowLeft: false,
      ArrowRight: false,
    };

    document.addEventListener('keydown', this.onKeyDown.bind(this));
    document.addEventListener('keyup', this.onKeyUp.bind(this));
    const skyMaterial = new THREE.ShaderMaterial({
      vertexShader: AtmosphericSkyShader.vertexShader,
      fragmentShader: AtmosphericSkyShader.fragmentShader,
      uniforms: THREE.UniformsUtils.clone(AtmosphericSkyShader.uniforms),
      side: THREE.BackSide // Ensure the sky is rendered on the inside of the sphere
  });

  this.bloodEmitter = new BloodEmitter(this.scene);

  
  this.skyMaterial = skyMaterial; // Store it for updating

    // Define colors for day and night
    this.dayAmbientColor = new THREE.Color(0xffffff);
    this.nightAmbientColor = new THREE.Color(0x545863);
 
    this.clickTimeout = 0;
    this.scrollDelta = 25;
    this.modelLoad = _options.modelcallback;

    this.hoverAnimationsEnabled = false;

    this.hide = false;
    this.current = 0;

    //mouse data
    this.mouse = new Vector2(0, 0);
    this.mouseNormalized = new Vector2(0, 0);
    this.lerpedMouse = new Vector2(0, 0);
    this.lerpedMouseRaw = new Vector2(0, 0);
    this.boot = false;
    this.fullLoaded = false;
    //time
    this.lastUpdate = performance.now()
    this.totalTime = 0.0;
    this.startTime = performance.now()

    this.frameIndexCap = 61;
    //camera vectors
       // Camera shake variables
       this.shakeDuration = 0;
       this.shakeIntensity = 0;
       this.shakeElapsed = 0;
    this.camDirection = new Vector3(0, 0, 0);
    this.camUp = new Vector3(0, 0, 0);
    this.camSide = new Vector3(0, 0, 0);
    this.camPos = new Vector3(0, 0, 0);
    this.camLookAt = new Vector3(0, 0, 0);
    this.currDronePoint = new Vector3(0, 0, 0);

    this.sendPoint = new Vector3(0, 0, 0);
    this.tempPoint = new Vector3(0, 0, 0);

    this.sendPointLookAt = new Vector3(0, 0, 0);
    this.tempPointLookAt = new Vector3(0, 0, 0);
    this.canMoveCamera = false;
 // Initial progress variable for color transition
 this.colorTransitionProgress = 0;
    let d = 50;
    let r = 1;
    let mapSize = 2048;
    this.ambient = new THREE.AmbientLight(0xffffff, 0.5, 0);

    this.dirLight = new THREE.DirectionalLight(0xffffff, 3.95);
    this.dirLight.castShadow = true;
    this.dirLight.shadow.radius = r;
    this.dirLight.shadow.mapSize.width = mapSize;
    this.dirLight.shadow.mapSize.height = mapSize;
    this.dirLight.shadow.camera.top = this.dirLight.shadow.camera.right = d;
    this.dirLight.shadow.camera.bottom = this.dirLight.shadow.camera.left = -d;
    this.dirLight.shadow.camera.near = 0.1;
    this.dirLight.shadow.camera.far = 100;
    this.dirLight.position.set(40, 3, 20);
   

    this.scene.add(this.ambient);
    this.scene.add(this.dirLight);

    this.dynamicMaterial = new THREE.MeshPhongMaterial({ color: 0x888888 });

    this.storeTop = 0.0;

    this.platformToggle = false;
    this.platformsInitialized = false;

    //add event listeners
    this.loadListener();
    this.startRender();

    this.requiredAssets = [

      //jet asset
      { name: 'jetModel', source: '/assets/jet.glb' },

      //platform asset
      { name: 'platformModel', source: '/assets/platform.glb' },


      // player textures
      { name: 'nightmareIdleTexture', source: '/assets/nightmare-idle.png', type: 'texture' },
      { name: 'nightmareRunTexture', source: '/assets/nightmare-run.png', type: 'texture' },
      { name: 'nightmareAttackTexture', source: '/assets/nightmare-attack.png', type: 'texture' },

      //enemy textures

      { name: 'humanIdleTexture', source: '/assets/human-idle.png', type: 'texture' },
      { name: 'humanRunTexture', source: '/assets/human-run.png', type: 'texture' },
      { name: 'humanDeathTexture', source: '/assets/human-death.png', type: 'texture' },

      // environment
      { name: 'stormTheRoofModel', source: '/assets/storm_the_roof_1.glb' },

      { name: 'floorModel', source: '/assets/floor.glb' },

      { name: 'whiteTexture', source: '/assets/white.jpg', type: 'texture' },

      { name: 'bloodNormalTexture', source: '/assets/noise_n.png', type: 'texture' },
      { name: 'bloodNormalDetailTexture', source: '/assets/detail_n.png', type: 'texture' },
      { name: 'bloodNoiseTexture', source: '/assets/noise.png', type: 'texture' },


    ];
    this.loadedAssets = 0;
    this.totalAssets = this.requiredAssets.length;

    this.loadingManager = new LoadingManager();
    this.loader = this.loadingManager.loader;
    this.initializeLoading();
  }

  createPlayerMesh() {
    const uniforms = {
        idleTexture: { value: this.loader.getAsset('nightmareIdleTexture') },
        movementTexture: { value: this.loader.getAsset('nightmareRunTexture') },
        attackTexture: { value: this.loader.getAsset('nightmareAttackTexture') },

        frameIndex: { value: this.frameIndex },
        atlasGrid: { value: new THREE.Vector2(16, 2) }, // 10x10 grid by default
        isMoving: { value: this.isMoving },
        isAttacking: { value: false }, // New uniform for attacking state

        colorMultiplier:{ value: new THREE.Vector4(1, 1,1,1) },
    };

    // Player material and mesh
    const playerMaterial = new THREE.ShaderMaterial({
        uniforms: uniforms,
        vertexShader: PlayerAnimationShader.vertexShader,
        fragmentShader: PlayerAnimationShader.fragmentShader,
        transparent: true,
    });

    const playerGeometry = new THREE.PlaneGeometry(1, 1); // Plane for player mesh
    const playerMesh = new THREE.Mesh(playerGeometry, playerMaterial);
    playerMesh.position.set(0, 1.5, 0); // Adjust position as needed
    playerMesh.scale.set(2,-2,2);
    // Shadow geometry and material
    const shadowGeometry = new THREE.PlaneGeometry(1.2, 1.2); // Slightly larger than the player
    const shadowMaterial =playerMaterial.clone();

    const shadowMesh = new THREE.Mesh(shadowGeometry, shadowMaterial);
    shadowMesh.position.set(0, 0.01, 0); // Slightly above the ground to avoid z-fighting
    shadowMesh.rotation.x = -Math.PI / 2; // Lie flat on the ground

    this.player = playerMesh;
    this.playerShadow = shadowMesh;
    this.scene.add(this.playerShadow);
}
createNpcMesh(type, position) {
  const npcType = this.enemyTypes.find((t) => t.name === type);

  if (!npcType) {
    console.warn(`NPC type '${type}' not found.`);
    return null;
  }

  const uniforms = {
    idleTexture: { value: this.loader.getAsset(npcType.idleTexture) },
    movementTexture: { value: this.loader.getAsset(npcType.runTexture) },
    deathTexture: { value: this.loader.getAsset(npcType.deathTexture) },

    frameIndex: { value: 0 },
    atlasGrid: { value: npcType.runAtlasGrid },
    isMoving: { value: true },
    isDead: { value: false },

    colorMultiplier: { value: new THREE.Vector4(1, 1, 1, 1) },
  };

  const npcMaterial = new THREE.ShaderMaterial({
    uniforms: uniforms,
    vertexShader: EnemyAnimationShader.vertexShader,
    fragmentShader: EnemyAnimationShader.fragmentShader,
    transparent: true,
  });

  const npcGeometry = new THREE.PlaneGeometry(1, 1);
  const npcMesh = new THREE.Mesh(npcGeometry, npcMaterial);

  npcMesh.position.copy(position);
  npcMesh.scale.set(1, -1, 1);
  npcMesh.dead = false;
  npcMesh.targetPoint = this.getRandomGridPoint();
  npcMesh.speed = 0.001; // Adjust movement speed
  npcMesh.isIdle = false;

  npcMesh.frameIndex = 0;          // Initialize frame index
  npcMesh.frameTime = 0;           // Initialize frame time
  npcMesh.frameIndexCap = 31;  // Set initial frameIndexCap
  npcMesh.idleAtlasGrid = npcType.idleAtlasGrid;
  npcMesh.runAtlasGrid = npcType.runAtlasGrid;
  npcMesh.deathAtlasGrid= npcType.deathAtlasGrid;

  this.scene.add(npcMesh);
  this.npcArray.push(npcMesh);

  return npcMesh;
}

getRandomGridPoint() {
  const x = Math.floor(Math.random() * 11) - 5; // Grid range from -5 to 5
  const z = Math.floor(Math.random() * 11) - 5;
  return new THREE.Vector3(x, 0.5, z);
}


spawnEnemies() {
  const currentTime = performance.now();
  if (currentTime - this.lastSpawnTime >= this.spawnInterval) {
    const randomX = Math.random() * 4 - 2;
    const randomZ = Math.random() * 4 - 2;
    const position = new THREE.Vector3(randomX, 0.5, randomZ);

    const enemyType = Math.random() > 0.7 ? "special" : "human"; // Randomly choose enemy type

    this.createNpcMesh(enemyType, position);
console.log(enemyType);
    this.lastSpawnTime = currentTime;
  }
}
getRandomGridPoint() {
  const x = Math.floor(Math.random() * 11) - 5; // Grid range from -5 to 5
  const z = Math.floor(Math.random() * 11) - 5;
  return new THREE.Vector3(x, 0.5, z);
}

updateNpcMovement(deltaTime) {
  this.npcArray.forEach((npc) => {
    if (!npc.isIdle) {
      const direction = npc.targetPoint.clone().sub(npc.position).normalize();
      const distance = npc.position.distanceTo(npc.targetPoint);

      const moveStep = npc.speed * deltaTime;

      if (distance > 0.1 &&!npc.dead ) {  // If NPC is not yet close enough to the target
        npc.position.addScaledVector(direction, Math.min(moveStep, distance));  // Move only the required distance to avoid overshooting
      }
      if(direction.x<0)
      {
        npc.scale.set(1,-1,1);
      }else{
        npc.scale.set(-1,-1,1);

      }

      if (distance <= 0.1) {  // If the NPC has effectively reached the target
        npc.isIdle = true;
        npc.material.uniforms.isMoving.value = false; // Switch to idle pose
        console.log('idle mode');

        setTimeout(() => {
          npc.isIdle = false;
          npc.targetPoint = this.getRandomGridPoint(); // Choose a new target point
          npc.material.uniforms.isMoving.value = true; // Switch back to run pose
        }, Math.random() * 3000 + 2000); // Random idle time between 2-5 seconds
      }
    }

    this.updateNpcFrame(this.totalTime, npc); // Update frame index based on movement
  });
}



  createFloor() {
     // Set up uniforms for the shader
     const floorMaterial = new THREE.ShaderMaterial({
      vertexShader: VertexColorShader.vertexShader,
      fragmentShader: VertexColorShader. fragmentShader,
      uniforms: {
          lightDir: { value: this.dirLight.position.clone().normalize() },
          viewPos: { value: this.newCamera.threeCamera.position.clone() },
          theTexture: { value: this.loader.getAsset('whiteTexture') },
          theTextureNormal: { value:  this.loader.getAsset('bloodNormalTexture') },
          theTextureNoise: { value:  this.loader.getAsset('bloodNoiseTexture') },
          theTextureNormalDetail: { value:  this.loader.getAsset('bloodNormalDetailTexture') },
          effect_x: { value: 0.0 },
          effect_y: { value: 0.0 },
          tiling_x: { value: 1.0 },
          tiling_y: { value: 1.0 },
          tiling_x_detail: { value: 1.0 },
          tiling_y_detail: { value: 1.0 },
          flipHorizontal: { value: false },
          effectBurn: { value: 0.0 },
      },
      vertexColors: true,
      
  });
    const modelName = 'floorModel';
    this.floorModel = new Model({
      modelLink: modelName,
      material: floorMaterial,
      position: new THREE.Vector3(0, 0, 0), // Hide initially by setting position off-screen
      rotation: new THREE.Vector3(0.0, 0.0, 0.0),
      scale: new THREE.Vector3(0, 0, 0),
      scene: this.scene,
      name: 'floorModel'
    });

    // Initialize color attribute to white (no blood)
    console.log(this.floorModel.all_model[0]);
    const colors = new Float32Array(this.floorModel.all_model[0].geometry.attributes.position.count * 3);
    for (let i = 0; i < colors.length; i += 3) {
        colors[i] = 0;   // Red channel
        colors[i + 1] = 0; // Green channel
        colors[i + 2] = 0; // Blue channel
    }

    this.floorModel.all_model[0].geometry.setAttribute('color', new THREE.BufferAttribute(colors, 3));

   
    return this.floorModel.all_model[0];
}
paintFloor(paintPosition,intensity,type) {
  const positions = this.floor.geometry.attributes.position.array;
  const colors = this.floor.geometry.attributes.color.array;

  // Transform each vertex to world space before comparison
  const worldMatrix = this.floor.matrixWorld;

  for (let i = 0; i < positions.length; i += 3) {
      // Local vertex position
      const localVertex = new THREE.Vector3(positions[i], positions[i + 1], positions[i + 2]);

      // Convert local vertex position to world space
      const worldVertex = localVertex.applyMatrix4(worldMatrix);

      // Calculate distance to the player
      const distance = worldVertex.distanceTo(paintPosition);

      if (distance < this.paintRadius) {
          // Apply red color gradually based on proximity to the player
          const influence = (this.paintRadius - distance) / this.paintRadius;
       console.log(type);
          switch(type)
          {
            case 0:
              colors[i] = Math.min(colors[i] + influence * intensity, 1); 
              break;
            case 1:
              colors[i + 1] = Math.min(colors[i+1] + influence * intensity, 1);
              break;
            case 2:
              colors[i + 2] =  Math.min(colors[i+2] + influence * intensity, 1);
              break;
          }
      }
  }

  this.floor.geometry.attributes.color.needsUpdate = true;
}


  updatePlayerMovement(deltaTime) {
    this.floor.material.uniforms.lightDir.value.copy(this.dirLight.position).normalize();
    this.floor.material.uniforms.viewPos.value.copy(this.newCamera.threeCamera.position);
    this.moveDirection.set(0, 0, 0);

    if (this.keys['ArrowUp']) this.moveDirection.z -= 1;
    if (this.keys['ArrowDown']) this.moveDirection.z += 1;
    if (this.keys['ArrowLeft']) this.moveDirection.x -= 1;
    if (this.keys['ArrowRight']) this.moveDirection.x += 1;

    this.moveDirection.normalize();
    this.isMoving = this.moveDirection.length() > 0;

    if (this.isMoving && !this.isAttacking) {
      this.player.position.addScaledVector(this.moveDirection, this.dt*0.002); // Move player
      this.playerShadow.position.addScaledVector(this.moveDirection, this.dt*0.002); // Move player

      if(this.moveDirection.x>0)
        {
          this.player.scale.set(2,-2,2);
          this.playerShadow.scale.set(2,2,2);

        }else{
          this.player.scale.set(-2,-2,2);
          this.playerShadow.scale.set(-2,2,2);

        }
      this.updatePlayerFrame(deltaTime*1000);
      this.player.material.uniforms.atlasGrid.value = new Vector2(16,2);
      this.playerShadow.material.uniforms.atlasGrid.value = new Vector2(16,2);
      this.frameIndexCap = 31;
    }else{
      this.updatePlayerFrame(deltaTime*1000);

      this.player.material.uniforms.atlasGrid.value = new Vector2(8,8);
      this.playerShadow.material.uniforms.atlasGrid.value = new Vector2(8,8);
      this.frameIndexCap = 61;

    }
    if(this.isAttacking)
    {
      this.player.material.uniforms.atlasGrid.value = new Vector2(8,4);
      this.playerShadow.material.uniforms.atlasGrid.value = new Vector2(8,4);
      this.frameIndexCap = 31;


    }
    this.player.material.uniforms.colorMultiplier.value = new Vector4(1,1,1,1);
    this.playerShadow.material.uniforms.colorMultiplier.value = new Vector4(0,0,0,0.5);

    // Update shader uniforms
    this.player.material.uniforms.isMoving.value = this.isMoving;
    this.player.material.uniforms.frameIndex.value = this.frameIndex;
    this.playerShadow.material.uniforms.isAttacking.value = this.isAttacking;

    this.playerShadow.material.uniforms.isMoving.value = this.isMoving;
    this.playerShadow.material.uniforms.frameIndex.value = this.frameIndex;
    
  }
  updateNpcFrame(deltaTime, npc) {
      npc.frameTime += deltaTime;
    
      if (npc.material.uniforms.isDead.value) {
        npc.frameIndexCap = 31; // Assuming death animation has 32 frames (0-31)
        if (npc.frameIndex < npc.frameIndexCap - 1) {
          npc.frameIndex++;
        }
      } else if (npc.frameTime >= this.frameDuration) {
        npc.frameIndex = (npc.frameIndex + 1) % npc.frameIndexCap;
        npc.frameTime = 0;
      }
    
      // Update shader uniforms
      npc.material.uniforms.frameIndex.value = npc.frameIndex;
    }

    updatePlayerFrame(deltaTime) {
      this.frameTime += deltaTime;
    
      if (this.player.material.uniforms.isAttacking.value) {

        if (this.frameIndex < this.frameIndexCap - 1) {
          this.frameIndex++;
        } else {
          this.isAttacking = false;
          // Attack animation finished, revert to idle/run state
          this.player.material.uniforms.isAttacking.value = false;
          this.frameIndex = 0;

          this.frameIndexCap = this.isMoving ? 31 : 61; // Set frame cap accordingly
        }
      } else if (this.frameTime >= this.frameDuration) {
        this.frameIndex = (this.frameIndex + 1) % this.frameIndexCap;
        this.frameTime = 0;
      }

      this.player.material.uniforms.frameIndex.value = this.frameIndex;
    }
  onKeyDown(event) {
    if (this.keys.hasOwnProperty(event.code)) {
      this.keys[event.code] = true;
    }
  }

  onKeyUp(event) {
    if (this.keys.hasOwnProperty(event.code)) {
      this.keys[event.code] = false;
    }
  }

  triggerBloodEffect(position) {
    if (this.bloodEmitter) {
        this.bloodEmitter.spawn(position,0);  // Spawn blood particles at the given position
    } else {
        console.warn("Blood emitter is not initialized.");
    }
}
triggerAcidEffect(position) {
  if (this.bloodEmitter) {
      this.bloodEmitter.spawn(position,1);  // Spawn blood particles at the given position
  } else {
      console.warn("Blood emitter is not initialized.");
  }
}
  takeDamage(amount) {
    this.playerHealth -= amount;
    if (this.playerHealth <= 0) {
      this.playerHealth = 0;
      console.log("Player is dead.");
      // Handle player death logic here
    }
    console.log(`Player health: ${this.playerHealth}`);
  }

  
  initializeLoading() {
    // Set up the asset group
    this.loader.groups.assets = [{
      name: 'paradeAssets',
      items: this.requiredAssets
    }];

    // Set up event listener
    this.loader.on('fileEnd', this.onFileLoaded.bind(this));

    // Start loading
    this.loader.loadNextGroup();
  }

  onFileLoaded(_resource, _data) {
    console.log(`Loaded: ${_resource.name}`);
    this.loadedAssets++;

    // Check if all assets are loaded
    if (this.loadedAssets === this.totalAssets) {
      this.onAllAssetsLoaded();
    }
  }
  badBloodLogicSetup() {
    this.createPlayerMesh();
    this.floor = this.createFloor();
    this.scene.add(this.floor);
    this.scene.add(this.player);

    this.clock = new THREE.Clock();
    this.velocity = new THREE.Vector3();
    this.speed = 2;
    this.paintRadius = 0.5;
    for (let i = 0; i < 60; i++) {
      const randomX = Math.random() *8 - 4;
      const randomZ = Math.random() *8 - 4;
      const position = new THREE.Vector3(randomX, 0.5, randomZ);
  
      const enemyType = Math.random() > 0.7 ? "special" : "human"; // Randomly choose enemy type
  
      this.createNpcMesh(enemyType, position);
    }
  }

  async onAllAssetsLoaded() {
    this.badBloodLogicSetup();


    this.allLoaded=true;
    this.initialCameraPosition = this.newCamera.threeCamera.position.clone();

    console.log("All assets loaded");
    this.createSceneObjects();

    // Start the introduction sequence
    this.startIntroSequence();

    let clickEnabled = false;

    function onMouseClick(event) {
      if (clickEnabled) {
        // Your click handling code here
        console.log("Mouse clicked");

        // Disable further clicks
        clickEnabled = false;
 
        // Remove the event listener to prevent further clicks
        window.removeEventListener('click', onMouseClick);
        window.removeEventListener('touchstart', onMouseClick);
      }
    }

    function onMouseUp(event) {
      // Re-enable clicks
      clickEnabled = true;
      console.log("Mouse up");

      // Re-attach the event listener
      window.addEventListener('click', onMouseClick, false);
      window.addEventListener('touchstart', onMouseClick, false);
    }

    // Initial event listeners
    window.addEventListener('click', onMouseClick, false);
    window.addEventListener('touchstart', onMouseClick, false);

    // Event listeners to re-enable clicks
    window.addEventListener('mouseup', onMouseUp, false);
    window.addEventListener('touchend', onMouseUp, false);
  }
  // Introduction sequence with scaling in the logo from 0 to full size
  startIntroSequence() {

    this.startParadeModelAnimation();
       
  }

  // Start hover animation after intro sequence
  startParadeModelAnimation() {
    this.ambient.intensity = 0.65;
    this.dirLight.intensity = 3;
    this.orbitalCamera.target = new Vector3(0, -2, 0);
    gsap.to(this.p1model.all_model[0].position, {
      duration: 1.1,
      ease: "power4.inOut",
      x: 0,
      y:-990,
      z: 0,
      onComplete: () => {
        this.canMoveCamera = true;
      }
    });
  }
  setButtonVisibilityCallback(callback) {
    this.buttonVisibilityCallback = callback;
  }


  createSceneObjects() {
    const paletteTexture = this.loader.getAsset("stormTheRoofTexture");

    console.log(paletteTexture);
    this.p1Material = new Material({
      uniforms: {
        downsideColor: { value: new THREE.Vector3(3 / 255, 3 / 255, 128 / 255) }
      },
      vertexShader: PropShader.vertexShader,
      fragmentShader: PropShader.fragmentShader
    });
    const standardMaterial = new THREE.MeshBasicMaterial({
      map: paletteTexture,
      receiveShadow: true,
      dithering: true,
      castShadow: true
    });

    standardMaterial.shininess = 5;
    const modelName = 'stormTheRoofModel';
    this.p1model = new Model({
      modelLink: modelName,
      material: standardMaterial,
      position: new THREE.Vector3(0, 0, 0), // Hide initially by setting position off-screen
      rotation: new THREE.Vector3(0.0, 0.0, 0.0),
      scale: new THREE.Vector3(0, 0, 0),
      scene: this.scene,
      name: 'stormTheRoofModel'
    });
    this.p1model.all_model[0].position.set(0, -100, 0);



    this.fullLoaded = true;
  }
 
  detectDevice() {
    if (navigator.userAgent.match(/Android/i)
      || navigator.userAgent.match(/webOS/i)
      || navigator.userAgent.match(/iPhone/i)
      || navigator.userAgent.match(/iPad/i)
      || navigator.userAgent.match(/iPod/i)
      || navigator.userAgent.match(/BlackBerry/i)
      || navigator.userAgent.match(/Windows Phone/i)
    ) {
      return 'phone';
    } else {
      return 'computer';
    }
  }

  updatePostProcessingSettings({ bloomIntensity, bokehAperture, focusDistance }) {
    if (this.postProcess) {
        if (bloomIntensity !== undefined && this.postProcess.bloomPass) {
            this.postProcess.bloomPass.strength = bloomIntensity;
        }
  
        if (bokehAperture !== undefined && this.postProcess.bokehPass) {
            this.postProcess.bokehPass.materialBokeh.uniforms['aperture'].value = bokehAperture;
        }

        if (focusDistance !== undefined && this.postProcess.bokehPass) {
            this.postProcess.bokehPass.materialBokeh.uniforms['focus'].value = focusDistance;
        }
    }
}
  componentDidMount() {
    console.log("scene 1 mounted");
    AudioManager.loadSounds();

    this.SceneMount();
    this.setCamera(40, 40, this.detectDevice() == 'computer' ? 20 : 20);
    this.setPostProcess();
    this.orbitalCamera = new OrbitalCameraGame(this.newCamera.threeCamera);
    this.orbitalCamera.setPosition(30, 12, -30); // Set the camera position to (30, 20, 50)
    this.cameraTarget = new THREE.Vector3(0, 0, 0);
     // Add mouse click listener
  document.addEventListener('click', this.onMouseClick.bind(this));
  }
  componentWillUnmount() {
    document.removeEventListener('click', this.onMouseClick.bind(this));

  }
  onMouseClick(event) {
    if (!this.player.material.uniforms.isAttacking.value) {
      this.isAttacking=true;
      this.player.material.uniforms.isAttacking.value = true;
      this.frameIndex = 0; // Restart the animation
      this.player.material.uniforms.atlasGrid.value = new THREE.Vector2(8, 4); // Change to attack atlas grid
      this.playerShadow.material.uniforms.atlasGrid.value = new THREE.Vector2(8, 4); // Change to attack atlas grid

      this.frameIndexCap = 15; // Adjust frame cap based on attack animation frames
    }
  
    const playerPosition = this.player.position;
    const killRadius = 1.5;  // Distance within which the player can "kill" an NPC
  
    this.npcArray.forEach((npc) => {
      if (!npc.material.uniforms.isDead.value && playerPosition.distanceTo(npc.position) < killRadius) {
        this.triggerBloodEffect(this.player.position);
        this.triggerAcidEffect(this.player.position);
         // Trigger camera shake
         this.triggerCameraShake(0.4, 2.5); // Shake for 0.5 seconds with 0.1 intensity
        npc.material.uniforms.isDead.value = true;
        npc.material.uniforms.isMoving.value = false; // Stop the NPC from moving
        npc.material.uniforms.atlasGrid.value = npc.deathAtlasGrid; // Change to death animation
        npc.frameIndex = 0; // Start death animation from the beginning
  npc.dead = true;
        // Handle NPC death animation or other death-related logic here
        console.log('NPC killed');
      }
    });
  }
  /******************************************************************************/
  /*!
  \brief  update per frame
  */
  /******************************************************************************/
  loadListener() {
    //document.addEventListener("mousedown", this.onPointerDown.bind(this));
    //document.addEventListener("mousemove", this.onPointerMove.bind(this));
    document.addEventListener("mouseup", this.onPointerUp.bind(this));

    // Touch events
    document.addEventListener("touchstart", this.onPointerDown.bind(this));
    document.addEventListener("touchmove", this.onPointerMove.bind(this));
    document.addEventListener("touchend", this.onPointerUp.bind(this));

    // Zoom event
    document.addEventListener("wheel", this.onZoom.bind(this));
  }
  onPointerDown(event) {
    this.isPointerDown = true;
    this.lastPointerPosition.set(
      event.clientX || event.touches[0].clientX,
      event.clientY || event.touches[0].clientY
    );
  }

  onPointerMove(event) {
    if (!this.isPointerDown) {
      if (event) {
        this.mouse.x = event.clientX !== undefined ? event.clientX : (event.touches && event.touches.length > 0 ? event.touches[0].clientX : this.mouse.x);
        this.mouse.y = event.clientY !== undefined ? event.clientY : (event.touches && event.touches.length > 0 ? event.touches[0].clientY : this.mouse.y);
      }
      return;
    }

    const clientX = event.clientX || event.touches[0].clientX;
    const clientY = event.clientY || event.touches[0].clientY;

    const deltaX = clientX - this.lastPointerPosition.x;
    const deltaY = clientY - this.lastPointerPosition.y;

    const deltaTheta = deltaX * 0.05;
    const deltaPhi = deltaY * 0.05;

    if(this.canMoveCamera)
      this.orbitalCamera.rotate(deltaTheta, deltaPhi);

    this.lastPointerPosition.set(clientX, clientY);
  }

  onPointerUp() {
    this.isPointerDown = false;
  }

  onZoom(event) {
    const delta = event.deltaY * 0.05;
    this.orbitalCamera.zoom(delta);
  }
  triggerCameraShake(duration, intensity) {
    this.shakeDuration = duration;
    this.shakeIntensity = intensity;
    this.shakeElapsed = 0;
  }

  applyCameraShake(position) {
    if (this.shakeElapsed < this.shakeDuration) {
      const shakeAmount = this.shakeIntensity * (1 - (this.shakeElapsed / this.shakeDuration));
      const noiseX = (Math.random() * 2 - 1) * shakeAmount;
      const noiseY = (Math.random() * 2 - 1) * shakeAmount;
      const noiseZ = (Math.random() * 2 - 1) * shakeAmount;

      position.x += noiseX;
      position.y += noiseY;
      position.z += noiseZ;

      this.shakeElapsed += this.dt * 0.001;
    }
  }
  Update() {
    this.spawnEnemies(); // Spawn NPCs based on the interval

    this.updateNpcMovement(this.dt); // Update NPC movements
    if (this.player) {

    // Smooth camera follow
 const desiredPosition = new THREE.Vector3().copy(this.player.position).add(this.cameraOffset);

      if (this.allLoaded) {
        this.applyCameraShake(desiredPosition); // Apply shake before moving the camera

        this.orbitalCamera.setPosition(
          MathUtils.lerp(this.newCamera.threeCamera.position.x, desiredPosition.x, 0.1),
          MathUtils.lerp(this.newCamera.threeCamera.position.y, desiredPosition.y, 0.1),
          MathUtils.lerp(this.newCamera.threeCamera.position.z, desiredPosition.z, 0.1)
        );
        let pos = this.player.position.clone();
        pos.add(new Vector3(0,-0.5,0));
        this.orbitalCamera.target.lerp(pos, 0.1);
        this.orbitalCamera.update();
    this.updatePlayerMovement(this.totalTime*0.001);

  }
}
    this.updatePostProcessingSettings({
      bloomIntensity: 0.0,
      focusDistance: 10,
      bokehAperture: 0.000025,
  });
    this.bloodEmitter.update(this);  // Update the particle emitter every frame
    this.skyMaterial.uniforms.time.value += this.dt * 0.001;
    this.skyMaterial.uniforms.sunPosition.value.set(
        100 * Math.sin(3),
        100 * Math.cos(6),
        100
    );
  
    //update delta time
    const now = performance.now();
    this.dt = (now - this.lastUpdate); // Delta time in seconds
    this.lastUpdate = now;
    this.totalTime = (now - this.startTime); // Total time in seconds

    this.mouseNormalized.x = this.mouse.x / window.innerWidth - 0.5;
    this.mouseNormalized.y = this.mouse.y / window.innerHeight - 0.5;
   
      this.append = 0;
      if (this.detectDevice() == 'computer') {
        this.lerpedMouse.x = MathUtils.lerp(this.lerpedMouse.x, this.mouseNormalized.x, 0.1 * 1 / this.dt * 1.5);
        this.lerpedMouse.y = MathUtils.lerp(this.lerpedMouse.y, this.mouseNormalized.y, 0.1 * 1 / this.dt * 1.5);
        this.lerpedMouseRaw.x = MathUtils.lerp(this.lerpedMouseRaw.x, this.mouse.x, 0.01 * 1 / this.dt * 1.5);
        this.lerpedMouseRaw.y = MathUtils.lerp(this.lerpedMouseRaw.y, this.mouse.y, 0.01 * 1 / this.dt * 1.5);
      }

    // Update orbital camera
    this.orbitalCamera.update(this.totalTime*0.0001,null,this.recoilOffset);
    // Ensure the Three.js camera is updated
 
  
    // Force the camera to update its matrix
    this.newCamera.threeCamera.updateMatrixWorld(true);
  
  if(this.avgPos)
      this.orbitalCamera.target =  this.avgPos;
    
  
    this.boot = true;
  }
}

export default React.forwardRef((props, ref) => <BadBlood {...props} ref={ref} />);
