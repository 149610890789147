import * as THREE from 'three';
import AudioManager from '../Audio/AudioManager';
import Firework from '../FX/Firework';

class Bomb {
  constructor(scene, startPosition, parent, removeCallback, fireworks, bombModel) {
    this.scene = scene;
    this.position = startPosition;
    this.parent = parent;
    this.removeCallback = removeCallback;
    this.fireworks = fireworks;
    this.velocity = new THREE.Vector3(0, -0.1, 0);
    this.health = 100;
    this.destroyed = false;

    // Create bomb model
    this.model = bombModel.all_model[0].clone();
    this.model.position.copy(this.position);
    this.scene.add(this.model);

    // Create hitbox
    this.hitbox = new THREE.Mesh(
      new THREE.BoxGeometry(2, 2, 2),
      new THREE.MeshBasicMaterial({ visible: false })
    );
    this.hitbox.position.copy(this.position);
    this.scene.add(this.hitbox);
  }

  // Update bomb position and check for explosion
  update() {
    if (this.destroyed) return;

    this.position.add(this.velocity);
    this.model.position.copy(this.position);
    this.hitbox.position.copy(this.position);

    if (this.position.y < 0) {
      this.explode();
    }
  }

  // Apply damage to the bomb
  takeDamage(amount) {
    this.health -= amount;
    if (this.health <= 0) {
      this.explode();
    }
  }

  // Handle bomb explosion
  explode() {
    AudioManager.playSound('explode');
    this.fireworks.push(new Firework(this.scene, this.position));
    this.destroyed = true;
    this.scene.remove(this.model);
    this.scene.remove(this.hitbox);
    this.removeCallback(this);
    this.parent.takeDamage(10);
  }
}

export default Bomb;