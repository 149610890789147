// pages/doc.tsx

import React from 'react';
import { Container, Typography, Box, Card, CardMedia } from '@mui/material';
import Layout from '../../components/Layout';
import CodeBlock from '../../components/CodeBlock';
const sampleCode = `<iframe
src="https://artcodestudio.co/uob"
width="100%"
height="100%"
frameBorder="0"
allowFullScreen
className="w-full"
></iframe>
`;

const Documentation = () => {
    return (
        <Layout>
            <Container maxWidth="md" sx={{ mt: 4 }}>
                <Box mb={4}>
                    <Typography variant="h4" component="h2" gutterBottom>
                        <b>Adding artcode elements to wordpress through iframe plugin</b>
                    </Typography>
                    <Typography>
                        <ul>
                            <li>
                                In the plugins section, add an iframe plugin - "Simple Iframe" plugin should work
                            </li>
                            <li>
                                Add the new iframe element under the embed category.
                            </li>
                            <li>
                                Add the following code or setting to show the 3d element.
                            </li>
                            <li>
                                The width and height can be set to any value, the example from the image below uses '500px'
                            </li>
                        </ul>
                    </Typography>
                    <Box sx={{ bgcolor: 'background.paper', p: 2, borderRadius: 1, mt: 2 }}>
                       <CodeBlock code = {sampleCode}/>
                    </Box>
                    <Typography mt={2}>
                        We should see the embedded element in the page itself, example below.
                    </Typography>
                    <Card sx={{ maxWidth: 600, margin: 'auto', mt: 2 }}>
                        <CardMedia
                            component="img"
                            width="100%"
                            height="auto"
                            image="example-iframe.jpg"
                            alt="Example Image"
                        />
                    </Card>
                </Box>
            </Container>
        </Layout>
    );
};

export default Documentation;
