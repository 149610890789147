import '../Home.css';
import '../../../src/Global.css';
import { Typography } from '@mui/material';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import TabletMacIcon from '@mui/icons-material/TabletMac';
import LaptopIcon from '@mui/icons-material/Laptop';
import { Grid } from '@mui/material';

import { Box } from '@mui/system';
export const panel_Part3 = () => {
    return (
        <>
            <div style={{ padding: 20, overflowY: 'scroll', overflowX: 'hidden' }}>
                <Typography fontFamily="Glacial"
                    paddingTop="105px"
                    align="center"
                    color={'#ffffffFF'}
                    variant="h3"
                    lineHeight={'1rem'}
                    fontSize="2rem"
                    fontWeight="100"
                    fontStyle={'normal'}>
                    RESPONSIVENESS
                </Typography>

                <Typography
                    style={{ backgroundColor: '#fc7b03ff' }}
                    fontFamily="Glacial"
                    paddingTop="10px"
                    paddingBottom="10px"
                    paddingLeft="25px"
                    marginTop="75px"
                    marginBottom="25px"
                    borderRadius={'0px'}
                    align="left"
                    color={'#000000ff'}
                    variant="h3"
                    fontSize="1rem"
                    fontWeight="600">
                    How is works
                </Typography>
                <Box
                    sx={{
                        margin: '50px',
                        display: 'grid',

                        justifyContent: 'center',
                        textAlign: 'center',

                        gridTemplateColumns: {
                            xs: 'repeat(3, 1fr)',
                            md: 'repeat(3, 1fr)',
                        },
                    }}
                >
                    <Box sx={{ textAlign: 'center' }}>
                        <TabletMacIcon sx={{ width: '60px', height: '60px' }} ></TabletMacIcon>
                    </Box>
                    <Box sx={{ textAlign: 'center' }}>
                        <PhoneIphoneIcon sx={{ width: '60px', height: '60px' }} ></PhoneIphoneIcon>
                    </Box>
                    <Box sx={{ textAlign: 'center' }}>
                        <LaptopIcon sx={{ width: '60px', height: '60px' }} ></LaptopIcon>
                    </Box>
                </Box >

                <div style={{ padding: 25 }}>

                    <Typography fontFamily="DMSans"
                        paddingBottom="15px"
                        align="left"
                        color={'#FFFFFFFF'}
                        variant="h3"
                        lineHeight={'2rem'}
                        fontSize="1rem"
                        fontWeight="100">
                        Over 60% of website visits now <b>happen on mobile</b> (versus 37% on desktop), a percentage that is still trending upward. With users now able to access information virtually anywhere, your website must deliver a seamless experience on all devices.

                    </Typography>
                    <Typography fontFamily="DMSans"
                        paddingBottom="15px"
                        paddingTop="15px"
                        align="left"
                        color={'#FFFFFFFF'}
                        variant="h3"
                        lineHeight={'2rem'}
                        fontSize="1rem"
                        fontWeight="100">

                        <b> The solution? Responsive website design.</b>

                        Responsive web design moves beyond the outdated mobile-friendly approach, delivering a website layout that dynamically changes and adjusts based on a user’s specific device parameters. The experience on a responsive site is seamless whether viewed on an iPhone or tablet.


                    </Typography>
                </div>


            </div>
        </>
    )
}
