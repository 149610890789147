import * as THREE from "three";
import AudioManager from "../Audio/AudioManager";
import Firework from "../FX/Firework";
import EnemyPoint from "./EnemyPoint";
import Model from "../../../../Render/Model";

class ParachutePoint extends EnemyPoint {
    constructor(scene, model, time) {
        // Using default hitbox size and initial height of 60
        super(scene, model, new THREE.Vector3(3, 3, 3), 60);
        this.time = time;
        this.setupParachute();
    }

    // Setup parachute properties
    setupParachute() {
        this.model.scale.set(2, 2, 2);
        this.fullLoaded = true;
    }

    // Update the path of the parachute
    updatePath() {
        if (!this.pathPoints || this.pathPoints.length === 0) {
            console.error("Path points not set or empty");
            return;
        }

        // Ensure index is within bounds
        const index = Math.min(Math.floor(this.runTime * 10), 199);

        // Update current position
        this.currPoint.copy(this.pathPoints[index]);

        // Calculate look-at point (next point in path or current if at end)
        const nextIndex = Math.min(index + 1, this.pathPoints.length - 1);
        this.currPointLookAt.copy(this.pathPoints[nextIndex]);
    }

    // Update the parachute state
    update(index) {
        if (!this.pathPoints) {
            return;
        }

        if (this.runTime >= this.pathPoints.length / 10) {
            this.handleDestruction();
            return;
        }

        this.updatePath(index);
        this.updateLerpedPositions();
        this.updatePosition();

        this.runTime += this.time.getDeltaTime();
    }

    // Handle parachute destruction
    handleDestruction() {
        this.canDespawn = true;
    }

    // Set the path points for the parachute
    setPathPoints(pathPoints) {
        this.pathPoints = pathPoints;
    }
}

export default ParachutePoint;