import React, { useState, useRef, useEffect } from 'react';
import '../Home.css';
import { useScroll } from 'framer-motion';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Grid, Box, Typography, IconButton } from '@mui/material';
import Content from '../Lander/Content';
import Topbar from 'src/components/TopBar';
import Bottombar from 'src/components/BottomBar';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import AppBar from '@mui/material/AppBar';
import Slide from '@mui/material/Slide';
import Container from 'src/components/Container';
import LoadingManager from '../../Render/LoadingManager';
import ArtCode from '../../components/Scenes/ArtCode/ArtCode';
import CalendlyWidget from './CalendlyWidget';
import CloseIcon from '@mui/icons-material/Close';
import AudioManager from '../../components/Scenes/ArtCode/AudioManager';
import { useHistory, useLocation } from 'react-router-dom';
import SwipeIcon from '@mui/icons-material/Swipe';
import gsap from 'gsap'; // Import GSAP
import { useMediaQuery } from '@mui/material';
import { getAudioState, setAudioState, toggleAudioState } from '../../utils/audioState';

const HideOnScroll = ({ children }: any) => {
  const trigger = useScrollTrigger();

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
};

export enum ContentPanel {
  CONTENT_STAGE_HIDDEN,
  CONTENT_STAGE_PART_1,
  CONTENT_STAGE_PART_2,
  CONTENT_STAGE_PART_3
}

var t = false;

const ArtCodePage: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const [contentValue, setContentValue] = useState<ContentPanel>(ContentPanel.CONTENT_STAGE_HIDDEN);
  const [showCalendly, setShowCalendly] = useState(false);
  const [showTopbar, setShowTopbar] = useState(false); // Initially hidden
  const [isSoundOn, setIsSoundOn] = useState(getAudioState());
  const [showExploreHint, setShowExploreHint] = useState(false); // Initially hidden
  const artCodeRef = useRef<any>(null);
  const topbarRef = useRef<HTMLDivElement>(null); // Add ref for topbar
  const exploreHintRef = useRef<HTMLDivElement>(null); // Add ref for explore hint
  const isMobile = useMediaQuery('(max-width:600px)');

  const theme = createTheme({
    palette: {
      primary: {
        main: '#FFFFFF',
        contrastText: '#000000',
      },
      secondary: {
        main: '#000000'
      },
    }
  });

  const OpenContent = (content: ContentPanel) => {
    setContentValue(content);
  }

  const CloseContent = () => {
    t = false;
    OpenContent(ContentPanel.CONTENT_STAGE_HIDDEN);
  }

  const OpenContent_Stage_Part1 = () => {
    if (contentValue === ContentPanel.CONTENT_STAGE_HIDDEN) {
      t = true;
      OpenContent(ContentPanel.CONTENT_STAGE_PART_1);
    }
  }

  const OpenContent_Stage_Part2 = () => {
    if (contentValue === ContentPanel.CONTENT_STAGE_HIDDEN) {
      t = true;
      OpenContent(ContentPanel.CONTENT_STAGE_PART_2);
    }
  }

  const OpenContent_Stage_Part3 = () => {
    if (contentValue === ContentPanel.CONTENT_STAGE_HIDDEN) {
      t = true;
      OpenContent(ContentPanel.CONTENT_STAGE_PART_3);
    }
  }

  const ReturnState = () => {
    return t;
  }

  const { scrollYProgress } = useScroll();

  const toggleCalendly = () => {
    setShowCalendly(prev => !prev);
    setShowTopbar(!showCalendly);
  };

  const closeCalendly = () => {
    setShowCalendly(false);
    setShowTopbar(true);
    if (artCodeRef.current) {
      artCodeRef.current.resetCamera();
    }
  };

  const navigateToVoyage = () => {
    if (artCodeRef.current) {
      artCodeRef.current.saveCameraPosition();
    }
    window.location.href = '/lab';
  };

  const toggleSound = () => {
    const newState = toggleAudioState();
    setIsSoundOn(newState);
  };

  useEffect(() => {
    if (artCodeRef.current) {
      artCodeRef.current.loadCameraPosition();
      artCodeRef.current.setOnCameraMove(() => {
        setShowExploreHint(false);
        gsap.to(exploreHintRef.current, { opacity: 0, duration: 0.5 });
      });
    }
    setAudioState(isSoundOn);
  }, []);

  const handleCameraAnimationComplete = () => {
    setShowTopbar(true);
    setShowExploreHint(true);

    // Animate the topbar and explore hint
    if (topbarRef.current) {
      gsap.fromTo(topbarRef.current, { opacity: 0, y: -20 }, { opacity: 1, y: 0, duration: 1 });
    }
    if (exploreHintRef.current) {
      gsap.fromTo(exploreHintRef.current, { opacity: 0, y: 20 }, { opacity: 1, y: 0, duration: 1, delay: 0.5 });
    }
  };


  return (
    <>
      <LoadingManager />
      <div 
        className="pageGlobal"
      >
        {/* Rest of your component */}
        <Grid container style={{
          position: 'fixed',
          zIndex: -1,
        }} >
          <ArtCode
            ref={artCodeRef}
            collision_part1_callback={OpenContent_Stage_Part1}
            collision_part2_callback={OpenContent_Stage_Part2}
            collision_part3_callback={OpenContent_Stage_Part3}
            statecallback={ReturnState}
            modelcallback={'main'}
            toggleCalendly={toggleCalendly}
            isSoundOn={isSoundOn}
            onCameraAnimationComplete={handleCameraAnimationComplete}
            onCalendlyClosed={closeCalendly} // Add this line
          >
          </ArtCode >
        </Grid>

        {contentValue === ContentPanel.CONTENT_STAGE_HIDDEN &&
          <>
            {showTopbar && (
              <HideOnScroll>
                <AppBar
                  position={'fixed'}
                  sx={{
                    boxShadow: 'none',
                    backgroundColor: '#00000000',
                    zIndex: 1100,
                  }}
                  ref={topbarRef} // Add ref to topbar
                >
                  <Container sx={{ py: { xs: 2, sm: 4 } }}>
                    <Topbar 
                      artcodeColor="#ffffff" 
                      isSoundOn={isSoundOn}
                      toggleSound={toggleSound}
                    />
                  </Container>
                </AppBar>
              </HideOnScroll>
            )}
            <AppBar
              position={'fixed'}
              sx={{
                top: 'auto', bottom: 0,
                boxShadow: 'none',
                backgroundColor: '#00000000',
              }}
            >
              <Container sx={{ py: { xs: 2, sm: 4 } }}>
                <Bottombar />
              </Container>
            </AppBar>
          </>}
        <Grid id='scc' container style={{
          position: 'fixed',
          height: '100vh',
          width: '100vw',
          zIndex: -1,
          overflowY: 'scroll',
          overflowX: 'hidden',
        }} >
          {contentValue === ContentPanel.CONTENT_STAGE_HIDDEN &&
            <Grid container item direction="column" style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              color: '#ffffff',
              overflowY: 'scroll',
            }}>
              <ThemeProvider theme={theme}>
              </ThemeProvider>
            </Grid>
          }
        </Grid>
        <Grid container item direction="column" style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
          overflowY: 'scroll',
        }}>
          <Content
            content_panel_value={contentValue}
            content_open={contentValue !== ContentPanel.CONTENT_STAGE_HIDDEN}
            content_close={CloseContent}>
          </Content>
        </Grid>
        {showCalendly && (
          <Box
            sx={{
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              backdropFilter: 'blur(5px)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: 2000, // Increased z-index
            }}
            onClick={closeCalendly} // Close when clicking outside
          >
            <Box
              sx={{
                position: 'relative',
                width: '700px',
                height: '500px',
                backgroundColor: 'white',
                borderRadius: '10px',
                overflow: 'hidden',
              }}
              onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside
            >
              <IconButton
                onClick={closeCalendly}
                sx={{
                  position: 'absolute',
                  top: 8,
                  right: 8,
                  color: 'black',
                  zIndex: 2001, // Ensure it's above the Calendly widget
                  backgroundColor: 'rgba(255, 255, 255, 0.7)', // Add a semi-transparent background
                  '&:hover': {
                    backgroundColor: 'rgba(255, 255, 255, 0.9)',
                  },
                }}
              >
                <CloseIcon />
              </IconButton>
              <CalendlyWidget />
            </Box>
          </Box>
        )}
        { (
          <Box
            sx={{
              position: 'fixed',
              bottom: '60px',
              left: '50%',
              transform: 'translateX(-50%)',
              backgroundColor: 'transparent',
              color: '#0000FF',
              padding: '8px 16px',
              borderRadius: '32px',
              display: 'flex',
              alignItems: 'center',
              transition: 'opacity 0.5s',
              opacity: showExploreHint ? 1 : 0,
              border: '2px solid #0000FF',
              maxWidth: '90%',
            }}
            ref={exploreHintRef} // Add ref to explore hint
          >
            <SwipeIcon sx={{ marginRight: '8px', color: '#0000FF' }} />
            <Typography 
              variant="body2" 
              fontFamily="Roboto Mono" 
              sx={{ 
                color: '#0000FF',
                fontSize: isMobile ? '0.75rem' : '0.875rem',
                whiteSpace: 'nowrap',
              }}
            >
              DRAG TO EXPLORE
            </Typography>
          </Box>
        )}
      </div>
    </>
  );
};

export default ArtCodePage;
