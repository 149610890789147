import * as THREE from "three";
import { Reflector } from 'three/examples/jsm/objects/Reflector';
import { MathUtils, Vector2, Vector3, BufferGeometry, BufferAttribute, Points, PointsMaterial, TextureLoader } from "three";
import { Mesh, BoxGeometry, MeshBasicMaterial } from 'three';
import gsap from 'gsap';

//renderer related
import Model from "../../../Render/Model";
import LoadingManager from "../../../Render/LoadingManager";
import Scene from "../Base/Scene";

import FlagMotionShader from "../../../Shaders/FlagMotionShader";
import CutoffShader from "../../../Shaders/CutoffShader";
import LogoShader from "../../../Shaders/LogoShader";
import ShadowSurfaceShader from "../../../Shaders/ShadowSurfaceShader";
import HeightCutoffShader from "../../../Shaders/HeightCutoffShader";
import { Water } from "three/examples/jsm/objects/Water.js";

import Sky from "./Environment/Sky";
import Environment from "./Environment/Environment";

import AudioManager from "./AudioManager";
import SmokeEmitter from './SmokeEmitter';

import TouchTexture from '../Projects/TouchTexture';
import ImageMotionShader from '../../../Shaders/ImageMotionShader';

/******************************************************************************/
/*!
\brief  main 3d scene setup
*/
/******************************************************************************/
class ArtCode extends Scene {
  //constructor
  constructor(_options) {
    super(_options);
    this.clickTimeout = 0;
    this.scrollDelta = 25;
    this.getState = _options.statecallback;
    this.modelLoad = _options.modelcallback;

    this.blinkingLight = null;
    this.createBlinkingLight();
    this.collectibles = [];
    this.collectiblePositions = [
      //{ position: new THREE.Vector3(-5, 8, 20), projectId: 'project1' },
      //{ position: new THREE.Vector3(-0.66, 21, -19), projectId: 'project2' },
     // { position: new THREE.Vector3(-8, -8, -21), projectId: 'project3' },
      // Add more positions as needed
    ];

    this.cutoffFactor = 0;

    // New camera control variables
    this.isDragging = false;
    this.clickStartPosition = { x: 0, y:0 };

    this.previousMousePosition = { x: 0, y: 0 };
    this.cameraPosition = new THREE.Vector3(40, 0, -10);
    this.targetPosition = new THREE.Vector3(40, 2, -10);
    this.moveSpeed = 0.1; // Adjust for faster or slower movement

    // Add min and max values for clamping
    this.minX = -15;
    this.maxX = 15;
    this.minY = -20;
    this.maxY = 35;

    this.hide = false;
    this.current = 0;

    // Add a callback for camera movement
    this.onCameraMove = null;

    // Add a callback for camera animation completion
    this.onCameraAnimationComplete = _options.onCameraAnimationComplete || null;

    //mouse data
    this.mouseTouchTexture = new Vector2(0, 0);
    this.mouse = new Vector2(0, 0);
    this.mouseNormalized = new Vector2(0, 0);
    this.lerpedMouse = new Vector2(0, 0);
    this.lerpedMouseRaw = new Vector2(0, 0);
    this.boot = false;
    this.fullLoaded = false;
    //time
    this.lastUpdate = Date.now();
    this.totalTime = 0.0;


    this.ambient = new THREE.AmbientLight(0xffffff, 1.5, 0);

    this.scene.add(this.ambient);
    this.smokeEmitters = [];
    this.smokeTexture = null;


    //add event listeners
    this.loadListener();
    this.startRender();

    this.requiredAssets = [
      { name: 'heroModel', source: '/assets/hero_model.glb' },
      { name: 'heroTexture', source: '/assets/hero_render.jpg', type: 'texture' },
      { name: 'waterNormalTexture', source: '/assets/noise_n.png', type: 'texture' },


      { name: 'floppyModel', source: '/assets/project_works_floppy.glb' },
      { name: 'floppyBaseColorTexture', source: '/assets/projects/DefaultMaterial_BaseColor.jpg', type: 'texture' },


      // page assets
      { name: 'flagModel', source: '/assets/landing/artcode_landing_page_flag.glb' },

      { name: 'logoModel', source: '/assets/landing/artcode_landing_page_floor_logo.glb' },

      { name: 'floor1Model', source: '/assets/landing/artcode_landing_page_floor_1.glb' },
      { name: 'floor1Texture', source: '/assets/landing/artcode_landing_floor_1.jpg', type: 'texture' },
      { name: 'floor2Model', source: '/assets/landing/artcode_landing_page_floor_2.glb' },
      { name: 'floor2Texture', source: '/assets/landing/artcode_landing_floor_2.jpg', type: 'texture' },
      { name: 'floor3Model', source: '/assets/landing/artcode_landing_page_floor_3.glb' },
      { name: 'floor3Texture', source: '/assets/landing/artcode_landing_floor_3.jpg', type: 'texture' },
      { name: 'floor4Model', source: '/assets/landing/artcode_landing_page_floor_4.glb' },
      { name: 'floor4Texture', source: '/assets/landing/artcode_landing_floor_4.jpg', type: 'texture' },
      
      { name: 'shutterModel', source: '/assets/landing/artcode_landing_page_floor_shutter.glb' },


      { name: 'facadeModel', source: '/assets/landing/artcode_landing_page_facade.glb' },
      { name: 'facadeTexture', source: '/assets/landing/artcode_landing_floor_facade.jpg', type: 'texture' },
      
      
      { name: 'stairModel', source: '/assets/landing/artcode_landing_page_staircase.glb' },
      { name: 'stairTexture', source: '/assets/landing/artcode_landing_floor_staircase.jpg', type: 'texture' },
      { name: 'whiteTexture', source: '/assets/landing/white.jpg', type: 'texture' },


      { name: 'smokeTexture', source: '/assets/landing/smoke.png', type: 'texture' },
      { name: 'subtitleTexture', source: '/assets/landing/subtitle.jpg', type: 'texture' },

      { name: 'noiseTexture', source: '/assets/noise.png', type: 'texture' },

      { name: 'backdropModel', source: '/assets/backdrop.glb' },

      { name: 'contactPageTitleModel', source: '/assets/landing/artcode_landing_page_text_contact.glb' },
      { name: 'projectsPageTitleModel', source: '/assets/landing/artcode_landing_page_text_project.glb' },
      { name: 'labPageTitleModel', source: '/assets/landing/artcode_landing_page_text_lab.glb' },
      { name: 'availableModel', source: '/assets/landing/artcode_landing_page_available.glb' },

      // backdrop page assets
      { name: 'heroBackdropModel', source: '/assets/artcode_landing_backdrop.glb' },
      { name: 'heroBackdropTexture', source: '/assets/artcode_landing_backdrop.jpg', type: 'texture' },
      { name: 'dustTexture', source: '/assets/dust.png', type: 'texture' },
    ];

    this.loadedAssets = 0;
    this.totalAssets = this.requiredAssets.length;

    this.loadingManager = new LoadingManager();
    this.loader = this.loadingManager.loader;
    this.initializeLoading();

    // Add new event listeners for camera control
    window.addEventListener('mousedown', this.onMouseDown.bind(this));
    window.addEventListener('mousemove', this.onMouseMove.bind(this));
    window.addEventListener('mouseup', this.onMouseUp.bind(this));
    window.addEventListener('touchstart', this.onTouchStart.bind(this));
    window.addEventListener('touchmove', this.onTouchMove.bind(this));
    window.addEventListener('touchend', this.onTouchEnd.bind(this));
    window.addEventListener('wheel', this.onWheel.bind(this)); // Add wheel event listener

    this.particles = null;
    this.particleCount = 100;
    this.particlePositions = new Float32Array(this.particleCount * 3);
    this.particleVelocities = new Float32Array(this.particleCount * 3);

    // Add raycaster for collision detection
    this.raycaster = new THREE.Raycaster();
    this.isPointingAtCollider = false;
    this.isPointingAtColliderProject = false;
    this.isPointingAtColliderLab = false;


    this.isDynamicCameraEnabled = false;

    this.toggleCalendly = _options.toggleCalendly; // Add this line
    this.calendly = null; // Initialize calendly as null

    this.initialTargetPosition = new THREE.Vector3(40, 27, -1);
    this.initialCameraPosition = new THREE.Vector3(40, 68, -1);

    // Set the initial camera position and target
    this.targetPosition = this.initialTargetPosition.clone();
    this.cameraPosition = this.initialCameraPosition.clone();

    // Immediately set the camera to the initial position
    this.initializeCameraPosition();

    this.clickStartTime = 0;
    this.clickStartPosition = { x: 0, y: 0 };
    this.clickThreshold = 200; // milliseconds
    this.moveThreshold = 5; // pixels

    this.touchTexture = new TouchTexture();
    this.mouse = new THREE.Vector2();
    this.resolution = new THREE.Vector2(window.innerWidth, window.innerHeight);

    this.onCalendlyClosed = _options.onCalendlyClosed || null;

    // Add a threshold Y position for water visibility
    this.waterVisibilityThreshold = -8; // Adjust this value as needed

  }

  initializeCameraPosition() {
    if (this.newCamera && this.newCamera.threeCamera) {
      this.newCamera.threeCamera.position.copy(this.initialCameraPosition);
      this.newCamera.threeCamera.lookAt(this.initialTargetPosition);
    }
  }

  onWheel(event) {
    if (!this.isDynamicCameraEnabled) return;

    //event.preventDefault();

    const scrollAmount = event.deltaY * -0.05; // Adjust this value to control scroll sensitivity
    this.targetPosition.y += scrollAmount;

    // Clamp the target position
    this.targetPosition.y = THREE.MathUtils.clamp(this.targetPosition.y, this.minY, this.maxY);

    // Trigger the camera move callback
    if (this.onCameraMove) {
      this.onCameraMove();
    }
  }

  onMouseDown(event) {
    
    this.isDragging = true;
    this.clickStartTime = Date.now();
    this.clickStartPosition = { x: event.clientX, y: event.clientY };
    this.previousMousePosition = {
      x: event.clientX,
      y: event.clientY
    };
    console.log('onMouseDown');
   
  }

  onMouseMove(event) {
          // Update touch texture
    this.mouseTouchTexture.x = event.clientX / window.innerWidth;
    this.mouseTouchTexture.y = 1 - (event.clientY / window.innerHeight);
    if (!this.isDragging) return;

    const deltaMove = {
      x: event.clientX - this.previousMousePosition.x,
      y: event.clientY - this.previousMousePosition.y
    };

    this.moveCamera(deltaMove);
    const moveDistance = Math.sqrt(
      Math.pow(event.clientX - this.clickStartPosition.x, 2) +
      Math.pow(event.clientY - this.clickStartPosition.y, 2)
    );
    this.potentialClick = true;
    if (moveDistance > this.moveThreshold) {
      this.isDragging = true;
      this.potentialClick = false;
    }
    this.previousMousePosition = {
      x: event.clientX,
      y: event.clientY
    };

    // Update mouse position for raycasting
    this.mouseNormalized.x = (event.clientX / window.innerWidth) * 2 - 1;
    this.mouseNormalized.y = -(event.clientY / window.innerHeight) * 2 + 1;

    // Update touch texture
    this.mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
    this.mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;
  }

  onMouseUp(event) {
    const clickDuration = Date.now() - this.clickStartTime;
    const moveDistance = Math.sqrt(
      Math.pow(event.clientX - this.clickStartPosition.x, 2) +
      Math.pow(event.clientY - this.clickStartPosition.y, 2)
    );

    if (clickDuration < this.clickThreshold && moveDistance < this.moveThreshold) {
      this.onClick(event);
    }
    this.isDragging = false;
  }

  onTouchStart(event) {
    if (event.touches.length === 1) {
      this.isDragging = true;
      this.clickStartTime = Date.now();
      this.clickStartPosition = { x: event.touches[0].clientX, y: event.touches[0].clientY };
      this.previousMousePosition = {
        x: event.touches[0].clientX,
        y: event.touches[0].clientY
      };
    }
  }

  onTouchMove(event) {
    if (!this.isDragging) return;

    const deltaMove = {
      x: event.touches[0].clientX - this.previousMousePosition.x,
      y: event.touches[0].clientY - this.previousMousePosition.y
    };

    this.moveCamera(deltaMove);

    this.previousMousePosition = {
      x: event.touches[0].clientX,
      y: event.touches[0].clientY
    };

    // Update mouse position for raycasting
    this.mouseNormalized.x = (event.touches[0].clientX / window.innerWidth) * 2 - 1;
    this.mouseNormalized.y = -(event.touches[0].clientY / window.innerHeight) * 2 + 1;

    // Update touch texture
    this.mouse.x = (event.touches[0].clientX / window.innerWidth) * 2 - 1;
    this.mouse.y = -(event.touches[0].clientY / window.innerHeight) * 2 + 1;
  }

  onTouchEnd(event) {
    const clickDuration = Date.now() - this.clickStartTime;
    const moveDistance = Math.sqrt(
      Math.pow(event.changedTouches[0].clientX - this.clickStartPosition.x, 2) +
      Math.pow(event.changedTouches[0].clientY - this.clickStartPosition.y, 2)
    );

    if (clickDuration < this.clickThreshold && moveDistance < this.moveThreshold) {
      this.onClick(event.changedTouches[0]);
    }
    this.isDragging = false;
  }

  moveCamera(deltaMove) {
    // Update target position based on mouse movement
    this.targetPosition.z += deltaMove.x * this.moveSpeed;
    this.targetPosition.y += deltaMove.y * this.moveSpeed;

    // Clamp the target position
    this.targetPosition.z = THREE.MathUtils.clamp(this.targetPosition.z, this.minX, this.maxX);
    this.targetPosition.y = THREE.MathUtils.clamp(this.targetPosition.y, this.minY, this.maxY);

    // Trigger the camera move callback
    if (this.onCameraMove) {
      this.onCameraMove();
    }
  }

  updateCameraPosition() {
    if (this.isDynamicCameraEnabled) {
      // Smoothly interpolate current position to target position
      this.cameraPosition.lerp(this.targetPosition, 0.05);

      // Clamp the camera position
      this.cameraPosition.z = THREE.MathUtils.clamp(this.cameraPosition.z, this.minX, this.maxX);
      this.cameraPosition.y = THREE.MathUtils.clamp(this.cameraPosition.y, this.minY, this.maxY);

      // Apply position to camera
      this.newCamera.threeCamera.position.copy(this.cameraPosition);

      // Ensure the camera always looks straight ahead
      this.newCamera.threeCamera.lookAt(this.cameraPosition.x - 1, this.cameraPosition.y, this.cameraPosition.z);
      
      // Update water visibility based on camera Y position
      this.setWaterVisibility(this.cameraPosition.y < this.waterVisibilityThreshold);

      if (this.detectDevice)
        this.newCamera.threeCamera.fov = this.deviceType === 'phone' ? 50 : 30;
    }
  }

  initializeLoading() {
    // Set up the asset group
    this.loader.groups.assets = [{
      name: 'uobAssets',
      items: this.requiredAssets
    }];

    // Set up event listener
    this.loader.on('fileEnd', this.onFileLoaded.bind(this));

    // Start loading
    this.loader.loadNextGroup();
  }

  onFileLoaded(_resource, _data) {
    this.newCamera.threeCamera.position.copy( this.cameraPosition);
    this.newCamera.threeCamera.lookAt(this.targetPosition);
    console.log(`Loaded: ${_resource.name}`);
    this.loadedAssets++;

    // Check if all assets are loaded
    if (this.loadedAssets === this.totalAssets) {
      this.onAllAssetsLoaded();
    }
  }

  async onAllAssetsLoaded() {

    // Ensure the camera is at the initial position before starting the animation
    this.newCamera.threeCamera.position.copy(this.initialCameraPosition);
    this.newCamera.threeCamera.lookAt(this.initialTargetPosition);

    console.log("All assets loaded");
    this.loadAllModels();
    this.createReflectivePlane();
    this.setSky();

    this.setEnvironment();

    // Ensure the camera is at the initial position before starting the animation
    this.newCamera.threeCamera.position.copy(this.initialCameraPosition);
    this.newCamera.threeCamera.lookAt(this.initialTargetPosition);
    this.setWaterVisibility(this.cameraPosition.y < this.waterVisibilityThreshold);

    this.animateCameraToTarget();
    this.animateHero(false);
    this.animateLabTitle(false);
    this.animateContactTitle(false);
    this.animateProjectsTitle(false);
    this.createCollectibles();

    // Create collider for contact title model
    this.createContactTitleCollider();
    this.createProjectsTitleCollider();
    this.createLabTitleCollider();
    this.createHeroCollider();
  }

  createHeroCollider() {
    // Existing collider
    const colliderGeometry = new THREE.BoxGeometry(12, 11, 18);
    const colliderMaterial = new THREE.MeshBasicMaterial({ visible: false });
    this.heroCollider = new THREE.Mesh(colliderGeometry, colliderMaterial);
    this.heroCollider.position.copy(new THREE.Vector3(0,27,0));
    this.scene.add(this.heroCollider);

    // New visible collider
    const visibleColliderGeometry = new THREE.BoxGeometry(6, 5.5, 6);
    const visibleColliderMaterial = new THREE.MeshBasicMaterial({ color: 0xff0000, transparent: true, opacity: 0.5, visible: false });
    this.visibleHeroCollider = new THREE.Mesh(visibleColliderGeometry, visibleColliderMaterial);
    this.visibleHeroCollider.position.copy(this.heroCollider.position);
    this.scene.add(this.visibleHeroCollider);
}
  createContactTitleCollider() {
    // Existing collider
    const colliderGeometry = new THREE.BoxGeometry(12, 14, 12);
    const colliderMaterial = new THREE.MeshBasicMaterial({ visible: false });
    this.contactTitleCollider = new THREE.Mesh(colliderGeometry, colliderMaterial);
    this.contactTitleCollider.position.copy(this.contactTitleModel.all_model[0].position);
    this.contactTitleCollider.position.add(new THREE.Vector3(0, -2, -2));
    this.scene.add(this.contactTitleCollider);

    // New visible collider
    const visibleColliderGeometry = new THREE.BoxGeometry(12, 12, 12);
    const visibleColliderMaterial = new THREE.MeshBasicMaterial({ color: 0xff0000, transparent: true, opacity: 0.5 ,visible:false});
    this.visibleContactCollider = new THREE.Mesh(visibleColliderGeometry, visibleColliderMaterial);
    this.visibleContactCollider.position.copy(this.contactTitleCollider.position);
    this.visibleContactCollider.position.y -= 3; // Move it in front of the existing collider
    this.scene.add(this.visibleContactCollider);
  }

  createProjectsTitleCollider() {
    // Existing collider
    const colliderGeometry = new THREE.BoxGeometry(12, 14, 18);
    const colliderMaterial = new THREE.MeshBasicMaterial({ visible: false });
    this.projectsTitleCollider = new THREE.Mesh(colliderGeometry, colliderMaterial);
    this.projectsTitleCollider.position.copy(this.projectsTitleModel.all_model[0].position);
    this.projectsTitleCollider.position.add(new THREE.Vector3(0, 13, -2));
    this.scene.add(this.projectsTitleCollider);

    // New visible collider
    const visibleColliderGeometry = new THREE.BoxGeometry(12,12, 12);
    const visibleColliderMaterial = new THREE.MeshBasicMaterial({ color: 0xff0000, transparent: true, opacity: 0.5 ,visible:false});
    this.visibleProjectsCollider = new THREE.Mesh(visibleColliderGeometry, visibleColliderMaterial);
    this.visibleProjectsCollider.position.copy(this.projectsTitleCollider.position);
    this.visibleProjectsCollider.position.y -= 3; 
    this.visibleProjectsCollider.position.z += 0; // Move it in front of the existing collider
    this.scene.add(this.visibleProjectsCollider);
  }

  createLabTitleCollider() {
    // Existing collider
    const colliderGeometry = new THREE.BoxGeometry(12, 11, 12);
    const colliderMaterial = new THREE.MeshBasicMaterial({ visible: false });
    this.labTitleCollider = new THREE.Mesh(colliderGeometry, colliderMaterial);
  
    // Make sure labTitleModel exists before accessing its position
    if (this.labTitleModel && this.labTitleModel.all_model && this.labTitleModel.all_model[0]) {
      this.labTitleCollider.position.copy(this.labTitleModel.all_model[0].position);
      this.labTitleCollider.position.add(new THREE.Vector3(0, -19, -2));
    } else {
      console.warn('labTitleModel not found, setting default position');
      this.labTitleCollider.position.set(0, -19, -2);
    }
    this.scene.add(this.labTitleCollider);

    // New visible collider
    const visibleColliderGeometry = new THREE.BoxGeometry(12, 7, 12);
    const visibleColliderMaterial = new THREE.MeshBasicMaterial({ color: 0x000000, transparent: true, opacity: 0.7,visible:true });
    this.visibleLabCollider = new THREE.Mesh(visibleColliderGeometry, visibleColliderMaterial);
    this.visibleLabCollider.position.copy(this.labTitleCollider.position);
    this.visibleLabCollider.position.z += 0; // Move it in front of the existing collider
    this.scene.add(this.visibleLabCollider);
  }

  // Add this method to handle clicks
  onClick(event) {
   
    if (!this.potentialClick) return;
    console.log('on click');
    const mouse = new THREE.Vector2(
      (event.clientX / window.innerWidth) * 2 - 1,
      -(event.clientY / window.innerHeight) * 2 + 1
    );

    this.raycaster.setFromCamera(mouse, this.newCamera.threeCamera);

    this.collectibles.forEach((collectible) => {
      if (!collectible.collected) {
        const intersects =this. raycaster.intersectObject(collectible.collider);
        if (intersects.length > 0) {
          this.collectCollectible(collectible);
        }
      }
    });
    const objectsToIntersect = [
      this.visibleContactCollider, 
      this.visibleProjectsCollider,
      this.visibleLabCollider
    ].filter(obj => obj !== undefined);

    if (objectsToIntersect.length === 0) {
      console.warn('No valid colliders found for intersection');
      return;
    }

    const intersects = this.raycaster.intersectObjects(objectsToIntersect);

    if (intersects.length > 0) {
      if (intersects[0].object === this.visibleContactCollider) {
        this.showCalendly();
      } else if (intersects[0].object === this.visibleProjectsCollider) {
        this.goToProjectsPage();
      } else if (intersects[0].object === this.visibleLabCollider) {
        this.goToLabPage();
      }
    }
  }
  collectCollectible(collectible) {
    collectible.collected = true;
   
      this.scene.remove( collectible.model);

    this.scene.remove(collectible.collider);
    this.saveCollectibleState();
    console.log(`Collected ${collectible.projectId}`);
    // You can add more effects or logic here when a collectible is collected
  }

  saveCollectibleState() {
    const state = this.collectibles.map(c => ({
      projectId: c.projectId,
      collected: c.collected
    }));
    sessionStorage.setItem('artCodeCollectibles', JSON.stringify(state));
  }

  loadCollectibleState() {
    const savedState = sessionStorage.getItem('artCodeCollectibles');
    if (savedState) {
      const state = JSON.parse(savedState);
      state.forEach(savedCollectible => {
        const collectible = this.collectibles.find(c => c.projectId === savedCollectible.projectId);
        if (collectible && savedCollectible.collected) {
          this.collectCollectible(collectible);
        }
      });
    }
  }
  toggleSound(isOn) {
    if (isOn) {
      AudioManager.unmute();
    } else {
      AudioManager.mute();
    }
  }
  showCalendly() {
    if(!this.isDynamicCameraEnabled)
      return;
    this.isDynamicCameraEnabled = false; // Disable dynamic camera movement

    // Define the target position for the camera
    const targetPosition = new THREE.Vector3(15, -5, -1.5); // Adjust these values as needed

    // Animate the camera to the target position
    gsap.to(this.newCamera.threeCamera.position, {
      x: targetPosition.x,
      y: targetPosition.y,
      z: targetPosition.z,
      duration: 2, // Animation duration in seconds
      ease: "power2.inOut",
      onComplete: () => {
        // After the animation is complete, toggle the Calendly widget
        if (this.toggleCalendly) {
          this.toggleCalendly();
        }
      }
    });

    // Optionally, you can also animate the camera's lookAt point
    const targetLookAt = new THREE.Vector3(40, 27, -10); // Adjust as needed
    gsap.to(this.newCamera.threeCamera.lookAt, {
      x: targetLookAt.x,
      y: targetLookAt.y,
      z: targetLookAt.z,
      duration: 2,
      ease: "power2.inOut"
    });
  }

  goToProjectsPage() {
    this.isDynamicCameraEnabled = false;

    // Define the target position for the camera
    const targetPosition = new THREE.Vector3(20, 9.5, -1.2); // Adjust these values as needed

    // Animate the camera to the target position
    gsap.to(this.newCamera.threeCamera.position, {
      x: targetPosition.x,
      y: targetPosition.y,
      z: targetPosition.z,
      duration: 2, // Animation duration in seconds
      ease: "power2.inOut",
      onComplete: () => {
        // After the animation is complete, navigate to the projects page
        window.location.href = '/projects';
      }
    });

    // Optionally, you can also animate the camera's lookAt point
    const targetLookAt = new THREE.Vector3(0, 0, 0); // Adjust as needed
    gsap.to(this.newCamera.threeCamera.lookAt, {
      x: targetLookAt.x,
      y: targetLookAt.y,
      z: targetLookAt.z,
      duration: 2,
      ease: "power2.inOut"
    });
  }

  goToLabPage() {
    this.isDynamicCameraEnabled = false;

    // Define the target position for the camera
    const targetPosition = new THREE.Vector3(23, -19, -1.2); // Adjust these values as needed

    // Animate the camera to the target position
    gsap.to(this.newCamera.threeCamera.position, {
      x: targetPosition.x,
      y: targetPosition.y,
      z: targetPosition.z,
      duration: 2, // Animation duration in seconds
      ease: "power2.inOut",
      onComplete: () => {
        // After the animation is complete, navigate to the lab page
        window.location.href = '/lab';
      }
    });

    // Optionally, you can also animate the camera's lookAt point
    const targetLookAt = new THREE.Vector3(0, 0, 0); // Adjust as needed
    gsap.to(this.newCamera.threeCamera.lookAt, {
      x: targetLookAt.x,
      y: targetLookAt.y,
      z: targetLookAt.z,
      duration: 2,
      ease: "power2.inOut"
    });
  }

  createCollectibles() {
    const floppyMaterial = new THREE.MeshBasicMaterial({
      map: this.loader.getAsset('floppyBaseColorTexture'),
    });
    const floppyModel = new Model({
      modelLink: 'floppyModel',
      material: floppyMaterial,
      position: new THREE.Vector3(5, 5, 5),
      rotation: new THREE.Vector3(0, Math.random() * Math.PI * 2, 0),
      scale: new THREE.Vector3(5, 5, 5),
      scene: this.scene,
    });
    floppyModel.all_model[0].position.set(0,990,0);
    this.collectiblePositions.forEach((collectibleData) => {
   
      const floppy = floppyModel.all_model[0].clone();
      floppy.name= `collectible_${collectibleData.projectId}`;
      floppy.position.copy(collectibleData.position);
      this.scene.add(floppy);
  
      const colliderSize = new THREE.Vector3(4, 4, 4); // Increased size
      const colliderGeometry = new THREE.BoxGeometry(colliderSize.x, colliderSize.y, colliderSize.z);
      const colliderMaterial = new THREE.MeshBasicMaterial({ visible: false });
      const collider = new THREE.Mesh(colliderGeometry, colliderMaterial);
      collider.position.copy(collectibleData.position);
      this.scene.add(collider);
  
      this.collectibles.push({
        model: floppy, // Store the actual mesh, not the Model instance
        collider: collider,
        projectId: collectibleData.projectId,
        collected: false
      });
    });
  }
  animateCameraToTarget() {
    gsap.to(this.cameraPosition, {
      x: this.targetPosition.x,
      y: this.targetPosition.y,
      z: this.targetPosition.z,
      duration: 3.5,
      ease: "power2.inOut",
      onUpdate: () => {
        this.newCamera.threeCamera.position.copy(this.cameraPosition);
        this.newCamera.threeCamera.lookAt(this.cameraPosition.x-1, this.cameraPosition.y, this.cameraPosition.z);
      },
      onComplete: () => {
        this.isDynamicCameraEnabled = true;
        if (this.onCameraAnimationComplete) {
          this.onCameraAnimationComplete();
        }
      }
    });

    const sunTargetPosition = new THREE.Vector3(-146, 30, -30);
    gsap.to(this.sky.skyMaterial.uniforms.sunPosition.value, {
      x: sunTargetPosition.x,
      y: sunTargetPosition.y,
      z: sunTargetPosition.z,
      duration: 3.5,
      ease: "power2.inOut",
      onUpdate: () => {
        this.sky.skyMaterial.uniforms.sunPosition.value.needsUpdate = true;
      }
    });

    // Animate environment sun position
    if (this.environment && this.environment.bgMaterial && this.environment.bgMaterial.uniforms) {
      gsap.to(this.environment.bgMaterial.uniforms.sunPosition.value, {
        x: sunTargetPosition.x,
        y: sunTargetPosition.y,
        z: sunTargetPosition.z,
        duration: 3.5,
        ease: "power2.inOut",
        onUpdate: () => {
          this.environment.bgMaterial.uniforms.sunPosition.value.needsUpdate = true;
        }
      });
    }
  }

  setEnvironment() {
    this.environment = new Environment(this.scene, this.loader);
  }
  setSky() {
    this.sky = new Sky(this.scene,this.loader);

    // set sky colors
    this.sky.setSkyColors(
      new THREE.Color(0xFDffff),  // Sky blue for top color
      new THREE.Color(0x4d81fa),  // White for bottom color (horizon)
      new THREE.Color(0xFDfdff),  // Sun yellow for sun color
      new THREE.Vector3(-146, -300, -30),
      0.3
    );
  }

  
  createBlinkingLight() {
    this.blinkingLight = new THREE.PointLight(0xff7b00, 100, 100); // Green color, high intensity, short distance
    this.blinkingLight.position.set(-1, 8, -1);
    this.blinkingLight.visible = true;
    this.scene.add(this.blinkingLight);
  }

  updateBlinkingLight(deltaTime) {
    if (this.blinkingLight) {
      const baseIntensity = 10; // Base intensity
      const frequency = 0.01; // Adjust this to change the speed of oscillation
      this.blinkingLight.intensity =   baseIntensity * (Math.cos(frequency * this.totalTime) + 0.5);
    }
  }
  createSceneObjects() {
  // Load smoke texture
  this.smokeTexture = this.loader.getAsset("smokeTexture");
  this.noiseTexture = this.loader.getAsset("noiseTexture");

  // Create smoke emitters
  const smokePositions = [
      new THREE.Vector3(-11, 17, -4),
      new THREE.Vector3(2, -1, 15),
      new THREE.Vector3(-5, -9, 2),
      // Add more positions as needed
  ];

  smokePositions.forEach(position => {
    const emitter = new SmokeEmitter(this.scene, this.smokeTexture,this.noiseTexture);
    emitter.spawn(position, 100); // Set maximum number of particles
    this.smokeEmitters.push(emitter);
});
    this.subtitleTexture = this.loader.getAsset("subtitleTexture");
    this.facadeTexture = this.loader.getAsset("facadeTexture");
    this.f1Texture = this.loader.getAsset("floor1Texture");
    this.whiteTexture = this.loader.getAsset("whiteTexture");
    this.f2Texture = this.loader.getAsset("floor2Texture");
    this.f3Texture = this.loader.getAsset("floor3Texture");
    this.f4Texture = this.loader.getAsset("floor4Texture");
    this.stairTexture = this.loader.getAsset("stairTexture");
    this.noiseTexture = this.loader.getAsset("noiseTexture");

    
    this.flagMaterial = new THREE.ShaderMaterial({
      uniforms: {
        ...FlagMotionShader.uniforms,
        imageTex: { value: this.subtitleTexture },
        time: { value: 0 },
        waveHeight: { value: 0.15},
        waveFrequency: { value: 1.2 },
        waveSpeed: { value: 0.55 },
      },
      vertexShader: FlagMotionShader.vertexShader,
      fragmentShader: FlagMotionShader.fragmentShader,
     
    });

    this.logoMaterial = new THREE.ShaderMaterial({
      uniforms: {
        ...LogoShader.uniforms,
        time: { value: 0 },
        waveHeight: { value: 0.15},
        waveFrequency: { value: 1.2 },
        waveSpeed: { value: 0.55 },
      },
      vertexShader: LogoShader.vertexShader,
      fragmentShader: LogoShader.fragmentShader,
 
    });
    // Calculate the shadow matrix
    const lightDirection = new THREE.Vector3(0.75, -0.5, 0.75).normalize();
    const lightDirection2 = new THREE.Vector3(0.75, 0.5, 0.75).normalize();

    const shadowMatrix = new THREE.Matrix4();
    const shadowProjectionMatrix = new THREE.Matrix4().makeOrthographic(-10, 10, 10, -10, 0.5, 500);
    const shadowViewMatrix = new THREE.Matrix4().lookAt(
      lightDirection.clone().multiplyScalar(-20),
      new THREE.Vector3(0, 0, 0),
      new THREE.Vector3(0, 1, 0)
    );
    shadowMatrix.multiplyMatrices(shadowProjectionMatrix, shadowViewMatrix);

    this.floor1Material = new THREE.ShaderMaterial({
      uniforms: {
        ...ShadowSurfaceShader.uniforms,
        time: { value: 0 },
        lightDirection: { value: lightDirection },
        scrollSpeed: { value: 0.005 },
        shadowScale: { value: 0.075 },
        shadowMap: { value: this.noiseTexture },
        shadowMapSize: { value: new THREE.Vector2(1024, 1024) },
        shadowBias: { value: 0.00005 },
        shadowMatrix: { value: shadowMatrix },
        imageTex: { value: this.f1Texture }
      },
      vertexShader: ShadowSurfaceShader.vertexShader,
      fragmentShader: ShadowSurfaceShader.fragmentShader,
    });
    this.floor2Material = new THREE.ShaderMaterial({
      uniforms: {
        ...ShadowSurfaceShader.uniforms,
        time: { value: 0 },
        lightDirection: { value: lightDirection },
        scrollSpeed: { value: 0.005 },
        shadowScale: { value: 0.075 },
        shadowMap: { value: this.noiseTexture },
        shadowMapSize: { value: new THREE.Vector2(1024, 1024) },
        shadowBias: { value: 0.00005 },
        shadowMatrix: { value: shadowMatrix },
        imageTex: { value: this.f2Texture }
      },
      vertexShader: ShadowSurfaceShader.vertexShader,
      fragmentShader: ShadowSurfaceShader.fragmentShader,
    });

    this.floor3Material = new THREE.ShaderMaterial({
      uniforms: {
        ...ShadowSurfaceShader.uniforms,
        time: { value: 0 },
        lightDirection: { value: lightDirection },
        scrollSpeed: { value: 0.005 },
        shadowScale: { value: 0.075 },
        shadowMap: { value: this.noiseTexture },
        shadowMapSize: { value: new THREE.Vector2(1024, 1024) },
        shadowBias: { value: 0.00005 },
        shadowMatrix: { value: shadowMatrix },
        imageTex: { value: this.f3Texture }
      },
      vertexShader: ShadowSurfaceShader.vertexShader,
      fragmentShader: ShadowSurfaceShader.fragmentShader,
    });

    this.floor4Material = new THREE.ShaderMaterial({
      uniforms: {
        ...ShadowSurfaceShader.uniforms,
        time: { value: 0 },
        lightDirection: { value: lightDirection },
        scrollSpeed: { value: 0.005 },
        shadowScale: { value: 0.075 },
        shadowMap: { value: this.noiseTexture },
        shadowMapSize: { value: new THREE.Vector2(1024, 1024) },
        shadowBias: { value: 0.00005 },
        shadowMatrix: { value: shadowMatrix },
        imageTex: { value: this.f4Texture }
      },
      vertexShader: ShadowSurfaceShader.vertexShader,
      fragmentShader: ShadowSurfaceShader.fragmentShader,
    });

    this.stairMaterial = new THREE.ShaderMaterial({
      uniforms: {
        ...ShadowSurfaceShader.uniforms,
        time: { value: 0 },
        lightDirection: { value: lightDirection },
        scrollSpeed: { value: 0.005 },
        shadowScale: { value: 0.075 },
        shadowMap: { value: this.noiseTexture },
        shadowMapSize: { value: new THREE.Vector2(1024, 1024) },
        shadowBias: { value: 0.00005 },
        shadowMatrix: { value: shadowMatrix },
        imageTex: { value: this.stairTexture }
      },
      vertexShader: ShadowSurfaceShader.vertexShader,
      fragmentShader: ShadowSurfaceShader.fragmentShader,
    });

    
    const facadeTexture = this.loader.getAsset("facadeTexture"); // Assuming you have this texture
    this.facadeMaterial = new THREE.ShaderMaterial({
      uniforms: {
        imageTex: { value: facadeTexture },
        cutoffFactor: { value: 0 },
        time: { value: 0 },
        lightDirection: { value: lightDirection },
        scrollSpeed: { value: 0.005 },
        shadowScale: { value: 0.075 },
        shadowMap: { value: this.noiseTexture },
        shadowMatrix: { value: shadowMatrix },
        shadowColor: { value: new THREE.Color(0x01153d) },
      },
      vertexShader: CutoffShader.vertexShader,
      fragmentShader: CutoffShader.fragmentShader,
      transparent: true,
    });
    this.availableMaterial = new THREE.ShaderMaterial({
      uniforms: {
        imageTex: { value: this.whiteTexture },
        cutoffPosition: { value: 7.0 },
        time: { value: 0 },
        lightDirection: { value: lightDirection },
        lightDirection2: { value: lightDirection2 },
        directionalLightIntensity: { value: 3.5 },
        scrollSpeed: { value: 0.005 },
        shadowScale: { value: 0.075 },
        shadowMap: { value: this.noiseTexture },
        shadowMatrix: { value: shadowMatrix },
        shadowColor: { value: new THREE.Color(0x01153d) },
      },
      vertexShader: HeightCutoffShader.vertexShader,
      fragmentShader: HeightCutoffShader.fragmentShader,
    });
    this.shutterMaterial = new THREE.ShaderMaterial({
      uniforms: {
        imageTex: { value: this.whiteTexture },
        cutoffPosition: { value: 7.0 },
        time: { value: 0 },
        lightDirection: { value: lightDirection },
        lightDirection2: { value: lightDirection2 },
        directionalLightIntensity: { value: 3.5 },
        scrollSpeed: { value: 0.005 },
        shadowScale: { value: 0.075 },
        shadowMap: { value: this.noiseTexture },
        shadowMatrix: { value: shadowMatrix },
        shadowColor: { value: new THREE.Color(0x01153d) },
    
      },
      vertexShader: HeightCutoffShader.vertexShader,
      fragmentShader: HeightCutoffShader.fragmentShader,
      transparent: true,
    });
  
    this.standardContactTitleMaterial = new THREE.ShaderMaterial({
      uniforms: {
        ...ImageMotionShader.uniforms,
        time: { value: 0 },
        imageTex: { value: this.whiteTexture }, // We'll set this later when we have the texture
        touchTexture: { value: this.touchTexture.texture },
        resolution: { value: this.resolution },
        transitionProgress: { value: 0 },
        maxPixelSize: { value: 40 },
        minPixelSize: { value: 1 },
        bottomFadeStart: { value: 0.05 },
        bottomCutStart: { value: 0.05 },
        waveHeight: { value: 0.08 },
        waveFrequency: { value: 1.0 },
        waveSpeed: { value: 0.4 },
        hoverIntensity: { value: 0.05 },
      },
      vertexShader: ImageMotionShader.vertexShader,
      fragmentShader: ImageMotionShader.fragmentShader,
    });
    console.log(this.touchTexture);

    this.shutterModel = new Model({
      modelLink: 'shutterModel',
      material:  this.shutterMaterial,
      position: new THREE.Vector3(0, 0, 0),
      rotation: new THREE.Vector3(0.0, 0.0, 0.0),
      scale: new THREE.Vector3(1, 1, 1),
      scene: this.scene,
      name: 'shutterModel'
    });
    this.shutterModel .all_model[0].position.set(0, 0, 0);

    this.facadeModel = new Model({
      modelLink: 'facadeModel',
      material:  this.facadeMaterial,
      position: new THREE.Vector3(0, 0, 0),
      rotation: new THREE.Vector3(0.0, 0.0, 0.0),
      scale: new THREE.Vector3(1, 1, 1),
      scene: this.scene,
      name: 'facadeModel'
    });
    this.facadeModel.all_model[0].position.set(0, 0, 0);
    this.flagModel = new Model({
      modelLink: 'flagModel',
      material: this.flagMaterial,
      position: new THREE.Vector3(0, 0, 0),
      rotation: new THREE.Vector3(0.0, 0.0, 0.0),
      scale: new THREE.Vector3(1, 1, 1),
      scene: this.scene,
      name: 'flagModel'
    });
    this.flagModel.all_model[0].position.set(-14, 36, -9.4);

    if(this.deviceType === 'phone')
    {
      this.flagModel.all_model[0].scale.set(1.5,1.5,1.5);
    }
    this.logoModel = new Model({
      modelLink: 'logoModel',
      material: this.logoMaterial,
      position: new THREE.Vector3(0, 0, 0),
      rotation: new THREE.Vector3(0.0, 0.0, 0.0),
      scale: new THREE.Vector3(1, 1, 1),
      scene: this.scene,
      name: 'logoModel'
    });
    this.logoModel.all_model[0].position.set(0, 0, 0);
    this.availableModel = new Model({
      modelLink: 'availableModel',
      material: this.availableMaterial,
      position: new THREE.Vector3(0, 0, 0),
      rotation: new THREE.Vector3(0.0, 0.0, 0.0),
      scale: new THREE.Vector3(1, 1, 1),
      scene: this.scene,
      name: 'availableModel'
    });
    this.availableModel.all_model[0].position.set(0, 0, 0);
    
    this.floor1model = new Model({
      modelLink: 'floor1Model',
      material: this.floor1Material,
      position: new THREE.Vector3(0, 0, 0),
      rotation: new THREE.Vector3(0.0, 0.0, 0.0),
      scale: new THREE.Vector3(1, 1, 1),
      scene: this.scene,
      name: 'floor1Model'
    });
    this.floor1model.all_model[0].position.set(0, 0, 0);

    this.floor2model = new Model({
      modelLink: 'floor2Model',
      material: this.floor2Material,
      position: new THREE.Vector3(0, 0, 0),
      rotation: new THREE.Vector3(0.0, 0.0, 0.0),
      scale: new THREE.Vector3(1, 1, 1),
      scene: this.scene,
      name: 'floor2Model' 
    });
    this.floor2model.all_model[0].position.set(0, 0, 0);

    this.floor3model = new Model({
      modelLink: 'floor3Model',
      material: this.floor3Material,
      position: new THREE.Vector3(0, 0, 0),
      rotation: new THREE.Vector3(0.0, 0.0, 0.0),
      scale: new THREE.Vector3(1, 1, 1),
      scene: this.scene,
      name: 'floor3Model'
    });
    this.floor3model.all_model[0].position.set(0, 0, 0);

    this.floor4model = new Model({
      modelLink: 'floor4Model',
      material: this.floor4Material,
      position: new THREE.Vector3(0, 0, 0),
      rotation: new THREE.Vector3(0.0, 0.0, 0.0),
      scale: new THREE.Vector3(1, 1, 1),
      scene: this.scene,
      name: 'floor4Model'
    });
    this.floor4model.all_model[0].position.set(0, 0, 0);

    this.staircaseModel = new Model({
      modelLink: 'stairModel',
      material: this.stairMaterial,
      position: new THREE.Vector3(0, 0, 0),
      rotation: new THREE.Vector3(0.0, 0.0, 0.0),
      scale: new THREE.Vector3(1, 1, 1),
      scene: this.scene,
      name: 'stairModel'
    });
    this.staircaseModel.all_model[0].position.set(0, 0, 0);

    const contactTitleName = 'contactPageTitleModel';
    this.contactTitleModel = new Model({
      modelLink: contactTitleName,
      material: this.standardContactTitleMaterial,
      position: new THREE.Vector3(0, 0, 0),
      rotation: new THREE.Vector3(0.0, 0.0, 0.0),
      scale: new THREE.Vector3(1, 1, 1),
      scene: this.scene,
      name: contactTitleName
    });
    this.contactTitleModel.all_model[0].position.set(0, 0, 0);


    const projectsTitleName = 'projectsPageTitleModel';
    this.projectsTitleModel = new Model({
      modelLink: projectsTitleName,
      material: this.standardContactTitleMaterial,
      position: new THREE.Vector3(0, 0, 0),
      rotation: new THREE.Vector3(0.0, 0.0, 0.0),
      scale: new THREE.Vector3(1, 1, 1),
      scene: this.scene,
      name: projectsTitleName
    });
    this.projectsTitleModel.all_model[0].position.set(0, 0, 0);

    const labTitleName = 'labPageTitleModel';
    this.labTitleModel = new Model({
      modelLink: labTitleName,
      material: this.standardContactTitleMaterial,
      position: new THREE.Vector3(0, 0, 0),
      rotation: new THREE.Vector3(0.0, 0.0, 0.0),
      scale: new THREE.Vector3(1, 1, 1),
      scene: this.scene,
      name: labTitleName
    });
    this.labTitleModel.all_model[0].position.set(0, 0, 0);

    // Store the original position of the title models
    this.contactTitleOriginalPosition = this.contactTitleModel.all_model[0].position.clone();
    this.shutterOriginalPosition = this.shutterModel.all_model[0].position.clone();

    this.projectsTitleOriginalPosition = this.projectsTitleModel.all_model[0].position.clone();
    this.labTitleOriginalPosition = this.labTitleModel.all_model[0].position.clone();
    this.heroOriginalPosition = this.flagModel.all_model[0].position.clone();
   
    this.fullLoaded = true;
  }

  detectDevice() {
    if (navigator.userAgent.match(/Android/i)
      || navigator.userAgent.match(/webOS/i)
      || navigator.userAgent.match(/iPhone/i)
      || navigator.userAgent.match(/iPad/i)
      || navigator.userAgent.match(/iPod/i)
      || navigator.userAgent.match(/BlackBerry/i)
      || navigator.userAgent.match(/Windows Phone/i)
    ) {
      return 'phone';
    } else {
      return 'computer';
    }
  }
  loadAllModels() {


    // render given model
    this.loader.waitForAssets(() => {
      this.createSceneObjects();
    });


  }
  componentDidMount() {
    //AudioManager.loadSounds();

    console.log("scene 1 mounted")
    this.SceneMount();
    //AudioManager.playSound('music');

    this.setPostProcess();
    this. deviceType = this.detectDevice();

    this.newCamera.threeCamera.fov = this.deviceType === 'phone' ? 50 : 30;
  }
  componentWillUnmount() {
    //window.removeEventListener('click', this.onClick.bind(this));
  }
  /******************************************************************************/
  /*!
  \brief  update per frame
  */
  /******************************************************************************/
  loadListener() {
    document.addEventListener("mousemove",
      (e) => {
        this.mouse.x = e.clientX;
        this.mouse.y = e.clientY;
      });
  }

  lerp_model(model, time) {
    if (model && model.all_model && model.all_model.length > 0) {
      for (var i = 0; i < model.all_model.length; ++i) {
        if (model.all_model[i].name.includes("move")) {
          var cosy = Math.cos(time * 0.001 + i * 0.676598) * 0.0125;
          model.all_model[i].position.setY(cosy + model.all_model_og_position[i].y);
        }
      }
    }
  }


  updatePostProcessingSettings({ bloomIntensity, dofFocus, dofAperture, filmGrainIntensity, filmGrainCount, chromaticAberrationAmount }) {
    if (this.postProcess) {
      if (bloomIntensity !== undefined && this.postProcess.bloomPass) {
        this.postProcess.bloomPass.strength = bloomIntensity;
      }

      if (dofFocus !== undefined && this.postProcess.bokehPass) {
        this.postProcess.bokehPass.uniforms['focus'].value = dofFocus;
      }

      if (dofAperture !== undefined && this.postProcess.bokehPass) {
        this.postProcess.bokehPass.uniforms['aperture'].value = dofAperture;
      }

      if (filmGrainIntensity !== undefined && this.postProcess.filmGrainPass) {
        this.postProcess.filmGrainPass.uniforms['intensity'].value = filmGrainIntensity;
      }

      if (filmGrainCount !== undefined && this.postProcess.filmGrainPass) {
        this.postProcess.filmGrainPass.uniforms['count'].value = filmGrainCount;
      }

      if (chromaticAberrationAmount !== undefined && this.postProcess.chromaticAberrationPass) {
        this.postProcess.chromaticAberrationPass.uniforms['amount'].value = chromaticAberrationAmount;
      }
    }
  }
  animateHero(moveUp) {
    const targetY = !moveUp ? 
      this.heroOriginalPosition.y - 8 : // Move up by 8 units
      this.heroOriginalPosition.y + (this.deviceType==='phone' ?2:0) ;      // Move back to original position
    gsap.to(this.flagModel.all_model[0].position, {
      y: targetY,
      duration: 1.5,
      ease: "power2.inout"
    });
  }
  animateContactTitle(moveUp) {
    const targetY = !moveUp ? 
      this.contactTitleOriginalPosition.y - 8: // Move up by 2 units
      this.contactTitleOriginalPosition.y;      // Move back to original position
    gsap.to(this.contactTitleModel.all_model[0].position, {
      y: targetY,
      duration: 0.5,
      ease: "power2.out"
    });
    const targetShutterY = !moveUp ? 
    this.shutterOriginalPosition.y : // Move up by 2 units
    this.shutterOriginalPosition.y+8;      // Move back to original position
    gsap.to(this.shutterModel.all_model[0].position, {
      y: targetShutterY,
      duration: 0.5,
      ease: "power2.out"
    });
  }
  animateProjectsTitle(moveUp) {
    
    const targetY = !moveUp  ? 
      this.projectsTitleOriginalPosition.y - 8 : 
      this.projectsTitleOriginalPosition.y;

    gsap.to(this.projectsTitleModel.all_model[0].position, {
      y: targetY,
      duration: 0.5,
      ease: "power2.out"
    });

    // Animate cutoff factor
    gsap.to(this, {
      cutoffFactor: moveUp ? 1 : 0,
      duration: 0.5,
      ease: "power2.out",
      onUpdate: () => {
        if (this.facadeMaterial) {
          this.facadeMaterial.uniforms.cutoffFactor.value = this.cutoffFactor;
        }
      }
    });
  }

  // Add this method
  animateLabTitle(moveUp) {
    const targetY = !moveUp ? 
      this.labTitleOriginalPosition.y - 8 : // Move up by 8 units
      this.labTitleOriginalPosition.y;      // Move back to original position
    gsap.to(this.labTitleModel.all_model[0].position, {
      y: targetY,
      duration: 0.5,
      ease: "power2.out"
    });
  }

  Update() {
    this.potentialClick = true;
    this.touchTexture.update(this.mouseTouchTexture);

    // Rotate collectibles
    if (this.collectibles) {
      this.collectibles.forEach(collectible => {
        if (!collectible.collected && collectible.model) {
          collectible.model.rotation.y += 0.01; // Reduced rotation speed
        }
      });
    }
     // Raycast for hero collider
     if (this.heroCollider) {
      this.raycaster.setFromCamera(new THREE.Vector2(0, 0), this.newCamera.threeCamera);
      const intersects = this.raycaster.intersectObject(this.heroCollider);
      if (intersects.length > 0) {
        if (!this.isPointingAtColliderHero) {
          this.isPointingAtColliderHero = true;
          this.animateHero(true);
        }
      } else {
        if (this.isPointingAtColliderHero) {
          this.isPointingAtColliderHero = false;
          this.animateHero(false);
        }
      }
    }
    // Raycast for contact title collider
    if(this.contactTitleCollider)
    {
    this.raycaster.setFromCamera(new THREE.Vector2(0, 0), this.newCamera.threeCamera);
    const intersects = this.raycaster.intersectObject(this.contactTitleCollider);
    if (intersects.length > 0) {
      if (!this.isPointingAtCollider) {
        this.isPointingAtCollider = true;
        this.animateContactTitle(true);
      }
    } else {
      if (this.isPointingAtCollider) {
        this.isPointingAtCollider = false;
        this.animateContactTitle(false);
      }
    }
    }
    if (this.projectsTitleCollider && this.isDynamicCameraEnabled) {
      this.raycaster.setFromCamera(new THREE.Vector2(0, 0), this.newCamera.threeCamera);
      const intersects = this.raycaster.intersectObject(this.projectsTitleCollider);
      if (intersects.length > 0) {
        if (!this.isPointingAtColliderProject) {
          this.isPointingAtColliderProject = true;
          this.animateProjectsTitle(true);
        }
      } else {
        if (this.isPointingAtColliderProject) {
          this.isPointingAtColliderProject = false;
          this.animateProjectsTitle(false);
        }
      }
    }
   
    // Raycast for lab title collider
    if (this.labTitleCollider) {
      this.raycaster.setFromCamera(new THREE.Vector2(0, 0), this.newCamera.threeCamera);
      const intersects = this.raycaster.intersectObject(this.labTitleCollider);
      if (intersects.length > 0) {
        if (!this.isPointingAtColliderLab) {
          this.isPointingAtColliderLab = true;
          this.animateLabTitle(true);
        }
      } else {
        if (this.isPointingAtColliderLab) {
          this.isPointingAtColliderLab = false;
          this.animateLabTitle(false);
        }
      }
    }
   // Update smoke emitters
   this.smokeEmitters.forEach(emitter => emitter.update(this.dt));

    this.updateCameraPosition();
    this.updatePostProcessingSettings({
      bloomIntensity: 0.005,
      dofFocus: 100.0,
      dofAperture: 0.0000025,
      filmGrainIntensity: 0.00005,
      filmGrainCount: 4096,
      chromaticAberrationAmount: 0.005
    });
    //update delta time
    var now = Date.now();
    this.dt = now - this.lastUpdate;
    this.lastUpdate = now;
    this.totalTime += this.dt;
    this.updateBlinkingLight(this.dt);

       // Update flag shader time uniform
       if (this.flagMaterial && this.flagMaterial.uniforms) {
        this.flagMaterial.uniforms.time.value += this.dt * 0.0035; // Convert milliseconds to seconds
      }
      // Update logo shader time uniform
      if (this.logoMaterial && this.logoMaterial.uniforms) {
        this.logoMaterial.uniforms.time.value += this.dt * 0.0035; // Convert milliseconds to seconds
      }
      // Update floor1 shader time uniform
      if (this.floor1Material && this.floor1Material.uniforms) {
        this.floor1Material.uniforms.time.value += this.dt * 0.0035; // Convert milliseconds to seconds
      }
      if (this.floor2Material && this.floor2Material.uniforms) {
        this.floor2Material.uniforms.time.value += this.dt * 0.0035; // Convert milliseconds to seconds
      }
      if (this.floor3Material && this.floor3Material.uniforms) {
        this.floor3Material.uniforms.time.value += this.dt * 0.0035; // Convert milliseconds to seconds
      }
      if (this.floor4Material && this.floor4Material.uniforms) {
        this.floor4Material.uniforms.time.value += this.dt * 0.0035; // Convert milliseconds to seconds
      }
      if (this.stairMaterial && this.stairMaterial.uniforms) {
        this.stairMaterial.uniforms.time.value += this.dt * 0.0035; // Convert milliseconds to seconds
      }
      // Update facade shader time uniform
      if (this.facadeMaterial && this.facadeMaterial.uniforms) {
        this.facadeMaterial.uniforms.time.value += this.dt * 0.0035; // Convert milliseconds to seconds
      }
      // Update shutter shader time uniform
      if (this.shutterMaterial && this.shutterMaterial.uniforms) {
        this.shutterMaterial.uniforms.time.value += this.dt * 0.0035; // Convert milliseconds to seconds
      }
      // Update uniforms for the standardContactTitleMaterial
      if (this.standardContactTitleMaterial && this.standardContactTitleMaterial.uniforms) {
        this.standardContactTitleMaterial.uniforms.time.value += this.dt * 0.0035; // Increment time
        this.standardContactTitleMaterial.uniforms.touchTexture.value = this.touchTexture.texture;
        this.standardContactTitleMaterial.uniforms.resolution.value = this.resolution;
      }
      if(this.environment)
        this.environment.update(this.dt);
    this.lerp_model(this.p1model, this.totalTime);

    if(this.water)
    this.water.material.uniforms['time'].value += 1.0 / 60.0  ;

    this.updateParticles();
  }

  createReflectivePlane() {
    const geometry = new THREE.PlaneGeometry(300, 300, 1, 1);

    const waterTexture = this.loader.getAsset("waterNormalTexture");
    waterTexture.repeat.set(40, 40); // Increase the repeat to make the texture smaller
    waterTexture.wrapS = waterTexture.wrapT = THREE.RepeatWrapping;

    // Create the water object
    const water = new Water(geometry, {
        textureWidth: 256,
        textureHeight: 256,
        waterNormals: waterTexture,
        alpha: 1.0,
        sunDirection: new THREE.Vector3(),
        sunColor: 0xffffff,
        waterColor: 0x001e0f,
        distortionScale: 1.0,
        fog: this.scene.fog !== undefined
    });

    water.position.y = -22.5;
    water.rotation.x = -Math.PI / 2;

    //this.water.visible = false; // Initially set water to invisible
    this.scene.add(water);
    this.water = water;
  }

  createParticles() {
    const geometry = new BufferGeometry();
    const dustTexture = this.loader.getAsset("dustTexture");
    const material = new PointsMaterial({
      color: 0xffffff,
      size: 2.5, // Reduced size
      transparent: true,
      opacity: 0.15, // Reduced opacity
      map: dustTexture,
      blending: THREE.AdditiveBlending,
      depthWrite: false, // Disable depth writing
      depthTest: true, // Enable depth testing
    });

    // Initialize particles in random positions
    for (let i = 0; i < this.particleCount * 3; i += 3) {
      this.particlePositions[i] = (Math.random() - 0.5) * 100;
      this.particlePositions[i + 1] = (Math.random() - 0.5) * 100;
      this.particlePositions[i + 2] = (Math.random() - 0.5) * 100;

      // Initialize velocities
      this.particleVelocities[i] = (Math.random() - 0.5) * 0.1;
      this.particleVelocities[i + 1] = (Math.random() - 0.5) * 0.1;
      this.particleVelocities[i + 2] = (Math.random() - 0.5) * 0.1;
    }

    geometry.setAttribute('position', new BufferAttribute(this.particlePositions, 3));
    this.particles = new Points(geometry, material);
    this.scene.add(this.particles);
  }

  updateParticles() {
    if(!this.particles) return;
    const positions = this.particles.geometry.attributes.position.array;

    for (let i = 0; i < positions.length; i += 3) {
      // Update positions based on velocities
      positions[i] += this.particleVelocities[i];
      positions[i + 1] += this.particleVelocities[i + 1];
      positions[i + 2] += this.particleVelocities[i + 2];

      // Wrap particles around if they go out of bounds
      if (Math.abs(positions[i]) > 50) positions[i] *= -0.9;
      if (Math.abs(positions[i + 1]) > 50) positions[i + 1] *= -0.9;
      if (Math.abs(positions[i + 2]) > 50) positions[i + 2] *= -0.9;

      // Reduce the random movement
      this.particleVelocities[i] += (Math.random() - 0.5) * 0.005;
      this.particleVelocities[i + 1] += (Math.random() - 0.5) * 0.005;
      this.particleVelocities[i + 2] += (Math.random() - 0.5) * 0.005;

      // Increase damping
      this.particleVelocities[i] *= 0.98;
      this.particleVelocities[i + 1] *= 0.98;
      this.particleVelocities[i + 2] *= 0.98;
    }

    this.particles.geometry.attributes.position.needsUpdate = true;
  }

  resetCamera() {
    const currentPosition = this.newCamera.threeCamera.position.clone();

    gsap.to(this.newCamera.threeCamera.position, {
      x: this.cameraPosition.x,
      y: this.cameraPosition.y,
      z: this.cameraPosition.z,
      duration: 2,
      ease: "power2.inOut",

      onComplete: () => {
        this.isDynamicCameraEnabled = true;
        //this.targetPosition.copy(this.initialTargetPosition);
        //this.cameraPosition.copy(this.initialCameraPosition);
      }
    });
  }

  getCameraPosition() {
    return this.newCamera.threeCamera.position.clone();
  }

  setCameraPosition(position) {
    if (position) {
      this.newCamera.threeCamera.position.copy(position);
      this.cameraPosition.copy(position);
      this.targetPosition.copy(position);
      this.isDynamicCameraEnabled = false; // Disable dynamic camera movement
    }
  }

  saveCameraPosition() {
    const position = this.newCamera.threeCamera.position;
    sessionStorage.setItem('artCodeCameraPosition', JSON.stringify({
      x: position.x,
      y: position.y,
      z: position.z
    }));
  }

  loadCameraPosition() {
    const savedPosition = sessionStorage.getItem('artCodeCameraPosition');
    if (savedPosition) {
      const position = JSON.parse(savedPosition);
      this.setCameraPosition(new THREE.Vector3(position.x, position.y, position.z));
      sessionStorage.removeItem('artCodeCameraPosition'); // Clear the saved position after using it
    }
  }

  setOnCameraMove(callback) {
    this.onCameraMove = callback;
  }

  setWaterVisibility(isVisible) {
    if (this.water) {
      this.water.visible = isVisible;
    }
  }
}

export default ArtCode
