import * as THREE from "three";

const COLLISION_LAYER = 1;

class EnemyPoint {
    constructor(scene, model, time) {
        this.scene = scene;
        this.model = model;
        this.time = time;
        this.health = 100;
        this.initialHeight = 60;
        this.destroyed = false;
        this.hitboxSize = new THREE.Vector3(3, 3, 3);
        this.runTime = 0.0;
        this.pathIndex = 0;
        this.index = 0;
        this.followPath = true;
        this.canDespawn = false;

        this.currPoint = new THREE.Vector3(0, this.initialHeight, 0);
        this.currPointLookAt = new THREE.Vector3(0, this.initialHeight, 0);
        this.lerpedPoint = new THREE.Vector3(0, this.initialHeight, 0);
        this.lerpedPointLookAt = new THREE.Vector3(0, this.initialHeight, 0);
        this.pointTarget = new THREE.Vector3(0, 0, 0);

        // Create hitbox
        this.hitbox = new THREE.Mesh(
            new THREE.BoxGeometry(this.hitboxSize.x, this.hitboxSize.y, this.hitboxSize.z),
            new THREE.MeshBasicMaterial({ color: 0xff0000, visible: true })
        );
        this.hitbox.position.copy(this.currPoint);
        this.hitbox.layers.set(COLLISION_LAYER);
        scene.add(this.hitbox);
    }

    // Method to apply damage to the enemy
    takeDamage(amount) {
        this.health -= amount;
        if (this.health <= 0) {
            this.destroyed = true;
        }
    }

    // Method to destroy the enemy and remove it from the scene
    destroy() {
        this.destroyed = true;
        this.scene.remove(this.model);
        this.scene.remove(this.hitbox);
    }

    // Method to update the enemy's position
    updatePosition() {
        if (this.model && this.followPath) {
            this.model.position.copy(this.lerpedPoint);
            this.model.lookAt(this.lerpedPointLookAt);
        }
        this.hitbox.position.copy(this.model.position);
    }

    // Method to update the enemy
    update(index) {
        this.updatePath(index);
        this.updateLerpedPositions();
        this.updatePosition();
    }

    // Method to update the enemy's path (to be overridden in child classes)
    updatePath(index) {
        // This method should be overridden in child classes
    }

    // Method to update the lerped positions
    updateLerpedPositions() {
        this.lerpedPoint.lerp(this.currPoint, 0.05);
        this.lerpedPointLookAt.lerp(this.currPointLookAt, 0.055);
    }
}

export default EnemyPoint;