import React, { useState, useRef, useEffect } from 'react';
import { AppBar, Button, Grid, Slide, ThemeProvider, createTheme, useScrollTrigger, Box, Typography, Paper, IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import gsap from 'gsap';
import '../Home.css';

import Topbar from '../../components/TopBar';
import Container from '@mui/material/Container';


import Bottombar from '../../components/BottomBar';
import LoadingManager from '../../Render/LoadingManager';
import FlareIcon from '@mui/icons-material/Flare';
import TouchAppIcon from '@mui/icons-material/TouchApp';
import CardMedia from '@mui/material/CardMedia';
import voyageCode from '../example/voyageExample';

import { useMediaQuery } from '@mui/material';
import Voyage from '../../components/Scenes/Voyage/Voyage';
import AudioManager from '../../components/Scenes/ArtCode/AudioManager';
import { getAudioState, setAudioState, toggleAudioState } from '../../utils/audioState';

const HideOnScroll = ({ children }: any) => {
  const trigger = useScrollTrigger();
  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
};

export enum ContentPanel {
  CONTENT_STAGE_HIDDEN,
  CONTENT_STAGE_PART_1,
  CONTENT_STAGE_PART_2,
  CONTENT_STAGE_PART_3
}

const VoyagePage: React.FC = () => {
  const [contentValue, setContentValue] = useState<ContentPanel>(ContentPanel.CONTENT_STAGE_HIDDEN);
  const [infoValue, setInfoValue] = useState<ContentPanel>(ContentPanel.CONTENT_STAGE_HIDDEN);
  const [buttonInfo, setButtonInfo] = useState<string>('main');
  const [showOverlay, setShowOverlay] = useState(false);
  const [showButtons, setShowButtons] = useState(false);
  const [showInfoPopup, setShowInfoPopup] = useState(false);
  const [showCodePopup, setShowCodePopup] = useState(false);
  const [isSectionActive, setIsSectionActive] = useState(false);
  const [hoveredPlanetText, setHoveredPlanetText] = useState<string | null>(null);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [hoveredPlanetIndex, setHoveredPlanetIndex] = useState<number | null>(null);
  const [hoveredPlanetVideoPath, setHoveredPlanetVideoPath] = useState<string | null>(null);
  const [showVideo, setShowVideo] = useState(false);
  const [isSoundOn, setIsSoundOn] = useState(getAudioState());

  // Capture mouse movement to update the position of the planet text box
  useEffect(() => {
    const handleMouseMove = (event: MouseEvent) => {
      setMousePosition({ x: event.clientX, y: event.clientY });
    };

    window.addEventListener('mousemove', handleMouseMove);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  // Listen for hover events from the Voyage component
  useEffect(() => {
    if (voyageRef.current) {
      voyageRef.current.setHoverCallback((text: string | null, index: number, videoPath: string | null) => {
        //setHoveredPlanetText(text);
        //setHoveredPlanetIndex(index);
        //setHoveredPlanetVideoPath(videoPath);
      });
    }
  }, []);

  // Function to create a glitch effect on text
  const useGlitchEffect = (text: string | null) => {
    const [displayedText, setDisplayedText] = useState('');

    useEffect(() => {
      if (text === null || text === undefined) {
        setDisplayedText('');
        return;
      }

      const charTimers: NodeJS.Timeout[] = [];
      let isComponentMounted = true;

      const glitchChar = (index: number) => {
        if (index >= text.length) {
          return;
        }

        let isGlitching = true;
        const glitchTimer = setInterval(() => {
          if (isComponentMounted) {
            setDisplayedText(prev => {
              const newChars = prev.split('');
              if (isGlitching) {
                newChars[index] = String.fromCharCode(65 + Math.floor(Math.random() * 26));
              } else {
                newChars[index] = text[index];
                clearInterval(glitchTimer);
                setTimeout(() => glitchChar(index + 1), 6);
              }
              return newChars.join('');
            });
          }
          isGlitching = !isGlitching;
        }, 15);

        charTimers.push(glitchTimer);
      };

      if (text && text.length > 0) {
        glitchChar(0);
      }

      return () => {
        isComponentMounted = false;
        charTimers.forEach(timer => clearTimeout(timer));
      };
    }, [text]);

    return displayedText;
  };
  const toggleSound = () => {
    const newState = toggleAudioState();
    setIsSoundOn(newState);
  };
  // Component to display the hovered planet's name and video
  const PlanetTextBox = () => {
    const displayedText = useGlitchEffect(hoveredPlanetText);
    const planetIndex = hoveredPlanetIndex;
    const chapterText = planetIndex ? `Chapter ${planetIndex}` : '';
    const displayedChapterText = useGlitchEffect(hoveredPlanetText ? chapterText : null);
    const [videoError, setVideoError] = useState<string | null>(null);
    const chapterImage = planetIndex !== null && voyageRef.current?.imageSettings[planetIndex - 1]?.chapterImage || '';

    useEffect(() => {
      if (displayedText === hoveredPlanetText && displayedChapterText === chapterText) {
      }
    }, [displayedText, displayedChapterText, hoveredPlanetText, chapterText]);


    useEffect(() => {
      setShowVideo(false);
    }, [hoveredPlanetText]);

    if (hoveredPlanetText === null) return null;

    return (
   
          <Box
            sx={{
              position: 'fixed',
              left: mousePosition.x + 20,
              top: mousePosition.y + 20,
              bgcolor: 'white',
              color: 'black',
              padding: '10px',
              borderRadius: '5px',
              zIndex: 1000,
              pointerEvents: 'none',
              maxWidth: '300px',
            }}
          >
                 <Box sx={{ position: 'relative' }}>
        
            <Typography 
              variant="body2" 
              sx={{ 
                fontFamily: 'Akira, sans-serif',
              }}
            >
              {displayedText}
            </Typography>
            <Typography 
              variant="body2" 
              sx={{ 
                fontFamily: 'Roboto Mono, monospace',
                marginTop: '5px',
              }}
            >
              {displayedChapterText}
            </Typography>
            {chapterImage && (
              <Box
                sx={{
                  position: 'relative',
                  width: '200px',
                  height: '100px',
                  overflow: 'hidden',
                  marginTop: '10px',
                  borderRadius: '3px',
                }}
              >
                <CardMedia
                  component="img"
                  image={chapterImage}
                  alt={`Chapter ${planetIndex} image`}
                  sx={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    animation: 'slideInFromLeft 0.5s ease-out',
                    '@keyframes slideInFromLeft': {
                      '0%': {
                        transform: 'translateX(-100%)',
                      },
                      '100%': {
                        transform: 'translateX(0)',
                      },
                    },
                  }}
                />
              </Box>
            )}
          </Box>
        </Box>
    );
  };

  const voyageRef = useRef<any>(null);

  const theme = createTheme({
    palette: {
      primary: {
        main: '#FFFFFF',
        contrastText: '#000000',
      },
      secondary: {
        main: '#000000'
      },
    }
  });

  const buttonColors = {
    fireworksRight: '#00000011',
    parachute: '#00000011',
    helicopter: '#00000011',
    p3: '#00000011',
    p4: '#00000011',
  };

  const OpenContent = (content: ContentPanel) => {
    setContentValue(content);
  }

  const CloseContent = () => {
    setInfoValue(ContentPanel.CONTENT_STAGE_HIDDEN);
    OpenContent(ContentPanel.CONTENT_STAGE_HIDDEN);
  }

  const toggleOverlay = () => {
    setShowOverlay(!showOverlay);
  }

  const handleLeftClick = () => {
    if (voyageRef.current) {
      voyageRef.current.changeImage('prev');
    }
  };

  const handleRightClick = () => {
    if (voyageRef.current) {
      voyageRef.current.changeImage('next');
    }
  };
  const handleButtonClick = (action: string) => {
    if (voyageRef.current && voyageRef.current[action]) {
      voyageRef.current[action]();
    }
  };
  const handleInfoButtonClick = () => {
    setShowInfoPopup(true);
    setIsSectionActive(true);
    if (voyageRef.current) {
      voyageRef.current.setSectionActive(true); // Update the state within Voyage
    }
  };
  
  const handleCodeButtonClick = () => {
    setShowCodePopup(true);
    setIsSectionActive(true);
    if (voyageRef.current) {
      voyageRef.current.setSectionActive(true); // Update the state within Voyage
    }
  };
  
  const closePopup = () => {
    setShowInfoPopup(false);
    setShowCodePopup(false);
    setIsSectionActive(false);
    if (voyageRef.current) {
      voyageRef.current.setSectionActive(false); // Reset the state within Voyage
    }
  };
  useEffect(() => {
    console.log('isSectionActive changed:', isSectionActive);
  }, [isSectionActive]);
  useEffect(() => {
    if (voyageRef.current) {
      voyageRef.current.setButtonVisibilityCallback(() => {
        setShowButtons(true);
        const buttons = document.querySelectorAll('.action-button');
        buttons.forEach((button, index) => {
          gsap.fromTo(button, { scale: 0 }, { scale: 1, duration: 0.5, delay: index * 0.1 });
        });
      });
    }
  }, []);
  const [code, setCode] = useState('');

  useEffect(() => {
    // Load the code content when the component mounts
    setCode(voyageCode);
  }, []);

  const isLandscape = useMediaQuery('(orientation: landscape)');

  useEffect(() => {
    if (!isSectionActive && isLandscape) {
      // Animate the "Voyage Galactic Map" box
      gsap.fromTo(
        "#galactic-map-box",
        { y: -50, opacity: 0 },
        { 
          y: 0, 
          opacity: 1, 
          duration: 1, 
          ease: "bounce.out",
          delay: 0.5
        }
      );

      // Animate the "Drag to Move Map" box
      gsap.fromTo(
        "#drag-map-box",
        { y: -30, opacity: 0 },
        { 
          y: 0, 
          opacity: 1, 
          duration: 1, 
          ease: "bounce.out",
          delay: 1
        }
      );
    }
  }, [isSectionActive, isLandscape]);

  const handleBackClick = () => {
    window.location.href = '/artcode';
  };

  useEffect(() => {
    // Initialize audio state when component mounts
    setAudioState(isSoundOn);
  }, []);

  return (
    <div className="pageGlobal">
        <PlanetTextBox />
      <LoadingManager />

      <Grid container style={{ position: 'fixed', zIndex: -1 }}>
      <Voyage
  ref={voyageRef}
/>
      </Grid>
      <HideOnScroll>
      <AppBar
                  position={'fixed'}
                  sx={{
                    boxShadow: 'none',
                    backgroundColor: '#00000000',
                    zIndex: 1100, // Make sure this is lower than the sound button's z-index
                  }}
                >
                  <Container  sx={{ py: { xs: 2, sm: 4 } }}>
                  <Topbar artcodeColor="#ffffff" isSoundOn={isSoundOn} toggleSound={toggleSound} />
                  </Container>
                </AppBar>
      </HideOnScroll>

 
      <AppBar position={'fixed'} sx={{ top: 'auto', bottom: 0, boxShadow: 'none', backgroundColor: '#00000000' }}>
        <Bottombar />
      </AppBar>

      {/* Box outline overlay */}
      <Box
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          border: '2px solid rgba(255, 255, 255, 0.1)',
          margin: '2.5%',
          pointerEvents: 'none',
          zIndex: 9999,
          borderRadius: '20px', // Added border radius
          animation: 'pulse2 2s infinite', // Pulsing animation
          '@keyframes pulse2': {
            '0%': { 
              boxShadow: '0 0 15px 8px rgba(255, 255, 255, 0.02)',
              filter: 'brightness(1)'
            },
            '50%': { 
              boxShadow: '0 0 25px 12px rgba(255, 255, 255, 0.05)',
              filter: 'brightness(1.2)'
            },
            '100%': { 
              boxShadow: '0 0 15px 8px rgba(525, 255, 225, 0.02)',
              filter: 'brightness(1)'
            },
          },
        }}
      />

      <Grid container item direction="column" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%', overflowY: 'scroll' }}>
      {!isSectionActive  && (
  <>
  
    <Box
      id="drag-map-box"
      sx={{
        position: 'fixed',
        top: 'calc(10% + 40px)',
        left: '10%',
        zIndex: 2,
        backgroundColor: 'rgba(255, 255, 255, 0)',
        padding: '8px 16px',
        borderRadius: '8px',
      }}
    >
      <Typography
        variant="h4"
        sx={{
          color: '#ffffff',
          display: 'flex',
          alignItems: 'center',
          fontSize: '0.75rem',
          fontWeight: '100',
          fontFamily: 'roboto-mono',
        }}
      >
        <TouchAppIcon
          sx={{
            width: 20,
            height: 20,
            marginRight: 1,
          }}
        />
        EXPLORE EXPERIMENTS THAT PUSHES WEB BOUNDARIES
      </Typography>
    </Box>
    
  </>
)}

        {infoValue === ContentPanel.CONTENT_STAGE_HIDDEN && contentValue !== ContentPanel.CONTENT_STAGE_HIDDEN &&
          <Button
            variant='contained'
            onClick={() => {
              setInfoValue(contentValue);
              setContentValue(ContentPanel.CONTENT_STAGE_HIDDEN);
            }}
            sx={{
              position: 'fixed',
              justifyContent: 'center',
              alignItems: 'center',
              bottom: 100,
              mb: '30px',
              height: '40px',
              fontFamily: 'DMSans',
              fontSize: '1.0rem',
              fontWeight: '100',
              color: '#000000',
              backgroundColor: '#ffffff',
              borderRadius: '30px',
              boxShadow: 'none',
            }}>
            Click to see {buttonInfo}
          </Button>
        }
     
        <Box component="footer" sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, textAlign: 'center', padding: 2 }}>
        </Box>
      </Grid>

      {showButtons && !isSectionActive && (
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
          sx={{
            position: 'fixed',
            top: '80%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 1,
          }}
        >
        </Grid>
      )}
    </div>
  );
};

export default VoyagePage;
