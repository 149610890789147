import * as THREE from 'three';

class OrbitalCameraGame {
    constructor(scene, camera, target = new THREE.Vector3(0, 0, 0)) {
        this.scene = scene;
        this.camera = camera;
        this.target = target;

        this.recoilOffset = new THREE.Vector3();
        this.currentPosition = new THREE.Vector3();
        this.currentLookAt = new THREE.Vector3();
        this.spherical = new THREE.Spherical();
        this.sphericalDelta = new THREE.Spherical();
        this.mouseNormalized = new THREE.Vector2();
        this.initialCameraPosition = new THREE.Vector3(0, 0, 0);

        this.lerpFactor = 0.1;
        this.currentLerpFactor = 0.05;
        this.lerpSmoothingFactor = 0.05;
        this.rotateX = 0;
        this.rotateY = 0;
        this.keyboardRotateSpeed = 0.5;
        this.keyboardInput = { up: false, down: false, left: false, right: false };

        // Add event listeners for keydown and keyup
        window.addEventListener('keydown', this.handleKeyDown.bind(this));
        window.addEventListener('keyup', this.handleKeyUp.bind(this));
    }

    // Update the normalized mouse position
    updateMouseNormalized(x, y) {
        this.mouseNormalized.set(x, y);
    }

    // Update the target position with double lerp
    updateTargetWithDoubleLerp(averagePosition, recoilOffset) {
        this.recoilOffset.copy(recoilOffset);
        const targetPosition = averagePosition.clone().add(recoilOffset);
        this.currentLerpFactor = THREE.MathUtils.lerp(this.currentLerpFactor, this.lerpFactor, this.lerpSmoothingFactor);
        this.target.lerp(targetPosition, this.currentLerpFactor);
    }

    // Set the camera position
    setPosition(x, y, z) {
        this.currentPosition.set(x, y, z);
        this.camera.position.copy(this.currentPosition);
        this.initialCameraPosition.copy(this.currentPosition);
    }

    // Update the target based on active enemies
    updateTarget(activeEnemies) {
        if (activeEnemies.length > 0) {
            const averagePosition = new THREE.Vector3();
            let validEnemyCount = 0;

            activeEnemies.forEach(enemy => {
                if (enemy && enemy.position) {
                    averagePosition.add(enemy.position);
                    validEnemyCount++;
                }
            });

            if (validEnemyCount > 0) {
                averagePosition.divideScalar(validEnemyCount);
                this.target.lerp(averagePosition, this.lerpFactor);
            }
        }
    }

    // Update the camera position and target
    update(time, recoilOffset) {
        const offsetY = Math.cos(time * 0.0005) * 0.5;
        const targetPosition = this.target.clone();
        targetPosition.y += offsetY;

        this.currentLookAt.lerp(targetPosition, this.lerpFactor);
        this.camera.lookAt(this.currentLookAt);

        this.updateCamera(this.scene.waveManager);

        // Reset camera position to initial position
        this.camera.position.lerp(this.initialCameraPosition, 0.1);
    }

    // Update the camera based on input and wave manager
    updateCamera(waveManager) {
        const threshold = 0.4;
        const rotateSpeed = 0.2;
        const rotationLerpFactor = 0.1;

        this.targetRotateX = 0;
        this.targetRotateY = 0;

        if (this.mouseNormalized.x < -threshold) {
            this.targetRotateY = -rotateSpeed;
        } else if (this.mouseNormalized.x > threshold) {
            this.targetRotateY = rotateSpeed;
        }
        if (this.mouseNormalized.y < -threshold) {
            this.targetRotateX = rotateSpeed;
        } else if (this.mouseNormalized.y > threshold) {
            this.targetRotateX = -rotateSpeed;
        }

        // Apply keyboard input
        this.applyKeyboardInput();

        // Lerp the rotation
        this.rotateX = THREE.MathUtils.lerp(this.rotateX, this.targetRotateX, rotationLerpFactor);
        this.rotateY = THREE.MathUtils.lerp(this.rotateY, this.targetRotateY, rotationLerpFactor);

        // Calculate the direction from camera to target
        const direction = new THREE.Vector3().subVectors(this.target, this.camera.position).normalize();

        // Calculate the right vector
        const right = new THREE.Vector3().crossVectors(direction, this.camera.up).normalize();

        // Move the target
        this.target.add(right.multiplyScalar(this.rotateY));
        this.target.add(this.camera.up.clone().multiplyScalar(this.rotateX));

        // Clamp the target's vertical position to avoid extreme angles
        this.target.y = THREE.MathUtils.clamp(this.target.y, -10, 20);
        this.target.x = THREE.MathUtils.clamp(this.target.x, -5, 8);

        if (waveManager.activeEnemies.length > 0) {
            this.updateTargetWithDoubleLerp(this.target, this.recoilOffset);
        } else {
            this.updateTargetWithDoubleLerp(this.target, new THREE.Vector3());
        }
    }

    // Set the mouse position
    setMousePosition(x, y) {
        this.mouseNormalized.set(x, y);
    }

    // Apply keyboard input to adjust the target position
    applyKeyboardInput() {
        if (this.keyboardInput.up) {
            this.target.y += this.keyboardRotateSpeed;
        }
        if (this.keyboardInput.down) {
            this.target.y -= this.keyboardRotateSpeed;
        }
        if (this.keyboardInput.left) {
            this.target.x += this.keyboardRotateSpeed;
        }
        if (this.keyboardInput.right) {
            this.target.x -= this.keyboardRotateSpeed;
        }
    }

    // Handle key down events
    handleKeyDown(event) {
        switch (event.key.toLowerCase()) {
            case 'w':
            case 'arrowup':
                this.keyboardInput.up = true;
                break;
            case 's':
            case 'arrowdown':
                this.keyboardInput.down = true;
                break;
            case 'a':
            case 'arrowleft':
                this.keyboardInput.left = true;
                break;
            case 'd':
            case 'arrowright':
                this.keyboardInput.right = true;
                break;
        }
    }

    // Handle key up events
    handleKeyUp(event) {
        switch (event.key.toLowerCase()) {
            case 'w':
            case 'arrowup':
                this.keyboardInput.up = false;
                break;
            case 's':
            case 'arrowdown':
                this.keyboardInput.down = false;
                break;
            case 'a':
            case 'arrowleft':
                this.keyboardInput.left = false;
                break;
            case 'd':
            case 'arrowright':
                this.keyboardInput.right = false;
                break;
        }
    }
}

export default OrbitalCameraGame;
