import * as THREE from 'three';
import * as CANNON from 'cannon-es';
import gsap from 'gsap';

class PhysicsSystem {
  constructor(scene) {
    this.scene = scene;
    this.world = null;
    this.bodies = [];
    this.meshes = [];
    this.draggedBody = null;
    this.dragConstraint = null;
    this.dragPlane = {
      normal: new CANNON.Vec3(0, 1, 0),
      constant: 0
    };
    this.dragPoint = new CANNON.Vec3();
    this.dragDistance = 0;
    this.boundary = {
      min: new CANNON.Vec3(-10, 0, 0),
      max: new CANNON.Vec3(10, 10, 10)
    };
    this.boundaryRestitution = 0.5; // Adjust this value to control the bounciness
    this.collisionBox = null;
    this.selectedObject = null;
    this.raycaster = new THREE.Raycaster();
    this.mouse = new THREE.Vector2();
    this.hoveredMesh = null;
    this.originalMaterials = new Map();

    // Add event listener for window resize
    window.addEventListener('resize', this.updateBoundarySize.bind(this));

    // Update boundary size initially
    this.updateBoundarySize();
  }

  initPhysics() {
    this.world = new CANNON.World({
      gravity: new CANNON.Vec3(0, -9.82 * 2.0, 0)
    });
  }

  createFloor(size, position) {
    // Three.js mesh
    const geometry = new THREE.PlaneGeometry(size.x, size.y);
    const material = new THREE.MeshPhongMaterial({ color: 0xcccccc });
    const mesh = new THREE.Mesh(geometry, material);
    mesh.rotation.x = -Math.PI / 2; // Rotate the floor to be horizontal
    mesh.position.copy(position);
   // this.scene.add(mesh);

    // Cannon.js body
    const shape = new CANNON.Plane();
    const body = new CANNON.Body({
      mass: 0, // Static body
      shape: shape,
      position: new CANNON.Vec3(position.x, position.y, position.z)
    });
    body.quaternion.setFromAxisAngle(new CANNON.Vec3(1, 0, 0), -Math.PI / 2); // Rotate to match Three.js mesh

    this.world.addBody(body);

    return { mesh, body };
  }
  createModel(mesh, size,collision_size, pos, mass) {
    // Three.js mesh
    mesh.position.copy(pos);
    mesh.scale.copy(size);
    this.scene.add(mesh);

    // Cannon.js body
    const shape = new CANNON.Box(new CANNON.Vec3(
      collision_size.x * 0.5,
      collision_size.y * 0.5,
      collision_size.z * 0.5
    ));
    const body = new CANNON.Body({
      mass: mass,
      shape: shape,
      position: new CANNON.Vec3(pos.x, pos.y, pos.z)
    });

    this.world.addBody(body);
    this.bodies.push(body);
    this.meshes.push(mesh);

    // Store the original material
    this.originalMaterials.set(mesh, mesh.material.clone());

    return { mesh, body };
  }

  createCollisionBox(size, position) {
    const geometry = new THREE.BoxGeometry(size.x, size.y, size.z);
    const material = new THREE.MeshPhongMaterial({ color: 0x888888 });
    const mesh = new THREE.Mesh(geometry, material);
    mesh.position.copy(position);
    //this.scene.add(mesh);

    const shape = new CANNON.Box(new CANNON.Vec3(size.x * 0.5, size.y * 0.5, size.z * 0.5));
    const body = new CANNON.Body({
      mass: 0, // Static body
      shape: shape,
      position: new CANNON.Vec3(position.x, position.y, position.z)
    });

    this.world.addBody(body);
    this.collisionBox = { mesh, body };
  }

  setDragPlane(y) {
    this.dragPlane.normal.set(0, 1, 0);
    this.dragPlane.constant = -y;
  }

  startDrag(bodyIndex) {
    const body = this.bodies[bodyIndex];
    if (body) {
      this.draggedBody = body;
      
      // Create a temporary static body at the dragged body's position
      const tempBody = new CANNON.Body({ mass: 0 });
      tempBody.position.copy(body.position);
      this.world.addBody(tempBody);

      // Create a spring constraint between the dragged body and the temporary body
      this.dragConstraint = new CANNON.Spring(body, tempBody, {
        stiffness: 50,
        damping: 5,
        localAnchorA: new CANNON.Vec3(0, 0, 0),
        localAnchorB: new CANNON.Vec3(0, 0, 0)
      });

    }
  }

  moveDrag(position) {
    if (this.draggedBody && this.dragConstraint) {
      // Move the temporary body to the new position
      this.dragConstraint.bodyB.position.copy(position);
      
    }
  }

  endDrag(mousePosition, camera,callback) {
    if (this.draggedBody) {
      // Remove the spring constraint and the temporary body
      this.world.removeConstraint(this.dragConstraint);
      this.world.removeBody(this.dragConstraint.bodyB);
      this.dragConstraint = null;
      this.selectedObject = this.draggedBody;
      this.draggedBody = null;

      // Check if mouse is over collision box
      if (this.isMouseOverCollisionBox(mousePosition, camera)) {
        this.animateSelectedObject(callback);
      } else {
        this.selectedObject = null;
      }
    }
  }

  isMouseOverCollisionBox(mousePosition, camera) {
    if (!this.collisionBox) return false;

    const raycaster = new THREE.Raycaster();
    raycaster.setFromCamera(mousePosition, camera);

    const intersects = raycaster.intersectObject(this.collisionBox.mesh);
    return intersects.length > 0;
  }

  animateSelectedObject(callback) {
    if (this.selectedObject && this.collisionBox) {
      const objectMesh = this.meshes[this.bodies.indexOf(this.selectedObject)];
      
      // First target position: In front of the collision box
      const targetPosition1 = new THREE.Vector3().copy(this.collisionBox.mesh.position);
      targetPosition1.z += this.collisionBox.mesh.scale.x * 13.5;
      targetPosition1.x -= 0.25;

      // Second target position: Inside the collision box
      const targetPosition2 = new THREE.Vector3().copy(this.collisionBox.mesh.position);
      targetPosition2.z += this.collisionBox.mesh.scale.x *5.5;
      targetPosition2.y += 0.5;
      targetPosition2.x -= 0.25;

      // Update the Cannon.js body position to match the mesh
      gsap.to(this.selectedObject.position, {
        duration: 0.75, // Total duration of both animations
        x: targetPosition1.x,
        y: targetPosition1.y,
        z: targetPosition1.z,
        ease: "power2.inOut",
        onComplete: () => {
          // Second animation: Move inwards
          gsap.to(this.selectedObject.position, {
            duration: 0.5,
            x: targetPosition2.x,
            y: targetPosition2.y,
            z: targetPosition2.z,
            ease: "power2.in",
            onComplete: () => {
              if (callback) 
                callback(this.selectedObject.userData.projectId);
            }
          });
        }
      });
      // Animate rotation to final position (90 degrees around x-axis and 90 degrees around y-axis)
      const finalRotation = new CANNON.Quaternion();
      finalRotation.setFromEuler(-Math.PI / 2, -Math.PI / 2, 0, 'XYZ');

      gsap.to(this.selectedObject.quaternion, {
        duration: 0.5, // Match the duration of the position animation
        x: finalRotation.x,
        y: finalRotation.y,
        z: finalRotation.z,
        w: finalRotation.w,
        ease: "power2.inOut",
        onUpdate: () => {
          // Update the Cannon.js body rotation
          this.selectedObject.quaternion.copy(this.selectedObject.quaternion);
          // Update the corresponding Three.js mesh rotation
          const meshIndex = this.bodies.indexOf(this.selectedObject);
          if (meshIndex !== -1 && this.meshes[meshIndex]) {
            this.meshes[meshIndex].quaternion.copy(this.selectedObject.quaternion);
          }
        }
      });

      this.selectedObject.type = CANNON.Body.STATIC;
    }
  }

  updateMousePosition(x, y) {
    this.mouse.x = (x / window.innerWidth) * 2 - 1;
    this.mouse.y = -(y / window.innerHeight) * 2 + 1;
  }

  checkHover(camera) {
    this.raycaster.setFromCamera(this.mouse, camera);

    const intersects = this.raycaster.intersectObjects(this.meshes);

    if (intersects.length > 0) {
      const newHoveredMesh = intersects[0].object;
      if (this.hoveredMesh !== newHoveredMesh) {
        this.unhoverObject();
        this.hoverObject(newHoveredMesh);
      }
    } else {
      this.unhoverObject();
    }
  }

  hoverObject(mesh) {
    if (mesh && this.originalMaterials.has(mesh)) {
      this.hoveredMesh = mesh;
      const material = mesh.material.clone();
      material.emissive = new THREE.Color(0x333333);
      material.emissiveIntensity = 3.5;
      mesh.material = material;
    }
  }

  unhoverObject() {
    if (this.hoveredMesh && this.originalMaterials.has(this.hoveredMesh)) {
      this.hoveredMesh.material = this.originalMaterials.get(this.hoveredMesh).clone();
      this.hoveredMesh = null;
    }
  }

  getBodyAtPosition(pos) {
    const bodies = this.world.bodies;
    for (let i = 0; i < bodies.length; i++) {
      const body = bodies[i];
      if (body.type === CANNON.Body.DYNAMIC) {
        const distance = body.position.distanceTo(pos);
        if (distance < 1) { // Adjust this value based on your scene scale
          return i;
        }
      }
    }
    return -1;
  }

  applyBoundaryConstraints(body) {
    const velocity = body.velocity;
    const position = body.position;

    if (position.x < this.boundary.min.x) {
      position.x = this.boundary.min.x;
      velocity.x = Math.abs(velocity.x) * this.boundaryRestitution;
    } else if (position.x > this.boundary.max.x) {
      position.x = this.boundary.max.x;
      velocity.x = -Math.abs(velocity.x) * this.boundaryRestitution;
    }

    if (position.y < this.boundary.min.y) {
      position.y = this.boundary.min.y;
      velocity.y = Math.abs(velocity.y) * this.boundaryRestitution;
    } else if (position.y > this.boundary.max.y) {
      position.y = this.boundary.max.y;
      velocity.y = -Math.abs(velocity.y) * this.boundaryRestitution;
    }

    if (position.z < this.boundary.min.z) {
      position.z = this.boundary.min.z;
      velocity.z = Math.abs(velocity.z) * this.boundaryRestitution;
    } else if (position.z > this.boundary.max.z) {
      position.z = this.boundary.max.z;
      velocity.z = -Math.abs(velocity.z) * this.boundaryRestitution;
    }

    body.position.copy(position);
    body.velocity.copy(velocity);
  }

  update(dt, camera) {
    if (this.dragConstraint) {
      this.dragConstraint.applyForce();
    }
    this.world.step(dt);

    for (let i = 0; i < this.bodies.length; i++) {
      this.applyBoundaryConstraints(this.bodies[i]);
      this.meshes[i].position.copy(this.bodies[i].position);
      this.meshes[i].quaternion.copy(this.bodies[i].quaternion);
    }

    this.checkHover(camera);
  }

  detectDevice() {
    if (navigator.userAgent.match(/Android/i)
      || navigator.userAgent.match(/webOS/i)
      || navigator.userAgent.match(/iPhone/i)
      || navigator.userAgent.match(/iPad/i)
      || navigator.userAgent.match(/iPod/i)
      || navigator.userAgent.match(/BlackBerry/i)
      || navigator.userAgent.match(/Windows Phone/i)
    ) {
      return 'phone';
    } else {
      return 'computer';
    }
  }

  updateBoundarySize() {
    const deviceType = this.detectDevice();
    const aspectRatio = window.innerWidth / window.innerHeight;
    const newScaleX = deviceType === 'phone' ? aspectRatio * 5 : aspectRatio * 7; // Adjust these values as needed
    const newScaleZ = deviceType === 'phone' ? 1.0/aspectRatio * 4.5 : aspectRatio * 7; // Adjust these values as needed

    this.boundary.min.x = -newScaleX;
    this.boundary.max.x = newScaleX;
    this.boundary.min.z = -newScaleZ;
    this.boundary.max.z = newScaleZ;
    // Update the boundary mesh if it exists
    if (this.boundaryMesh) {
      this.boundaryMesh.scale.setX(newScaleX);
      this.boundaryMesh.scale.setZ(newScaleZ);
    }
  }
}

export default PhysicsSystem;