import * as THREE from "three";

const ImageMotionShader = {
  uniforms: {
    time: { value: 0 },
    imageTex: { value: null }, 
    waveHeight: { value: 0.1 },
    waveFrequency: { value: 2.0 },
    waveSpeed: { value: 1.0 },
    touchTexture: { value: null },
    resolution: { value: new THREE.Vector2() },
    transitionProgress: { value: 0 },
    maxPixelSize: { value: 40 }, // Maximum pixel size for the most pixelated state
    minPixelSize: { value: 1 },  // Minimum pixel size for the clearest state
    bottomFadeStart: { value: 0.05 }, 
    bottomCutStart: { value: 0.05 }, 
    hoverIntensity: { value: 0.35 }, 

  },
  vertexShader: `
    uniform float hoverIntensity;
    uniform float time;
    uniform float waveHeight;
    uniform float waveFrequency;
    uniform float waveSpeed;
    uniform sampler2D touchTexture;
    uniform vec2 resolution;
    varying vec2 vUv;
    varying vec4 vPosition;

    void main() {
      vUv = uv;
      vec3 pos = position;

      // Calculate screen space UV coordinates
      vec4 worldPos = modelViewMatrix * vec4(pos, 1.0);
      vec4 projectedPos = projectionMatrix * worldPos;
      vec2 screenUV = (projectedPos.xy / projectedPos.w + 1.0) * 0.5;
      
      // Sample touch texture using screen space coordinates
      vec4 touchColor = texture2D(touchTexture, screenUV);

      // Apply wave effect
      float angle = (pos.x + pos.y) * waveFrequency + time * waveSpeed;
      pos.z += sin(angle) * waveHeight;

      // Convert touch effect to world space
      vec4 touchOffset = vec4(touchColor.rgb * 2.0 - 1.0, 0.0); // Convert [0,1] to [-1,1] range
      touchOffset = inverse(projectionMatrix) * touchOffset;
      touchOffset = inverse(modelViewMatrix) * touchOffset;
      
      // Apply touch effect in world space
      pos -= touchOffset.xyz * touchColor.r * hoverIntensity; // Adjust multiplier for desired effect strength

      vPosition = projectionMatrix * modelViewMatrix * vec4(pos, 1.0);
      gl_Position = vPosition;
    }
  `,
  fragmentShader: `
    uniform float time;
    uniform sampler2D imageTex;
    uniform sampler2D touchTexture;
    uniform vec2 resolution;
    uniform float transitionProgress;
    uniform float maxPixelSize;
    uniform float minPixelSize;
    uniform float bottomFadeStart;
    uniform float bottomCutStart;
    uniform float waveHeight;
    uniform float waveFrequency;
    uniform float waveSpeed;
    varying vec2 vUv;
    varying vec4 vPosition;

    void main() {
      vec3 pos = vPosition.xyz;

      float angle = (pos.x + pos.y) * waveFrequency/4.0 + time * waveSpeed;

      // Calculate current pixel size based on transition progress
      float currentPixelSize = mix(maxPixelSize, minPixelSize, transitionProgress);

      // Pixelate the UV coordinates
      vec2 pixelatedUV = floor(vUv * resolution / currentPixelSize) * currentPixelSize / resolution;
      vec4 color = texture2D(imageTex, pixelatedUV);
      
      // Convert clip space to screen space UV
      vec2 screenUV = (vPosition.xy / vPosition.w + 1.0) * 0.5;
      
      vec4 touchColor = texture2D(touchTexture, screenUV);
      
      float currentPixelSizeHover = mix(maxPixelSize, minPixelSize,clamp(1.0 - touchColor.r*2.0,0.0,1.0));
      // Pixelate the UV coordinates
      vec2 pixelatedUVHover = floor(vUv * resolution / currentPixelSizeHover) * currentPixelSizeHover / resolution;
      vec4 colorHover = texture2D(imageTex, pixelatedUVHover) ;
      
      // Modify the color based on the touch texture
      color.rgb *= (1.0 + touchColor.r * 1.5 + (sin(angle) * waveHeight));
      
      // Apply transition effect
      float transitionY = 1.0 - vUv.y; // Invert Y for top-to-bottom effect
      float fadeEdge = 0.1; // Adjust for desired fade edge softness
      float alpha = smoothstep(1.0 -transitionProgress*1.1+0.1 - fadeEdge, 1.0-transitionProgress*1.1+0.1 + fadeEdge, transitionY);      
      // Apply bottom fade
      float bottomFade = smoothstep(bottomCutStart, bottomFadeStart+bottomCutStart, screenUV.y);
      alpha *= bottomFade;
      
      gl_FragColor = vec4(color.rgb * (1.0 - touchColor.r) + colorHover.rgb*touchColor.r, color.a * alpha);
    }
  `,
};

export default ImageMotionShader;