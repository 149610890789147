import * as THREE from "three";

const AtmosphericScreenSpaceSkyShader = {
    name: 'AtmosphericScreenSpaceSkyShader',
    uniforms: {
        time: { value: 0.0 },
        topColor: { value: new THREE.Color(0x0077ff) },
        bottomColor: { value: new THREE.Color(0xffffff) },
        sunColor: { value: new THREE.Color(0xffff80) },
        sunPosition: { value: new THREE.Vector3(0.0, 1.0, 0.0) },
        sunSize: { value: 0.3 },
        sunIntensity: { value: 1.0 },
        skyTexture: { value: null },
    },

    vertexShader: `
        varying vec3 vWorldPosition;
        varying vec2 vUv;

        void main() {
            vec4 worldPosition = modelMatrix * vec4(position, 1.0);
            vWorldPosition = worldPosition.xyz;
            vUv = uv;
            gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
        }
    `,

    fragmentShader: `
        uniform float time;
        uniform vec3 topColor;
        uniform vec3 bottomColor;
        uniform vec3 sunColor;
        uniform vec3 sunPosition;
        uniform float sunSize;
        uniform float sunIntensity;
        uniform sampler2D skyTexture;

        varying vec3 vWorldPosition;
        varying vec2 vUv;

        #define PI 3.14159265359

        float sdSphere(vec3 p, float r) {
            return length(p) - r;
        }

        vec3 getSkyColor(vec3 worldPos) {
            float atmosphere = sqrt(1.0 - clamp(worldPos.y, 0.0, 1.0));
            return mix(topColor, bottomColor, atmosphere);
        }

        void main() {
            vec3 viewDir = normalize(vWorldPosition - cameraPosition);
            vec3 skyColor = getSkyColor(viewDir);

            // Calculate sun
            vec3 sunDir = normalize(sunPosition);
            float sunDot = dot(viewDir, sunDir);
            float sunDist = acos(sunDot) / PI;
            
            // More gradual and realistic sun
            float sunCore = 1.0 - smoothstep(0.0, sunSize * 0.5, sunDist);
            float sunCorona = 1.0 - smoothstep(sunSize * 0.5, sunSize * 2.0, sunDist);
            float sunGlow = 1.0 - smoothstep(sunSize * 2.0, sunSize * 5.0, sunDist);
            
            vec3 sunContribution = sunColor * (
                sunCore * sunIntensity * 2.0 +
                sunCorona * sunIntensity * 0.8 +
                sunGlow * sunIntensity * 0.3
            );

            // Combine sky and sun
            vec3 finalColor = skyColor + sunContribution;

            // Add some simple noise for a more natural look
            float noise = fract(sin(dot(viewDir.xy, vec2(12.9898, 78.233))) * 43758.5453);
            finalColor += vec3(noise * 0.02);

            // Multiply with the sky texture
            vec4 textureColor = texture2D(skyTexture, vUv);
            finalColor *= textureColor.rgb;

            gl_FragColor = vec4(finalColor, 1.0);
        }
    `
};

export { AtmosphericScreenSpaceSkyShader };
