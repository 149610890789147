import * as THREE from "three";

const AtmosphericBackdropShader = {
    name: 'AtmosphericBackdropShader',
    uniforms: {
        time: { value: 0.0 },
        topColor: { value: new THREE.Color(0xffffff) }, // Light sky blue
        bottomColor: { value: new THREE.Color(0xffffff) }, // Soft orange at horizon
        sunColor: { value: new THREE.Color(0xde9e62) }, // Sun color
        sunPosition: { value: new THREE.Vector3(10.0, 100.0, 100.0) },
        sunIntensity: { value: 1.25 },
        specularColor: { value: new THREE.Color(0xffffff) }, // White specular highlight
        shininess: { value: 90.0 } // Controls the size of the specular highlight
    },

    vertexShader: `
        varying vec3 vWorldPosition;
        varying vec3 vNormal;
        varying vec3 vViewPosition;
        varying float vVertexColor;

        void main() {
            vec4 worldPosition = modelMatrix * vec4(position, 1.0);
            vWorldPosition = worldPosition.xyz;
            vNormal = normalize(normalMatrix * normal);
            
            vec4 mvPosition = modelViewMatrix * vec4(position, 1.0);
            vViewPosition = -mvPosition.xyz;

            // Pass the vertex color to the fragment shader
            vVertexColor = color.r;

            gl_Position = projectionMatrix * mvPosition;
        }
    `,

    fragmentShader: `
        uniform float time;
        uniform vec3 topColor;
        uniform vec3 bottomColor;
        uniform vec3 sunColor;
        uniform vec3 sunPosition;
        uniform float sunIntensity;
        uniform vec3 specularColor;
        uniform float shininess;

        varying vec3 vWorldPosition;
        varying vec3 vNormal;
        varying vec3 vViewPosition;
        varying float vVertexColor;

        void main() {
            float heightFactor = (normalize(vWorldPosition).y);

            // Sky color gradient
            vec3 skyColor = mix(bottomColor, topColor, smoothstep(0.0, 1.0, heightFactor));

            // Sun effect
            vec3 toSun = normalize(sunPosition - vWorldPosition);
            float sunAmount = max(dot(normalize(vWorldPosition), toSun), 0.0) ;
            vec3 sunEffect = sunColor * pow(sunAmount, sunIntensity);

            // Final color with sun effect
            vec3 finalColor = skyColor + sunEffect;

            // Apply shading through normals
            float shading = max(dot(vNormal, normalize(sunPosition)), 0.0);
            finalColor *= shading;

            // Add specular highlight towards the camera
            vec3 viewDir = normalize(vViewPosition);
            vec3 reflectDir = reflect(-viewDir, vNormal);
            float specularStrength = pow(max(dot(reflectDir, toSun), 0.0), shininess);
            vec3 specular = specularColor * specularStrength * 99.0;

            finalColor += specular;

            // Apply vertex color
            finalColor *= vVertexColor;

            gl_FragColor = vec4(finalColor, 1.0);
        }
    `
};

export { AtmosphericBackdropShader };
