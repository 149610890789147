import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

//store all pages
import MainPage from './Pages/Lander/MainPage';
import IslandPage from './Pages/Island/IslandPage';
import InteriorPage from './Pages/Interior/InteriorPage';
import MovingFramesPage from './Pages/MovingFrames/MovingFramesPage';
import Documentation from './Pages/Lander/Documentation';
import MiniGamePage from './Pages/Misc/MiniGamePage';
import StormTheRoofPage from './Pages/StormTheRoof/StormTheRoofPage';
import BadBloodPage from './Pages/BadBlood/BadBloodPage';
import RevokePage from './Pages/Revoke/RevokePage';
import LinkProfile from './Pages/Lander/LinkProfile';
import ArtCodePage from './Pages/ArtCode/ArtCodePage';
import PointCloudPage from './Pages/PointCloud/PointCloudPage';
import VoyagePage from './Pages/Voyage/VoyagePage';
import ProjectsPage from './Pages/Projects/ProjectsPage';


const App: React.FC = () => {
  return (
    <div className="main">
      <BrowserRouter>
        <Switch>
          <Route path="/" exact component={ArtCodePage} />

          {/*main website links */}
          <Route path="/revoke" exact component={RevokePage} />
          <Route path="/lab" exact component={VoyagePage} />
          <Route path="/projects" exact component={ProjectsPage} />

          {/*voyage website links */}
          <Route path="/lab/storm-the-roof" exact component={StormTheRoofPage} />
          <Route path="/lab/bad-blood" exact component={BadBloodPage} />
          <Route path="/lab/point-cloud" exact component={PointCloudPage} />
          <Route path="/lab/moving-frames" exact component={MovingFramesPage} />
          <Route path="/lab/interior" exact component={InteriorPage} />

          {/*misc links */}
          <Route path="/doc" exact component={Documentation} />
          <Route path="/interior" exact component={InteriorPage} />

          <Route path="/minigame" exact component={MiniGamePage} />
          <Route path="/theon" exact component={LinkProfile} />
          <Route path="/Main" exact component={MainPage} />
        </Switch>
      </BrowserRouter>
    </div>
  );
};

export default App;
