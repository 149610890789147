import React, { useState, useRef, useEffect } from 'react';
import { AppBar, Button, Grid, Slide, ThemeProvider, createTheme, useScrollTrigger, Box, Modal, Typography } from '@mui/material';
import { useScroll } from 'framer-motion';
import gsap from 'gsap';

import '../Home.css';
import Bottombar from '../../components/BottomBar';
import LoadingManager from '../../Render/LoadingManager';
import BadBlood from '../../components/Scenes/BadBlood/BadBlood';
import Overlay from '../Misc/Overlay';

import ModeStandbyIcon from '@mui/icons-material/ModeStandby';


import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import CircleIcon from '@mui/icons-material/Circle';
const HideOnScroll = ({ children }: any) => {
  const trigger = useScrollTrigger();
  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
};

export enum ContentPanel {
  CONTENT_STAGE_HIDDEN,
  CONTENT_STAGE_PART_1,
  CONTENT_STAGE_PART_2,
  CONTENT_STAGE_PART_3
}

const BadBloodPage: React.FC = () => {
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const iconRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleMouseMove = (event: MouseEvent) => {
      setMousePosition({ x: event.clientX, y: event.clientY });
    };

    window.addEventListener('mousemove', handleMouseMove);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);
  useEffect(() => {
    if (iconRef.current) {
      const iconWidth = iconRef.current.clientWidth;
      const iconHeight = iconRef.current.clientHeight;
      const offsetX = iconWidth / 2;
      const offsetY = iconHeight / 2;

      gsap.to(iconRef.current, {
        x: mousePosition.x - offsetX,
        y: mousePosition.y - offsetY,
        duration: 0.1,
        ease: 'power3.out',
      });
    }
  }, [mousePosition]);
  const [contentValue, setContentValue] = useState<ContentPanel>(ContentPanel.CONTENT_STAGE_HIDDEN);
  const [infoValue, setInfoValue] = useState<ContentPanel>(ContentPanel.CONTENT_STAGE_HIDDEN);
  const [buttonInfo, setButtonInfo] = useState<string>('main');
  const [showOverlay, setShowOverlay] = useState(false);
  const [showButtons, setShowButtons] = useState(false);

  const [playerHealth, setPlayerHealth] = useState<number>(100);
  const [ammoCount, setAmmoCount] = useState<number>(8);
  const [showUpgradeMenu, setShowUpgradeMenu] = useState(false);

  const badBloodRef = useRef<any>(null);

  const theme = createTheme({
    palette: {
      primary: {
        main: '#FFFFFF',
        contrastText: '#000000',
      },
      secondary: {
        main: '#000000'
      },
    }
  });


  const handleUpgradeSelection = (upgrade: string) => {
    if (badBloodRef.current) {
      badBloodRef.current.handleUpgradeSelection(upgrade);
    }
    setShowUpgradeMenu(false);
  };
  const handleUpgradeChoice = (upgrade: string) => {
    setShowUpgradeMenu(false);
    // Apply upgrade logic
    if (badBloodRef.current) {
      badBloodRef.current.upgradeManager.applyUpgrade(upgrade); // Hypothetical method to apply the upgrade
      badBloodRef.current.waveManager.startWave(); // Start the next wave
    }
  };

  const handleNextWave = () => {
    setShowUpgradeMenu(false);
    if (badBloodRef.current) {
      badBloodRef.current.waveManager.startWave(); // Start the next wave directly
    }
  };

  useEffect(() => {
    if (badBloodRef.current) {
      badBloodRef.current.setButtonVisibilityCallback(() => {
        setShowButtons(true);
        const buttons = document.querySelectorAll('.action-button');
        buttons.forEach((button, index) => {
          gsap.fromTo(button, { scale: 0 }, { scale: 1, duration: 0.5, delay: index * 0.1 });
        });
      });

      const interval = setInterval(() => {
        if (badBloodRef.current) {
          setPlayerHealth(badBloodRef.current.playerHealth);
          setAmmoCount(badBloodRef.current.ammoCount);
        }
      }, 100);

      return () => clearInterval(interval);
    }
  }, []);

  const handleReload = () => {
    if (badBloodRef.current) {
      badBloodRef.current.reloadAmmo();
    }
  };

  const OpenContent = (content: ContentPanel) => {
    setContentValue(content);
  };

  const CloseContent = () => {
    setInfoValue(ContentPanel.CONTENT_STAGE_HIDDEN);
    OpenContent(ContentPanel.CONTENT_STAGE_HIDDEN);
  };

  const toggleOverlay = () => {
    setShowOverlay(!showOverlay);
  };

  const { scrollYProgress } = useScroll();

  const handleButtonClick = (action: string) => {
    if (badBloodRef.current && badBloodRef.current[action]) {
      badBloodRef.current[action]();
    }
  };

  return (
    <>
      <LoadingManager />
      <div className="pageGlobal">
        <Grid container style={{ position: 'fixed', zIndex: -1 }}>
        <Box ref={iconRef} sx={{ position: 'fixed', pointerEvents: 'none', zIndex: 9999 }}>
        <ModeStandbyIcon sx={{ fontSize: 20, color: '#ffffff66' }} />
      </Box>
        <BadBlood ref={badBloodRef} />
        
        </Grid>
        <Box
      sx={{
        position: 'fixed',
        top: '15%',
        left: '10%',
        zIndex: 2,
        backgroundColor: 'rgba(1, 1, 1, 0.00)',
        padding: '8px 16px',
        borderRadius: '8px',
     
      }}
    >

<Button
        onClick={() => {
          window.location.href = '/lab';
        }}
        sx={{
          color: '#000000',
          backgroundColor: '#ffffff',
          padding: '8px',
          minWidth: '40px',
          width: '20',
          height: '20',
          fontSize: '0.5rem',
          fontFamily: 'roboto-mono',
          '&:hover': {
            backgroundColor: '#f0f0f0',
          },
        }}
      >
        <KeyboardReturnIcon sx={{ color: '#000000', fontSize: '1.0rem' }} />
      </Button>
    </Box>
    <Box
      sx={{
        position: 'fixed',
        top: '15%',
        right: '10%',
        zIndex: 2,
        backgroundColor: 'rgba(1, 1, 1, 0.5)',
        padding: '8px 16px',
        borderRadius: '8px',
      }}
    >
      <Typography
        variant="h4"
        sx={{
          color: '#ffffff',
          display: 'flex',
          alignItems: 'center',
          fontSize: '0.75rem',
          fontWeight: '100',
          fontFamily: 'roboto-mono',
          textAlign: 'right',
        }}
      >
        PERSISTENT PAINT SYSTEM
        <CircleIcon
          sx={{
            width: 20,
            height: 20,
            marginLeft: 1,
            color:'#caf700'
          }}
        />
      </Typography>
    </Box>
        <HideOnScroll>
        <AppBar position={'fixed'} sx={{ boxShadow: 'none', backgroundColor: '#00000000' }}>
          
          </AppBar>
        </HideOnScroll>

        <AppBar position={'fixed'} sx={{ top: 'auto', bottom: 0, boxShadow: 'none', backgroundColor: '#00000000' }}>
          <Bottombar />
        </AppBar>

        <Overlay show={showOverlay} />

        <Grid container item direction="column" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%', overflowY: 'scroll' }}>
   
          {infoValue === ContentPanel.CONTENT_STAGE_HIDDEN && contentValue !== ContentPanel.CONTENT_STAGE_HIDDEN &&
            <Button
              variant='contained'
              onClick={() => {
                setInfoValue(contentValue);
                setContentValue(ContentPanel.CONTENT_STAGE_HIDDEN);
              }}
              sx={{
                position: 'fixed',
                justifyContent: 'center',
                alignItems: 'center',
                bottom: 100,
                mb: '30px',
                height: '40px',
                fontFamily: 'DMSans',
                fontSize: '1.0rem',
                fontWeight: '100',
                color: '#000000',
                backgroundColor: '#ffffff',
                borderRadius: '30px',
                boxShadow: 'none',
              }}>
              Click to see {buttonInfo}
            </Button>
          }

          <Box component="footer" sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, textAlign: 'center', padding: 2 }}>
       

          </Box>
        </Grid>

        {showButtons && (
          <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2} sx={{ position: 'fixed', top: '90%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 1 }}>
            {/* Action buttons */}
          </Grid>
        )}

       
      </div>
    </>
  );
};

export default BadBloodPage;
