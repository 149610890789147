
import * as THREE from 'three'
import LoadingManager from "./LoadingManager";

/******************************************************************************/
/*!
\brief  Store/load model data
*/
/******************************************************************************/
export default class Model {
  /******************************************************************************/
  /*!
  \brief  constructor
  */
  /******************************************************************************/
  constructor(_options) {
    //set variables
    this.pos = _options.position;
    this.rot = _options.rotation;
    this.modelLink = _options.modelLink;
    this.matLink = _options.matLink;
    this.material = _options.material;
    this.scene = _options.scene;
    this.scale = _options.scale;
    this.name = _options.name;

    //Add loader
    this.loadingManager = new LoadingManager();
    this.loader = this.loadingManager.loader;
    this.all_model = [];
    this.all_model_og_position = [];
    this.loadModel();
  }
  /******************************************************************************/
  /*!
  \brief  load model 
  */
  /******************************************************************************/
  async loadModel() {

    // render given model
    await this.loader.findAsset(this.modelLink, (mesh) => {
      for (var i = 0; i < mesh.scene.children.length; ++i) {
        mesh.scene.children[i].position.add (this.pos);
        this.all_model.push(mesh.scene.children[i]);
        this.all_model_og_position.push(mesh.scene.children[i].position);
      }
      this.setMaterial();
    });
    for (var i = 0; i < this.all_model.length; ++i) {

     
      this.scene.add(this.all_model[i]);
    }
  }


  /******************************************************************************/
  /*!
  \brief  set model material
  */
  /******************************************************************************/
  setMaterial() {
    for (var i = 0; i < this.all_model.length; ++i) {
      this.all_model[i].traverse((_child) => {
        if (_child instanceof THREE.Mesh) {
          //_child.material = this.material.getMaterial();
          _child.material = this.material;
          _child.receiveShadow = true;
          _child.castShadow = true;
        }
      })
    }
  }

  updateUniform(uniform, value) {
    this.material.uniforms[uniform].value = value;
    this.mesh.traverse((_child) => {
      if (_child instanceof THREE.Mesh) {
        _child.material.uniforms[uniform].value = value;
        _child.material.uniformsNeedUpdate = true;
      }
    })

  }
  /******************************************************************************/
  /*!
  \brief  set model child position
  */
  /******************************************************************************/
  setPosition(n, x, y, z) {
    console.log("tel:", this.mainModel);
    this.all_model[n].position.setX(x);
    this.all_model[n].position.setY(y);
    this.all_model[n].position.setZ(z);
  }
  /******************************************************************************/
  /*!
  \brief  set model position
  */
  /******************************************************************************/
  setPosition(x, y, z) {
    console.log("tel:", this.mesh);
    this.mesh.position.setX(x);
    this.mesh.position.setY(y);
    this.mesh.position.setZ(z);
  }
  /******************************************************************************/
  /*!
  \brief  set model scale
  */
  /******************************************************************************/
  setScale(scale) {
    this.mesh.scale.set(scale);
  }
  setRotate(rot) {
    this.mesh.rotation.set(rot);
  }
}
