import * as THREE from "three";

const LogoShader = {
  uniforms: {
    time: { value: 0 },
    waveHeight: { value: 0.1 },
    waveFrequency: { value: 2.0 },
    waveSpeed: { value: 1.0 },
    lightDirection: { value: new THREE.Vector3(1, 0, 1).normalize() },
  },
  vertexShader: `
    uniform float time;
    uniform float waveHeight;
    uniform float waveFrequency;
    uniform float waveSpeed;
    varying vec2 vUv;
    varying vec3 vNormal;
    varying vec3 vPosition;

    void main() {
      vUv = uv;
      vec3 pos = position;
      float waveIntensity = 0.0;

      float angle = (pos.x + pos.z + pos.y/2.0) * waveFrequency + time * waveSpeed;
      //pos.x += sin(angle) * waveHeight * waveIntensity;
      
      // Calculate normal
      vNormal = normalMatrix * normal;
      vPosition = pos;
      gl_Position = projectionMatrix * modelViewMatrix * vec4(pos, 1.0);
    }
  `,
  fragmentShader: `
    uniform float time;
    uniform float waveHeight;
    uniform float waveFrequency;
    uniform float waveSpeed;
    uniform vec3 lightDirection;
    varying vec2 vUv;
    varying vec3 vNormal;
    varying vec3 vPosition;
    void main() {

    vec3 pos = vPosition;
    float waveIntensity = 2.0;
      float angle = (pos.x + pos.z + pos.y/2.0) * waveFrequency + time * waveSpeed;
      float shine = clamp(0.5 + sin(angle) * waveHeight * waveIntensity, -2.0, 2.0);
      vec4 texColor = vec4(0.3,0.4,1,1);
      
      // Calculate lighting
      float lightIntensity = max(dot(normalize(vNormal), lightDirection), 0.0);
      vec3 litColor = texColor.rgb * ( lightIntensity *2.65) + vec3(shine,shine,shine);

      gl_FragColor = vec4(litColor, 1.0);
    }
  `,
};

export default LogoShader;