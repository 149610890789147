import * as THREE from "three";
import { MathUtils, Vector2, Vector3 } from "three";
import React, { Component } from 'react';

import LoadingManager from "../../../Render/LoadingManager";
import Scene from "../Base/Scene";
import ParticleEmitter from './Particles/ParticleEmitter';
import OrbitalCameraGame from './Camera/OrbitalCameraGame';
import gsap from "gsap";
import AudioManager from './Audio/AudioManager';

import Time from "./Misc/Time";
import Lighting from "./Environment/Lighting";
import Environment from "./Environment/Environment";
import Sky from "./Environment/Sky";
import UpgradeManager from "./Manager/UpgradeManager";
import EnemyManager from "./Manager/EnemyManager";
import Health from "./Gameplay/Health";
import Score from "./Gameplay/Score";
import WaveManager from "./Manager/WaveManager";
import BulletCounter from "./FX/BulletCounter";
import detectDevice from "./Misc/Device";
import Weapon from "./Gameplay/Weapon";
import EventHandler from "./Misc/Event";

class StormTheRoof extends Scene {
  constructor(_options) {
    super(_options);
    this.setupClickHandlers();

    this.buttonVisibilityCallback = null;
    this.noShoot = false;
    this.boot = false;
    this.fullLoaded = false;
    this.canMoveCamera = false;

    this.waveManager = new WaveManager(this);

    // Add event listeners
    this.startRender();
    this.initialize();
  }

  initialize() {
    this.setLoader();
  }

  setLoader() {
    this.requiredAssets = [
      { name: 'paradeModel', source: '/assets/parade.glb' },
      { name: 'paradeTexture', source: '/assets/parade_render.jpg', type: 'texture' },
      { name: 'parachuteModel', source: '/assets/parachute.glb' },
      { name: 'parachuteTexture', source: '/assets/parade_render.jpg', type: 'texture' },
      { name: 'helicopterModel', source: '/assets/helicopter.glb' },
      { name: 'helicopterBladeModel', source: '/assets/helicopter_blade.glb' },
      { name: 'jetModel', source: '/assets/jet.glb' },
      { name: 'platformModel', source: '/assets/platform.glb' },
      { name: 'logoModel', source: '/assets/logo.glb' },
      { name: 'paradeBackgroundModel', source: '/assets/parade_background.glb' },
      { name: 'stormTheRoofModel', source: '/assets/storm_the_roof_1.glb' },
      { name: 'stormTheRoofTexture', source: '/assets/storm_the_roof.jpg', type: 'texture' },
      { name: 'muzzleTexture', source: '/assets/muzzle_flash.png', type: 'texture' },
      { name: 'bulletFullModel', source: '/assets/bullet_full.glb' },
      { name: 'bulletEmptyModel', source: '/assets/bullet_empty.glb' },
      { name: 'bombModel', source: '/assets/bomb.glb' },
      { name: 'backdropModel', source: '/assets/backdrop.glb' },
    ];

    this.loadedAssets = 0;
    this.totalAssets = this.requiredAssets.length;

    this.loadingManager = new LoadingManager();
    this.loader = this.loadingManager.loader;
    this.initializeLoading();
  }

  initializeLoading() {
    // Set up the asset group
    this.loader.groups.assets = [{
      name: 'paradeAssets',
      items: this.requiredAssets
    }];

    // Set up event listener
    this.loader.on('fileEnd', this.onFileLoaded.bind(this));

    // Start loading
    this.loader.loadNextGroup();
  }

  onFileLoaded(_resource, _data) {
    this.loadedAssets++;

    // Check if all assets are loaded
    if (this.loadedAssets === this.totalAssets) {
      this.onAllAssetsLoaded();
    }
  }

  async onAllAssetsLoaded() {
    this.setEnvironment();
    this.setSky();
    this.setTime();
    this.setLighting();
    this.setGameCamera();

    this.setScore();
    this.setHealth();
    this.setEventHandler();
    this.setBulletCounter();
    this.setBloodEmitter();
    this.setUpgradeManager();

    this.updatePostProcessingSettings({
      bloomIntensity: 0.2,
      focusDistance: 150,
      bokehAperture: 0.00005,
      maxBlur: 0.01,
      bokehScale: 10.0
    });

    this.setWeapon();
    this.setEnemyManager();
    this.initialCameraPosition = this.newCamera.threeCamera.position.clone();

    // Start the introduction sequence
    this.startIntroSequence();
  }

  startIntroSequence() {
    this.newCamera.threeCamera.lookAt(this.environment.logoModel.all_model[0].position); // Look at the logo mesh
    const logoSize = detectDevice() == 'computer' ? 3 : 1;
    gsap.to(this.environment.logoModel.all_model[0].scale, {
      duration: 1,
      ease: "elastic.inOut",
      x: logoSize,
      y: logoSize,
      z: logoSize,
      onComplete: () => {
        gsap.to(this.environment.logoModel.all_model[0].scale, {
          duration: 1,
          ease: "elastic.inOut",
          x: 0,
          y: 0,
          z: 0,
          delay: 0.5,
          onComplete: () => {
            this.scene.remove(this.environment.logoModel.all_model[0]);
            this.startParadeModelAnimation();
          }
        });
      }
    });
  }

  startParadeModelAnimation() {
    this.lighting.ambient.intensity = 0.65;
    this.lighting.dirLight.intensity = 3;
    gsap.to(this.environment.p1model.all_model[0].position, {
      duration: 1.1,
      ease: "power4.inOut",
      x: 0,
      y: 0,
      z: 0,
      onComplete: () => {
        this.bulletCounter.createBulletCounter(); // Initialize the bullet counter
        this.waveManager.startWave();

        if (this.buttonVisibilityCallback) {
          this.buttonVisibilityCallback();
        }
        this.canMoveCamera = true;
      }
    });
  }

  setButtonVisibilityCallback(callback) {
    this.buttonVisibilityCallback = callback;
  }

  updatePostProcessingSettings({ bloomIntensity, bokehAperture, focusDistance, maxBlur, bokehScale }) {
    if (this.postProcess) {
      if (bloomIntensity !== undefined && this.postProcess.bloomPass) {
        this.postProcess.bloomPass.strength = bloomIntensity;
      }

      if (this.postProcess.bokehPass && this.postProcess.bokehPass.uniforms) {
        if (bokehAperture !== undefined && this.postProcess.bokehPass.uniforms['aperture']) {
          this.postProcess.bokehPass.uniforms['aperture'].value = bokehAperture;
        }
        if (focusDistance !== undefined && this.postProcess.bokehPass.uniforms['focus']) {
          this.postProcess.bokehPass.uniforms['focus'].value = focusDistance;
        }
        if (maxBlur !== undefined && this.postProcess.bokehPass.uniforms['maxblur']) {
          this.postProcess.bokehPass.uniforms['maxblur'].value = maxBlur;
        }
        if (bokehScale !== undefined && this.postProcess.bokehPass.uniforms['focalLength']) {
          this.postProcess.bokehPass.uniforms['focalLength'].value = bokehScale;
        }
      }
    }
  }

  setupClickHandlers() {
    window.addEventListener('click', (event) => {
      if (this.weapon.ammoCount > 0 && !this.weapon.isReloading) {
        this.ammoCount--; // Reduce ammo on each shot
        this.weapon.handleShooting(event); // Handle the shooting logic
      }
    });
  }

  triggerBloodEffect(position, count = 20) {
    if (this.bloodEmitter) {
      this.bloodEmitter.spawn(position, count); // Spawn blood particles at the given position
    } else {
      console.warn("Blood emitter is not initialized.");
    }
  }

  takeDamage(amount) {
    this.playerHealth -= amount;
    if (this.playerHealth <= 0) {
      this.playerHealth = 0;
      // Handle player death logic here
    }

    // Trigger the damage effect
    this.handleDamageEffect();
  }

  setScore() {
    this.score = new Score();
  }

  setHealth() {
    this.health = new Health(100, 100);
  }

  setBloodEmitter() {
    this.bloodEmitter = new ParticleEmitter(this.scene);
  }

  setUpgradeManager() {
    this.upgradeManager = new UpgradeManager(this);
  }

  setTime() {
    this.time = new Time();
  }

  setSky() {
    this.sky = new Sky(this.scene);
  }

  setLighting() {
    this.lighting = new Lighting(this.scene);
  }

  setBulletCounter() {
    this.bulletCounter = new BulletCounter(this, this.scene, this.newCamera);
  }

  setWeapon() {
    this.weapon = new Weapon(this, this.upgradeManager);
  }

  setEnvironment() {
    this.environment = new Environment(this.scene, this.loader);
  }

  setEventHandler() {
    this.eventHandler = new EventHandler(this.scene);
  }

  setEnemyManager() {
    this.enemyManager = new EnemyManager(this, this.scene);
  }

  setGameCamera() {
    this.orbitalCamera = new OrbitalCameraGame(this, this.newCamera.threeCamera);
    this.orbitalCamera.setPosition(30, 12, -30); // Set the camera position to (30, 20, 50)
    this.cameraTarget = new THREE.Vector3(0, 0, 0);
  }

  getScore() {
    return this.score;
  }

  componentDidMount() {
    AudioManager.loadSounds();

    this.SceneMount();
    this.setCamera(40, 40, detectDevice() == 'computer' ? 35 : 60);
    this.setPostProcess();
    AudioManager.playSound('music');
  }

  componentWillUnmount() {
    // Cleanup if necessary
  }

  Update() {
    this.renderer.info.reset();
    if (!this.environment) return;

    this.time.update();
    this.environment.update();
    this.bloodEmitter.update(); // Update the particle emitter every frame
    this.bulletCounter.update();
    this.weapon.update();
    this.sky.update();
    this.waveManager.update();
    this.enemyManager.update();

    // Smoothly apply recoil using lerp
    this.orbitalCamera.update(this.time.getElapsedTime() * 0.0001, this.weapon.recoilOffset);
    this.orbitalCamera.updateMouseNormalized(this.eventHandler.mouseNormalized.x, this.eventHandler.mouseNormalized.y);

    // Force the camera to update its matrix
    this.newCamera.threeCamera.updateMatrixWorld(true);

    // Update draw calls at the end of each frame
    if (this.renderer) {
      this.drawCalls = this.renderer.info.render.calls;
      // Reset the render info to ensure accurate counting for the next frame
      this.renderer.info.autoReset = false;
    }

    this.boot = true;
  }
}

export default React.forwardRef((props, ref) => <StormTheRoof {...props} ref={ref} />);
