import * as THREE from 'three';
import { detectDevice } from './Helper';
class Project3dom {
  constructor(scene, loader) {
    this.scene = scene;
    this.loader = loader;
    this.sections = [];
    this.wallDistance = -5;
    this.deviceType = detectDevice();
  }

  createSections() {
    this.sections = [
      {
        height: 6,
        motionText: {
          text: '3DOM',
          font: '/fonts/Akira Super Bold.ttf',
          size: this.deviceType =='phone'? 0.75: 1.5,
          position: new THREE.Vector3(0, 0, this.wallDistance),
          color: 0xffffff,
          alignment: 'center',
          maxWidth: 12,
          lineHeight: 1.2
        }
      },
      {
        height: 6,
        motionMesh: {
          texture: this.loader.getAsset("whiteTexture"),
          size: new THREE.Vector2(8, 5),
          position: new THREE.Vector3(0, 0, this.wallDistance),
          rotation: new THREE.Euler(Math.PI, 0, 0),
          subdivisions: new THREE.Vector2(64, 64)
        }
      },
      {
        height: 5,
        motionText: {
          text: 'To showcase revolutionary 3DOM batteries technology, we\'ve created a unique 3D website. This interactive platform allows users to explore a virtual island, each area representing different aspects of the company and their technology',
          font: '/fonts/RobotoMono.ttf',
          size: 0.3,
          position: new THREE.Vector3(0, 0, this.wallDistance),
          color: 0xffffff,
          alignment: 'center',
          maxWidth: 10,
          lineHeight: 1.2
        }
      },
      {
        height: 5,
        motionMesh: {
          texture: this.loader.getAsset("whiteTexture"),
          size: new THREE.Vector2(8, 5),
          position: new THREE.Vector3(0, 0, this.wallDistance),
          rotation: new THREE.Euler(Math.PI, 0, 0),
          subdivisions: new THREE.Vector2(64, 64)
        }
      },
      {
        height:5,
        motionText: {
          text: 'Services include \n Creative Coding\n 3D visualization\n Interactive Experiences',
          font: '/fonts/RobotoMono.ttf',
          size: 0.3,
          position: new THREE.Vector3(0, 0, this.wallDistance),
          color: 0xffffff,
          alignment: 'left',
          maxWidth: 10,
          lineHeight: 1.2
        }
      },
      {
        height: 5,
        motionMesh: {
          texture: this.loader.getAsset("whiteTexture"),
          size: new THREE.Vector2(8, 5),
          position: new THREE.Vector3(0, 0, this.wallDistance),
          rotation: new THREE.Euler(Math.PI, 0, 0),
          subdivisions: new THREE.Vector2(64, 64)
        }
      },
    
    ];
  
    return this.sections;
  }
}

export default Project3dom;