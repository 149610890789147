import * as THREE from "three";
import { Text } from 'troika-three-text';
import TextMotionShader from "../../../Shaders/TextMotionShader";
import { gsap } from 'gsap';
import { detectDevice } from './ProjectSections/Helper';
class MotionText {
  constructor(options) {
    this.text = options.text || '';
    this.font = options.font;
    this.size = options.size || 1;
    this.position = options.position || new THREE.Vector3(0, 0, 0);
    this.rotation = options.rotation || new THREE.Euler(0, 0, 0);
    this.color = options.color || 0xffffff;
    this.scene = options.scene;
    this.alignment = options.alignment || 'center';
    this.maxWidth = options.maxWidth || 10;
    this.lineHeight = options.lineHeight || 1.2;
    this.renderOrder = options.renderOrder || 1; // Default to render after MotionMesh

    this.fullText = this.text;
    this.currentTextLength = 0;
    this.isAppearing = false;
    this.isDisappearing = false;
    this.isVisible = false;

    this.group = new THREE.Group();

    // Adjust size based on device type
    this.deviceType =detectDevice();
    this.createText();
  }

  createText() {
    this.group.clear();


    const textMesh = new Text();
    textMesh.text = ''; // Start with empty text
    textMesh.font = this.font;
    textMesh.fontSize = this.deviceType =='phone'?  this.size*1.5: this.size;
    textMesh.color = new THREE.Color(this.color);
    textMesh.anchorX = this.alignment;
    textMesh.anchorY = 'middle';
    textMesh.maxWidth =  this.deviceType =='phone'?  this.maxWidth*0.75:  this.maxWidth;
    textMesh.lineHeight = this.lineHeight;

    textMesh.material = new THREE.ShaderMaterial({
      uniforms: {
        ...TextMotionShader.uniforms,
        color: { value: new THREE.Color(this.color) },
        waveHeight: { value: 0.1 },
        waveFrequency: { value: 1.0 },
        waveSpeed: { value: 0.5 },
        map: { value: null },
        touchTexture: { value: null },
      },
      vertexShader: TextMotionShader.vertexShader,
      fragmentShader: TextMotionShader.fragmentShader,
      side: THREE.DoubleSide,
      transparent: true,
      blending: THREE.AdditiveBlending,
      depthWrite: false,
      depthTest: true,
    });

    textMesh.renderOrder = this.renderOrder;

    textMesh.sync(() => {
      textMesh.material.uniforms.map.value = textMesh.material.map;
    });

    this.group.add(textMesh);
    this.group.position.copy(this.position);
    this.group.rotation.copy(this.rotation);


    if (this.scene) {
      this.scene.add(this.group);
    }
  }

  update(deltaTime) {
    if (this.group.children[0] && this.group.children[0].material) {
      this.group.children[0].material.uniforms.time.value += deltaTime;
    }
  }

  updateTouchTexture(texture) {
    if (this.group.children[0] && this.group.children[0].material) {
      this.group.children[0].material.uniforms.touchTexture.value = texture;
    }
  }

  setWaveProperties(height, frequency, speed) {
    if (this.group.children[0] && this.group.children[0].material) {
      this.group.children[0].material.uniforms.waveHeight.value = height;
      this.group.children[0].material.uniforms.waveFrequency.value = frequency;
      this.group.children[0].material.uniforms.waveSpeed.value = speed;
    }
  }

  setOpacity(opacity) {
    this.opacity = opacity;
    if (this.group.children[0] && this.group.children[0].material) {
      this.group.children[0].material.uniforms.opacity.value = opacity;
    }
  }

  setColor(color) {
    if (this.group.children[0] && this.group.children[0].material) {
      this.group.children[0].material.uniforms.color.value = new THREE.Color(color);
    }
  }

  updateResolution(resolution) {
    if (this.group.children[0] && this.group.children[0].material) {
      this.group.children[0].material.uniforms.resolution.value = resolution;
    }
  }

  show() {
    if (!this.isVisible && !this.isAppearing) {
      this.isVisible = true;
      this.group.visible = true;
      this.group.scale.set(1, 1, 1); // Reset scale
      this.appearText();
    }
  }

  async hide() {
    return;
    if (this.isVisible && !this.isDisappearing) {
      this.isVisible = false;
      await this.disappearText();
      this.group.visible = false;
    }
  }

  appearText() {
    this.isAppearing = true;
    this.currentTextLength = 0;
    this.updateTextContent();

    const appearInterval = setInterval(() => {
      this.currentTextLength++;
      this.updateTextContent();

      if (this.currentTextLength >= this.fullText.length) {
        clearInterval(appearInterval);
        this.isAppearing = false;
      }
    }, 2); // 0.05s for each letter
  }

  disappearText() {
    return new Promise((resolve) => {
      this.isDisappearing = true;
      gsap.to(this.group.scale, {
        x: 0,
        y: 0,
        z: 0,
        duration: 0.2,
        ease: "power2.in",
        onComplete: () => {
          this.isDisappearing = false;
          resolve();
        }
      });
    });
  }

  updateTextContent() {
    if (this.group.children[0] && this.fullText) {
      this.group.children[0].text = this.fullText.slice(0, this.currentTextLength);
    }
  }

  update(deltaTime) {
    if (this.group.children[0] && this.group.children[0].material) {
      this.group.children[0].material.uniforms.time.value += deltaTime;
    }

    if (this.isAppearing) {
      this.updateTextContent();
    }
  }
}

export default MotionText;